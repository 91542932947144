import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import logo from "assets/images/icons/logo.svg";
import { useLocation } from 'react-router-dom';

const AuthLayout = () => {
    const location = useLocation();
    const currentUrl = location.pathname;
    const [ pageLabel, setPageLabel ] = useState("");

    useEffect(() => {
        if (currentUrl.includes("/trustee") || currentUrl.includes("/admin")){
            setPageLabel("Admin");
        }
    }, [])
    

    return (
        <div className="md:flex">
            <div className="w-full p-6 pb-10 md:pb-20 md:w-2/4 md:min-h-screen overflow-hidden bg-vetiva-black bg-auth-pattern">
                <div className="w-full h-full flex">
                    
                    <div className="py-4 m-auto flex space-x-4 items-center">
                        <div className="w-14 h-14 sm:w-20 sm:h-20">
                            <img src={logo} alt="Vetiva" className="w-full h-full object-contain" />
                        </div>
                        <div className="text-vetiva-gold font-verdana">
                            <div className="text-2xl sm:text-3xl font-semibold">
                                Vetiva Trustees Ltd
                            </div>
                            <div className="mt-1 text-sm font-normal">
                                Staff Share Schemes {pageLabel} Portal
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="w-full md:w-2/4 md:flex">
                <div className="w-full p-8 sm:p-14 md:my-auto">
                    <div className="pb-14 lg:pl-4 lg:pr-4">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthLayout
