import { useEffect, useState } from "react";

export interface ToggleSwitchProps {
    label?: string;
    isChecked?: any;
    onCheck: (value: string) => void;
}
export function ToggleSwitch(props: ToggleSwitchProps) {
const { label, isChecked, onCheck } = props;
const [checked, setChecked] = useState(isChecked);

const handleChecked = (e: any) => {
    onCheck(e.target.checked);
}

useEffect(() => {
  setChecked(isChecked);
}, [isChecked])


  return (
    <label className="inline-flex relative items-center cursor-pointer">
      <input type="checkbox" value="" checked={checked} onChange={e => handleChecked(e)} className="sr-only peer" />
      <div className="w-11 h-6 bg-white border border-gray-300
      _peer-focus:outline-none 
      _peer-focus:ring-4 
      _peer-focus:ring-blue-300 
      rounded-full 
      peer 
      peer-checked:after:translate-x-full 
      peer-checked:after:border-white 
      peer-checked:after:bg-white 
      after:content-[''] 
      after:absolute 
      after:top-[2px] 
      after:left-[2px] 
      after:bg-gray-300 
      after:border-gray-300 
      after:border 
      after:rounded-full 
      after:h-5 
      after:w-5 
      after:transition-all 
      peer-checked:bg-vetiva-brown"></div>
      <span className="ml-3 text-sm font-medium text-gray-900">
        {label}
      </span>
    </label>
  );
}

export default ToggleSwitch;
