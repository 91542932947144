import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Input, SelectInput } from "components/ui";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import validate from "utils/validate";
import { toast } from "react-toastify";
import Maybe from "components/Maybe";
import { PageContent } from "components/PageContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLoginInfo from "hooks/useLoginInfo";
import * as employeeAction from "store/entities/employee/action";
import * as stockBrockingFirmAction from "store/ui/stockBrockingFirm/action";

export interface CompanyEmployeeInstructionTransferProps {}

export function CompanyEmployeeInstructionTransfer(props: CompanyEmployeeInstructionTransferProps) {
  const {userInfo} = useLoginInfo();
  let { companyId, employeeId, schemeTypeId } = useParams();
  let employeeDetailsUrl = "/trustee/company/"+companyId+"/employee/"+employeeId;
  let sendInstructionSelectionUrl = "/trustee/company/"+companyId+"/employee/"+employeeId;
  
  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    employeeDetailsUrl = "/company/employees/"+employeeId;
    sendInstructionSelectionUrl = employeeDetailsUrl;
  }
  else if (userInfo.userType === "SCHEME_MEMBER"){
    companyId = userInfo.organizationId;
    employeeId = userInfo.employeeId;
    employeeDetailsUrl = "/member/overview";
    sendInstructionSelectionUrl = "/member";
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { employeeLoading, employee, savedShareTransferInstruction } = useSelector((s: any) => s.entities.employee);
  const { stockBrockingFirmLoading, stockBrockingFirm } = useSelector((s: any) => s.ui.stockBrockingFirm);


  const [transferForm, setTransferForm] = useState({
    clearingHouseNumber: (savedShareTransferInstruction.clearingHouseNumber || ""),
    centralSecurityClearingSystem: (savedShareTransferInstruction.centralSecurityClearingSystem || ""),
    stockBrockingFirm: (savedShareTransferInstruction.stockBrockingFirm || ""),
    otherStockBrokingFirm: (savedShareTransferInstruction.otherStockBrokingFirm || ""),
    stockBrokerFullName: (savedShareTransferInstruction.stockBrokerFullName || ""),
    stockBrokerEmail: (savedShareTransferInstruction.stockBrokerEmail || ""),
    stockBrokerPhoneNo: (savedShareTransferInstruction.stockBrokerPhoneNo || ""),
    shareAmount: (savedShareTransferInstruction.shareAmount || ""),
  })


  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const {clearingHouseNumber, centralSecurityClearingSystem, stockBrockingFirm, stockBrokerFullName, stockBrokerEmail} = transferForm;
    const validateParams = {
      clearingHouseNumber,
      centralSecurityClearingSystem,
      stockBrockingFirm,
      firstName: stockBrokerFullName,
      email: stockBrokerEmail,
    }

    const errors:any = validate(validateParams);
    if (errors){
        for (var x in errors) {
            toast.error(errors[x]);
        };
        return;
    }
    else{
      const params = {
        employeeId,
        schemeTypeId,
        ...transferForm,
      }

      dispatch(employeeAction.saveShareTransferInstruction(params));
      navigate(sendInstructionSelectionUrl + "/instruction/" + schemeTypeId + "/transfer-amount");
    }
  }


  useEffect(() => {
    dispatch(employeeAction.getEmployee(employeeId));
    dispatch(stockBrockingFirmAction.getFirms());
  }, []);


  return (
    <div>

      <FormWizardNavBar currentStep={1} totalSteps={4} showSteps={true} closeUrl={employeeDetailsUrl} />

      <div className="container py-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Share Transfer
          </div>
          <p className="mt-2 text-fade text-sm">
            Kindly fill in the details below
          </p>
        </div>

        <div className="mt-12">
          
          <PageContent loading={employeeLoading} data={employee.data || undefined} emptyText="Employee info not found" />

          <Maybe condition={employeeLoading === false}>
            <form onSubmit={submitForm}>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input type="text" label="First name" value={employee.data?.userInfo?.firstName} disabled={true} />
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input type="text" label="Last name" value={employee.data?.userInfo?.lastName} disabled={true} />
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input type="tel" label="Phone number (optional)" value={employee.data?.userInfo?.phoneNumber || ""} disabled={true} />
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input type="email" label="Email Address" value={employee.data?.userInfo?.email} disabled={true} />
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input type="text" label="Clearing House Number (CHN)" value={transferForm.clearingHouseNumber} onChange={(e:any) => setTransferForm({...transferForm, clearingHouseNumber: e.target.value})} />
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input type="text" label="Central Security Clearing System (CSCS)" value={transferForm.centralSecurityClearingSystem} onChange={(e:any) => setTransferForm({...transferForm, centralSecurityClearingSystem: e.target.value})} />
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Maybe condition={stockBrockingFirmLoading === true}>
                    <div className="h-12 flex items-center text-sm px-3 rounded border-2 border-gray-200">
                      <FontAwesomeIcon icon="spinner" spin className="mr-2" />
                      Loading stockbroking firm...
                    </div>
                  </Maybe>
                  <Maybe condition={stockBrockingFirmLoading === false}>
                    <SelectInput label="Stockbroking firm" value={transferForm.stockBrockingFirm} onChange={(e:any) => setTransferForm({...transferForm, stockBrockingFirm: e.target.value})}>
                      <option value="" disabled>Select Stockbroking firm</option>
                      {stockBrockingFirm.data && stockBrockingFirm.data.map((firm:any, index:number) =>
                        <option value={JSON.stringify(firm)} key={index}>{firm.name}</option>
                      )}
                      <option value={JSON.stringify({id:"0", name:"Others"})}>Others</option>
                    </SelectInput>

                    <Maybe condition={transferForm.stockBrockingFirm !== "" && JSON.parse(transferForm.stockBrockingFirm).name === "Others"}>
                      <div className="flex flex-wrap mt-2">
                        <div className="w-full">
                          <Input type="text" label="Other stockbroking firm" value={transferForm.otherStockBrokingFirm} onChange={(e:any) => setTransferForm({...transferForm, otherStockBrokingFirm: e.target.value})} />
                        </div>
                      </div>
                    </Maybe>
                  </Maybe>
                </div>
              </div>

              <div className="mb-6 text-gray-400 text-sm font-bold">
                STOCK BROKER DETAILS
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input label="Full Name" type={"text"} value={transferForm.stockBrokerFullName} onChange={(e:any) => setTransferForm({...transferForm, stockBrokerFullName: e.target.value})} />
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input label="Email Address" type={"email"} value={transferForm.stockBrokerEmail} onChange={(e:any) => setTransferForm({...transferForm, stockBrokerEmail: e.target.value})} />
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input label="Phone number (optional)" type={"tel"} value={transferForm.stockBrokerPhoneNo} onChange={(e:any) => setTransferForm({...transferForm, stockBrokerPhoneNo: e.target.value})} />
                </div>
              </div>

              <div className="italic text-sm text-gray-400">
                <span className="text-red-500">*</span> Employees are responsible for transfer charges
              </div>


              <div className="mt-10 flex justify-end space-x-3">
                <Link to={sendInstructionSelectionUrl + "/instruction/" + schemeTypeId} className="btn btn-gold">
                  Back
                </Link>
                <button type="submit" className="btn btn-brown">
                  Continue
                </button>
              </div>

            </form>
          </Maybe>

        </div>
      </div>

    </div>
  );
}

export default CompanyEmployeeInstructionTransfer;
