import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Menu } from "@headlessui/react"
import { Link } from "react-router-dom";
import { getPageNumber } from "utils/getPageNumber";
import useUrlNavigation from "hooks/useUrlNavigation";
import ReactToolTip from 'react-tooltip';
import Maybe from "./Maybe";

interface Props {
  meta?: any,
  prefix?: any,
  showLimit?: boolean,
}

export const Pagination = (props: Props) => {
  const {meta, prefix, showLimit} = props;
  const {urlQuery} = useUrlNavigation();
  const addPrefix = prefix !== undefined ? prefix : "";
  const displayLimit = showLimit !== undefined ? showLimit : true;
  
  
  let thisCurrentPage = 1;
  let thisNextPage = null;
  let thisPreviousPage = null;
  let thisTotalPages = 10;
  let thisPageSize = 10;
  let thisTotal = 10;


  if (meta && meta.pagination){
    const {nextPage, previousPage, totalPages, pageSize, total} = meta.pagination;
    
    thisNextPage = getPageNumber(nextPage);
    thisPreviousPage = getPageNumber(previousPage);
    thisTotalPages = totalPages;
    thisPageSize = pageSize;
    thisTotal = total;

    if (thisPreviousPage === null){
      thisCurrentPage = 1;
    }
    else{
      thisCurrentPage = parseInt(thisPreviousPage) + 1;
    }
  }


  const firstIndex = ((thisCurrentPage - 1) * thisPageSize) + 1;
  const lastIndex = thisCurrentPage * thisPageSize;

  
  return (
    <>
      <ReactToolTip />
    
      <div className="pt-5 border-t border-gray-300 text-sm">
        <div className="flex justify-end space-x-10">

          {displayLimit === true &&
            <div className="flex">
              <div className="mr-3">
                Rows per page:
              </div>
              <div>
                <Menu>
                  <Menu.Button>
                    {thisPageSize}
                    <FontAwesomeIcon icon="angle-down" className="ml-1" />
                  </Menu.Button>
                  <Menu.Items className="dropdown">
                    <Menu.Item>
                      <Link to={urlQuery(addPrefix + "limit=20")} className="dropdown-item flex">
                        20
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link to={urlQuery(addPrefix + "limit=50")} className="dropdown-item flex">
                        50
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link to={urlQuery(addPrefix + "limit=100")} className="dropdown-item flex">
                        100
                      </Link>
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              </div>
            </div>
          }

          <div>
            {firstIndex} - {(lastIndex > thisTotal) ? thisTotal : lastIndex} of {thisTotal}
          </div>

          <div className="-mt-0.5 flex space-x-1">

            <Maybe condition={thisPreviousPage === null}>
              <div className="w-6 h-6 flex text-gray-300 hover:bg-gray-200 rounded cursor-pointer">
                <FontAwesomeIcon icon="angle-left" className="m-auto" />
              </div>
            </Maybe>

            <Maybe condition={thisPreviousPage !== null}>
              <Link to={urlQuery(addPrefix + "page=" + thisPreviousPage)} data-tip={"Page " + thisPreviousPage} className="w-6 h-6 flex hover:bg-gray-200 rounded cursor-pointer">
                <FontAwesomeIcon icon="angle-left" className="m-auto" />
              </Link>
            </Maybe>

            <Maybe condition={thisNextPage === null}>
              <div className="w-6 h-6 flex text-gray-300 hover:bg-gray-200 rounded cursor-pointer">
                <FontAwesomeIcon icon="angle-right" className="m-auto" />
              </div>
            </Maybe>

            <Maybe condition={thisNextPage !== null}>
              <Link to={urlQuery(addPrefix + "page=" + thisNextPage)} data-tip={"Page " + thisNextPage} className="w-6 h-6 flex hover:bg-gray-200 rounded cursor-pointer">
                <FontAwesomeIcon icon="angle-right" className="m-auto" />
              </Link>
            </Maybe>

          </div>
        </div>
      </div>
    </>
  )
}

export default Pagination
