import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import {ReactComponent as NotificationBellIcon } from "assets/images/icons/notificationBell.svg";
import logo from "assets/images/icons/logo.svg";
import { Menu } from "@headlessui/react";
import useLoginInfo from "hooks/useLoginInfo";
import { nameInitials } from "utils/nameInitials";
import * as user from "store/auth/user/action";

const HeaderMember = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {userInfo, logoutUser} = useLoginInfo();


    const switchToCompanyView = () => {
        navigate("/switch-view", {replace: true});
        dispatch(user.switchUserView("employer"));
    }


    return (
        <div className="fixed top-0 left-0 right-0 z-20 text-white bg-vetiva-black border-b border-white border-opacity-5">
            <div className="container w-full h-16 flex justify-between items-center">
            
                <Link to="/" className="flex items-center">
                    <div className="w-8 h-8 mr-1">
                        <img src={logo} alt="Vetiva" className="w-full h-full object-contain" />
                    </div>
                    <div className="text-lg font-normal font-noto leading-4">
                        VTL Staff Share Scheme
                    </div>
                </Link>
                
                <div className="hidden md:flex space-x-4 header-links">
                    
                </div>

                <div className="hidden md:flex items-center gap-5 relative">
                    <div className="hidden w-8 h-8 p-1 flex items-center justify-center hover:bg-gray-700 cursor-pointer rounded-md">
                        <NotificationBellIcon />
                    </div>
                    
                    <Menu>
                        <Menu.Button as={Fragment}>
                            <div className="w-8 h-8 text-center flex items-center justify-center text-xs rounded-full bg-vetiva-purple cursor-pointer">
                                {nameInitials(userInfo.firstName, userInfo.lastName)}
                            </div>
                        </Menu.Button>
                        <Menu.Items className="dropdown dropdown-right top-10 rounded-xl">
                            <div className="w-64 text-black">
                                <div className="h-16 bg-vetiva-black border border-b-0 border-gray-800"></div>
                                <div className="w-16 h-16 -mt-8 text-white text-xl font-bold bg-vetiva-purple rounded-full mx-auto uppercase flex items-center justify-center">
                                    {nameInitials(userInfo.firstName, userInfo.lastName)}
                                </div>
                                <div className="px-2 py-6 text-center bg-white">
                                    <div>
                                        {userInfo.name}
                                    </div>
                                    <div className="text-fade text-sm">
                                        {userInfo.email}
                                    </div>
                                </div>
                                {(userInfo.userTypeDefault === "SCHEME_MEMBER,SCHEME_COMMITTEE" || userInfo.userTypeDefault === "SCHEME_COMMITTEE,SCHEME_MEMBER") &&
                                    <div onClick={switchToCompanyView} className="pb-4 px-4">
                                        <div className="btn btn-brown btn-block rounded-full">
                                            Switch to Committee Member
                                        </div>
                                    </div>
                                }
                                <div>
                                    <div onClick={logoutUser} className="h-10 text-sm flex items-center justify-center bg-vetiva-gold hover:text-white hover:bg-vetiva-brown cursor-pointer">
                                        Logout
                                    </div>
                                </div>
                            </div>
                        </Menu.Items>
                    </Menu>
                </div>
                
                <div className="p-2 md:hidden cursor-pointer hover:bg-gray-700 rounded">
                    <svg
                    width="26"
                    height="18"
                    viewBox="0 0 26 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        d="M13 17.5H0.25V14.6667H13V17.5ZM25.75 10.4167H0.25V7.58333H25.75V10.4167ZM25.75 3.33333H13V0.5H25.75V3.33333Z"
                        fill="white"
                    />
                    </svg>
                </div>
            </div>
                

        </div>
    )
}

export default HeaderMember
