import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TimeDateBlock } from "components/time-date-block";
import { PieChart } from "components/ui/charts/pie-chart";
import Maybe from "components/Maybe";
import NumberFormat from "utils/numberFormat";
import { PageContent } from "components/PageContent";
import { OverviewDashboardCompany } from "components/OverviewDashboardCompany";
import useLoginInfo from "hooks/useLoginInfo";
import { Link } from "react-router-dom";
import { FileUploadPreview, PreviewAccordion } from "components/ui";
import { DateMiniFormat } from "utils/dateFormat";
import { pluralizeFull } from "utils/pluralize";
import CurrencyFormat from "utils/currencyFormat";
import * as overviewAction from "store/entities/overview/action";
import * as companyAction from "store/entities/company/action";


const Details = () => {
    let { schemeTypeId } = useParams();
    const {userInfo} = useLoginInfo();
    const companyIdDefault = userInfo.organizationId;

        
    const location = useLocation();
    const dispatch = useDispatch();
    const { shareDistributionLoading, shareDistribution, schemeMetricsAwardsLoading, schemeMetricsAwards, schemeMetricsOptionsLoading, schemeMetricsOptions, schemeMetricsPurchaseLoading, schemeMetricsPurchase, schemeMetricsLtipLoading, schemeMetricsLtip, schemeMetricsOthersLoading, schemeMetricsOthers } = useSelector((s: any) => s.entities.overview);
    const { companyLoading, company } = useSelector((s: any) => s.entities.company);


    const [selectedSchemeType, setSelectedSchemeType] = useState<any>([]);


    const getCompanyShareDistribution = (id:string) => {
        dispatch(overviewAction.getCompanyShareDistribution(id));
    }


    const getCompanyDetails = (id:string) => {
        dispatch(companyAction.getCompany(id));
    }


    const getCompanySchemeTypeInfo = () => {
        dispatch(overviewAction.resetAllSchemeMetrics());

        const id = companyIdDefault;
        if (id !== "" && id !== null){
                
            const schemeType = company.data?.organizationShareSetting?.schemeTypes.filter((type:any) => type.id === schemeTypeId);
            const schemeTypeName = schemeType[0]?.name;

            if (schemeType.length === 1){
                if (schemeTypeName === "Access to Share Awards"){
                    dispatch(overviewAction.getSchemeMetricAwards(id));
                }
                else if (schemeTypeName === "Access to Share Options"){
                    dispatch(overviewAction.getSchemeMetricOptions(id));
                }
                else if (schemeTypeName === "Access to Share Purchase"){
                    dispatch(overviewAction.getSchemeMetricPurchase(id));
                }
                else if (schemeTypeName === "Access to Long Term Incentice Plan (LTIP)"){
                    dispatch(overviewAction.getSchemeMetricLtip(id));
                }
                else if (schemeTypeName === "Access to Others"){
                    dispatch(overviewAction.getSchemeMetricOthers(id));
                }
            }

            setSelectedSchemeType(schemeType);

        }
    }


    const setSubSchemeAplhaNumberTypes = (data:any) => {
        let nameType:string = "";
        if (data.length > 0){
          const nameTypeValue = parseInt(data[0].name.split(" ")[1]);
          if (isNaN(nameTypeValue)){
            nameType = "Alphabets";
          }
          else{
            nameType = "Numbers";
          }
        }
        return nameType;
      }
      
    
      const plural = (data: string) => {
        if (data === "Batch") return "Batches";
        else if (data === "Group") return "Groups";
        else if (data === "Category") return "Categories";
        else return "";
      }


    const isSchemeTypesLoading = () => {
        if (
            schemeMetricsAwardsLoading === true ||
            schemeMetricsOptionsLoading === true ||
            schemeMetricsPurchaseLoading === true ||
            schemeMetricsLtipLoading === true ||
            schemeMetricsOthersLoading === true
        ){
            return true;
        }
        else{
            return false;
        }
    }


    const valueZero = (value:any) => {
        if (value === undefined || value === null || value === ""){
            return 0;
        }
        else{
            return parseInt(value);
        }
    }


    useEffect(() => {
        if (companyIdDefault !== "" && companyIdDefault !== null){
            getCompanyShareDistribution(companyIdDefault);
            getCompanyDetails(companyIdDefault);
        }
    }, [location]);


    useEffect(() => {
        if (company.success && company.success === true && company.data !== undefined){
            getCompanySchemeTypeInfo();
        }
    }, [company]);


    return (
        <div className="pb-10">

            <div className="bg-vetiva-black">
                <div className="container pt-16 pb-24 flex flex-wrap">
                    <div className="w-full xl:w-3/12">
                        <div className="h-full flex flex-col justify-between">
                            <div className="overflow-hidden">
                                <TimeDateBlock />
                            </div>
                        </div>
                    </div>
                    <div className="w-full xl:w-9/12">
                        <div className="ml-10">

                            <div className="flex">
                                <Link to="/company/overview" className="w-8 h-7 flex mr-3 my-auto bg-gray-700 justify-center rounded group hover:bg-white">
                                    <FontAwesomeIcon icon="angle-left" className="text-xl text-white my-auto group-hover:text-vetiva-brown" />
                                </Link>
                                <div className="text-2xl">
                                    <Link to="/company/overview" className="text-vetiva-brown mr-2 hover:underline">
                                        Overview
                                    </Link>
                                    <span className="text-gray-300">
                                        /&nbsp;
                                        {/* 
                                        <Maybe condition={companyLoading}>
                                            <FontAwesomeIcon icon="spinner" spin className="text-vetiva-brown" />
                                        </Maybe>
                                        <Maybe condition={!companyLoading}>
                                            {schemeType[0]?.name}
                                        </Maybe>
                                         */}
                                        Scheme Details
                                    </span>
                                </div>
                            </div>

                            <div className="mt-3 border-t border-gray-600"></div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container flex -mt-16">
                <div className="w-full md:w-3/12">
                    <div className="text-white mb-2 font-bold text-sm">
                        Share distribution
                    </div>

                    <div className="p-6 bg-white shadow-md rounded-lg">

                        <PageContent loading={shareDistributionLoading} data={shareDistribution.data || undefined} emptyText="Info not found" errorText="Info not found" />
                        
                        <Maybe condition={!shareDistributionLoading}>
                            <Maybe condition={shareDistribution.data !== undefined}>
                                <div className="w-48 h-48 mx-auto">
                                    <PieChart data={[
                                        Math.round(shareDistribution.data?.shareAwardPercentage) || 0,
                                        Math.round(shareDistribution.data?.shareOptionPercentage) || 0,
                                        Math.round(shareDistribution.data?.sharePurchasePercentage) || 0,
                                        Math.round(shareDistribution.data?.longTermIncentivePercentage) || 0,
                                        Math.round(shareDistribution.data?.othersPercentage) || 0,
                                    ]} />
                                </div>
                            </Maybe>

                            <div className="mt-6 space-y-6 text-gray-600">
                                <div className="flex space-x-2 justify-between">
                                    <div>
                                        Share award
                                    </div>
                                    <div className="font-bold text-right">
                                        <Maybe condition={shareDistribution.data?.totalShareAwardAllocated !== undefined}>
                                            {NumberFormat(shareDistribution.data?.totalShareAwardAllocated, "no-kobo")} ({Math.round(shareDistribution.data?.shareAwardPercentage)}%)
                                        </Maybe>
                                        <Maybe condition={shareDistribution.data?.totalShareAwardAllocated === undefined}>
                                            ---
                                        </Maybe>
                                    </div>
                                </div>
                                <div className="flex space-x-2 justify-between">
                                    <div>
                                        Share option
                                    </div>
                                    <div className="font-bold text-right">
                                        <Maybe condition={shareDistribution.data?.totalShareOptionAllocated !== undefined}>
                                            {NumberFormat(shareDistribution.data?.totalShareOptionAllocated, "no-kobo")} ({Math.round(shareDistribution.data?.shareOptionPercentage)}%)
                                        </Maybe>
                                        <Maybe condition={shareDistribution.data?.totalShareOptionAllocated === undefined}>
                                            ---
                                        </Maybe>
                                    </div>
                                </div>
                                <div className="flex space-x-2 justify-between">
                                    <div>
                                        Share purchase
                                    </div>
                                    <div className="font-bold text-right">
                                        <Maybe condition={shareDistribution.data?.totalSharePurchaseAllocated !== undefined}>
                                            {NumberFormat(shareDistribution.data?.totalSharePurchaseAllocated, "no-kobo")} ({Math.round(shareDistribution.data?.sharePurchasePercentage)}%)
                                        </Maybe>
                                        <Maybe condition={shareDistribution.data?.totalSharePurchaseAllocated === undefined}>
                                            ---
                                        </Maybe>
                                    </div>
                                </div>
                                <div className="flex space-x-2 justify-between">
                                    <div>
                                        Long term incentive
                                    </div>
                                    <div className="font-bold text-right">
                                        <Maybe condition={shareDistribution.data?.totalLongTermIncentiveAllocated !== undefined}>
                                            {NumberFormat(shareDistribution.data?.totalLongTermIncentiveAllocated, "no-kobo")} ({Math.round(shareDistribution.data?.longTermIncentivePercentage)}%)
                                        </Maybe>
                                        <Maybe condition={shareDistribution.data?.totalLongTermIncentiveAllocated === undefined}>
                                            ---
                                        </Maybe>
                                    </div>
                                </div>
                                <div className="flex space-x-2 justify-between">
                                    <div>
                                        Others
                                    </div>
                                    <div className="font-bold text-right">
                                        <Maybe condition={shareDistribution.data?.totalOthersAllocated !== undefined}>
                                            {NumberFormat(shareDistribution.data?.totalOthersAllocated, "no-kobo")} ({Math.round(shareDistribution.data?.othersPercentage)}%)
                                        </Maybe>
                                        <Maybe condition={shareDistribution.data?.totalOthersAllocated === undefined}>
                                            ---
                                        </Maybe>
                                    </div>
                                </div>
                            </div>
                        </Maybe>

                    </div>
                </div>

                <div className="w-full md:w-9/12">
                    <div className="ml-10">
                        <div className="text-white mb-2 font-bold text-sm">
                            Scheme details
                        </div>

                        <div>
                            <PageContent loading={companyLoading} data={company.data || undefined} loadingText="Loading company information..." emptyText="Info not found" errorText="Info not found" />

                            <Maybe condition={!companyLoading}>
                                <div className="w-full p-4 bg-white shadow-md rounded-lg">


                                    <OverviewDashboardCompany name="Share Award" loading={schemeMetricsAwardsLoading} data={schemeMetricsAwards} showDetailsButton={false} />
                                    <OverviewDashboardCompany name="Share Option" loading={schemeMetricsOptionsLoading} data={schemeMetricsOptions} showDetailsButton={false} />
                                    <OverviewDashboardCompany name="Share Purchase" loading={schemeMetricsPurchaseLoading} data={schemeMetricsPurchase} showDetailsButton={false} />
                                    <OverviewDashboardCompany name="Long-term Incentive" loading={schemeMetricsLtipLoading} data={schemeMetricsLtip} showDetailsButton={false} />
                                    <OverviewDashboardCompany name="Others" loading={schemeMetricsOthersLoading} data={schemeMetricsOthers} showDetailsButton={false} />
                                    

                                    <div>

                                        {selectedSchemeType.map((schemeType:any, index:number) => 
                                            <div key={index} className="bg-gray-100 p-4">
                                                <div id="header" className="flex justify-between border-b border-gray-300 mb-4 pb-3">
                                                    <label className="text-fade font-normal">
                                                    Scheme Type
                                                    </label>
                                                    <p className="text-vetiva-brown font-bold">
                                                    {schemeType.name !== "Access to Others" ? schemeType.name : schemeType.schemeDetail?.otherSchemeName}
                                                    </p>
                                                </div>

                                                <div className="border-b border-gray-300 mb-4">
                                                    {schemeType.schemePropertyType === 1 &&
                                                    <div className="flex justify-between mb-4">
                                                        <label className="text-fade font-normal">
                                                        Strike Price
                                                        </label>
                                                        <p>
                                                        {CurrencyFormat(schemeType.schemeDetail?.strikePrice)}
                                                        </p>
                                                    </div>
                                                    }
                                                    {schemeType.schemePropertyType === 2 &&
                                                    <div className="flex justify-between mb-4">
                                                        <label className="text-fade font-normal">
                                                        Purchase Price
                                                        </label>
                                                        <p>
                                                        {CurrencyFormat(schemeType.schemeDetail?.purchasePrice)}
                                                        </p>
                                                    </div>
                                                    }
                                                    {(schemeType.schemePropertyType === 1 || schemeType.schemePropertyType === 2) &&
                                                    <div className="flex justify-between mb-4">
                                                        <label className="text-fade font-normal">
                                                        Valuation
                                                        </label>
                                                        <p>
                                                        {CurrencyFormat(schemeType.schemeDetail?.valuation)}
                                                        </p>
                                                    </div>
                                                    }
                                                    {schemeType.schemePropertyType === 1 &&
                                                    <div className="flex justify-between mb-4">
                                                        <label className="text-fade font-normal">
                                                        Exercise Period
                                                        </label>
                                                        <p>
                                                        {DateMiniFormat(schemeType.schemeDetail?.exercisePeriodStartDate)} - {DateMiniFormat(schemeType.schemeDetail?.exercisePeriodEndDate)}
                                                        </p>
                                                    </div>
                                                    }
                                                    {schemeType.schemePropertyType === 1 &&
                                                    <div className="flex justify-between mb-4">
                                                        <label className="text-fade font-normal">
                                                        Exercise Date
                                                        </label>
                                                        <p>
                                                        {DateMiniFormat(schemeType.schemeDetail?.expirationDate)}
                                                        </p>
                                                    </div>
                                                    }
                                                    <div className="flex justify-between mb-4">
                                                    <label className="text-fade font-normal">
                                                        Holding Period
                                                    </label>
                                                    <p>
                                                        {DateMiniFormat(schemeType.startDate)} - {DateMiniFormat(schemeType.endDate)}
                                                    </p>
                                                    </div>
                                                    <div className="flex justify-between mb-4">
                                                    <label className="text-fade font-normal">
                                                        Access to subscheme
                                                    </label>
                                                    <p>
                                                        {schemeType.hasSubSchemeAccess === true ? "Yes" : "No"}
                                                    </p>
                                                    </div>

                                                    {schemeType.hasSubSchemeAccess === false &&
                                                    <>
                                                        <div className="flex justify-between mb-4">
                                                        <label className="text-fade font-normal">
                                                            Number of Allocation
                                                        </label>
                                                        <p>
                                                            {NumberFormat(schemeType.schemeDetail?.numberOfAllocation, "no-kobo")}
                                                        </p>
                                                        </div>
                                                        <div className="flex justify-between mb-4">
                                                        <label className="text-fade font-normal">
                                                            Date of Allocation
                                                        </label>
                                                        <p>
                                                            {DateMiniFormat(schemeType.schemeDetail?.dateOfAllocation)}
                                                        </p>
                                                        </div>
                                                        <div className="flex justify-between mb-4">
                                                        <label className="text-fade font-normal">
                                                            Percentage for vesting
                                                        </label>
                                                        <p>
                                                            {schemeType.schemeDetail?.percentageVesting}%
                                                        </p>
                                                        </div>
                                                        <div className="flex justify-between mb-4">
                                                        <label className="text-fade font-normal">
                                                            Vesting period
                                                        </label>
                                                        <p>
                                                            {schemeType.schemeDetail?.vestingPeriod}
                                                        </p>
                                                        </div>
                                                        <Maybe condition={schemeType.schemeDetail?.vestingPeriod === "Fixed period"}>
                                                        <div className="flex justify-between mb-4">
                                                            <label className="text-fade font-normal">
                                                            Vesting date
                                                            </label>
                                                            <p>
                                                            Every {schemeType.schemeDetail?.period} {pluralizeFull(schemeType.schemeDetail?.period, "year", "years")}
                                                            </p>
                                                        </div>
                                                        </Maybe>
                                                        <Maybe condition={schemeType.schemeDetail?.vestingPeriod === "Recurring date"}>
                                                        <div className="flex justify-between mb-4">
                                                            <label className="text-fade font-normal">
                                                            Vesting date
                                                            </label>
                                                            <p>
                                                            {DateMiniFormat(schemeType.schemeDetail?.vestingDate)}
                                                            </p>
                                                        </div>
                                                        </Maybe>
                                                    </>
                                                    }
                                                </div>

                                                <div className="mb-4">

                                                    <Maybe condition={schemeType.hasSubSchemeAccess === true}>
                                                    {schemeType.subSchemes.length > 0 &&
                                                        <>
                                                        <Maybe condition={schemeType.subSchemes[0].batches.length > 0}>
                                                            <>
                                                            {(() => {
                                                                let subSchemeBatch:any = [];
                                                                let subSchemeGroup:any = [];
                                                                let subSchemeCategory:any = [];
                                                                
                                                                schemeType.subSchemes[0].batches.map((data:any) => {
                                                                if (data.name.includes("Batch")){
                                                                    subSchemeBatch.push(data);
                                                                }
                                                                else if (data.name.includes("Group")){
                                                                    subSchemeGroup.push(data);
                                                                }
                                                                else if (data.name.includes("Category")){
                                                                    subSchemeCategory.push(data);
                                                                }
                                                                return null;
                                                                })

                                                                let subSchemeIndex:any = ["Batch","Group","Category"];
                                                                let subSchemeAll:any = [subSchemeBatch, subSchemeGroup, subSchemeCategory];

                                                                let render = subSchemeAll.map((data:any, index2:number) =>
                                                                <Maybe key={index2} condition={data.length > 0}>
                                                                    <div className="flex justify-between mb-4">
                                                                    <PreviewAccordion
                                                                        allocType={subSchemeIndex[index2]}
                                                                        countType={setSubSchemeAplhaNumberTypes(data)}
                                                                        stats={data.length + " " + pluralizeFull(data.length, subSchemeIndex[index2], plural(subSchemeIndex[index2])).toLowerCase()}
                                                                    >
                                                                        <div className="py-2 flex flex-col space-y-2">

                                                                        {data.map((dataBatch:any, index3:number) =>
                                                                            <div key={index3} className="bg-white p-3">
                                                                            <div>
                                                                                {dataBatch.name}
                                                                            </div>
                                                                            <Maybe condition={dataBatch.allocations.length > 0}>
                                                                                {dataBatch.allocations.map((dataAllocation:any, index4:number) =>
                                                                                <div key={index4} className="mt-4 pt-4 border-t border-gray-300">
                                                                                    <div className="flex flex-wrap -mx-3 mb-6">
                                                                                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                                                                        <div className="space-y-1">
                                                                                        <label className="text-fade font-normal">
                                                                                            Number of allocation
                                                                                        </label>
                                                                                        <p className="font-normal">
                                                                                            {NumberFormat(dataAllocation.numberOfAllocation, "no-kobo")}
                                                                                        </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full md:w-1/3 px-3">
                                                                                        <div className="space-y-1">
                                                                                        <label className="text-fade font-normal">
                                                                                            Date of allocation
                                                                                        </label>
                                                                                        <p className="font-normal">
                                                                                            {DateMiniFormat(dataAllocation.dateOfAllocation)}
                                                                                        </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="w-full md:w-1/3 px-3">
                                                                                        <div className="space-y-1">
                                                                                        <label className="text-fade font-normal">
                                                                                            Percentage for vesting
                                                                                        </label>
                                                                                        <p className="font-normal">
                                                                                            {dataAllocation.percentageVesting}%
                                                                                        </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    </div>
                                                                                    <div className="flex flex-wrap -mx-3 mb-6">
                                                                                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                                                                        <div className="space-y-1">
                                                                                        <label className="text-fade font-normal">
                                                                                            Vesting period
                                                                                        </label>
                                                                                        <p className="font-normal">
                                                                                            {dataAllocation.vestingPeriod}
                                                                                        </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Maybe condition={dataAllocation.vestingPeriod === "Fixed period"}>
                                                                                        <div className="w-full md:w-1/3 px-3">
                                                                                        <div className="space-y-1">
                                                                                            <label className="text-fade font-normal">
                                                                                            Vesting date
                                                                                            </label>
                                                                                            <p className="font-normal">
                                                                                            Every {dataAllocation.period} {pluralizeFull(dataAllocation.period, "year", "years")}
                                                                                            </p>
                                                                                        </div>
                                                                                        </div>
                                                                                    </Maybe>
                                                                                    <Maybe condition={dataAllocation.vestingPeriod === "Recurring date"}>
                                                                                        <div className="w-full md:w-1/3 px-3">
                                                                                        <div className="space-y-1">
                                                                                            <label className="text-fade font-normal">
                                                                                            Vesting date
                                                                                            </label>
                                                                                            <p className="font-normal">
                                                                                            {DateMiniFormat(dataAllocation.vestingDate)}
                                                                                            </p>
                                                                                        </div>
                                                                                        </div>
                                                                                    </Maybe>
                                                                                    </div>
                                                                                    <div className="mb-2">
                                                                                        <FileUploadPreview
                                                                                            className="bg-gray-100"
                                                                                            file={dataAllocation.schemeRuleObj ? dataAllocation.schemeRuleObj[0] || undefined : undefined}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                )}
                                                                            </Maybe>
                                                                            <Maybe condition={dataBatch.allocations.length <= 0}>
                                                                                <span className="italic">No allocations</span>
                                                                            </Maybe>
                                                                            </div>
                                                                        )}

                                                                        </div>
                                                                    </PreviewAccordion>
                                                                    </div>
                                                                </Maybe>
                                                                )

                                                                return (render);

                                                            })()}
                                                            </>
                                                        </Maybe>

                                                        <Maybe condition={schemeType.subSchemes[0].batches.length <= 0}>
                                                            <div className="page-info-italic">
                                                            No batches, groups & categories
                                                            </div>
                                                        </Maybe>

                                                        <div className="border-t border-gray-300 mt-4">
                                                            <div className="flex flex-col justify-between mt-4">
                                                                <label className="text-fade font-normal">
                                                                    Trust Deed
                                                                </label>
                                                                <div className="mt-2">
                                                                    <FileUploadPreview
                                                                        className="bg-white"
                                                                        file={schemeType.subSchemes[0].trustDeedObject ? schemeType.subSchemes[0].trustDeedObject[0] || undefined : undefined}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </>
                                                    }

                                                    <Maybe condition={schemeType.subSchemes.length <= 0}>
                                                        <div className="page-info-italic mb-4">
                                                        No subschemes
                                                        </div>
                                                    </Maybe>
                                                    </Maybe>

                                                </div>
                                            </div>
                                        )}

                                    </div>


                                </div>
                            </Maybe>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default Details