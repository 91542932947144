import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconFilePDF from "assets/images/icons/filePDF.svg";
import iconDelete from "assets/images/icons/delete.svg";
import Maybe from "components/Maybe";
import getFileSize from "utils/getFileSize";

export interface FileUploadPreviewProps {
  name?: string;
  size?: string;
  type?: string;
  icon?: string;
  url?: string;
  emptyText?: string;
  file?: any;
  allowDismiss?: boolean;
  cancelUpload?: any;
  className?: string;
}

export function FileUploadPreview(props: FileUploadPreviewProps) {
  let { name, size, type, icon, url, emptyText, file, cancelUpload, allowDismiss, className } = props;

  
  if (file !== undefined){
    name = file?.filename;
    size = getFileSize(file?.bytes);
    url = file?.url;
    icon = file?.url;   // check if filetype is an image
  }

  const openFile = () => {
    if (url !== undefined){
      window.open(url, '_blank');
    }
  }

  return (
    <div className="relative z-[1]">
      <div className={"p-3 flex justify-between rounded " + className + " " + (url !== undefined && "group cursor-pointer") + " " + (allowDismiss && "pr-14")} onClick={openFile}>
        <div className="w-10 h-10 flex flex-shrink-0 mr-3">
          <Maybe condition={icon !== undefined}>
            <div className="w-full bg-black rounded">
              <img src={icon} alt="preview" className="object-contain" />
            </div>
          </Maybe>
          <Maybe condition={icon === undefined}>
            <Maybe condition={type !== undefined}>
              <Maybe condition={type==="pdf"}>
                <img src={iconFilePDF} alt="file" className="m-auto" />
              </Maybe>
              <Maybe condition={type!=="pdf"}>
                <FontAwesomeIcon icon="file" className="m-auto text-3xl text-vetiva-brown" />
              </Maybe>
            </Maybe>
            <Maybe condition={type === undefined}>
              <FontAwesomeIcon icon="file" className="m-auto text-3xl text-vetiva-brown" />
            </Maybe>
          </Maybe>
        </div>
        <div className="text-sm flex flex-col flex-grow">
          <Maybe condition={name !== undefined}>
            <div className={"font-bold " + (url !== undefined && "group-hover:underline")}>
              {name}
            </div>
            <div>
              {size}
            </div>
          </Maybe>
          <Maybe condition={name === undefined}>
            <div className="my-auto font-bold italic">
              {emptyText || "No file attached"}
            </div>
          </Maybe>
        </div>
      </div>

      {allowDismiss === true &&
        <div onClick={cancelUpload} className="absolute z-[2] top-3 right-3 w-10 h-10 flex flex-shrink-0 ml-3 rounded cursor-pointer hover:bg-vetiva-gold">
          <img src={iconDelete} alt="file" className="m-auto" />
        </div>
      }
    </div>
  );
}

export default FileUploadPreview;
