import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import { UserInfoCard } from "components/user-info-card";
import ButtonLoader from "components/ButtonLoader";
import NumberFormat from "utils/numberFormat";
import Maybe from "components/Maybe";
import useLoginInfo from "hooks/useLoginInfo";
import { PageContent } from "components/PageContent";
import * as employeeAction from "store/entities/employee/action";

export interface CompanyEmployeeInstructionTransferPreviewProps {}

export function CompanyEmployeeInstructionTransferPreview(props: CompanyEmployeeInstructionTransferPreviewProps) {
  const {userInfo} = useLoginInfo();
  let { companyId, employeeId, schemeTypeId } = useParams();
  let employeeDetailsUrl = "/trustee/company/"+companyId+"/employee/"+employeeId;
  let sendInstructionSelectionUrl = "/trustee/company/"+companyId+"/employee/"+employeeId;
  
  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    employeeDetailsUrl = "/company/employees/"+employeeId;
    sendInstructionSelectionUrl = employeeDetailsUrl;
  }
  else if (userInfo.userType === "SCHEME_MEMBER"){
    companyId = userInfo.organizationId;
    employeeId = userInfo.employeeId;
    employeeDetailsUrl = "/member/overview";
    sendInstructionSelectionUrl = "/member";
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { employeeLoading, employee, savedShareTransferInstruction, sendShareTransferInstructionLoading, sendShareTransferInstruction } = useSelector((s: any) => s.entities.employee);


  const submitForm = () => {
    let params = {
      ...savedShareTransferInstruction,
    }
    if (JSON.parse(savedShareTransferInstruction.stockBrockingFirm).name !== "Others"){
      params["stockBrockingFirmId"] = JSON.parse(savedShareTransferInstruction.stockBrockingFirm).id;
    }
    else{
      params["otherStockBrokingFirm"] = savedShareTransferInstruction.otherStockBrokingFirm;

    }

    delete params["stockBrockingFirm"];
    delete params["remainingShares"];
    // console.log("params", params);
    
    dispatch(employeeAction.sendShareTransferInstruction(employeeId, params));
  }


  useEffect(() => {
    if (sendShareTransferInstruction.success === true){
      dispatch(employeeAction.saveShareTransferInstruction({}));
      dispatch(employeeAction.sendShareTransferInstructionReset());
      navigate(sendInstructionSelectionUrl + "/instruction/" + schemeTypeId + "/transfer-success");
    }
  }, [sendShareTransferInstruction]);

  
  useEffect(() => {
    dispatch(employeeAction.getEmployee(employeeId));
  }, []);

  
  useEffect(() => {
    if (
      (Object.keys(savedShareTransferInstruction).length === 0) ||
      (savedShareTransferInstruction.clearingHouseNumber === "") ||
      (savedShareTransferInstruction.centralSecurityClearingSystem === "") ||
      (savedShareTransferInstruction.stockBrockingFirm === "") ||
      (savedShareTransferInstruction.stockBrokerFullName === "") ||
      (savedShareTransferInstruction.stockBrokerEmail === "")
    ){
      navigate(sendInstructionSelectionUrl + "/instruction/" + schemeTypeId + "/transfer");
    }
  }, []);


  return (
    <div>

      <FormWizardNavBar currentStep={3} totalSteps={4} showSteps={true} closeUrl={employeeDetailsUrl} />

      <div className="container py-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Preview
          </div>
          <p className="mt-2 text-fade text-sm">
            Kindly preview details before submitting
          </p>
        </div>

        <div className="mt-12">
          
          <PageContent loading={employeeLoading} data={employee.data || undefined} emptyText="Employee info not found" />

          <Maybe condition={employeeLoading === false}>
            <form onSubmit={submitForm}>

              <UserInfoCard
                name={employee.data?.userInfo?.firstName + " " + employee.data?.userInfo?.lastName}
                tel={employee.data?.userInfo?.phoneNumber}
                email={employee.data?.userInfo?.email}
              />


              <div className="mt-10">
                <div className="text-gray-400 text-sm font-bold">
                  OFFICIAL DETAILS
                </div>
                <div className="mt-6 space-y-4 text-sm break-words">
                  <div className="sm:flex justify-between">
                    <div className="text-gray-400">
                      Clearing House Number (CHN)
                    </div>
                    <div className="max-w-[50%] flex-shrink-0 font-bold text-right">
                      {savedShareTransferInstruction.clearingHouseNumber}
                    </div>
                  </div>
                  <div className="sm:flex justify-between">
                    <div className="text-gray-400">
                      Central Security Clearing System (CSCS)
                    </div>
                    <div className="max-w-[50%] flex-shrink-0 font-bold text-right">
                      {savedShareTransferInstruction.centralSecurityClearingSystem}
                    </div>
                  </div>
                  <div className="sm:flex justify-between">
                    <div className="text-gray-400">
                      Stock broking firm
                    </div>
                    <div className="max-w-[50%] flex-shrink-0 font-bold text-right">
                      <Maybe condition={savedShareTransferInstruction.stockBrockingFirm && JSON.parse(savedShareTransferInstruction.stockBrockingFirm).name !== "Others"}>
                        {savedShareTransferInstruction.stockBrockingFirm && JSON.parse(savedShareTransferInstruction.stockBrockingFirm).name}
                      </Maybe>
                      <Maybe condition={savedShareTransferInstruction.stockBrockingFirm && JSON.parse(savedShareTransferInstruction.stockBrockingFirm).name === "Others"}>
                        {savedShareTransferInstruction.otherStockBrokingFirm}
                      </Maybe>
                      
                    </div>
                  </div>
                </div>
              </div>


              <div className="mt-10">
                <div className="text-gray-400 text-sm font-bold">
                  STOCK BROKERS DETAILS
                </div>
                <div className="mt-6 space-y-4 text-sm break-words">
                  <div className="sm:flex justify-between">
                    <div className="text-gray-400">
                      Full Name
                    </div>
                    <div className="max-w-[50%] flex-shrink-0 font-bold text-right">
                      {savedShareTransferInstruction.stockBrokerFullName}
                    </div>
                  </div>
                  <div className="sm:flex justify-between">
                    <div className="text-gray-400">
                      Email address
                    </div>
                    <div className="max-w-[50%] flex-shrink-0 font-bold text-right">
                      {savedShareTransferInstruction.stockBrokerEmail}
                    </div>
                  </div>
                  <div className="sm:flex justify-between">
                    <div className="text-gray-400">
                      Phone Number
                    </div>
                    <div className="max-w-[50%] flex-shrink-0 font-bold text-right">
                      {savedShareTransferInstruction.stockBrokerPhoneNo}
                    </div>
                  </div>
                </div>
              </div>


              <div className="mt-10">
                <div className="text-gray-400 text-sm font-bold">
                  AMOUNT DETAILS
                </div>
                <div className="mt-6 space-y-4 text-sm break-words">
                  <div className="sm:flex justify-between">
                    <div className="text-gray-400">
                      Amount to transfer
                    </div>
                    <div className="max-w-[50%] flex-shrink-0 font-bold text-right">
                      {NumberFormat(savedShareTransferInstruction.shareAmount, "no-kobo")}
                    </div>
                  </div>
                </div>
              </div>


              <div className="mt-10 flex justify-end space-x-3">
                <Link to={sendInstructionSelectionUrl + "/instruction/" + schemeTypeId + "/transfer-amount"} className="btn btn-gold">
                  Back
                </Link>
                <ButtonLoader type="button" onClick={submitForm} loading={sendShareTransferInstructionLoading} className="btn btn-brown">
                  Proceed with transfer
                </ButtonLoader>
              </div>


            </form>
          </Maybe>

        </div>
      </div>

    </div>
  );
}

export default CompanyEmployeeInstructionTransferPreview;
