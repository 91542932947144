import Maybe from "components/Maybe";
import { useEffect, useImperativeHandle, forwardRef, useState } from "react";
import FileUploadPreview from "./file-upload-preview";
import getFileSize from "utils/getFileSize";

export interface DocPickerProps {
  files?: any,
  onValueChange?: any,
  multiple?: boolean,
  accept?: any,
}

export const DocPicker = forwardRef((props: DocPickerProps, ref:any) => {
  const { files, onValueChange, multiple, accept } = props;
  const [ uploadedfiles, setUploadedFiles ] = useState<any>("");
  const [ action, setAction ] = useState(false);
  const allowMultipleUploads = multiple || false;
  const acceptableFormats = accept || "*";

  useImperativeHandle(ref, () => ({
    cancelFileUpload(index:number) {
      cancelUpload(index);
    }
  }))

  const handleChange = (event: any) => {
    // console.log(event.target.files);
    setUploadedFiles(event.target.files);
    setAction(true);
  };


  const cancelUpload = (index: number) => {
    let fileBuffer = Array.from(uploadedfiles);
    fileBuffer.splice(index, 1);
    const dT:any = new ClipboardEvent('').clipboardData || new DataTransfer();
    for (let file of fileBuffer) { dT.items.add(file); }
    let newFiles = dT.files;
    if (newFiles.length === 0){
      newFiles = "";
    }
    setUploadedFiles(newFiles);
    setAction(true);
  };
  
  
  useEffect(() => {
    if (files !== "" && files !== null && files !== undefined){
      if (files.length > 0 && files[0] !== "" && files[0] !== null && files[0] !== undefined){
        const newFiles = files.map((file:any) => {
          return {
            name: file.filename,
            size: file.bytes,
            url: file?.url,
            icon: file?.url,
            type: file?.format,
          }
        })
        setUploadedFiles(newFiles);
      }
    }
  }, []);


  useEffect(() => {
    if (action === true){
      if (uploadedfiles !== undefined){
        setAction(false);
        return onValueChange(uploadedfiles);
      }
    }
  }, [uploadedfiles]);

  
  return (
    <>
      <div>

        <Maybe condition={uploadedfiles === ""}>
          <label className="flex justify-center items-center w-full h-full bg-gray-50 rounded cursor-pointer hover:bg-gray-100 border-2 border-dashed border-gray-300">
            <div className="flex flex-col justify-center items-center py-4">
              <p className="font-bold">Upload file</p>
              <p className="text-fade text-sm">Click here to upload your document</p>
            </div>
            <input type="file" multiple={allowMultipleUploads} accept={acceptableFormats} className="hidden" onChange={handleChange} />
          </label>
        </Maybe>

        <Maybe condition={uploadedfiles !== ""}>
          <Maybe condition={uploadedfiles && uploadedfiles.length > 0}>
            {[...uploadedfiles].map((file:any, index:number) => (
              <div key={index} className="mb-1">
                <FileUploadPreview name={file.name} size={getFileSize(file.size)} className="bg-white" allowDismiss={true} cancelUpload={() => cancelUpload(index)}  />
              </div>
            ))}
          </Maybe>
        </Maybe>


      </div>
    </>
  );
})

export default DocPicker;
