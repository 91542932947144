
export const GET_COMPANIES_DASHBOARD_STATS = "GET_COMPANIES_DASHBOARD_STATS";
export const GET_COMPANIES_DASHBOARD_STATS_START = "GET_COMPANIES_DASHBOARD_STATS_START";
export const GET_COMPANIES_DASHBOARD_STATS_FAILED = "GET_COMPANIES_DASHBOARD_STATS_FAILED";

export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_START = "GET_COMPANIES_START";
export const GET_COMPANIES_FAILED = "GET_COMPANIES_FAILED";

export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_START = "GET_COMPANY_START";
export const GET_COMPANY_FAILED = "GET_COMPANY_FAILED";

export const CREATE_COMPANY_STEP_1 = "CREATE_COMPANY_STEP_1";
export const CREATE_COMPANY_STEP_1_START = "CREATE_COMPANY_STEP_1_START";
export const CREATE_COMPANY_STEP_1_FAILED = "CREATE_COMPANY_STEP_1_FAILED";
export const CREATE_COMPANY_STEP_1_RESET = "CREATE_COMPANY_STEP_1_RESET";

export const CREATE_COMPANY_STEP_2 = "CREATE_COMPANY_STEP_2";
export const CREATE_COMPANY_STEP_2_START = "CREATE_COMPANY_STEP_2_START";
export const CREATE_COMPANY_STEP_2_FAILED = "CREATE_COMPANY_STEP_2_FAILED";
export const CREATE_COMPANY_STEP_2_RESET = "CREATE_COMPANY_STEP_2_RESET";

export const CREATE_COMPANY_STEP_3 = "CREATE_COMPANY_STEP_3";
export const CREATE_COMPANY_STEP_3_START = "CREATE_COMPANY_STEP_3_START";
export const CREATE_COMPANY_STEP_3_FAILED = "CREATE_COMPANY_STEP_3_FAILED";
export const CREATE_COMPANY_STEP_3_RESET = "CREATE_COMPANY_STEP_3_RESET";

export const CREATE_COMPANY_STEP_31 = "CREATE_COMPANY_STEP_31";
export const CREATE_COMPANY_STEP_31_START = "CREATE_COMPANY_STEP_31_START";
export const CREATE_COMPANY_STEP_31_FAILED = "CREATE_COMPANY_STEP_31_FAILED";
export const CREATE_COMPANY_STEP_31_RESET = "CREATE_COMPANY_STEP_31_RESET";

export const ACTIVATE_COMPANY = "ACTIVATE_COMPANY";
export const ACTIVATE_COMPANY_START = "ACTIVATE_COMPANY_START";
export const ACTIVATE_COMPANY_FAILED = "ACTIVATE_COMPANY_FAILED";

export const DEACTIVATE_COMPANY = "DEACTIVATE_COMPANY";
export const DEACTIVATE_COMPANY_START = "DEACTIVATE_COMPANY_START";
export const DEACTIVATE_COMPANY_FAILED = "DEACTIVATE_COMPANY_FAILED";

export const RESEND_INVITE_COMPANY = "RESEND_INVITE_COMPANY";
export const RESEND_INVITE_COMPANY_START = "RESEND_INVITE_COMPANY_START";
export const RESEND_INVITE_COMPANY_FAILED = "RESEND_INVITE_COMPANY_FAILED";

export const GET_COMPANY_EMPLOYEE_DASHBOARD_STATS = "GET_COMPANY_EMPLOYEE_DASHBOARD_STATS";
export const GET_COMPANY_EMPLOYEE_DASHBOARD_STATS_START = "GET_COMPANY_EMPLOYEE_DASHBOARD_STATS_START";
export const GET_COMPANY_EMPLOYEE_DASHBOARD_STATS_FAILED = "GET_COMPANY_EMPLOYEE_DASHBOARD_STATS_FAILED";

export const GET_COMPANY_ADMIN = "GET_COMPANY_ADMIN";
export const GET_COMPANY_ADMIN_START = "GET_COMPANY_ADMIN_START";
export const GET_COMPANY_ADMIN_FAILED = "GET_COMPANY_ADMIN_FAILED";

export const GET_COMPANY_UPLOADS = "GET_COMPANY_UPLOADS";
export const GET_COMPANY_UPLOADS_START = "GET_COMPANY_UPLOADS_START";
export const GET_COMPANY_UPLOADS_FAILED = "GET_COMPANY_UPLOADS_FAILED";

export const COMPANY_UPLOAD_SALES = "COMPANY_UPLOAD_SALES";
export const COMPANY_UPLOAD_SALES_START = "COMPANY_UPLOAD_SALES_START";
export const COMPANY_UPLOAD_SALES_FAILED = "COMPANY_UPLOAD_SALES_FAILED";
export const COMPANY_UPLOAD_SALES_RESET = "COMPANY_UPLOAD_SALES_RESET";

export const COMPANY_UPLOAD_BONUS = "COMPANY_UPLOAD_BONUS";
export const COMPANY_UPLOAD_BONUS_START = "COMPANY_UPLOAD_BONUS_START";
export const COMPANY_UPLOAD_BONUS_FAILED = "COMPANY_UPLOAD_BONUS_FAILED";
export const COMPANY_UPLOAD_BONUS_RESET = "COMPANY_UPLOAD_BONUS_RESET";

export const COMPANY_UPLOAD_DIVIDEND = "COMPANY_UPLOAD_DIVIDEND";
export const COMPANY_UPLOAD_DIVIDEND_START = "COMPANY_UPLOAD_DIVIDEND_START";
export const COMPANY_UPLOAD_DIVIDEND_FAILED = "COMPANY_UPLOAD_DIVIDEND_FAILED";
export const COMPANY_UPLOAD_DIVIDEND_RESET = "COMPANY_UPLOAD_DIVIDEND_RESET";

export const COMPANY_UPLOAD_TRANSFER = "COMPANY_UPLOAD_TRANSFER";
export const COMPANY_UPLOAD_TRANSFER_START = "COMPANY_UPLOAD_TRANSFER_START";
export const COMPANY_UPLOAD_TRANSFER_FAILED = "COMPANY_UPLOAD_TRANSFER_FAILED";
export const COMPANY_UPLOAD_TRANSFER_RESET = "COMPANY_UPLOAD_TRANSFER_RESET";
