import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";

const initialState = {
    stockBrockingFirmLoading: false,
    stockBrockingFirm: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_FIRMS:
            state = {
                ...state,
                stockBrockingFirmLoading: false,
                stockBrockingFirm: action.payload,
            }
            return state;


        case actions.GET_FIRMS_START:
            state = {
                ...state,
                stockBrockingFirmLoading: true,
                stockBrockingFirm: {},
            }
            return state;


        case actions.GET_FIRMS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                stockBrockingFirmLoading: false,
                stockBrockingFirm: action.payload,
            }
            return state;


        default:
            return state;
    }
}