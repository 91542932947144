import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IButtonLoader {
    loading: boolean,
    type: "button" | "submit" | "reset",
    className?: string,
    onClick?: any,
    children: React.ReactNode,
    [x:string]: any,
}

const ButtonLoader = (props: IButtonLoader) => {

    return (
        <>
            
            <button type={props.type} disabled={props.loading} className={props.className} onClick={props.onClick}>
                <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (props.loading?"inline":"hidden")} />
                {props.children}
            </button>

        </>
    )
}

export default ButtonLoader
