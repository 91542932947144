import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DateInput, Input, SelectInput } from "components/ui";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import { Link } from "react-router-dom";
import validate from "utils/validate";
import { toast } from "react-toastify";
import { PageContent } from "components/PageContent";
import Maybe from "components/Maybe";
import ButtonLoader from "components/ButtonLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dateFormatRemoveISO } from "utils/dataTypes";
import useLoginInfo from "hooks/useLoginInfo";
import * as employeeAction from "store/entities/employee/action";
import * as banksAction from "store/ui/banks/action";

export interface CompanyEmployeesCreateSingleOfficialProps {}

export function CompanyEmployeesCreateSingleOfficial(props: CompanyEmployeesCreateSingleOfficialProps) {
  let { companyId, id } = useParams();
  let navigationBaseUrl = "/trustee/company/"+companyId;
  const {userInfo} = useLoginInfo();
  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    navigationBaseUrl = "/company/employees"
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { employeeLoading, employee, createEmployeeLoading2, createEmployee2 } = useSelector((s: any) => s.entities.employee);
  const { banksLoading, banks } = useSelector((s: any) => s.ui.banks);


  const [employeeForm, setEmployeeForm] = useState({
    officialEmail: "",
    employeeNumber: "",
    gradeCategory: "",
    dateOfHire: "",
    bankId: "",
    accountNumber: "",
  })

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    let validateParams:any = {...employeeForm};
    delete validateParams["gradeCategory"];

    const errors:any = validate(validateParams);
    if (errors){
      for (var x in errors) {
        toast.error(errors[x]);
      };
      return;
    }
    else{
      const params:any = {...employeeForm, id, organizationId: companyId};
      // console.log(params);
      dispatch(employeeAction.createStep2(params));
    }
  }

  useEffect(() => {
    if (createEmployee2.success && createEmployee2.success === true){
      dispatch(employeeAction.createStep2Reset());
      navigate(navigationBaseUrl+"/form/"+createEmployee2.data.id+"/scheme");
    }
  }, [createEmployee2]);


  const populateForm = () => {
    if (employee.data?.userInfo){
      const {data} = employee;

      if (data.id === id){
        const form = {
          firstName: data.userInfo.firstName || "",
          lastName: data.userInfo.lastName || "",
          email: data.userInfo.email || "",

          officialEmail: data.officialEmail || "",
          employeeNumber: data.employeeNumber || "",
          gradeCategory: data.gradeCategory || "",
          dateOfHire: dateFormatRemoveISO(data.dateOfHire) || "",
          bankId: data.bankId || "",
          accountNumber: data.accountNumber || "",
        }
        setEmployeeForm(form);
      }
    }
  }

  useEffect(() => {
    dispatch(employeeAction.getEmployee(id));
    dispatch(banksAction.getBanks());
  }, []);

  
  useEffect(() => {
    populateForm();
  }, [employee]);


  return (
    <div>

      <FormWizardNavBar showSteps={true} currentStep={2} totalSteps={6} closeUrl={navigationBaseUrl} />

      <div className="container pb-10 mt-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Official details
          </div>
          <p className="mt-2 text-fade text-sm">
            Fill in employee details
          </p>
        </div>

        <div className="mt-12">
          
          <PageContent loading={employeeLoading} data={employee.data || undefined} />

          <Maybe condition={employeeLoading === false && employee.data && Object.keys(employee.data).length > 0}>

            <form onSubmit={submitForm}>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input type="email" label="Official Email Address" value={employeeForm.officialEmail} onChange={(e:any) => setEmployeeForm({...employeeForm, officialEmail: e.target.value})} />
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input type="text" label="Employee ID number" value={employeeForm.employeeNumber} onChange={(e:any) => setEmployeeForm({...employeeForm, employeeNumber: e.target.value})} />
                </div>
              </div>

              <div className="flex flex-wrap sm:flex-nowrap space-y-6 sm:space-y-0 justify-between mb-6">
                <div className="w-full">
                  <Input type="text" label="Grade category" value={employeeForm.gradeCategory} onChange={(e:any) => setEmployeeForm({...employeeForm, gradeCategory: e.target.value})} />
                </div>
                <div className="w-6 flex-shrink-0 hidden sm:flex"></div>
                <div className="w-full">
                  <Input type="date" label="Date of Hire" value={employeeForm.dateOfHire} onChange={(e:any) => setEmployeeForm({...employeeForm, dateOfHire: e.target.value})} />
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <div className="text-gray-600 text-sm mb-2">Bank details</div>
                  <Maybe condition={banksLoading === true}>
                    <div className="h-12 flex items-center text-sm px-3 rounded border-2 border-gray-200">
                      <FontAwesomeIcon icon="spinner" spin className="mr-2" />
                      Loading banks...
                    </div>
                  </Maybe>
                  <Maybe condition={banksLoading === false}>
                    <SelectInput value={employeeForm.bankId} onChange={(e:any) => setEmployeeForm({...employeeForm, bankId: e.target.value})}>
                      <option value="" disabled>Bank name</option>
                      {banks.data && banks.data.map((bank:any, index:number) =>
                        <option value={bank.id} key={index}>{bank.name}</option>
                      )}
                    </SelectInput>
                  </Maybe>
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input type="number" label="Account number" value={employeeForm.accountNumber} onChange={(e:any) => setEmployeeForm({...employeeForm, accountNumber: e.target.value})} />
                </div>
              </div>

              <div className="flex justify-end space-x-3 mt-6 mb-6">
                <Link to={navigationBaseUrl + "/form/" + id + "/personal"} className="btn btn-gold">
                  Back
                </Link>
                <ButtonLoader type="submit" loading={createEmployeeLoading2} className="btn btn-brown">
                  Continue
                </ButtonLoader>
              </div>

            </form>

          </Maybe>

        </div>
      </div>

    </div>
  );
}

export default CompanyEmployeesCreateSingleOfficial;
