import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FilePicker, FileUploadPreview, Input, SelectInput } from "components/ui";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import validate from "utils/validate";
import paramsToFormData from "utils/paramsToFormData";
import { toast } from "react-toastify";
import { PageContent } from "components/PageContent";
import Maybe from "components/Maybe";
import ButtonLoader from "components/ButtonLoader";
import * as companyAction from "store/entities/company/action";

export interface CompanyCreateCompanyProps {}

export function CompanyCreateCompany(props: CompanyCreateCompanyProps) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyLoading, company, createCompanyLoading1, createCompany1 } = useSelector((s: any) => s.entities.company);
  
  
  const typesOfBusiness = [
    "Agriculture & Forestry/Wildlife",
    "Business & Information",
    "Construction/Utilities/Contracting",
    "Education",
    "Finance & Insurance",
    "Food & Hospitality",
    "Gaming",
    "Health Services",
    "Motor Vehicle",
    "Natural Resources/Environmental",
    "Personal Services",
    "Real Estate & Housing",
    "Safety/Security & Legal",
    "Transportation",
    "Others",
  ]

  const [showFileUploadLogo, setShowFileUploadLogo] = useState(true);
  const [companyForm, setCompanyForm] = useState<any>({
    organizationId: id || null,
    companyName: "",
    SubDomain: "",
    RcNumber: "",
    businessType: "",
    otherType: "",
    companySize: "",
    logo: null,
    Address1: "",
    City: "",
    PostalCode: "",
    State: "",
    Country: "",
  })


  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    let validateParams:any = {...companyForm};
    delete validateParams["organizationId"];
    delete validateParams["logo"];
    delete validateParams["otherType"];

    const errors:any = validate(validateParams);
    if (errors){
      for (var x in errors) {
        toast.error(errors[x]);
      };
      return;
    }
    else{
      const params:any = {
        ...companyForm,
        Name: companyForm.companyName,
        Size: companyForm.companySize,
        Type: companyForm.businessType,
        OtherType: companyForm.otherType,
      };

      delete params["companyName"];
      delete params["companySize"];
      delete params["businessType"];
      delete params["otherType"];
      // if (params.logo === null){
      //   delete params["logo"];
      // }
      if (params.organizationId === null){
        delete params["organizationId"];
      }

      // console.log("step 1 params", params);
      const formData = paramsToFormData(params);
      dispatch(companyAction.createStep1(formData));
    }
  }

  
  const getPageType = () => {
    if (!id){
      return true;
    }
    else if (id && companyLoading === false && company.data && Object.keys(company.data).length > 0){
      return true;
    }
    else{
      return false;
    }
  }


  useEffect(() => {
    if (createCompany1.data){
      dispatch(companyAction.createStep1Reset());
      const id = createCompany1.data.id;
      navigate("/trustee/company/form/"+id+"/admin-details");
    }
  }, [createCompany1]);


  const populateForm = () => {
    if (company.data){
      const {data} = company;

      const form = {
        organizationId: data.id || "",
        companyName: data.name || "",
        SubDomain: data.subDomain || "",
        RcNumber: data.rcNumber || "",
        businessType: data.type || "",
        otherType: data.otherType || "",
        companySize: data.size || "",
        logo: data.logoObject || null,
        Address1: data.address1 || "",
        City: data.city || "",
        PostalCode: data.postalCode || "",
        State: data.state || "",
        Country: data.country || "",
      }
      setCompanyForm(form);
      if (form.logo !== null){
        setShowFileUploadLogo(false);
      }
    }
  }
  

  useEffect(() => {
    if (id){
      dispatch(companyAction.getCompany(id));
    }
  }, []);

  
  useEffect(() => {
    if (id){
      populateForm();
    }
  }, [company]);


  return (
    <div>

      <FormWizardNavBar showSteps={true} currentStep={1} totalSteps={5} closeUrl="/trustee/company" />

      <div className="container pb-10 mt-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Company details
          </div>
          <p className="mt-2 text-fade text-sm">
            Fill in company details
          </p>
        </div>

        <div className="mt-12">

          <Maybe condition={id !== undefined}>
            <PageContent loading={companyLoading} data={company.data || undefined} />
          </Maybe>

          <Maybe condition={getPageType()}>
            <div>
              <form onSubmit={submitForm}>

                <div className="mb-6">
                  <Input type="text" label="Name of company" value={companyForm.companyName} onChange={(e:any) => setCompanyForm({...companyForm, companyName: e.target.value})} />
                </div>

                <div className="flex space-x-5 justify-between">
                  <div className="w-[50%] mb-6">
                    <Input type="text" label="Subdomain (short company name)" value={companyForm.SubDomain} onChange={(e:any) => setCompanyForm({...companyForm, SubDomain: e.target.value})} />
                  </div>
                  <div className="w-[50%] mb-6">
                    <Input type="text" label="RC Number" value={companyForm.RcNumber} onChange={(e:any) => setCompanyForm({...companyForm, RcNumber: e.target.value})} />
                  </div>
                </div>

                <div className="flex space-x-5 justify-between">
                  <div className="w-[50%] mb-6">
                    <SelectInput label="Type of business" value={companyForm.businessType} onChange={(e:any) => setCompanyForm({...companyForm, businessType: e.target.value})}>
                      <option value="" disabled>Type of business</option>
                      {typesOfBusiness.map((type, index) =>
                        <option value={type} key={index}>{type}</option>
                      )}
                    </SelectInput>
                    <Maybe condition={companyForm.businessType === "Others"}>
                      <div className="mt-2">
                        <Input type="text" label="Other types of business" value={companyForm.otherType} onChange={(e:any) => setCompanyForm({...companyForm, otherType: e.target.value})} />
                      </div>
                    </Maybe>
                  </div>
                  <div className="w-[50%] mb-6">
                    <Input type="number" label="Number of employees" value={companyForm.companySize} onChange={(e:any) => setCompanyForm({...companyForm, companySize: e.target.value})} />
                  </div>
                </div>

                <div className="mb-7">
                  <Maybe condition={showFileUploadLogo === false}>
                    <Maybe condition={companyForm.logo !== null}>
                      <FileUploadPreview
                        className="bg-gray-100"
                        file={companyForm.logo}
                        allowDismiss={true}
                        cancelUpload={() => {setCompanyForm({...companyForm, logo: null}); setShowFileUploadLogo(true)}} />
                    </Maybe>
                  </Maybe>
                  <Maybe condition={showFileUploadLogo === true}>
                    <FilePicker content={(e:any) => setCompanyForm({...companyForm, logo: e[0]})} />
                  </Maybe>
                </div>

                <div className="flex flex-wrap mb-6">
                  <div className="w-full">
                    <Input label="Address line 1" type={"text"} value={companyForm.Address1} onChange={(e:any) => setCompanyForm({...companyForm, Address1: e.target.value})} />
                  </div>
                </div>

                <div className="flex space-x-5 justify-between">
                  <div className="w-[50%] mb-6">
                    <Input type="text" label="City" value={companyForm.City} onChange={(e:any) => setCompanyForm({...companyForm, City: e.target.value})} />
                  </div>
                  <div className="w-[50%] mb-6">
                    <Input type="text" label="Postal/Zip code" value={companyForm.PostalCode} onChange={(e:any) => setCompanyForm({...companyForm, PostalCode: e.target.value})} />
                  </div>
                </div>

                <div className="flex space-x-5 justify-between">
                  <div className="w-[50%] mb-6">
                    <Input type="text" label="State" value={companyForm.State} onChange={(e:any) => setCompanyForm({...companyForm, State: e.target.value})} />
                  </div>
                  <div className="w-[50%] mb-6">
                    <Input type="text" label="Country" value={companyForm.Country} onChange={(e:any) => setCompanyForm({...companyForm, Country: e.target.value})} />
                  </div>
                </div>

                <div className="flex justify-end space-x-3 mt-6 mb-6">
                  {/* 
                  <Link to="/trustee/company" className="btn btn-gold">
                    Back
                  </Link>
                   */}
                  <ButtonLoader type="submit" loading={createCompanyLoading1} className="btn btn-brown">
                    Continue
                  </ButtonLoader>
                </div>

              </form>
            </div>
          </Maybe>

        </div>
      </div>

    </div>
  );
}

export default CompanyCreateCompany;
