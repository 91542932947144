import { Link } from "react-router-dom";
import { nameInitialsCompanyName } from "utils/nameInitials";
import iconCall from "assets/images/icons/call.svg";
import iconMail from "assets/images/icons/mail.svg";
import iconEdit from "assets/images/icons/edit.svg";
import iconCalendar from "assets/images/icons/calendar.svg";
import iconUsers from "assets/images/icons/users.svg";
import iconCompany from "assets/images/icons/company.svg";

/* eslint-disable-next-line */
export interface UserInfoCardProps {
  name: string;
  image?: string;
  imageSize?: string;
  email?: string;
  tel?: string;
  company?: string;
  employeesCount?: string;
  idNumber?: string;
  iconUrl?: string;
  date?: string;
  status?: string;
}

export function UserInfoCard(props: UserInfoCardProps) {
  const { image, imageSize, name, email, tel, date, company, employeesCount, status, idNumber, iconUrl } = props;

  const imageSizeRender:number = imageSize ? parseInt(imageSize) : 20;
  const styles = {
    width: (imageSizeRender/4) + "rem",
    height: (imageSizeRender/4) + "rem",
  }

  
  return (
    <>
      <div className="flex p-3 sm:p-6 text-white bg-vetiva-black bg-vetiva-pattern rounded">
        <div style={styles} className="mr-3 my-auto text-2xl flex flex-shrink-0 items-center justify-center font-bold bg-vetiva-brown rounded-full overflow-hidden">
          {image ? <img src={image} alt="logo" className="object-cover" /> : nameInitialsCompanyName(name)}
        </div>
        <div className="flex-grow">
          <div className="font-black sm:flex justify-between">

            <div className="font-bold text-xl flex-grow">
              {name}
            </div>

            <div className="flex space-x-1">
            {idNumber &&
              <div className="px-2 py-1 my-auto font-normal inline sm:flex flex-shrink-0 bg-vetiva-brown text-xs uppercase rounded-md">
                {idNumber}
              </div>
            }
            {iconUrl &&
              <Link to={iconUrl} className="w-6 h-6 p-0.5 my-auto rounded-md hover:bg-gray-600">
                <img src={iconEdit} alt="Edit" className="m-auto" />
              </Link>
            }
            </div>

          </div>
          <div className="mt-2 text-sm">

            {tel &&
            <div className="flex">
              <div className="flex-shrink-0 w-4 h-4 mr-2">
                <img src={iconCall} alt="tel" className="mt-0.5" />
              </div>
              <div>
                {tel}
              </div>
            </div>
            }

            {email &&
              <div className="flex">
                <div className="flex-shrink-0 w-4 h-4 mr-2">
                  <img src={iconMail} alt="mail" className="mt-0.5" />
                </div>
                <div>
                  {email}
                </div>
              </div>
            }

            
            <div className="flex justify-between">
              {date &&
                <div className="flex">
                  <div className="flex-shrink-0 w-4 h-4 mr-2">
                    <img src={iconCalendar} alt="date" />
                  </div>
                  <div>
                    {date}
                  </div>
                </div>
              }
              
              {status &&
                <div>
                  Status: {status}
                </div>
              }
            </div>


            <div className="flex justify-between">
              {company &&
                <div className="flex">
                  <div className="flex-shrink-0 w-4 h-4 mr-2">
                    <img src={iconCompany} alt="mail" className="mt-0" />
                  </div>
                  <div>
                    {company}
                  </div>
                </div>
              }

              {employeesCount &&
                <div className="flex">
                  <div className="flex-shrink-0 w-5 h-5 mr-2 flex items-center">
                    <img src={iconUsers} alt="users" />
                  </div>
                  <div>
                    {employeesCount}
                  </div>
                </div>
              }
            </div>


          </div>
        </div>
      </div>
    </>
  );
}

export default UserInfoCard;
