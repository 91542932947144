import { FormWizardNavBar } from "components/form-wizard-navbar";
import { Link, useParams } from "react-router-dom";
import iconsuccess from "assets/images/icons/success.gif";
import useLoginInfo from "hooks/useLoginInfo";

export interface CompanyEmployeeInstructionSalesSuccessProps {}

export function CompanyEmployeeInstructionSalesSuccess(props: CompanyEmployeeInstructionSalesSuccessProps) {
  const {userInfo} = useLoginInfo();
  let { companyId, employeeId } = useParams();
  let employeeDetailsUrl = "/trustee/company/"+companyId+"/employee/"+employeeId;
  let sendInstructionSelectionUrl = "/trustee/company/"+companyId+"/employee/"+employeeId;
  
  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    employeeDetailsUrl = "/company/employees/"+employeeId;
    sendInstructionSelectionUrl = employeeDetailsUrl;
  }
  else if (userInfo.userType === "SCHEME_MEMBER"){
    companyId = userInfo.organizationId;
    employeeId = userInfo.employeeId;
    employeeDetailsUrl = "/member/overview";
    sendInstructionSelectionUrl = "/member";
  }

  return (
    <div>

      <FormWizardNavBar currentStep={3} totalSteps={3} showSteps={true} closeUrl={employeeDetailsUrl} />

      <div className="container mt-20 max-w-2xl mx-auto text-center">
        
          <div className="h-48">
            <img src={iconsuccess} alt="Success" className="object-contain" />
          </div>

          <div className="mt-4 text-2xl">
            Successful!
          </div>
          <div className="mt-1 text-gray-400">
            You have successfully sold your shares
          </div>

          <div>
            <div className="mt-6 flex flex-wrap space-x-6 justify-center">
              <Link to={sendInstructionSelectionUrl + "/instruction/"} className="underline text-vetiva-brown">
                Send new instruction
              </Link>
              <Link to={employeeDetailsUrl} className="underline text-vetiva-brown">
                Go to dashboard
              </Link>
            </div>
          </div>

      </div>

    </div>
  );
}

export default CompanyEmployeeInstructionSalesSuccess;
