import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export interface BatchAccordionProps {
  label: any;
  isOpen: boolean;
  children: any;
  customStyles?: boolean;
}

export const BatchAccordion = (props: BatchAccordionProps) => {
  const { label, children, customStyles } = props;
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);

  const defaultStyles= (open ? "bg-vetiva-gold-lighter" : "border border-gray-300") + " rounded-md px-4 py-4 antialiased leading-snug select-none hover:text-blue-gray-900 transition-colors text-vetiva-black text-sm";

  return (
    <div className="block relative w-full">
      <div
        onClick={handleClick}
        className={(customStyles !== undefined ? customStyles : defaultStyles) + " flex justify-between items-center w-full cursor-pointer"}
      >
        {label}
        <FontAwesomeIcon
          className={`${
            open ? "rotate-0 transition-all" : "rotate-180 transition-all"
          }`}
          icon={faChevronUp}
        />
      </div>
      <div>
        <div
          className={`${
            open ? "h-auto" : "h-0 hidden"
          } transition-all duration-1000 ease-in-out w-full py-0 text-gray-700 antialiased text-sm font-light leading-normal`}
          style={{ opacity: "1" }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default BatchAccordion;
