import { isDate, dateFormatSubmit } from "utils/dataTypes";


export const jsonToFormData = (obj, form, namespace) => {
  
  var fd = form || new FormData();
  var formKey;
  
  for(var property in obj) {
    if(obj.hasOwnProperty(property)) {
      
      if(namespace) {
        // formKey = namespace + '[' + property + ']';
        if (isNaN(property)){
          formKey = namespace + '.' + property;
        }
        else{
          formKey = namespace + '[' + property + ']';
        }
      } else {
        formKey = property;
      }
     
      // if the property is an object, but not a File,
      // use recursivity.
      if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        
        jsonToFormData(obj[property], fd, formKey);
        
      } else {
        
        // if it's a string or a File object

        let value = obj[property];
        if (isDate(obj[property])) {
          value = dateFormatSubmit(value);
        }
        
        fd.append(formKey, value);
      }
      
    }
  }
  
  return fd;

};