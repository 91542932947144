
export const paramsToFormData = (params) => {
    let formData = new FormData();

    Object.keys(params).map((key) =>
      formData.append(key, params[key])
    )

    return formData;
}

export default paramsToFormData;
