import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPageNumber } from "utils/getPageNumber";
import { Link } from "react-router-dom";
import Maybe from "./Maybe";


interface TableContentProps {
    loading: boolean,
    loadingText?: string,
    data: any,
    colspan: number,
}


export const TableContent = (props: TableContentProps) => {
    const {loading, loadingText, data, colspan} = props;
    let thisCurrentPage = 1;
    let thisPreviousPage = null;
    let thisTotalPages = 10;
    let thisTotal = 0;

    if (data?.meta?.pagination){
        const {nextPage, previousPage, totalPages, pageSize, total} = data.meta.pagination;
    
        thisPreviousPage = getPageNumber(previousPage);
        thisTotalPages = totalPages;
        thisTotal = total;
        
        if (thisPreviousPage === null){
            thisCurrentPage = 1;
        }
        else{
            thisCurrentPage = parseInt(thisPreviousPage) + 1;
        }
    }
    

    return (
        <>


            <Maybe condition={loading === true}>
                <tr className="table-no-data">
                    <td colSpan={colspan}>
                        <div className="table-info">
                            <FontAwesomeIcon icon="spinner" spin />
                            <div className="font-bold uppercase">
                                {loadingText || "Loading"}
                            </div>
                        </div>
                    </td>
                </tr>
            </Maybe>

            
            <Maybe condition={loading === false && !data}>
                <tr className="table-no-data">
                    <td colSpan={colspan}>
                        <div className="table-info">
                            <FontAwesomeIcon icon="unlink" />
                            <div className="font-bold uppercase">
                                An error occurred
                                <br />
                                Please try again later.
                            </div>
                        </div>
                    </td>
                </tr>
            </Maybe>


            <Maybe condition={loading === false && data?.data?.length <= 0}>
                <tr className="table-no-data">
                    <td colSpan={colspan}>

                        {/* <Maybe condition={thisCurrentPage <= thisTotalPages}> */}
                        {/* <Maybe condition={thisTotal === 0 || thisCurrentPage <= thisTotalPages}> */}
                        <Maybe condition={thisTotal === 0}>
                            <div className="table-info">
                                <FontAwesomeIcon icon="list" />
                                <div className="font-bold uppercase">
                                    No results found
                                </div>
                            </div>
                        </Maybe>
                        
                        {/* <Maybe condition={thisCurrentPage > thisTotalPages}> */}
                        {/* <Maybe condition={thisTotal !== 0 || thisCurrentPage > thisTotalPages}> */}
                        <Maybe condition={thisTotal !== 0}>
                            <div className="table-info">
                                <FontAwesomeIcon icon="exclamation-triangle" />
                                <div className="font-bold uppercase">
                                    Invalid Page Number
                                </div>
                                <div className="mt-2">
                                    <Link to="?page=1" className="btn btn-vetiva-brown btn-sm mx-auto">Goto Page 1</Link>
                                </div>
                            </div>
                        </Maybe>

                    </td>
                </tr>
            </Maybe>


        </>
    )
}
