
export const renderStatusColor = (status) => { 
  if (status !== "" && status !== undefined){
    const statusObject = {
      "active": "label-green",
      "deactivated": "label-red",
      "disable": "label-red",
      "disabled": "label-red",
      "exit": "label-red",
      "exited": "label-red",
      "draft": "bg-[#f0f5ff] text-purple",
      "pending": "label-yellow",
    }
    return statusObject[status.toLocaleLowerCase()] || "";
  }
  return "";
};