import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as selectionAction from "store/ui/selections/action";


const useMultiSelect = () => {
    const dispatch = useDispatch();
    const { selections: savedSelections } = useSelector((s: any) => s.ui.selections);
    const [selections, setSelections] = useState<any>(savedSelections);
    

    const toggleMultiSelectHeader = () => {
        const selectionHead:any = document.querySelector(".multi-select-header");
        if (selectionHead.checked === true){
            toggleSelectAll(true);
        }
        else{
            toggleSelectAll(false);
        }
    }


    const toggleSelectAll = (action: boolean) => {
        const selectionsBody:any = document.querySelectorAll(".multi-select");
        selectionsBody.forEach((selectionBody:any) => {
            selectionBody.checked = action;
        });
        getMultiSelectBody();
    }


    const checkMultiSelectHeader = () => {
        const selectionHead:any = document.querySelector(".multi-select-header");
        const selectionsBody:any = document.querySelectorAll(".multi-select");
        let deselections = 0;

        selectionsBody.forEach((selection:any) => {
            if (selection.checked !== true){
                deselections++;
            }
        });

        if (deselections === 0){
            selectionHead.checked = true;
        }
        else{
            selectionHead.checked = false;
        }
    }


    const getMultiSelectBody = () => {
        checkMultiSelectHeader();
        const selectionsBody = document.querySelectorAll(".multi-select");
        let selectionsCopy = [...selections];
        selectionsBody.forEach((selection:any) => {
            if (selection.checked === true){
                // if (!selectionsCopy.includes(selection.value)){
                if (selectionsCopy.filter(item => item === selection.value).length === 0){
                    selectionsCopy.push(selection.value);
                }
            }
            else{
                selectionsCopy = selectionsCopy.filter(item => item !== selection.value);
            }
        });
        setSelections(selectionsCopy);
    }
    
    
    const removeFromSelections = (data:any) => {
        if (data.length > 0){
            const newSelection = selections.filter((item:any) => !data.includes(item));
            setSelections(newSelection);
        }
    }


    const clearSelections = () => {
        toggleSelectAll(false);
        setSelections([]);
    }


    useEffect(() => {
        dispatch(selectionAction.saveSelection(selections));
    }, [selections]);


    return {toggleMultiSelectHeader, selections, getMultiSelectBody, clearSelections, removeFromSelections};
}


export default useMultiSelect;
