import * as actions from "./actionTypes";
import * as config from "data/config";

export function getFirms() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetStockBrockingFirm,
            method: "get",
            data: {},
            onStart: actions.GET_FIRMS_START,
            onSuccess: actions.GET_FIRMS,
            onError: actions.GET_FIRMS_FAILED,
        },
    }
}
