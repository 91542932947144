import {
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export interface PreviewAccordionProps {
  allocType: string;
  countType: string;
  stats: string;
  children: any;
}

export const PreviewAccordion = (props: PreviewAccordionProps) => {
  const { allocType, countType, stats, children } = props;
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);

  return (
    <div className="block relative w-full">
      <span
        className="flex justify-between items-center w-full px-0 py-0 cursor-pointer focus:none"
        onClick={handleClick}
      >
        <div className="">
          <label className="text-fade text-body1 leading-body1 font-normal mr-2">
            {allocType}
          </label>
          <span
              className={`${
                open ? "bg-vetiva-brown" : "bg-gray-400"
              } label rounded-full text-white relative -top-0.5`}
            >
              {countType}
            </span>
        </div>

        <div className="flex justify-center items-center text-fade text-body1">
          <div className="mx-4 text-fade">{stats}</div>

          <div className="">
            <FontAwesomeIcon
              className={`${
                open ? "rotate-0 transition-all" : "rotate-180 transition-all"
              }`}
              icon={faChevronUp}
            />
          </div>
        </div>
      </span>
      <div className="overflow-hidden">
        <div
          className={`${
            open ? "h-auto" : "h-0"
          } transition-all ease-in-out delay-150 duration-300 w-full py-0 text-gray-700 antialiased text-sm font-light leading-normal`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default PreviewAccordion;
