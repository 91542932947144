
export interface SubSchemeTemplateProps {
  label: string;
  count: number;
  onItemClick: () => void;
}

export function SubSchemeTemplate(props: SubSchemeTemplateProps) {
  const { label, count, onItemClick } = props;
  
  const plural = (data: string) => {
    if (data === "Batch") return "batches";
    else if (data === "Group") return "groups";
    else if (data === "Category") return "categories";
    else return "";
  }

  return (
    <>
      <div className="flex justify-between items-center p-2 border border-gray-300 bg-white cursor-pointer" onClick={onItemClick}>
        <div className="flex justify-center items-center">
          <span className="m-3">
            <svg
              width="32"
              height="33"
              viewBox="0 0 32 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="32" height="32" rx="12" fill="#F1F2F6" />
              <g clipPath="url(#clip0_547_64352)">
                <path
                  d="M16.5625 11.4375H10.9375V9.1875C10.9375 8.88913 11.056 8.60298 11.267 8.392C11.478 8.18103 11.7641 8.0625 12.0625 8.0625H15.4375C15.7359 8.0625 16.022 8.18103 16.233 8.392C16.444 8.60298 16.5625 8.88913 16.5625 9.1875V11.4375Z"
                  stroke="#8491A5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.1875 22.6875H8.6875C8.38913 22.6875 8.10298 22.569 7.892 22.358C7.68103 22.147 7.5625 21.8609 7.5625 21.5625V10.3125C7.5625 10.0141 7.68103 9.72798 7.892 9.517C8.10298 9.30603 8.38913 9.1875 8.6875 9.1875H10.9375"
                  stroke="#8491A5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.5625 9.1875H18.8125C19.1109 9.1875 19.397 9.30603 19.608 9.517C19.819 9.72798 19.9375 10.0141 19.9375 10.3125V13.6875"
                  stroke="#8491A5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.1875 20.4375H9.8125V11.4375H17.6875V13.6852"
                  stroke="#8491A5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.4375 15.9375H24.4375V24.9375H15.4375V15.9375Z"
                  stroke="#8491A5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.6875 18.1875H22.1875"
                  stroke="#8491A5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.6875 20.4375H22.1875"
                  stroke="#8491A5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.6875 22.6875H19.375"
                  stroke="#8491A5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_547_64352">
                  <rect
                    width="18"
                    height="18"
                    fill="white"
                    transform="translate(7 7.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div className="">
            <label className="block">{label}</label>
            <span className="block text-sm text-fade">
              Number of {plural(label)}: <span className="font-bold">{count}</span>
              {/* &nbsp;&nbsp;&nbsp;&nbsp; / &nbsp;&nbsp;&nbsp; */}
              {/* Attachments: <span className="font-bold">12</span> */}
            </span>
          </div>
        </div>
        <div className="">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 6L14.7173 11.7173C14.7545 11.7544 14.784 11.7985 14.8041 11.847C14.8242 11.8955 14.8345 11.9475 14.8345 12C14.8345 12.0525 14.8242 12.1045 14.8041 12.153C14.784 12.2015 14.7545 12.2456 14.7173 12.2827L9 18"
              stroke="#B5BDC9"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </>
  );
}

export default SubSchemeTemplate;
