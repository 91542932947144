import { Fragment } from "react";
import { Link } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import {ReactComponent as NotificationBellIcon } from "assets/images/icons/notificationBell.svg";
import logo from "assets/images/icons/logo.svg";
import { Menu } from "@headlessui/react";
import useLoginInfo from "hooks/useLoginInfo";
import { nameInitials } from "utils/nameInitials";

const Header = () => {

    
    // const [mobileNav, setMobileNav] = useState<boolean>(false);
    const {userInfo, logoutUser} = useLoginInfo();

    /*
    const toggleMobileNav = () => {
        const mobileMenu = document.querySelector(".mobile-menu");
        const navIcon = document.querySelector("#nav-icon");

        if (!mobileNav){
            mobileMenu?.classList.remove("hidden");
            navIcon?.classList.add("open");
        }
        else{
            mobileMenu?.classList.add("hidden");
            navIcon?.classList.remove("open");
        }
        
        setMobileNav(!mobileNav);
    }
    */

    return (
        <div className="fixed top-0 left-0 right-0 z-20 text-white bg-vetiva-black border-b border-white border-opacity-5">
            <div className="container w-full h-16 flex justify-between items-center">
            
                <Link to="/" className="flex items-center">
                    <div className="w-8 h-8 mr-1">
                        <img src={logo} alt="Vetiva" className="w-full h-full object-contain" />
                    </div>
                    <div className="text-lg font-normal font-noto leading-4">
                        VTL Staff Share Scheme
                    </div>
                </Link>
                
                <div className="hidden md:flex space-x-4 header-links">
                    <NavLink to="/trustee/overview">
                        <div>Overview</div>
                    </NavLink>
                    <NavLink to="/trustee/company">
                        <div>Companies</div>
                    </NavLink>
                    <NavLink to="/trustee/users">
                        <div>Users Mgt</div>
                    </NavLink>
                </div>

                <div className="hidden md:flex items-center gap-5 relative">
                    <div className="hidden w-8 h-8 p-1 flex items-center justify-center hover:bg-gray-700 cursor-pointer rounded-md">
                        <NotificationBellIcon />
                    </div>
                    
                    <Menu>
                        <Menu.Button as={Fragment}>
                            <div className="w-8 h-8 text-center flex items-center justify-center text-xs rounded-full bg-vetiva-purple cursor-pointer">
                                {nameInitials(userInfo.firstName, userInfo.lastName)}
                            </div>
                        </Menu.Button>
                        <Menu.Items className="dropdown dropdown-right top-10 rounded-xl">
                            <div className="w-64 text-black">
                                <div className="h-16 bg-vetiva-black border border-b-0 border-gray-800"></div>
                                <div className="w-16 h-16 -mt-8 text-white text-xl font-bold bg-vetiva-purple rounded-full mx-auto uppercase flex items-center justify-center">
                                    {nameInitials(userInfo.firstName, userInfo.lastName)}
                                </div>
                                <div className="px-2 py-6 text-center bg-white">
                                    <div>
                                        {userInfo.name}
                                    </div>
                                    <div className="text-fade text-sm">
                                        {userInfo.email}
                                    </div>
                                </div>
                                <div>
                                    <div onClick={logoutUser} className="h-10 text-sm flex items-center justify-center bg-vetiva-gold hover:text-white hover:bg-vetiva-brown cursor-pointer">
                                        Logout
                                    </div>
                                </div>
                            </div>
                        </Menu.Items>
                    </Menu>
                </div>
                
                <div className="p-2 md:hidden cursor-pointer hover:bg-gray-700 rounded">
                    <svg
                    width="26"
                    height="18"
                    viewBox="0 0 26 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        d="M13 17.5H0.25V14.6667H13V17.5ZM25.75 10.4167H0.25V7.58333H25.75V10.4167ZM25.75 3.33333H13V0.5H25.75V3.33333Z"
                        fill="white"
                    />
                    </svg>
                </div>
            </div>
                

            {/* 
            <div className="h-9 md:hidden flex">
                <div onClick={toggleMobileNav} id="nav-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
                

            <div className="mt-10 mobile-menu hidden bg-blur w-full h-full fixed z-40 inset-0 overflow-auto overscroll-contain">
                <div className="w-full h-full flex bg-white bg-opacity-80">
                    <div className="my-auto pb-20">

                        <div className="w-screen">
                            <div className="header-links-mobile">
                                <Link to="/">Home</Link>
                            </div>
                            <div className="header-links-mobile">
                                <Link to="/">Search</Link>
                            </div>
                            <div className="header-links-mobile">
                                <Link to="/">Browse Products</Link>
                            </div>
                            <div className="header-links-mobile">
                                <Maybe condition={userLogin}>
                                    <Link to="/account">{userInfo?.firstName}</Link>
                                </Maybe>
                                <Maybe condition={!userLogin}>
                                    <Link to="/login">Login / Register</Link>
                                </Maybe>
                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>
            */}


        </div>
    )
}

export default Header
