import { useEffect, useState } from "react";
import { ToggleSwitch } from "components/ui";

export interface AccordionProps {
  label: any;
  isOpen: boolean;
  onChange?: any;
  children: any;
}

export const Accordion = (props: AccordionProps) => {
  const { label, isOpen, onChange, children } = props;
  const [open, setCollapsed] = useState(isOpen);
  const handleCheck = (event: any) => {
    setCollapsed(event);
    onChange(event);
  }

  useEffect(() => {
    setCollapsed(isOpen);
  }, [isOpen])
  
  return (
    <div className="block relative w-full">
      <div className="shadow border border-gray-100 rounded flex justify-between items-center w-full px-4 py-4 text-sm text-vetiva-brown">
        {label}
        <ToggleSwitch isChecked={open} onCheck={handleCheck} />
      </div>
      <div className="overflow-hidden">
        <div
          className={`${
            open ? "h-auto" : "h-0"
          } transition-all ease-in-out delay-150 w-full py-0 text-gray-700 antialiased text-sm font-light leading-normal`}
          style={{ opacity: "1" }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
