import * as actions from "./actionTypes";
import { toast } from 'react-toastify';
import errorReducer from "utils/errorReducer";

const initialState = {
    userMgmtDashboardStatsLoading: false,
    userMgmtDashboardStats: {},

    userMgmtsLoading: false,
    userMgmts: {},

    userMgmtLoading: false,
    userMgmt: {},

    createUserMgmtLoading: false,
    createUserMgmt: {},
    editUserMgmtLoading: false,
    editUserMgmt: {},

    userMgmtActivateLoading: false,
    userMgmtActivateSuccess: false,
    userMgmtDeactivateLoading: false,
    userMgmtDeactivateSuccess: false,
    userMgmtResendInviteLoading: false,
    userMgmtResendInviteSuccess: false,

    userActivityHistoryLoading: false,
    userActivityHistory: {},

    companyAdminDashboardStatsLoading: false,
    companyAdminDashboardStats: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_USERMGMTS_DASHBOARD_STATS:
            state = {
                ...state,
                userMgmtDashboardStatsLoading: false,
                userMgmtDashboardStats: action.payload,
            }
            return state;


        case actions.GET_USERMGMTS_DASHBOARD_STATS_START:
            state = {
                ...state,
                userMgmtDashboardStatsLoading: true,
                userMgmtDashboardStats: {},
            }
            return state;


        case actions.GET_USERMGMTS_DASHBOARD_STATS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                userMgmtDashboardStatsLoading: false,
                userMgmtDashboardStats: { ...action.payload },
            }
            return state;
    

        case actions.GET_USERMGMTS:
            state = {
                ...state,
                userMgmtsLoading: false,
                userMgmts: { ...action.payload },
            }
            return state;


        case actions.GET_USERMGMTS_START:
            state = {
                ...state,
                userMgmtsLoading: true,
                userMgmts: {},
            }
            return state;


        case actions.GET_USERMGMTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                userMgmtsLoading: false,
                userMgmts: { ...action.payload },
            }
            return state;
    

        case actions.GET_USERMGMT:
            state = {
                ...state,
                userMgmtLoading: false,
                userMgmt: { ...action.payload },
            }
            return state;


        case actions.GET_USERMGMT_START:
            state = {
                ...state,
                userMgmtLoading: true,
                userMgmt: {},
            }
            return state;


        case actions.GET_USERMGMT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                userMgmtLoading: false,
                userMgmt: { ...action.payload },
            }
            return state;
    

        case actions.CREATE_USERMGMT:
            state = {
                ...state,
                createUserMgmtLoading: false,
                createUserMgmt: { ...action.payload },
            }
            return state;


        case actions.CREATE_USERMGMT_START:
            state = {
                ...state,
                createUserMgmtLoading: true,
                createUserMgmt: {},
            }
            return state;


        case actions.CREATE_USERMGMT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                createUserMgmtLoading: false,
                createUserMgmt: { ...action.payload },
            }
            return state;


        case actions.CREATE_USERMGMT_RESET:
            state = {
                ...state,
                createUserMgmtLoading: false,
                createUserMgmt: {},
            }
            return state;


        case actions.EDIT_USERMGMT:
            toast.success("User Updated");
            state = {
                ...state,
                editUserMgmtLoading: false,
                editUserMgmt: { ...action.payload },
            }
            return state;
            
        
        case actions.EDIT_USERMGMT_START:
            state = {
                ...state,
                editUserMgmtLoading: true,
                editUserMgmt: {},
            }
            return state;
            
            
        case actions.EDIT_USERMGMT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                editUserMgmtLoading: false,
                editUserMgmt: { ...action.payload },
            }
            return state;





        case actions.ACTIVATE_USERMGMT:
            toast.success("User Activated");
            // toast.success(action.payload.message);
            state = {
                ...state,
                userMgmtActivateLoading: false,
                userMgmtActivateSuccess: true,
                reloadPage: true,
            }
            return state;


        case actions.ACTIVATE_USERMGMT_START:
            toast("Activating User");
            state = {
                ...state,
                userMgmtActivateLoading: true,
                userMgmtActivateSuccess: false,
            }
            return state;


        case actions.ACTIVATE_USERMGMT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                userMgmtActivateLoading: false,
                userMgmtActivateSuccess: false,
            }
            return state;


        case actions.DEACTIVATE_USERMGMT:
            toast.success("User Deactivated");
            // toast.success(action.payload.message);
            state = {
                ...state,
                userMgmtDeactivateLoading: false,
                userMgmtDeactivateSuccess: true,
                reloadPage: true,
            }
            return state;


        case actions.DEACTIVATE_USERMGMT_START:
            toast("Deactivating User");
            state = {
                ...state,
                userMgmtDeactivateLoading: true,
                userMgmtDeactivateSuccess: false,
            }
            return state;


        case actions.DEACTIVATE_USERMGMT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                userMgmtDeactivateLoading: false,
                userMgmtDeactivateSuccess: false,
            }
            return state;


        case actions.RESEND_INVITE_USERMGMT:
            toast.success("Invite sent");
            state = {
                ...state,
                userMgmtResendInviteLoading: false,
                userMgmtResendInviteSuccess: true,
                reloadPage: true,
            }
            return state;


        case actions.RESEND_INVITE_USERMGMT_START:
            toast("Resending invite...");
            state = {
                ...state,
                userMgmtResendInviteLoading: true,
                userMgmtResendInviteSuccess: false,
            }
            return state;


        case actions.RESEND_INVITE_USERMGMT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                userMgmtResendInviteLoading: false,
                userMgmtResendInviteSuccess: false,
            }
            return state;


        case actions.GET_USER_ACTIVITY_HISTORY:
            state = {
                ...state,
                userActivityHistoryLoading: false,
                userActivityHistory: action.payload,
            }
            return state;


        case actions.GET_USER_ACTIVITY_HISTORY_START:
            state = {
                ...state,
                userActivityHistoryLoading: true,
                userActivityHistory: {},
            }
            return state;


        case actions.GET_USER_ACTIVITY_HISTORY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                userActivityHistoryLoading: false,
                userActivityHistory: action.payload,
            }
            return state;


        case actions.GET_COMPANY_USERMGMTS_DASHBOARD_STATS:
            state = {
                ...state,
                companyAdminDashboardStatsLoading: false,
                companyAdminDashboardStats: action.payload,
            }
            return state;


        case actions.GET_COMPANY_USERMGMTS_DASHBOARD_STATS_START:
            state = {
                ...state,
                companyAdminDashboardStatsLoading: true,
                companyAdminDashboardStats: {},
            }
            return state;


        case actions.GET_COMPANY_USERMGMTS_DASHBOARD_STATS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                companyAdminDashboardStatsLoading: false,
                companyAdminDashboardStats: action.payload,
            }
            return state;
    

        default:
            return state;
    }
}