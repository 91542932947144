import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { TimeDateBlock } from "components/time-date-block";
import { RfcSearchBox } from "components/rfc-search-box";
import { Pagination } from "components/Pagination";
import { renderStatusColor } from "utils/statusColor";
import useUrlNavigation from "hooks/useUrlNavigation";
import { FormDrawer, Input, SelectInput } from "components/ui";
import iconDisabled from "assets/images/icons/disabled.svg";
import iconFilter from "assets/images/icons/filter_list.svg";
import iconRedo from "assets/images/icons/redo.svg";
import iconEdit from "assets/images/icons/edit.svg";
import iconSuccess from "assets/images/icons/success.gif";
import Maybe from "components/Maybe";
import DialogBox from "utils/dialogBox";
import NumberFormat from "utils/numberFormat";
import { TableContent } from "components/TableContent";
import { nameInitials } from "utils/nameInitials";
import validate from "utils/validate";
import { toast } from "react-toastify";
import ButtonLoader from "components/ButtonLoader";
import DateFormat from "utils/dateFormat";
import { dateFormatSubmit } from "utils/dataTypes";
import SearchBar from "components/SearchBar";
import { PageContent } from "components/PageContent";
import * as userMgmtAction from "store/entities/userMgmt/action";

export interface UsersMgmtListProps { }

export function UsersMgmtList(props: UsersMgmtListProps) {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { urlQuery } = useUrlNavigation();
  const [searchParams] = useSearchParams();

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenAdmin, setIsOpenAdmin] = useState(false);
  const [isOpenAdminForm, setIsOpenAdminForm] = useState(false);
  const [adminFormDrawerType, setAdminFormDrawerType] = useState("");
  const { userMgmtDashboardStatsLoading, userMgmtDashboardStats, userMgmtsLoading, userMgmts,
    userMgmtActivateSuccess, userMgmtDeactivateSuccess, createUserMgmtLoading, createUserMgmt, editUserMgmtLoading, editUserMgmt } = useSelector((s: any) => s.entities.userMgmt);

  const [admin, setAdmin] = useState<any>({});
  const [companyParams, setCompanyParams] = useState<any>({
    "PageNumber": 1,
    "PageSize": 20,
    "dateCreated": searchParams.get("dateCreated") || "",
    "status": searchParams.get("status") || "",
    "lastActivity": searchParams.get("lastActivity") || "",
  });

  const [adminForm, setAdminForm] = useState<any>({
    "firstName": "",
    "lastName": "",
    "email": "",
    "phoneNumber": "",
    "receiveInstructionMail": "",
  });

  const [filterForm, setFilterForm] = useState<any>({
    "dateCreated": searchParams.get("dateCreated") || "",
    "status": searchParams.get("status") || "",
    "lastActivity": searchParams.get("lastActivity") || "",
  });

  const toggleFormDrawerFilter = (action: boolean) => {
    setIsOpenFilter(action);
  };

  const toggleFormDrawerAdmin = (user: any, action: boolean) => {
    setAdmin(user);
    setIsOpenAdmin(action);
  };

  const toggleFormDrawerAdminForm = (action: boolean, type: string, user?: any) => {
    setIsOpenAdminForm(action);
    setAdminFormDrawerType(type);

    if (user !== undefined) {
      setAdmin(user);
      setAdminForm({
        "firstName": user.firstName,
        "lastName": user.lastName,
        "email": user.email,
        "phoneNumber": user.phoneNumber,
        "receiveInstructionMail": user.receiveInstructionMail,
      });
    }
    else {
      emptyAdminForm();
    }
  };

  const emptyAdminForm = () => {
    setAdminForm({
      "firstName": "",
      "lastName": "",
      "email": "",
      "phoneNumber": "",
      "receiveInstructionMail": "",
    })
  }

  const getDashboardStats = () => {
    dispatch(userMgmtAction.getUserMgmtDashboardStats());
  }

  const getUserMgmts = () => {
    let params = { ...companyParams };

    const page = searchParams.get("page");
    if (page !== null) {
      params = { ...params, PageNumber: parseInt(page) }
    }
    else {
      params = { ...params, PageNumber: 1 }
    }

    const limit = searchParams.get("limit");
    if (limit !== null) {
      params = { ...params, PageSize: parseInt(limit) }
    }
    else {
      params = { ...params, PageSize: 20 }
    }

    const search = searchParams.get("search");
    if (search !== null) {
      params = { ...params, search }
    }

    const dateCreated = searchParams.get("dateCreated");
    if (dateCreated !== null) {
      params = { ...params, dateCreated: dateFormatSubmit(dateCreated) }
    }

    const status = searchParams.get("status");
    if (status !== null) {
      params = { ...params, status }
    }

    const lastActivity = searchParams.get("lastActivity");
    if (lastActivity !== null) {
      params = { ...params, lastActivity: dateFormatSubmit(lastActivity) }
    }

    // remove empty queries
    setCompanyParams(params);
    dispatch(userMgmtAction.getUserMgmts(params));
  }


  const submitAdminForm = async (e: React.FormEvent) => {
    e.preventDefault();

    const errors: any = validate(adminForm);
    if (errors) {
      for (var x in errors) {
        toast.error(errors[x]);
      };
      return;
    }
    else {
      if (adminFormDrawerType === "create") {
        dispatch(userMgmtAction.createUser(adminForm));
      }
      else if (adminFormDrawerType === "edit") {
        dispatch(userMgmtAction.editUser(admin.id, adminForm));
      }
    }
  }


  const submitFilterForm = async (e: React.FormEvent) => {
    e.preventDefault();

    let url = "";
    for (let i in filterForm) {
      if (url !== "") {
        url += "&";
      }
      url += i + "=" + filterForm[i];
    }

    navigate(urlQuery(url));
    toggleFormDrawerFilter(false);
  }


  const activateUserMgmt = async (user: any) => {
    const result = await DialogBox({
      buttonYes: "Yes, activate user",
    });
    if (result) {
      dispatch(userMgmtAction.activateUserMgmt(user.id));
    }
  }


  const deactivateUserMgmt = async (user: any) => {
    const result = await DialogBox({
      buttonYes: "Yes, deactivate user",
    });
    if (result) {
      dispatch(userMgmtAction.deactivateUserMgmt(user.id));
    }
  }


  const resendInvite = async (user: any) => {
    const result = await DialogBox({
      buttonYes: "Yes, resend invite",
    });
    if (result) {
      dispatch(userMgmtAction.resendInvite(user.id));
    }
  }


  const showCreateSuccessDialog = async () => {
    emptyAdminForm();

    const result = await DialogBox({
      icon: iconSuccess,
      title: "Invitation sent successfully!",
      content: "Invitation has been sent",
      buttonYes: "Add another user",
      buttonNo: "Close",
      buttonNoClass: "btn-gold",
    });
    if (result) {

    }
    else {
      setIsOpenAdminForm(false);
    }
  }


  useEffect(() => {
    getDashboardStats();
    getUserMgmts();

  }, [location]);


  useEffect(() => {
    if (userMgmtActivateSuccess === true || userMgmtDeactivateSuccess === true || editUserMgmt.success === true) {
      getDashboardStats();
      getUserMgmts();
      setIsOpenAdmin(false);
      setIsOpenAdminForm(false);
    }

  }, [userMgmtActivateSuccess, userMgmtDeactivateSuccess, editUserMgmt]);


  useEffect(() => {
    if (createUserMgmtLoading === false && createUserMgmt.success === true) {
      getDashboardStats();
      getUserMgmts();
      showCreateSuccessDialog();
    }

  }, [createUserMgmt]);


  return (
    <div className="pb-10">


      <div className="bg-vetiva-black">
        <div className="container pt-16 pb-24 flex flex-wrap">
          <div className="w-full xl:w-3/12">
            <div className="h-full flex flex-col justify-between">
              <div className="overflow-hidden">
                <TimeDateBlock />
              </div>

              <div>
                <RfcSearchBox />
              </div>
            </div>
          </div>
          <div className="w-full xl:w-9/12">

            <div className="ml-10">
              <div className="w-full flex space-x-4">
                <div className="w-[85%] h-36 p-6 bg-white flex items-center rounded-lg">
                  <div className="flex-grow flex">
                    <div className="mx-auto">
                      <div className="text-sm text-gray-500">
                        Total Users
                      </div>
                      <div className="mt-1 text-2xl text-vetiva-brown">
                        <Maybe condition={userMgmtDashboardStatsLoading}>
                          <FontAwesomeIcon icon="spinner" spin />
                        </Maybe>
                        <Maybe condition={!userMgmtDashboardStatsLoading}>
                          {NumberFormat(userMgmtDashboardStats.data?.total, "no-kobo") || "---"}
                        </Maybe>
                      </div>
                    </div>
                  </div>
                  <div className="w-6 flex-shrink-0 flex items-center justify-center">/</div>
                  <div className="flex-grow flex">
                    <div className="mx-auto">
                      <div className="text-sm text-green-500">
                        Active
                      </div>
                      <div className="mt-1 text-2xl">
                        <Maybe condition={userMgmtDashboardStatsLoading}>
                          <FontAwesomeIcon icon="spinner" spin />
                        </Maybe>
                        <Maybe condition={!userMgmtDashboardStatsLoading}>
                          {NumberFormat(userMgmtDashboardStats.data?.active, "no-kobo") || "---"}
                        </Maybe>
                      </div>
                    </div>
                  </div>
                  <div className="w-6 flex-shrink-0 flex items-center justify-center">/</div>
                  <div className="flex-grow flex">
                    <div className="mx-auto">
                      <div className="text-sm text-vetiva-brown">
                        Pending
                      </div>
                      <div className="mt-1 text-2xl">
                        <Maybe condition={userMgmtDashboardStatsLoading}>
                          <FontAwesomeIcon icon="spinner" spin />
                        </Maybe>
                        <Maybe condition={!userMgmtDashboardStatsLoading}>
                          {NumberFormat(userMgmtDashboardStats.data?.pending, "no-kobo") || "---"}
                        </Maybe>
                      </div>
                    </div>
                  </div>
                  <div className="w-6 flex-shrink-0 flex items-center justify-center">/</div>
                  <div className="flex-grow flex">
                    <div className="mx-auto">
                      <div className="text-sm text-red-500">
                        Deactivated
                      </div>
                      <div className="mt-1 text-2xl">
                        <Maybe condition={userMgmtDashboardStatsLoading}>
                          <FontAwesomeIcon icon="spinner" spin />
                        </Maybe>
                        <Maybe condition={!userMgmtDashboardStatsLoading}>
                          {NumberFormat(userMgmtDashboardStats.data?.disabled, "no-kobo") || "---"}
                        </Maybe>
                      </div>
                    </div>
                  </div>
                </div>

                <div onClick={() => toggleFormDrawerAdminForm(true, "create")} className="w-[15%] h-36 p-2 flex flex-col justify-center items-center space-y-1 text-white bg-vetiva-brown hover:bg-vetiva-brown-darker rounded-lg cursor-pointer">
                  <FontAwesomeIcon icon="plus" className="text-3xl" />
                  <div className="text-center text-sm">
                    Add Admin
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>





      <FormDrawer isOpen={isOpenAdminForm} setIsOpen={setIsOpenAdminForm}>
        <form onSubmit={submitAdminForm} className="h-full">
          <div className="fixed h-20 flex items-center w-full top-0 bg-vetiva-black text-white">
            <div className="px-6">

              <Maybe condition={adminFormDrawerType === "create"}>
                <div className="text-xl">
                  Add Admin
                </div>
                <div className="text-gray-400 text-xs">
                  An email will be sent to this user to complete their registration
                </div>
              </Maybe>

              <Maybe condition={adminFormDrawerType === "edit"}>
                <div className="text-xl">
                  Edit Admin
                </div>
                <div className="text-gray-400 text-xs">
                  Edit admin's information
                </div>
              </Maybe>

            </div>
          </div>

          <div className="w-full p-6 absolute top-20 bottom-16 overflow-y-auto">

            <div className="flex flex-col space-y-6">
              <div className="w-full">
                <Input label="First name" value={adminForm.firstName} onChange={(e: any) => setAdminForm({ ...adminForm, firstName: e.target.value })} />
              </div>

              <div className="w-full">
                <Input label="Last name" value={adminForm.lastName} onChange={(e: any) => setAdminForm({ ...adminForm, lastName: e.target.value })} />
              </div>

              <div className="w-full">
                <Input label="Email address" type={"email"} value={adminForm.email} onChange={(e: any) => setAdminForm({ ...adminForm, email: e.target.value })} />
              </div>

              <div className="w-full">
                <Input label="Phone number" type={"tel"} value={adminForm.phoneNumber} onChange={(e: any) => setAdminForm({ ...adminForm, phoneNumber: e.target.value })} />
              </div>

              <div className="w-full">
                <SelectInput label="Send instruction notifications" value={adminForm.receiveInstructionMail} onChange={(e: any) => setAdminForm({ ...adminForm, receiveInstructionMail: (e.target.value === "true" ? true : false) })}>
                  <option value="" disabled>Select</option>
                  <option value="true">Receive mail notifications</option>
                  <option value="false">Don't receive mail notifications</option>
                </SelectInput>
              </div>
            </div>

          </div>

          <div className="w-full fixed bottom-0 p-3 bg-white flex space-x-3 items-center justify-end">
            <button type="button" onClick={() => toggleFormDrawerAdminForm(false, "")} className="btn btn-gold">
              Cancel
            </button>

            <Maybe condition={adminFormDrawerType === "create"}>
              <ButtonLoader type="submit" loading={createUserMgmtLoading} className="btn btn-brown">
                Send Invitation
              </ButtonLoader>
            </Maybe>

            <Maybe condition={adminFormDrawerType === "edit"}>
              <ButtonLoader type="submit" loading={editUserMgmtLoading} className="btn btn-brown">
                Save changes
              </ButtonLoader>
            </Maybe>

          </div>
        </form>
      </FormDrawer>






      <AdminActivityHistory
        isOpenAdmin={isOpenAdmin}
        setIsOpenAdmin={setIsOpenAdmin}
        admin={admin}
        activateUserMgmt={(e: any) => activateUserMgmt(e)}
        deactivateUserMgmt={(e: any) => deactivateUserMgmt(e)}
      />






      <FormDrawer isOpen={isOpenFilter} setIsOpen={setIsOpenFilter}>
        <form onSubmit={submitFilterForm} className="h-full">
          <div className="fixed h-20 flex items-center w-full top-0 bg-vetiva-black text-white">
            <div className="px-6">
              <div className="text-xl">
                Filter
              </div>
              <div className="text-gray-400 text-xs">
                Filter across table using the parameters shown below
              </div>
            </div>
          </div>

          <div className="w-full p-6 absolute top-20 bottom-16 overflow-y-auto">

            <div className="flex flex-col space-y-6">
              <div>
                <Input type="date" label="Date" value={filterForm.dateCreated} onChange={(e: any) => setFilterForm({ ...filterForm, dateCreated: e.target.value })} />
              </div>
              <div>
                <SelectInput label="Status" value={filterForm.status} onChange={(e: any) => setFilterForm({ ...filterForm, status: e.target.value })}>
                  <option value="">All</option>
                  <option value="ACTIVE">Active</option>
                  <option value="PENDING">Pending</option>
                  <option value="DISABLE">Disabled</option>
                </SelectInput>
              </div>
              <div>
                <Input type="date" label="Last activity" value={filterForm.lastActivity} onChange={(e: any) => setFilterForm({ ...filterForm, lastActivity: e.target.value })} />
              </div>
            </div>

          </div>

          <div className="w-full fixed bottom-0 p-3 bg-white flex space-x-3 items-center justify-end">
            <button type="button" onClick={() => toggleFormDrawerFilter(false)} className="btn btn-gold">
              Cancel
            </button>
            <button type="submit" className="btn btn-brown">
              Show results
            </button>
          </div>
        </form>
      </FormDrawer>






      <div className="container -mt-16">
        <div className="w-full">
          <div>
            <div className="text-white mb-2 font-bold text-sm">
              Users
            </div>

            <div className="p-4 bg-white shadow-md rounded-lg">
              <div className="w-full flex justify-between">
                <div className="w-[50%]">


                </div>
                <div className="w-full flex space-x-4 justify-end">
                  <SearchBar placeholder="Search admins" />
                  <div onClick={() => toggleFormDrawerFilter(true)} className="w-10 h-10 flex flex-shrink-0 rounded-full border border-gray-300 cursor-pointer hover:bg-gray-100">
                    <img src={iconFilter} alt="Filter" className="m-auto" />
                  </div>
                </div>
              </div>

              <div>

                <div className="mt-3 table-container">
                  <table className="table table-auto table-rounded table-border">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Phone number</th>
                        <th>Email address</th>
                        <th>Status</th>
                        <th>Last activity</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                      <TableContent loading={userMgmtsLoading} data={userMgmts} colspan={6} />

                      {userMgmts.data?.map((user: any, index: number) =>
                        <tr key={index}>
                          <td onClick={() => toggleFormDrawerAdmin(user, true)} className="cursor-pointer">
                            <div className="flex items-center space-x-2">
                              <div className="w-9 h-9 text-white text-sm text-center flex-shrink-0 flex items-center justify-center rounded-full bg-vetiva-purple">
                                {nameInitials(user.firstName, user.lastName)}
                              </div>
                              <div>
                                {user.firstName} {user.lastName}
                              </div>
                            </div>
                          </td>
                          <td onClick={() => toggleFormDrawerAdmin(user, true)} className="cursor-pointer">
                            {user.phoneNumber}
                          </td>
                          <td onClick={() => toggleFormDrawerAdmin(user, true)} className="cursor-pointer">
                            {user.email}
                          </td>
                          <td onClick={() => toggleFormDrawerAdmin(user, true)} className="cursor-pointer">
                            <div className={"label " + renderStatusColor(user.status)}>
                              {user.status}
                            </div>
                          </td>
                          <td onClick={() => toggleFormDrawerAdmin(user, true)} className="cursor-pointer">
                            {DateFormat(user.updatedAt)}
                          </td>
                          <td>
                            <Menu>
                              <Menu.Button className="w-7 h-7 rounded-sm flex bg-white">
                                <FontAwesomeIcon icon="ellipsis-v" className="m-auto" />
                              </Menu.Button>
                              <Menu.Items className="dropdown">
                                <Menu.Item>
                                  <div onClick={() => toggleFormDrawerAdminForm(true, "edit", user)} className="dropdown-item flex">
                                    <img src={iconEdit} alt="Edit" className="w-4 h-4 mr-3" />
                                    Edit
                                  </div>
                                </Menu.Item>

                                <Maybe condition={user.status?.toLowerCase() === "disable"}>
                                  <Menu.Item>
                                    <div onClick={() => activateUserMgmt(user)} className="dropdown-item flex">
                                      <FontAwesomeIcon icon={["far", "check-circle"]} className="w-4 h-4 mr-3 text-gray-400" />
                                      Activate
                                    </div>
                                  </Menu.Item>
                                </Maybe>

                                <Maybe condition={user.status?.toLowerCase() !== "disable"}>
                                  <Menu.Item>
                                    <div onClick={() => deactivateUserMgmt(user)} className="dropdown-item flex">
                                      <img src={iconDisabled} alt="Disable" className="w-4 h-4 mr-3" />
                                      Deactivate
                                    </div>
                                  </Menu.Item>
                                </Maybe>

                                <Menu.Item>
                                  <div onClick={() => resendInvite(user)} className="dropdown-item flex">
                                    <img src={iconRedo} alt="Resend Invite" className="w-4 h-4 mr-3" />
                                    Resend Invite
                                  </div>
                                </Menu.Item>
                              </Menu.Items>
                            </Menu>
                          </td>
                        </tr>
                      )}

                    </tbody>
                  </table>
                </div>

                <Pagination meta={userMgmts.meta} />

              </div>

            </div>
          </div>
        </div>
      </div>



    </div>
  );
}



export function AdminActivityHistory(props: any) {
  const { isOpenAdmin, setIsOpenAdmin, admin, activateUserMgmt, deactivateUserMgmt } = props;


  const navigate = useNavigate();
  const location = useLocation();
  const { urlQuery } = useUrlNavigation();
  const [searchParams] = useSearchParams();


  const dispatch = useDispatch();
  const { userActivityHistoryLoading, userActivityHistory } = useSelector((s: any) => s.entities.userMgmt);


  const [historyParams, setHistoryParams] = useState<any>({
    "PageNumber": 1,
    "PageSize": 20,
    "historyStartDate": searchParams.get("historyStartDate") || "",
    "historyEndDate": searchParams.get("historyEndDate") || "",
  })

  const [filterForm, setFilterForm] = useState<any>({
    "historyStartDate": searchParams.get("historyStartDate") || "",
    "historyEndDate": searchParams.get("historyEndDate") || "",
  });


  const getUserActivityHistory = () => {
    let params = { ...historyParams };
    delete params["historyStartDate"];
    delete params["historyEndDate"];

    const page = searchParams.get("historypage");
    if (page !== null) {
      params = { ...params, PageNumber: parseInt(page) }
    }
    else {
      params = { ...params, PageNumber: 1 }
    }

    const limit = searchParams.get("historylimit");
    if (limit !== null) {
      params = { ...params, PageSize: parseInt(limit) }
    }
    else {
      params = { ...params, PageSize: 20 }
    }

    const StartDate = searchParams.get("historyStartDate");
    if (StartDate !== null) {
      params = { ...params, StartDate: dateFormatSubmit(StartDate) }
    }

    const EndDate = searchParams.get("historyEndDate");
    if (EndDate !== null) {
      params = { ...params, EndDate: dateFormatSubmit(EndDate) }
    }

    setHistoryParams(params);
    dispatch(userMgmtAction.getUserActivityHistory(admin.id, params));
  }


  const submitFilterForm = async (e: React.FormEvent) => {
    e.preventDefault();

    let url = "";
    for (let i in filterForm) {
      if (url !== "") {
        url += "&";
      }
      url += i + "=" + filterForm[i];
    }

    navigate(urlQuery(url));
  }


  const clearFilterForm = () => {
    let url = "";
    const newFilterForm = {
      ...filterForm,
      "historyStartDate": "",
      "historyEndDate": "",
    };

    for (let i in newFilterForm) {
      if (url !== "") {
        url += "&";
      }
      url += i + "=" + newFilterForm[i];
    }

    setFilterForm(newFilterForm);
    navigate(urlQuery(url));
  }


  useEffect(() => {
    if (isOpenAdmin === true) {
      getUserActivityHistory();
    }
  }, [isOpenAdmin])


  useEffect(() => {
    if (admin.id !== undefined) {
      getUserActivityHistory();
    }
  }, [location]);


  return (
    <FormDrawer size="2xl" isOpen={isOpenAdmin} setIsOpen={setIsOpenAdmin}>
      <div className="h-full">
        <div className="w-full relative">

          <div className="w-full flex h-full relative">
            <div className="w-52 fixed top-0 bottom-0 left-0 flex-shrink-0 p-6 bg-[#F9F6F1] bg-vetiva-pattern-2">

              <div className="w-28 h-28 mt-6 text-white text-4xl font-bold bg-vetiva-brown rounded-full mx-auto uppercase flex items-center justify-center">
                {nameInitials(admin.firstName, admin.lastName)}
              </div>

              <div>
                <div className="mt-10 space-y-3">
                  <div>
                    <div className="font-semibold">
                      Name
                    </div>
                    <div className="text-gray-500">
                      {admin.firstName} {admin.lastName}
                    </div>
                  </div>
                  <div>
                    <div className="font-semibold">
                      Role
                    </div>
                    <div className="text-gray-500">
                      Administrator
                    </div>
                  </div>
                </div>

                <div className="mt-10 text-gray-400 font-bold">
                  Contact
                </div>

                <div className="mt-3 space-y-3">
                  <div>
                    <div className="font-semibold">
                      Email
                    </div>
                    <div className="text-vetiva-purple">
                      {admin.email}
                    </div>
                  </div>
                  <div>
                    <div className="font-semibold">
                      Phone number
                    </div>
                    <div className="text-vetiva-purple">
                      {admin.phoneNumber}
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full absolute bottom-0 right-0 p-6">
                <Maybe condition={admin.status && admin.status.toLowerCase() === "disable"}>
                  <button type="button" onClick={() => activateUserMgmt(admin)} className="btn bg-white btn-block text-red-500">
                    Activate this user
                  </button>
                </Maybe>
                <Maybe condition={admin.status && admin.status.toLowerCase() !== "disable"}>
                  <button type="button" onClick={() => deactivateUserMgmt(admin)} className="btn bg-white btn-block text-red-500">
                    Deactivate this user
                  </button>
                </Maybe>
              </div>

            </div>

            <div className="w-full ml-52 relative">

              <form onSubmit={submitFilterForm} className="h-24 fixed z-[51] top-0 left-0 right-0 ml-52 p-4 bg-white border-b">
                <div className="flex justify-between">
                  <div>
                    <Input type="date" label="Start date" value={filterForm.historyStartDate} onChange={(e: any) => setFilterForm({ ...filterForm, historyStartDate: e.target.value })} />
                  </div>
                  <div>
                    <Input type="date" label="End date" value={filterForm.historyEndDate} onChange={(e: any) => setFilterForm({ ...filterForm, historyEndDate: e.target.value })} />
                  </div>
                </div>
                <div className="mt-2 flex justify-end space-x-6 text-xs">
                  <div onClick={clearFilterForm} className="hover:underline cursor-pointer">
                    Clear filter
                  </div>
                  <div onClick={submitFilterForm} className="hover:underline cursor-pointer">
                    Apply filter
                  </div>
                </div>
              </form>

              <div className="w-[19rem] p-4 relative top-24">


                <PageContent loading={userActivityHistoryLoading} loadingText="Loading history" data={userActivityHistory?.data} emptyText={<div className="normal-case">No activity yet.<br />Activities done by this user will show here</div>} />

                {userActivityHistory.data?.map((history: any, index: number) =>
                  <div key={index} className="flex space-x-4">
                    <div>
                      <div className="w-2 h-2 bg-vetiva-brown rounded-full mx-auto"></div>
                      <div className="w-px h-full bg-gray-300 mx-auto"></div>
                    </div>
                    <div className="pb-6">
                      <div className="-mt-1 text-xs text-gray-500">
                        {DateFormat(history.createdAt)}
                        {/* 11:34PM */}
                      </div>
                      <div className="mt-1 text-gray-700">
                        {history.details}
                        {/* User added a new employee <span className="font-bold">Adebola Aliu Chioma</span> */}
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-4">
                  <Pagination meta={userActivityHistory.meta} prefix="history" showLimit={false} />
                </div>


              </div>
            </div>
          </div>

        </div>
      </div>
    </FormDrawer>
  )
}


export default UsersMgmtList;
