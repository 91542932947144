import { Link, useParams } from "react-router-dom";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import iconsuccess from "assets/images/icons/success.gif";

export interface CompanyCreateSuccessProps {}

export function CompanyCreateSuccess(props: CompanyCreateSuccessProps) {
  const { id } = useParams();


  return (
    <div>

      <FormWizardNavBar showSteps={true} currentStep={5} totalSteps={5} closeUrl="/trustee/company" />

      <div className="container mt-20 max-w-2xl mx-auto text-center">
        
        <div className="h-48">
          <img src={iconsuccess} alt="Success" className="object-contain" />
        </div>

        <div className="mt-4 text-2xl">
          Successful!
        </div>
        <div className="mt-1 text-gray-400">
          You have successfully added
        </div>

        <div>
          <div className="mt-6 flex flex-wrap space-x-6 justify-center">
            <Link to="/trustee/company/create" className="underline text-vetiva-brown">
              Add new company
            </Link>
            <Link to={"/trustee/company/" + id} className="underline text-vetiva-brown">
              View company profile
            </Link>
          </div>
          <div className="mt-6">
            <Link to="/trustee/company" className="underline text-vetiva-brown">
              Go to overview
            </Link>
          </div>
        </div>

      </div>

    </div>
  );
}

export default CompanyCreateSuccess;
