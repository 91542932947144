import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Input } from "components/ui";
import validate from "utils/validate";
import ButtonLoader from "components/ButtonLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {ReactComponent as IconBuilding } from "assets/images/icons/building-alt.svg";
import {ReactComponent as IconMail } from "assets/images/icons/mail-gray.svg";
import {ReactComponent as IconLock } from "assets/images/icons/lock.svg";
import * as user from "store/auth/user/action";

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const gotoUrl = location.state?.previousUrl?.pathname || "/";
    
    const { userLogin } = useSelector((s: any) => s.auth.userPersist);
    const { loginLoading } = useSelector((s: any) => s.auth.user);
    
    
    const [subdomainFromUrl] = useState(searchParams.get("subdomain") || "");
    const [viewPassword, setViewPassword] = useState(false);
    const [loginForm, setLoginForm] = useState({
        email: "",
        password: "",
        subdomain: "",
    })


    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();

        const errors:any = validate(loginForm);
        if (errors){
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            dispatch(user.login(loginForm));
        }
    }


    useEffect(() => {
        if (userLogin){
            navigate(gotoUrl, {replace: true});
        }
        // eslint-disable-next-line
    }, [userLogin]);


    useEffect(() => {
        if (subdomainFromUrl !== ""){
            setLoginForm({...loginForm, subdomain: subdomainFromUrl});
        }
        // eslint-disable-next-line
    }, []);


    return (
        <div className="w-full max-w-md lg:w-96 mx-auto md:mx-0">

            <div className="text-2xl font-semibold">
                Login
            </div>
            <div className="mt-1 text-sm">
                Enter your credentials to login
            </div>

            <div>
                <form onSubmit={submitForm}>

                    <div className="mt-10">

                        <div className="mb-5">
                            <Input type="text" label="Company name (subdomain)" value={loginForm.subdomain} onChange={(e:any) => setLoginForm({...loginForm, subdomain: e.target.value})}
                                leftIcon={<IconBuilding className="w-5 h-5" />}
                            />
                        </div>
                        
                        <div className="mb-5">
                            <Input type="email" label="Email Address" value={loginForm.email} onChange={(e:any) => setLoginForm({...loginForm, email: e.target.value})}
                                leftIcon={<IconMail />}
                            />
                        </div>
                        
                        <div className="mb-5">
                            <Input type={viewPassword ? "text" : "password"}
                                label="Password" value={loginForm.password} onChange={(e:any) => setLoginForm({...loginForm, password: e.target.value})}
                                leftIcon={<IconLock className="object-contain" />}
                                rightIcon={<FontAwesomeIcon icon={viewPassword ? "eye" : "eye-slash"} className="text-gray-400" />}
                                rightIconClicked={() => setViewPassword(!viewPassword)}
                            />
                        </div>
                        
                        <div className="-mt-3 text-sm font-semibold">
                            <Link to="/forgot-password" className="text-vetiva-brown hover:underline">Forgot Password?</Link>
                        </div>

                        <ButtonLoader type="submit" loading={loginLoading} className="mt-8 btn btn-lg btn-block btn-vetiva-brown">
                            Login
                        </ButtonLoader>

                        <div className="mt-4 text-center text-sm text-gray-500 font-semibold">
                            By continuing, you agree to our&nbsp;
                            <Link to="/terms" className="text-vetiva-brown hover:underline">Terms of service</Link>
                        </div>

                    </div>
                    
                </form>
            </div>
                
        </div>
    )
}

export default Login;
