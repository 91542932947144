import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Input } from "components/ui";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import NumberFormat from "utils/numberFormat";
import { toast } from "react-toastify";
import Maybe from "components/Maybe";
import { default as ReactSelect, components } from "react-select";
import { PageContent } from "components/PageContent";
import { removeUndefined } from "utils/misc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validate from "utils/validate";
import useLoginInfo from "hooks/useLoginInfo";
import * as companyAction from "store/entities/company/action";
import * as employeeAction from "store/entities/employee/action";


export interface CompanyEmployeeInstructionTransferAmountProps {}

export function CompanyEmployeeInstructionTransferAmount(props: CompanyEmployeeInstructionTransferAmountProps) {
  const {userInfo} = useLoginInfo();
  let { companyId, employeeId, schemeTypeId } = useParams();
  let employeeDetailsUrl = "/trustee/company/"+companyId+"/employee/"+employeeId;
  let sendInstructionSelectionUrl = "/trustee/company/"+companyId+"/employee/"+employeeId;
  
  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    employeeDetailsUrl = "/company/employees/"+employeeId;
    sendInstructionSelectionUrl = employeeDetailsUrl;
  }
  else if (userInfo.userType === "SCHEME_MEMBER"){
    companyId = userInfo.organizationId;
    employeeId = userInfo.employeeId;
    employeeDetailsUrl = "/member/overview";
    sendInstructionSelectionUrl = "/member";
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyLoading, company } = useSelector((s: any) => s.entities.company);
  const { shareSaleInstructionLoading, shareSaleInstruction, savedShareTransferInstruction } = useSelector((s: any) => s.entities.employee);
  const { employeeSchemeLoading, employeeScheme } = useSelector((s: any) => s.entities.employee);

  const [schemeType, setSchemeType] = useState<any>({});
  const [isAllocationLoaded, setIsAllocationLoaded] = useState<boolean>(false);
  const [transferForm, setTransferForm] = useState<any>({
    clearingHouseNumber: (savedShareTransferInstruction.clearingHouseNumber || ""),
    centralSecurityClearingSystem: (savedShareTransferInstruction.centralSecurityClearingSystem || ""),
    stockBrockingFirm: (savedShareTransferInstruction.stockBrockingFirm || ""),
    stockBrokerFullName: (savedShareTransferInstruction.stockBrokerFullName || ""),
    stockBrokerEmail: (savedShareTransferInstruction.stockBrokerEmail || ""),
    stockBrokerPhoneNo: (savedShareTransferInstruction.stockBrokerPhoneNo || ""),
    shareAmount: (savedShareTransferInstruction.shareAmount || ""),
  })


  const updateSelection = (data:any) => {
    const subschemeId = schemeType.subSchemes[0]?.id || null;
    const batchId = data.batch.length > 0 ? data.batch[0]?.id : null;
    const allocationId = data.allocation.length > 0 ? data.allocation[0]?.id : null;

    if (batchId !== null && allocationId !== null){
      setIsAllocationLoaded(true);
      dispatch(employeeAction.shareSaleInstruction({employeeId, schemeTypeId, subschemeId, batchId, allocationId}));
    }
    else{
      setIsAllocationLoaded(false);
    }

    setTransferForm({...transferForm, subschemeId, batchId, allocationId});
  }


  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const {shareAmount} = transferForm;
    const validateParams = {
      amount: shareAmount,
    }
    const errors:any = validate(validateParams);
    if (errors){
        for (var x in errors) {
            toast.error(errors[x]);
        };
        return;
    }
    else{
      
      if (shareAmount > 0){
        if (shareAmount <= shareSaleInstruction.data?.sharesVested){
          
          const paramsAll = {
            ...savedShareTransferInstruction,
            shareAmount: parseInt(shareAmount),
            remainingShares: (shareSaleInstruction.data.sharesVested - parseInt(shareAmount)),
            subschemeId: transferForm.subschemeId,
            batchId: transferForm.batchId,
            allocationId: transferForm.allocationId,
          }

          const params = removeUndefined(paramsAll);
          // console.log("what to save", params);

          dispatch(employeeAction.saveShareTransferInstruction(params));
          navigate(sendInstructionSelectionUrl + "/instruction/" + schemeTypeId + "/transfer-preview");

        }
        else{
          toast.error("Amount of shares to transfer cannot be greater than shares vested");
        }
      }
      else{
        toast.error("Please enter a valid amount of shares to transfer");
      }

    }
  }


  const getLoading = () => {
    if (companyLoading === true || employeeSchemeLoading === true){
      return true;
    }
    return false;
  }


  const checkEmployeeBatch = (companyBatch:any) => {
    const employeeSchemeType = employeeScheme?.data?.employeeSubSchemes?.filter((schemeType:any) => schemeType.schemeTypeId === schemeTypeId);
    const check = employeeSchemeType[0]?.batches?.filter((batch:any) => batch.employeeOrgBatchId === companyBatch.id);
    if (check.length === 1){
      return true;
    }
    return false;
  }


  useEffect(() => {
    dispatch(companyAction.getCompany(companyId));
    dispatch(employeeAction.getEmployeeScheme(employeeId));
  }, []);


  useEffect(() => {
    if (
      (Object.keys(savedShareTransferInstruction).length === 0) ||
      (savedShareTransferInstruction.clearingHouseNumber === "") ||
      (savedShareTransferInstruction.centralSecurityClearingSystem === "") ||
      (savedShareTransferInstruction.stockBrockingFirm === "") ||
      (savedShareTransferInstruction.stockBrokerFullName === "") ||
      (savedShareTransferInstruction.stockBrokerEmail === "")
    ){
      navigate(sendInstructionSelectionUrl + "/instruction/" + schemeTypeId + "/transfer");
    }
  }, []);


  useEffect(() => {
    if (companyLoading === false && company.data && employeeSchemeLoading === false && employeeScheme.data){
      let schemeType = company?.data?.organizationShareSetting?.schemeTypes.filter((schemeType:any) => schemeType.id === schemeTypeId);
      if (schemeType[0]?.hasSubSchemeAccess === true){
        const employeeBatches = schemeType[0]?.subSchemes[0]?.batches.filter((batch:any) => checkEmployeeBatch(batch) === true);
        schemeType = [{
          ...schemeType[0],
          subSchemes: [{
            ...schemeType[0].subSchemes[0],
            batches: employeeBatches,
          }]
        }]
      }
      
      if (schemeType.length === 1){
        setSchemeType(schemeType[0]);
        if (schemeType[0].hasSubSchemeAccess === false){
          setIsAllocationLoaded(true);
          dispatch(employeeAction.shareSaleInstruction({employeeId, schemeTypeId}));
        }
      }
    }
  }, [company]);


  return (
    <div>

      <FormWizardNavBar currentStep={2} totalSteps={4} showSteps={true} closeUrl={employeeDetailsUrl} />

      <div className="container py-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Share Transfer
          </div>
          <p className="mt-2 text-fade text-sm">
            Kindly fill in the details below
          </p>
        </div>

        <div className="mt-12">

          <Maybe condition={getLoading() === false}>
            <Maybe condition={schemeType?.hasSubSchemeAccess === true}>
              <div className="mb-8">
                <SchemeTypeBatchSelection schemeType={schemeType} selection={(e:any) => updateSelection(e)} />
              </div>
            </Maybe>
          </Maybe>


          <Maybe condition={getLoading() === true}>
            <PageContent loading={getLoading()} data={employeeScheme.data || undefined} loadingText="Loading employee info" emptyText="Employee info not found" />
          </Maybe>


          <Maybe condition={getLoading() === false && shareSaleInstructionLoading}>
            <PageContent loading={shareSaleInstructionLoading} data={shareSaleInstruction.data || undefined} loadingText="Loading shares info" emptyText="Shares info not found" />
          </Maybe>


          <Maybe condition={getLoading() === false && shareSaleInstructionLoading === false && isAllocationLoaded === true}>
            <form onSubmit={submitForm}>

              <div className="mb-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="p-8 text-sm text-white bg-vetiva-black bg-vetiva-pattern rounded-md">
                  <div>
                    Shares Allocated
                  </div>
                  <div className="mt-2 font-bold text-3xl">
                    {NumberFormat(shareSaleInstruction.data?.sharesAllocation, "no-kobo")}
                  </div>
                </div>
                <div className="p-8 text-sm text-white bg-vetiva-black bg-vetiva-pattern rounded-md">
                  <div>
                    Shares Vested
                  </div>
                  <div className="mt-2 font-bold text-3xl">
                    {NumberFormat(shareSaleInstruction.data?.sharesVested, "no-kobo")}
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input type="number" label="Amount of shares to transfer" value={transferForm.shareAmount} onChange={(e:any) => setTransferForm({...transferForm, shareAmount: e.target.value})} />
                </div>
              </div>

              <div className="mt-10 flex justify-end space-x-3">
                <Link to={sendInstructionSelectionUrl + "/instruction/" + schemeTypeId + "/transfer"} className="btn btn-gold">
                  Back
                </Link>
                <button type="submit" className="btn btn-brown" disabled={!isAllocationLoaded}>
                  Continue
                </button>
              </div>

            </form>
          </Maybe>


          <Maybe condition={getLoading() === false && shareSaleInstructionLoading === false && isAllocationLoaded === false}>
            <div className="mt-10 flex justify-end space-x-3">
              <Link to={sendInstructionSelectionUrl + "/instruction/" + schemeTypeId + "/transfer"} className="btn btn-gold">
                Back
              </Link>
              <button type="button" className="btn btn-brown disabled" onClick={() => toast.error("Please select an allocation")}>
                Continue
              </button>
            </div>
          </Maybe>


        </div>
      </div>

    </div>
  );
}






const Option = (props:any) => {
  return (
      <div className="p-0">
          <components.Option {...props}>
              <div className="-mx-2 -my-1.5 p-4 text-sm flex justify-between border rounded-sm">
                  <div className="w-10 flex-shrink-0">
                    <input
                      type="radio"
                      checked={props.isSelected}
                      onChange={() => null}
                      className="w-5 h-5 form-radio rounded-full"
                    />
                  </div>
                  <div className="flex-grow text-left font-semibold">
                    {props.label}
                  </div>
              </div>
          </components.Option>
      </div>
  );
};


const ReactSelectStyles = {
  option: (provided:any, state:any) => ({
      ...provided,
      color: "#333",
      background: "#fff",
      cursor: "pointer",
      "&:hover": {
          background: "#eee",
      }
    }),
};


const SchemeTypeBatchSelection = ({schemeType: defaultSchemeType, selection}:any) => {
  
  const [schemeType, setSchemeType] = useState<any>(defaultSchemeType);
  const [selectedBatch, setSelectedBatch] = useState<any>([]);
  const [selectedAllocation, setSelectedAllocation] = useState<any>([]);

  const getBatches = (batches:any) => {
    if (batches !== undefined && batches.length > 0){
      const batchesSelection = batches.map((batch:any) => {
        return {
          label: batch.name,
          value: batch.id,
        }
      })
      return batchesSelection;
    }
    return [];
  }

  const updateBatchSelection = (selection:any) => {
    const newSubSchemeBatches = schemeType.subSchemes[0]?.batches.map((batch:any) => {
      if (selection.value === batch.id){
        return {...batch, selected: true};
      }
      else{
        return {...batch, selected: false};
      }
    })
    const newSubScheme = [{
      id: schemeType.subSchemes[0].id,
      trustDeedObject: schemeType.subSchemes[0].trustDeedObject,
      batches: newSubSchemeBatches,
    }]
    const newSchemeType = {...schemeType, subSchemes: newSubScheme};
    setSchemeType(newSchemeType);
    setSelectedBatch(newSchemeType.subSchemes[0]?.batches.filter((batch:any) => batch.selected === true));
    setSelectedAllocation([]);
  } 

  useEffect(() => {
    selection({
      batch: selectedBatch,
      allocation: selectedAllocation,
    });
  }, [selectedBatch, selectedAllocation]);


  return (
    <div className="p-6 bg-gray-100">

      <div className="font-semibold mb-2">
        Please select an allocation:
      </div>

      <ReactSelect
        options={getBatches(schemeType.subSchemes[0]?.batches)}
        placeholder="Select batches, groups, categories"
        styles={ReactSelectStyles}
        isMulti={false}
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        components={{Option}}
        onChange={(e:any) => updateBatchSelection(e)}
        value={getBatches(selectedBatch)}
      />

      <Maybe condition={selectedBatch.length > 0}>
        <div className="mt-3 space-y-2">
          {selectedBatch[0]?.allocations?.map((allocation:any) =>
            <div key={allocation.id} onClick={() => setSelectedAllocation([allocation])} className="p-5 bg-white border flex justify-between items-center rounded-md hover:bg-vetiva-gold cursor-pointer">
              <div className="font-bold">
                {allocation.name} - ({NumberFormat(allocation.numberOfAllocation, "no-kobo")})
              </div>
              <div>
                <Maybe condition={selectedAllocation[0]?.id !== allocation.id}>
                  <FontAwesomeIcon icon={["far", "circle"]} className="w-5 h-5 ml-3 text-vetiva-brown" />
                </Maybe>
                <Maybe condition={selectedAllocation[0]?.id === allocation.id}>
                  <FontAwesomeIcon icon={["fas", "check-circle"]} className="w-5 h-5 ml-3 text-vetiva-brown" />
                </Maybe>
              </div>
            </div>
          )}
        </div>
      </Maybe>

    </div>
  )
}



export default CompanyEmployeeInstructionTransferAmount;
