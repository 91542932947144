import { Link } from "react-router-dom";
import IconMail from "assets/images/icons/email-sent.gif";

const ForgotPasswordSuccess = () => {
    
    return (
        <div className="max-w-md px-8 pb-10 pt-32 m-auto text-center">

            <div className="h-32 md:h-48 -mt-10">
                <img src={IconMail} alt="Vetiva success" className="h-full object-contain mx-auto" />
            </div>
            <div className="mt-4 font-semibold text-2xl">
                Reset Email Sent
            </div>
            <div className="mt-2 text-gray-500">
                Check your inbox or spam folder for further instructions
            </div>

            <div className="mt-8 flex flex-col space-y-2">
                <Link to="/login" className="btn btn-block btn-lg btn-vetiva-brown">
                    Back to Login
                </Link>
            </div>

        </div>
    )
}

export default ForgotPasswordSuccess;
