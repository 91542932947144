export interface SelectInputProps {
  label?: string;
  value?: any;
  defaultValue?: any;
  type?: any;
  leftIcon?: any;
  rightIcon?: any;
  defaultProps?: any;
  onChange?: any;
  children?: React.ReactNode,
}

export function SelectInput(props: SelectInputProps) {
  const { value, label, defaultValue, defaultProps, onChange } = props;
  const focus = "vetiva-brown";

  return (
    <>
      {/* <div className="relative w-full min-w-[200px] h-12"> */}
      <div className="relative w-full h-12">
        <select
          className={`
        focus:shadow-none
        peer 
        w-full 
        h-full 
        bg-white 
        text-blue-gray-700 
        font-normal 
        outline 
        outline-0 
        focus:outline-0 
        disabled:bg-blue-gray-50 
        disabled:border-0 
        transition-all 
        placeholder-shown:border 
        placeholder-shown:border-blue-gray-200 
        placeholder-shown:border-t-blue-gray-200 
        border-2
        focus:border-2 
        border-t-transparent 
        focus:border-t-transparent 
        text-sm 
        px-3 
        py-2.5 
        rounded 
        border-gray-200 
        focus:border-${focus}`}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        {...defaultProps }
        >
          {props.children}
        </select>
        <label
          className={`flex w-full h-full select-none pointer-events-none absolute left-0 font-normal peer-placeholder-shown:text-fade leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.25] text-blue-gray-400 peer-focus:text-${focus} before:border-blue-gray-200 peer-focus:before:border-${focus} after:border-blue-gray-200 peer-focus:after:border-${focus}`}
        >

          {/* {value === "" ? "" : label} */}
          {label}

        </label>
      </div>
    </>
  );
}

export default SelectInput;
