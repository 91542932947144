import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "components/ui";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import validate from "utils/validate";
import { toast } from "react-toastify";
import { PageContent } from "components/PageContent";
import ButtonLoader from "components/ButtonLoader";
import Maybe from "components/Maybe";
import * as companyAction from "store/entities/company/action";

export interface CompanyCreateAdminProps {}

export function CompanyCreateAdmin(props: CompanyCreateAdminProps) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyLoading, company, createCompanyLoading2, createCompany2 } = useSelector((s: any) => s.entities.company);

  const [companyForm, setCompanyForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    jobTitle: ""
  })


  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    let validateParams:any = {...companyForm};
    delete validateParams["phoneNumber"];

    const errors:any = validate(validateParams);
    if (errors){
      for (var x in errors) {
        toast.error(errors[x]);
      };
      return;
    }
    else{
      dispatch(companyAction.createStep2(id, companyForm));
    }
  }


  useEffect(() => {
    if (createCompany2.data){
      dispatch(companyAction.createStep2Reset());
      navigate("/trustee/company/form/"+id+"/company-settings");
    }

  }, [createCompany2]);


  const populateForm = () => {
    if (company.data?.admin?.userInfo){
      const admin = company.data.admin;
      const data = admin.userInfo;

      if (company.data.id === id){
        const form = {
          firstName: data.firstName || "",
          lastName: data.lastName || "",
          email: data.email || "",
          phoneNumber: data.phoneNumber || "",
          jobTitle: admin.jobTitle || "",
        }
        setCompanyForm(form);
      }
    }
  }
  

  useEffect(() => {
    dispatch(companyAction.getCompany(id));
  }, []);

  
  useEffect(() => {
    populateForm();
  }, [company]);


  return (
    <div>

      <FormWizardNavBar showSteps={true} currentStep={2} totalSteps={5} closeUrl="/trustee/company" />

      <div className="container pb-10 mt-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Admin details
          </div>
          <p className="mt-2 text-fade text-sm">
            Fill in admin details
          </p>
        </div>

        <div className="mt-12">
          
          <PageContent loading={companyLoading} data={company.data || undefined} />

          <Maybe condition={companyLoading === false && company.data && Object.keys(company.data).length > 0}>
            <div>
              <form onSubmit={submitForm}>

                <div className="mb-6">
                  <Input type="text" label="First name" value={companyForm.firstName} onChange={(e:any) => setCompanyForm({...companyForm, firstName: e.target.value})} />
                </div>

                <div className="mb-6">
                  <Input type="text" label="Last name" value={companyForm.lastName} onChange={(e:any) => setCompanyForm({...companyForm, lastName: e.target.value})} />
                </div>

                <div className="mb-6">
                  <Input type="text" label="Job title" value={companyForm.jobTitle} onChange={(e:any) => setCompanyForm({...companyForm, jobTitle: e.target.value})} />
                </div>

                <div className="mb-6">
                  <Input type="tel" label="Phone number (optional)" value={companyForm.phoneNumber} onChange={(e:any) => setCompanyForm({...companyForm, phoneNumber: e.target.value})} />
                </div>

                <div className="mb-6">
                  <Input type="email" label="Email Address" value={companyForm.email} onChange={(e:any) => setCompanyForm({...companyForm, email: e.target.value})} />
                </div>

                <div className="flex justify-end space-x-3 mt-12 mb-6">
                  <Link to={"/trustee/company/form/"+id+"/company-details"} className="btn btn-gold">
                    Back
                  </Link>
                  <ButtonLoader type="submit" loading={createCompanyLoading2} className="btn btn-brown">
                    Continue
                  </ButtonLoader>
                </div>

              </form>
            </div>
          </Maybe>

        </div>
      </div>

    </div>
  );
}

export default CompanyCreateAdmin;
