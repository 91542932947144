// Main
export const projectName = 'Vetiva';
export const repoName = 'Vetiva';

// API - middleware
export const apiRequestStart = "API/REQUEST_START";
export const apiRequestSuccess = "API/REQUEST_SUCCESS";     // for logging purpose only
export const apiRequestFailed = "API/REQUEST_FAILED";       // for logging purpose only

// API - base url
export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

// API - user auth
export const apiLoginUser = "/auth/login";
export const apiForgotPassword = "/auth/reset-password";
export const apiVerifyToken = "/auth/verify-token";
export const apiResetPassword = "/auth/set-password";

// API - overview
export const apiOverviewDashboardStats = "/organization/get-organizations-metric";

// API - company
export const apiCompanyDashboardStats = "/organization/dashboard-stats";
export const apiCompanyEmployeeDashboardStats = "/organization/share-stats";
export const apiCompany = "/organization";
export const apiCompanyUploads = "/file-uploads/organization/";
export const apiCompanyUploadSales = "/share-sale/organization/";
export const apiCompanyUploadBonus = "/bonus/organization/";
export const apiCompanyUploadDividend = "/dividend";
export const apiCompanyUploadTransfer = "/share-transfer/organization/";

// API - employee
export const apiEmployeeDashboardStats = "";
export const apiEmployee = "/employee";
export const apiDividendHistory = "/dividend/organization/";
export const apiShareSaleHistory = "/share-sale/organization/";
export const apiShareTransferHistory = "/share-transfer/organization/";
export const apiShareSaleInstruction = "/share-sale-instruction";
export const apiShareTransferInstruction = "/share-transfer-instruction";
export const apiEmailBroadcast = "/employee/broadcast-emails";

// API - user mgmt
export const apiUserMgmtDashboardStats = "/trustee/dashboard-stats";
export const apiUserMgmt = "/trustee";

// API - misc
export const apiGetBanks = "/bank";
export const apiGetStockBrockingFirm = "/stock-broking-firm";
export const apiUserActivityHistory = "/useractivity/user/";