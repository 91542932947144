import { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip);

const options = {
  cutout: 80,
}

export interface DoughnutChartProps {
  data: any;
}

export function DoughnutChart(props: DoughnutChartProps) {

  const [data, setData] = useState({
    labels: ['Unallocated','Allocated'],
    datasets: [
      {
        label: '# of allocations',
        data: [0, 70],
        backgroundColor: [
          '#967842',
          '#ccd0d7',
        ],
        borderColor: [
          '#967842',
          '#ccd0d7',
        ],
        borderWidth: 1,
      },
    ]
  });


  useEffect(() => {
    if (props.data !== undefined){
      setData({
        ...data,
        datasets: [{
          ...data.datasets[0],
          data: props.data,
        }]
      })
    }
  }, [props.data]);

  return (
      <Doughnut data={data} options={options} />
  )
}
