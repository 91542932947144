import Joi from "joi-browser";


const schemas = {
    firstName: Joi.string().required().label("First Name").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the first name";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    lastName: Joi.string().required().label("Last Name").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the last name";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    jobTitle: Joi.string().required().label("Job Title").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the job title";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    email: Joi.string().email().required().label("Email Address").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the email address";
                    break;
                case "string.email":
                    err.message = "Please enter a valid email address";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    officialEmail: Joi.string().email().required().label("Official Email Address").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the official email address";
                    break;
                case "string.email":
                    err.message = "Please enter a valid official email address";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    // phoneNumber: Joi.string().min(11).max(11).required().label("Phone Number").error(errors => {
    //     errors.forEach(err => {
    //         switch (err.type) {
    //             case "any.empty":
    //             case "any.required":
    //                 err.message = "Please enter the phone number";
    //                 break;
    //             case "string.min":
    //                 err.message = `Please enter 11 digits for the phone number`;
    //                 break;
    //             case "string.max":
    //                 err.message = `Please enter 11 digits for the phone number`;
    //                 break;
    //             default:
    //                 break;
    //         }
    //     });
    //     return errors;
    // }),
    phoneNumber: Joi.string().required().label("Phone Number").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the phone number";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    password: Joi.string().required().label("Password").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter your password";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    confirmPassword: Joi.any().valid(Joi.ref('password')).label("Confirm Password").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.allowOnly":
                    err.message = "Your passwords do not match";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    


    subdomain: Joi.string().required().label("Company Name (subdomain)").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the company name (subdomain)";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    logo: Joi.any().label("Company Logo").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                default:
                    break;
            }
        });
        return errors;
    }),
    SubDomain: Joi.string().required().label("Company Name (subdomain)").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the company name (subdomain)";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    companyName: Joi.string().required().label("Company Name").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the company name";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    companySize: Joi.string().required().label("Company Size").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the company size";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    Logo: Joi.string().required().label("Logo").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please upload the company logo";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    businessType: Joi.string().required().label("Business Type").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select the business type";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    companyProfile: Joi.string().required().label("Company Profile").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the company profile (description)";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    RcNumber: Joi.string().required().label("Registration Number").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the RC number";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    employeeNumber: Joi.string().required().label("Employee ID Number").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the employee ID number";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    dateOfHire: Joi.string().required().label("Date of hire").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the Date of hire";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    bankId: Joi.string().required().label("Bank Name").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select the bank name";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    accountNumber: Joi.string().min(10).max(10).required().label("Account Number").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the account number";
                    break;
                case "string.min":
                    err.message = `Please enter 10 digits for the account number`;
                    break;
                case "string.max":
                    err.message = `Please enter 10 digits for the account number`;
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    receiveInstructionMail: Joi.boolean().required().label("Send Instruction Notification").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "boolean.base":
                case "any.empty":
                case "any.required":
                    err.message = "Please select the send instruction notification type";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),



    amount: Joi.number().integer().required().label("Amount").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "number.base":
                case "any.empty":
                case "any.required":
                    err.message = "Please enter a valid amount";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    numberOfShareForSale: Joi.number().integer().required().label("Amount of shares for sale").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "number.base":
                case "any.empty":
                case "any.required":
                    err.message = "Please enter a valid amount of shares for sale";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    shareSalePrice: Joi.number().integer().required().label("Share sale price").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "number.base":
                case "any.empty":
                case "any.required":
                    err.message = "Please enter a valid share sales price";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    shareSaleName: Joi.string().required().label("Share Sales Name").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter a valid share sales name";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    currency: Joi.string().required().label("Currency").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select a valid currency";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    clearingHouseNumber: Joi.string().required().label("Clearing House Number").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter a valid clearing house number";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    centralSecurityClearingSystem: Joi.string().required().label("Central Security Clearing System").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter a valid central security clearing system";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    stockBrockingFirm: Joi.string().required().label("Stock Brocking Firm").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please select a valid stock brocking firm";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    subject: Joi.string().required().label("Subject").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the subject";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    message: Joi.string().required().label("Message").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the message";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),



    Address1: Joi.string().required().label("Company Address").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the address";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    City: Joi.string().required().label("City").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the city";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    PostalCode: Joi.string().required().label("Postal Code").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the postal code";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    State: Joi.string().required().label("State").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the state";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
    Country: Joi.string().required().label("Country").error(errors => {
        errors.forEach(err => {
            switch (err.type) {
                case "any.empty":
                case "any.required":
                    err.message = "Please enter the country";
                    break;
                default:
                    break;
            }
        });
        return errors;
    }),
}


const validate = (data) => {
    const dataKeys = Object.keys(data);
    let schema = {};
    for (let key in dataKeys){
        if (schemas[dataKeys[key]]){
            schema = {
                ...schema,
                [dataKeys[key]]: schemas[dataKeys[key]],
            }
        }
        else{
            return {
                0: dataKeys[key] + "'s validation has not been configured",
            };
        }
    }

    const options = { abortEarly: false, allowUnknown: true };
    const result = Joi.validate(data, schema, options);
    const error = result.error;
    if (!error) return null;

    const errors = {};
    for (let item of result.error.details){
        errors[item.path[0]] = item.message;
    }
    return errors;
};


export default validate;