import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FileUploadPreview } from "components/ui";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import { UserInfoCard } from "components/user-info-card";
import { PageContent } from "components/PageContent";
import { removeSchemeTypeNamePrefix } from "utils/misc";
import Maybe from "components/Maybe";
import DateFormat from "utils/dateFormat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from "utils/numberFormat";
import { pluralizeFull } from "utils/pluralize";
import useLoginInfo from "hooks/useLoginInfo";
import * as companyAction from "store/entities/company/action";
import * as employeeAction from "store/entities/employee/action";

export interface CompanyEmployeesCreateSinglePreviewProps {}

export function CompanyEmployeesCreateSinglePreview(props: CompanyEmployeesCreateSinglePreviewProps) {
  let { companyId, id } = useParams();
  let navigationBaseUrl = "/trustee/company/"+companyId;
  
  const {userInfo} = useLoginInfo();
  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    navigationBaseUrl = "/company/employees"
  }

  const dispatch = useDispatch();
  const { companyLoading, company } = useSelector((s: any) => s.entities.company);
  const { employeeLoading, employee, employeeSchemeLoading, employeeScheme } = useSelector((s: any) => s.entities.employee);

  
  const getScheme = (id:string) => {
    const filteredSchemeType = company.data?.organizationShareSetting?.schemeTypes.filter((schemeType:any) => schemeType.id === id);
    return filteredSchemeType;
  }


  useEffect(() => {
    dispatch(companyAction.getCompany(companyId));
    dispatch(employeeAction.getEmployee(id));
    dispatch(employeeAction.getEmployeeScheme(id));
  }, []);


  return (
    <div>

      <FormWizardNavBar showSteps={true} currentStep={5} totalSteps={6} closeUrl={navigationBaseUrl} />

      <div className="container pb-10 mt-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Preview details
          </div>
          <p className="mt-2 text-fade text-sm">
            Kindly preview details before submitting
          </p>
        </div>

        <div className="mt-12 text-sm">

          <PageContent loading={employeeLoading || employeeSchemeLoading} data={employee.data || undefined} />

          <Maybe condition={companyLoading === false && company.data && Object.keys(company.data).length > 0}>
            <Maybe condition={employeeLoading === false && employee.data && Object.keys(employee.data).length > 0}>
              <Maybe condition={employeeSchemeLoading === false && employeeScheme.data && Object.keys(employeeScheme.data).length > 0}>
                <div>

                  <UserInfoCard
                    name={employee.data?.userInfo?.firstName + " " + employee.data?.userInfo?.lastName}
                    tel={employee.data?.userInfo?.phoneNumber}
                    email={employee.data?.userInfo?.email}
                    idNumber={employee.data?.employeeNumber}
                  />


                  <div className="mt-10">
                    <div className="text-gray-400 text-xs font-bold">
                      OFFICIAL DETAILS
                    </div>
                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6 break-words">
                      <div>
                        <div className="text-gray-400">
                          Email address
                        </div>
                        <div className="font-bold">
                          {employee.data?.officialEmail}
                        </div>
                      </div>
                      <div>
                        <div className="text-gray-400">
                          Grade Category
                        </div>
                        <div className="font-bold">
                          {employee.data?.gradeCategory || "-"}
                        </div>
                      </div>
                      <div>
                        <div className="text-gray-400">
                          Employee ID Number
                        </div>
                        <div className="font-bold">
                          {employee.data?.employeeNumber || "-"}
                        </div>
                      </div>
                      <div>
                        <div className="text-gray-400">
                          Date of hire
                        </div>
                        <div className="font-bold">
                          {DateFormat(employee.data?.dateOfHire) || "-"}
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="h-px bg-gray-300 my-10"></div>


                  <div>
                    <div className="text-gray-400 text-xs font-bold">
                      BANKS DETAILS
                    </div>
                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6 break-words">
                      <div>
                        <div className="text-gray-400">
                          Bank Name
                        </div>
                        <div className="font-bold">
                          {employee.data?.bankName || "-"}
                        </div>
                      </div>
                      <div>
                        <div className="text-gray-400">
                          Account Number
                        </div>
                        <div className="font-bold">
                          {employee.data?.accountNumber || "-"}
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="h-px bg-gray-300 my-10"></div>


                  <div>
                    <div className="text-gray-400 text-xs font-bold">
                      ALTERNATE BENEFICIARY DETAILS
                    </div>
                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6 break-words">
                      <div>
                        <div className="text-gray-400">
                          First name
                        </div>
                        <div className="font-bold">
                          {employee.data?.alternateBenefiaryFirstName || "-"}
                        </div>
                      </div>
                      <div>
                        <div className="text-gray-400">
                          Last name
                        </div>
                        <div className="font-bold">
                          {employee.data?.alternateBenefiaryLasName || "-"}
                        </div>
                      </div>
                      <div>
                        <div className="text-gray-400">
                          Phone number
                        </div>
                        <div className="font-bold">
                          {employee.data?.alternateBenefiaryPhoneNumber || "-"}
                        </div>
                      </div>
                      <div>
                        <div className="text-gray-400">
                          Email address
                        </div>
                        <div className="font-bold">
                          {employee.data?.alternateBenefiaryEmail || "-"}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="h-px bg-gray-300 my-10"></div>


                  <div>
                    <div className="text-gray-400 text-xs font-bold">
                      SCHEME SETTINGS
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-6 break-words">
                      <div>
                        <div className="text-gray-400">
                          Allocation Type
                        </div>
                        <div className="pt-1 font-bold text-vetiva-brown">
                          <FontAwesomeIcon icon="check" className="inline mr-2 -mt-0.5" />
                          {company.data?.organizationShareSetting?.sharesAllocation}
                        </div>
                      </div>
                      <div>
                        <div className="text-gray-400">
                          Sub-scheme settings
                        </div>

                        <Maybe condition={employeeScheme.data?.schemeTypes.length > 0}>
                          {employeeScheme.data?.employeeSubSchemes.map((subScheme:any, indexSubScheme:number) => {
                            const companySchemeTypeAll = getScheme(subScheme.schemeTypeId);
                            const companySchemeType = companySchemeTypeAll ? companySchemeTypeAll[0] : "";
                            // console.log("companySchemeType", companySchemeType);

                            return (
                              <div key={indexSubScheme} className="mt-4 bg-[#F8F9FD] p-4 rounded-md">
                                {companySchemeType !== "" &&
                                  <>
                                    <div className="text-vetiva-brown font-bold">
                                      <Maybe condition={companySchemeType?.name !== "Access to Others"}>
                                        {removeSchemeTypeNamePrefix(companySchemeType?.name) || "-"}
                                      </Maybe>
                                      <Maybe condition={companySchemeType?.name === "Access to Others"}>
                                        {companySchemeType.schemeDetail?.otherSchemeName}
                                      </Maybe>
                                    </div>

                                    <Maybe condition={subScheme.batches.length > 0}>
                                      {subScheme.batches.map((batch:any, indexBatch:number) => (
                                        <div key={indexBatch} className="mt-4 border border-gray-300 rounded-md">
                                          <div className="p-2 text-sm bg-gray-300">
                                            {batch.name}
                                          </div>
                                          <div className="p-4 flex flex-col space-y-6">
                                            {batch.allocations.map((allocation:any, indexAllocation:number) => {
                                              const companyAllocationAll = companySchemeType.subSchemes && companySchemeType.subSchemes[0]?.batches?.filter((companyBatch:any) => companyBatch.name === batch.name)[0]?.allocations?.filter((companyAllocation:any) => companyAllocation?.id === allocation.employeeOrgAlloctionId);
                                              const companyAllocation = companyAllocationAll ? companyAllocationAll[0] : "";

                                              return (
                                                <div key={indexAllocation} className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-6 break-words bg-[#F9F6F1]">
                                                  <div>
                                                    <div className="text-gray-400">
                                                      Employee's Number of allocation
                                                    </div>
                                                    <div className="font-bold">
                                                      {NumberFormat(allocation.numberOfAllocation, "no-kobo") || "-"}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div className="text-gray-400">
                                                      Employee's Date of allocation
                                                    </div>
                                                    <div className="font-bold">
                                                      {DateFormat(allocation.dateOfAllocation) || "-"}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div className="text-gray-400">
                                                      Company's Number of allocation
                                                    </div>
                                                    <div className="font-bold">
                                                      {NumberFormat(companyAllocation?.numberOfAllocation, "no-kobo") || "-"}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div className="text-gray-400">
                                                      Company's Date of allocation
                                                    </div>
                                                    <div className="font-bold">
                                                      {DateFormat(companyAllocation?.dateOfAllocation) || "-"}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div className="text-gray-400">
                                                      Percentage for vesting
                                                    </div>
                                                    <div className="font-bold">
                                                      {companyAllocation?.percentageVesting}%
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div className="text-gray-400">
                                                      Vesting date
                                                    </div>
                                                    <div className="font-bold">
                                                      <Maybe condition={companyAllocation?.vestingPeriod === "Fixed period"}>
                                                        {companyAllocation?.period} {pluralizeFull(companyAllocation?.period, "year", "years")}
                                                      </Maybe>
                                                      <Maybe condition={companyAllocation?.vestingPeriod === "Recurring date"}>
                                                        {DateFormat(companyAllocation?.vestingDate)}
                                                      </Maybe>
                                                    </div>
                                                  </div>
                                                  <div className="col-span-2">
                                                    <FileUploadPreview
                                                      className="bg-white"
                                                      file={companyAllocation?.schemeRuleObj ? companyAllocation?.schemeRuleObj[0] || undefined : undefined}
                                                    />
                                                  </div>
                                                </div>
                                              )

                                            })}
                                          </div>
                                        </div>
                                      ))}
                                    </Maybe>

                                    <Maybe condition={subScheme.batches.length === 0}>
                                      <div className="page-info-italic py-10 bg-gray-100">
                                        No batches for this scheme type
                                      </div>
                                    </Maybe>

                                    <div className="mt-4 border border-gray-300 rounded-md">
                                      <div className="p-2 text-sm bg-gray-300">
                                        Trust Deed
                                      </div>
                                      <div className="p-4">
                                        <div>
                                          <FileUploadPreview
                                            className="bg-white"
                                            file={companySchemeType.subSchemes[0].trustDeedObject ? companySchemeType.subSchemes[0].trustDeedObject[0] || undefined : undefined}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                }

                                <Maybe condition={companySchemeType === ""}>
                                  <div className="page-info-italic py-10 bg-gray-100">
                                    Unable to fetch scheme information
                                  </div>
                                </Maybe>
                              </div>
                            )

                          })}


                          {employeeScheme.data?.noSubschemeSchemeTypeDetailsResponses.map((subScheme:any, indexSubScheme:number) => {
                            const companySchemeTypeAll = getScheme(subScheme.schemeTypeId);
                            const companySchemeType = companySchemeTypeAll ? companySchemeTypeAll[0] : "";
                            // console.log("companySchemeType", companySchemeType);

                            return (
                              <div key={indexSubScheme} className="mt-4 bg-[#F8F9FD] p-4 rounded-md">
                                {companySchemeType !== "" &&
                                  <>
                                    <div className="text-vetiva-brown font-bold">
                                      <Maybe condition={companySchemeType?.name !== "Access to Others"}>
                                        {removeSchemeTypeNamePrefix(companySchemeType?.name) || "-"}
                                      </Maybe>
                                      <Maybe condition={companySchemeType?.name === "Access to Others"}>
                                        {companySchemeType.schemeDetail?.otherSchemeName}
                                      </Maybe>
                                    </div>

                                    <div className="mt-4 border border-gray-300 rounded-md">
                                      <div className="p-2 text-sm bg-gray-300"></div>
                                      <div className="p-4 flex flex-col space-y-6">
                                        <div className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-6 break-words bg-[#F9F6F1]">
                                          <div>
                                            <div className="text-gray-400">
                                              Employee's Number of allocation
                                            </div>
                                            <div className="font-bold">
                                              {NumberFormat(subScheme.numberOfAllocation, "no-kobo") || "-"}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="text-gray-400">
                                              Employee's Date of allocation
                                            </div>
                                            <div className="font-bold">
                                              {DateFormat(subScheme.dateOfAllocation) || "-"}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="text-gray-400">
                                              Company's Number of allocation
                                            </div>
                                            <div className="font-bold">
                                              {NumberFormat(companySchemeType.schemeDetail.numberOfAllocation, "no-kobo") || "-"}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="text-gray-400">
                                              Company's Date of allocation
                                            </div>
                                            <div className="font-bold">
                                              {DateFormat(companySchemeType.schemeDetail.dateOfAllocation) || "-"}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="text-gray-400">
                                              Percentage for vesting
                                            </div>
                                            <div className="font-bold">
                                              {companySchemeType.schemeDetail.percentageVesting}%
                                            </div>
                                          </div>
                                          <div>
                                            <div className="text-gray-400">
                                              Vesting date
                                            </div>
                                            <div className="font-bold">
                                              <Maybe condition={companySchemeType.schemeDetail.vestingPeriod === "Fixed period"}>
                                                {companySchemeType.schemeDetail.period} {pluralizeFull(companySchemeType.schemeDetail.period, "year", "years")}
                                              </Maybe>
                                              <Maybe condition={companySchemeType.schemeDetail.vestingPeriod === "Recurring date"}>
                                                {DateFormat(companySchemeType.schemeDetail.vestingDate)}
                                              </Maybe>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                }

                                <Maybe condition={companySchemeType === ""}>
                                  <div className="page-info-italic py-10 bg-gray-100">
                                    Unable to fetch scheme information
                                  </div>
                                </Maybe>
                              </div>
                            )

                          })}
                        </Maybe>

                        <Maybe condition={employeeScheme.data?.schemeTypes.length === 0}>
                          <div className="page-info-italic py-10 bg-gray-100">
                            No scheme types configured for this employee
                          </div>
                        </Maybe>

                      </div>
                    </div>
                  </div>


                  <div className="mt-10 flex justify-end space-x-3">
                    <Link to={navigationBaseUrl + "/form/" + id + "/beneficiary"} className="btn btn-gold">
                      Back
                    </Link>
                    <Link to={navigationBaseUrl + "/form/" + id + "/success"} className="btn btn-brown">
                      Submit
                    </Link>
                  </div>

                </div>
              </Maybe>
            </Maybe>
          </Maybe>

        </div>
      </div>

    </div>
  );
}

export default CompanyEmployeesCreateSinglePreview;
