import { ISelectOptions } from "data/interfaces";
import { useEffect, useState } from "react";

export interface ButtonGroupCheckboxProps {
  selectOptions: ISelectOptions[];
  onValueChange?: any;
  defaultProps?: any;
  allowValueChange?: boolean;
}

export function ButtonGroupCheckbox(props: ButtonGroupCheckboxProps) {
  const { selectOptions, onValueChange, defaultProps, allowValueChange } = props;
  const [items, setItems] = useState(selectOptions);

  const active = "text-vetiva-brown bg-white rounded-md border border-vetiva-brown";
  const inActive = "text-gray-400 bg-gray-100 rounded-md border border-gray-300";

  const handleClick = (data: any, index: number) => {
    if (allowValueChange === undefined || allowValueChange === true){
      const itemsUpdated = items.map((obj, i) => {
        if (index === i) {
          return { ...obj, selected: obj.selected ? false : true };
        }
        return { ...obj };
      })
      setItems(itemsUpdated);

      const selected = itemsUpdated.filter((item) => (item.selected === true))
      const selectedArray = selected.map((item) => item.value)
      onValueChange(selectedArray)
    }
  }

  const func = () => {}


  useEffect(() => {
    setItems(selectOptions);
  }, [selectOptions])

  return (
    <div className="inline-flex flex-wrap">
      {items.map((item, i) => (
        <div key={i}>
          <input {...defaultProps} type="hidden" />
          <button
            onClick={() => handleClick(item?.value, i)}
            type="button"
            className={`mr-4 py-3 px-4 mb-4 text-sm font-medium ${
              item?.selected ? active : inActive
            }`}
          >
            <input type="checkbox" checked={item?.selected ? true : false} className="form-checkbox mr-2 -mt-1 " onChange={func} />
            {item?.label}
          </button>
        </div>
      ))}
    </div>
  );
}

//hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700

export default ButtonGroupCheckbox;
