import * as actions from "./actionTypes";
import jwt_decode from "jwt-decode";

const initialState = {
    userLogin: false,
    userInfo: {},
};


export default function reducer(state = initialState, action) {

    switch (action.type) {

        case actions.LOGIN_USER:
            state = {
                ...state,
                userLogin: true,
                userInfo: {
                    accessToken: action.payload.data.accessToken,
                    refreshToken: action.payload.data.refreshToken,
                    userViewWhenDuo: "employer",
                },
            }
            return state;


        case actions.LOGIN_USER_START:
            state = {
                ...state,
                userLogin: false,
                userInfo: {}
            }
            return state;


        case actions.LOGIN_USER_FAILED:
            state = {
                ...state,
                userLogin: false,
                userInfo: {}
            }
            return state;


        case actions.UPDATE_LOGIN_USER:
            const userLoginUpdate = action.payload.data;
            state = {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    firstName: userLoginUpdate.firstName,
                    lastName: userLoginUpdate.lastName,
                    name: userLoginUpdate.firstName + " " + userLoginUpdate.lastName,
                    email: userLoginUpdate.email,
                    phoneNumber: userLoginUpdate.phoneNumber,
                    jobTitle: userLoginUpdate.jobPosition,
                },
            }
            return state;

            
        case actions.LOGOUT_USER:
            const {params} = action.payload;

            let gotoUrl = "/login";
            const user = jwt_decode(state?.userInfo?.accessToken);
            if (user.RolesStr && user.RolesStr === "TRUSTEE"){
                gotoUrl = "/trustee/login";
            }

            state = {
                ...state,
                userLogin: false,
                userInfo: {}
            }
            
            if (params.redirect === undefined || params.redirect === true){
                window.location.href = gotoUrl;
            }
            return state;


        case actions.SWITCH_USER_VIEW:
            const {view} = action.payload;

            state = {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    userViewWhenDuo: view,
                },
            }

            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
            return state;


        default:
            return state;
    }
}