import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import Maybe from "components/Maybe";
import { PageContent } from "components/PageContent";
import { useEffect, useState } from "react";
import { DateMiniFormat } from "utils/dateFormat";
import CurrencyFormat from "utils/currencyFormat";
import useLoginInfo from "hooks/useLoginInfo";
import { default as ReactSelect, components } from "react-select";
import { BatchAccordion } from "components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from "utils/numberFormat";
import * as companyAction from "store/entities/company/action";
import * as employeeAction from "store/entities/employee/action";


export interface CompanyEmployeeInstructionSchemeProps {}

export function CompanyEmployeeInstructionScheme(props: CompanyEmployeeInstructionSchemeProps) {
  const navigate = useNavigate();
  const {userInfo} = useLoginInfo();

  let { companyId, employeeId } = useParams();
  let employeeDetailsUrl = "/trustee/company/"+companyId+"/employee/"+employeeId;
  let sendInstructionSelectionUrl = "/trustee/company/"+companyId+"/employee/"+employeeId;
  
  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    employeeDetailsUrl = "/company/employees/"+employeeId;
    sendInstructionSelectionUrl = employeeDetailsUrl;
  }
  else if (userInfo.userType === "SCHEME_MEMBER"){
    companyId = userInfo.organizationId;
    employeeId = userInfo.employeeId;
    employeeDetailsUrl = "/member/overview";
    sendInstructionSelectionUrl = "/member";
  }

  const dispatch = useDispatch();
  const { companyLoading, company } = useSelector((s: any) => s.entities.company);
  const { employeeSchemeLoading, employeeScheme } = useSelector((s: any) => s.entities.employee);
  const [employeeSchemeData, setEmployeeSchemeData] = useState([]);


  useEffect(() => {
    dispatch(companyAction.getCompany(companyId));
    dispatch(employeeAction.getEmployeeScheme(employeeId));
  }, []);


  useEffect(() => {
    if (companyLoading === false && company.data && employeeScheme.data){
      const newData = company.data.organizationShareSetting.schemeTypes.filter((schemeType:any) =>
        employeeScheme.data?.schemeTypes.filter((employeeSchemeType:any) => employeeSchemeType.id === schemeType.id).length === 1
      );
      setEmployeeSchemeData(newData);
    }
  }, [company, employeeScheme])


  return (
    <div>

      <FormWizardNavBar showSteps={false} closeUrl={employeeDetailsUrl} />

      <div className="container py-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Select Scheme type
          </div>
          <p className="mt-2 text-fade text-sm">
            Select Scheme type
          </p>
        </div>


        <PageContent loading={companyLoading || employeeSchemeLoading} data={employeeScheme.data || undefined} emptyText="Employee scheme settings not found" />

        <Maybe condition={companyLoading === false && company.data && Object.keys(company.data).length > 0}>
          <Maybe condition={employeeSchemeLoading === false && employeeScheme.data && Object.keys(employeeScheme.data).length > 0}>
            <div className="mt-12 mx-auto grid grid-cols-1 gap-6 max-w-md">

              {employeeSchemeData.map((schemeType:any, index:number) => 
                <div key={index}>
                  {/* <Maybe condition={schemeType.hasSubSchemeAccess === false}> */}
                    <Link to={sendInstructionSelectionUrl + "/instruction/" + schemeType.id} className="flex justify-between items-center shadow-md p-4 rounded-md hover:bg-gray-100 cursor-pointer">
                      <div>
                        <SchemeInfo schemeType={schemeType} />
                      </div>
                      <FontAwesomeIcon icon="chevron-right" />
                    </Link>
                  {/* </Maybe> */}

                  {/* 
                  <Maybe condition={schemeType.hasSubSchemeAccess === true}>
                    <div className="block shadow-md p-4 rounded-md hover:bg-gray-100">
                      <BatchAccordion label={<div><SchemeInfo schemeType={schemeType} /></div>} isOpen={false} customStyles={true}>
                        <div className="p-4 mt-4 bg-vetiva-gold-lighter">
                          <SchemeTypeBatchSelection schemeType={schemeType} sendInstructionSelectionUrl={sendInstructionSelectionUrl} />
                        </div>
                      </BatchAccordion>
                    </div>
                  </Maybe>
                   */}
                </div>
              )}

            </div>
          </Maybe>
        </Maybe>

      </div>
    </div>
  );
}


const SchemeInfo = ({schemeType}:any) => {
  return (
    <>
      <div className="text-lg font-bold">
        {schemeType.name !== "Access to Others" ? schemeType.name : schemeType.schemeDetail.otherSchemeName}
      </div>
      <div>
        <div className="mt-1 text-sm text-fade">
          Holding period: <span className="font-bold">{DateMiniFormat(schemeType.startDate)} - {DateMiniFormat(schemeType.endDate)}</span>
        </div>
        <Maybe condition={schemeType.hasSubSchemeAccess === false}>
          <Maybe condition={schemeType.schemeDetail?.purchasePrice !== null && schemeType.schemeDetail?.purchasePrice !== 0}>
            <div className="mt-0.5 text-sm text-fade">
              Purchase price: <span className="font-bold">{CurrencyFormat(schemeType.schemeDetail?.purchasePrice)}</span>
            </div>
          </Maybe>
          <Maybe condition={schemeType.schemeDetail?.strikePrice !== null && schemeType.schemeDetail?.strikePrice !== 0}>
            <div className="mt-0.5 text-sm text-fade">
              Strike price: <span className="font-bold">{CurrencyFormat(schemeType.schemeDetail?.strikePrice)}</span>
            </div>
          </Maybe>
          <Maybe condition={schemeType.schemeDetail?.valuation !== null && schemeType.schemeDetail?.valuation !== 0}>
            <div className="mt-0.5 text-sm text-fade">
              Valuation: <span className="font-bold">{CurrencyFormat(schemeType.schemeDetail?.valuation)}</span>
            </div>
          </Maybe>
        </Maybe>
      </div>
    </>
  )
}





const Option = (props:any) => {
  return (
      <div className="p-0">
          <components.Option {...props}>
              <div className="-mx-2 -my-1.5 p-4 text-sm flex justify-between border rounded-sm">
                  <div className="w-10 flex-shrink-0">
                    <input
                      type="radio"
                      checked={props.isSelected}
                      onChange={() => null}
                      className="w-5 h-5 form-radio rounded-full"
                    />
                  </div>
                  <div className="flex-grow text-left font-semibold">
                    {props.label}
                  </div>
              </div>
          </components.Option>
      </div>
  );
};


const ReactSelectStyles = {
  option: (provided:any, state:any) => ({
      ...provided,
      color: "#333",
      background: "#fff",
      cursor: "pointer",
      "&:hover": {
          background: "#eee",
      }
    }),
};


const SchemeTypeBatchSelection = ({schemeType: defaultSchemeType, sendInstructionSelectionUrl}:any) => {
  
  const [schemeType, setSchemeType] = useState<any>(defaultSchemeType);
  const [selectedBatch, setSelectedBatch] = useState<any>([]);

  const getBatches = (batches:any) => {
    if (batches !== undefined && batches.length > 0){
      const batchesSelection = batches.map((batch:any) => {
        return {
          label: batch.name,
          value: batch.id,
        }
      })
      return batchesSelection;
    }
    return [];
  }

  const updateBatchSelection = (selection:any) => {
    const newSubSchemeBatches = schemeType.subSchemes[0]?.batches.map((batch:any) => {
      if (selection.value === batch.id){
        return {...batch, selected: true};
      }
      else{
        return {...batch, selected: false};
      }
    })
    const newSubScheme = [{
      id: schemeType.subSchemes[0].id,
      trustDeedObject: schemeType.subSchemes[0].trustDeedObject,
      batches: newSubSchemeBatches,
    }]
    const newSchemeType = {...schemeType, subSchemes: newSubScheme};
    setSchemeType(newSchemeType);
    setSelectedBatch(newSchemeType.subSchemes[0]?.batches.filter((batch:any) => batch.selected === true));
  }  

  return (
    <div>
      <ReactSelect
        options={getBatches(schemeType.subSchemes[0]?.batches)}
        placeholder="Select batches, groups, categories"
        styles={ReactSelectStyles}
        isMulti={false}
        closeMenuOnSelect={schemeType.subSchemes[0]?.batches.length > 1 ? false : true}
        hideSelectedOptions={false}
        components={{Option}}
        onChange={(e:any) => updateBatchSelection(e)}
        value={getBatches(selectedBatch)}
      />

    <Maybe condition={selectedBatch.length > 0}>
      <div className="mt-3 space-y-2">
        {selectedBatch[0]?.allocations?.map((allocation:any) =>
          <Link
            key={allocation.id}
            to={sendInstructionSelectionUrl + "/instruction/" + schemeType.id + "/" + selectedBatch[0].id + "/" + allocation.id}
            className="px-4 py-3 bg-white border flex justify-between items-center rounded-md hover:bg-vetiva-gold cursor-pointer"
          >
            <div className="font-bold">
              {allocation.name} - ({NumberFormat(allocation.numberOfAllocation, "no-kobo")})
            </div>
            <FontAwesomeIcon icon="chevron-right" />
          </Link>
        )}
      </div>
    </Maybe>

    </div>
  )
}


export default CompanyEmployeeInstructionScheme;
