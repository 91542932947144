import axios from "axios";
import * as config from "data/config";
import * as userActions from "store/auth/user/actionTypes";

const api = store => next => async action => {



    const getBaseUrl = (baseUrl) => {
        let apiBaseUrl;
        if (typeof baseUrl !== "undefined") {
            apiBaseUrl = baseUrl;
        }
        else {
            apiBaseUrl = config.apiBaseUrl;
        }
        return apiBaseUrl;
    }



    const getToken = (customToken) => {
        let apiToken;
        if (typeof customToken !== "undefined") {
            apiToken = customToken;
        }
        else {
            const loginJwt = store.getState().auth.userPersist.userInfo.accessToken;
            apiToken = loginJwt;
        }
        return apiToken;
    }



    if (action.type !== config.apiRequestStart) {
        next(action);
    }
    else {

        const { baseUrl, url, method, data, onStart, onSuccess, onError, useLoginJwt, customToken, customHeaders, additionalResponse } = action.payload;
        if (onStart) {
            store.dispatch({ type: onStart });
        }

        const apiBaseUrl = getBaseUrl(baseUrl);

        let http;
        if ((typeof useLoginJwt !== "undefined") && (useLoginJwt === false)) {
            http = axios.create({
                headers: {
                    ...customHeaders
                }
            });
        }
        else {
            const token = getToken(customToken);
            http = axios.create({
                headers: {
                    Authorization: "Bearer " + token,
                    ...customHeaders
                }
            });
        }



        next(action);                                                                           // for debugger logging purpose (in chrome Redux dev tools)



        await http.request({
            baseURL: apiBaseUrl,
            url,
            method,
            data
        }).then((response) => {
            store.dispatch({ type: config.apiRequestSuccess, payload: response });                // for debugger logging purpose
            if (onSuccess) {
                if (!additionalResponse){
                    store.dispatch({ type: onSuccess, payload: response.data });
                }
                else{
                    store.dispatch({ type: onSuccess, payload: {
                            response: response.data,
                            additionalResponse: additionalResponse,
                        }
                    });
                }
            }
        }).catch((error) => {
            store.dispatch({ type: config.apiRequestFailed, payload: error });                    // for debugger logging purpose
            if (onError) {
                if (error.response) {
                    store.dispatch({ type: onError, payload: error.response?.data });
                    if (error.response?.data?.message === "Expired Session, Please Login Again") {
                        // window.setTimeout(function () {
                            store.dispatch({ type: userActions.LOGOUT_USER });
                        // }, 1000);
                    }
                }
                else if (error.request) {
                    if (navigator.onLine) {
                        store.dispatch({
                            type: onError,
                            payload: {
                                message: "Error: Invalid request. Please try again later."
                            }
                        });
                    }
                    else {
                        store.dispatch({
                            type: onError,
                            payload: {
                                message: "No Internet Connection"
                            }
                        });
                    }
                }
                else {
                    store.dispatch({
                        type: onError,
                        payload: {
                            message: "An error occurred. Please try again later."
                        }
                    });
                }
            }
        });

    }

}

export default api;