import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useUrlNavigation from "hooks/useUrlNavigation";
import Maybe from "./Maybe";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import * as companyAction from "store/entities/company/action";


const Option = (props:any) => {
  return (
      <div className="p-0">
          <components.Option {...props}>
              <div className="-mx-2 -my-1.5 p-4 text-sm flex justify-between border rounded-sm">
                  <div className="w-10 flex-shrink-0">
                    <input
                      type="checkbox"
                      checked={props.isSelected}
                      onChange={() => null}
                      className="w-5 h-5 form-checkbox rounded-full"
                    />
                  </div>
                  <div className="flex-grow text-left font-semibold">
                    {props.label}
                  </div>
              </div>
          </components.Option>
      </div>
  );
};


const ReactSelectStyles = {
  option: (provided:any, state:any) => ({
      ...provided,
      color: "#333",
      background: "#fff",
      cursor: "pointer",
      "&:hover": {
          background: "#eee",
      }
    }),
};


/* eslint-disable-next-line */
export interface CompanySearchSelectProps {}

export function CompanySearchSelect(props: CompanySearchSelectProps) {
  const navigate = useNavigate();
  const {urlQuery} = useUrlNavigation();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const { companiesLoading, companies } = useSelector((s: any) => s.entities.company);
  

  const [companiesArray, setCompaniesArray] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);


  const getCompanies = () => {
    const params = {
      PageNumber: 1,
      PageSize: 1000,
    };
    dispatch(companyAction.getCompanies(params));
  }
  

  const companyToggle = (e:any) => {
    setSelectedCompany(e);
    navigate(urlQuery("company="+e.value));
  }
  

  const getCompaniesArray = () => {
    if (companies?.success && companies?.success === true && companies?.data?.length > 0){
      const array = companies?.data?.map((company:any) => {
        return {
          label: company.name,
          value: company.id,
        }
      })
      setCompaniesArray(array);
      getSelectedCompanyArray();
    }
  }
  

  const getSelectedCompanyArray = () => {
    const companyId = searchParams.get("company");
    if (companyId !== null && companyId !== ""){
      if (companies?.success && companies?.success === true && companies?.data?.length > 0){
        const company = companies?.data?.filter((company:any) => company.id === companyId);
        if (company.length === 1){
          const params = {
            label: company[0].name,
            value: company[0].id,
          }
          setSelectedCompany(params);
        }
      }
    }
    else{
      setSelectedCompany(null);
    }
  }
  

  useEffect(() => {
    getCompanies();
  }, []);


  useEffect(() => {
    getCompaniesArray();
  }, [companies]);


  useEffect(() => {
    getSelectedCompanyArray();
  }, [location]);


  return (
    <div>

        <Maybe condition={companiesLoading}>
          <div className="box py-2 flex space-x-2 items-center">
            <FontAwesomeIcon icon="spinner" spin />
            <div>
              Loading companies
            </div>
          </div>
        </Maybe>

        <Maybe condition={!companiesLoading}>
          <ReactSelect
            options={companiesArray}
            onChange={(e:any) => companyToggle(e)}
            value={selectedCompany}
            placeholder="Search company"
            noOptionsMessage={() => "No companies yet"}
            styles={ReactSelectStyles}
            isMulti={false}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            components={{Option}}
          />
        </Maybe>


    </div>
  );
}

export default CompanySearchSelect;
