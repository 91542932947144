import { Link, useNavigate } from "react-router-dom";
import StepperNavigation from "components/stepper-navigation";
import DialogBox from "utils/dialogBox";

export interface FormWizardNavBarProps {
  showSteps: boolean,
  currentStep?: number,
  totalSteps?: number,
  closeUrl: string,
}

export function FormWizardNavBar(props: FormWizardNavBarProps) {

  const navigate = useNavigate();
  

  const closeUrl = async () => {
    const result = await DialogBox({
      theme: "red",
      content: "If you proceed, you will lose all unsaved data and this cannot be recovered",
      buttonYes: "Yes, exit and lose unsaved data",
      buttonNo: "Don't exit",
    });
    if (result) {
      navigate(props.closeUrl);
    }
  }


  return (
    <div>

      
      <div className="w-full h-16 fixed z-10 top-0 right-0 left-0 flex bg-white border border-b-gray-200">
        <div className="container my-auto flex items-center justify-between">

          
          <div className="hidden md:flex w-1/4">
            <Link to="/" className="text-lg font-noto flex items-center leading-4">
              <div className="w-8 h-8 mr-2">
                  <img src="/brand-logo.png" className="object-contain" alt="Vetiva" />
              </div>
              VTL Staff Share Scheme
            </Link>
          </div>


          <div className="w-1/4">
            {props.showSteps &&
              <StepperNavigation {...props} />
            }
          </div>


          <div className="w-1/4">
            <div className="flex justify-end">
            <button type="button" onClick={closeUrl} className="btn bg-gray-200 hover:text-white hover:bg-vetiva-brown">
              Close
            </button>
            </div>
          </div>


        </div>
      </div>

      <div className="h-16"></div>
      
    </div>
  );
}

export default FormWizardNavBar;
