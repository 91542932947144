
export const GET_USERMGMTS_DASHBOARD_STATS = "GET_USERMGMTS_DASHBOARD_STATS";
export const GET_USERMGMTS_DASHBOARD_STATS_START = "GET_USERMGMTS_DASHBOARD_STATS_START";
export const GET_USERMGMTS_DASHBOARD_STATS_FAILED = "GET_USERMGMTS_DASHBOARD_STATS_FAILED";

export const GET_USERMGMTS = "GET_USERMGMTS";
export const GET_USERMGMTS_START = "GET_USERMGMTS_START";
export const GET_USERMGMTS_FAILED = "GET_USERMGMTS_FAILED";

export const GET_USERMGMT = "GET_USERMGMT";
export const GET_USERMGMT_START = "GET_USERMGMT_START";
export const GET_USERMGMT_FAILED = "GET_USERMGMT_FAILED";

export const CREATE_USERMGMT = "CREATE_USERMGMT";
export const CREATE_USERMGMT_START = "CREATE_USERMGMT_START";
export const CREATE_USERMGMT_FAILED = "CREATE_USERMGMT_FAILED";
export const CREATE_USERMGMT_RESET = "CREATE_USERMGMT_RESET";

export const EDIT_USERMGMT = "EDIT_USERMGMT";
export const EDIT_USERMGMT_START = "EDIT_USERMGMT_START";
export const EDIT_USERMGMT_FAILED = "EDIT_USERMGMT_FAILED";
export const EDIT_USERMGMT_RESET = "EDIT_USERMGMT_RESET";

export const ACTIVATE_USERMGMT = "ACTIVATE_USERMGMT";
export const ACTIVATE_USERMGMT_START = "ACTIVATE_USERMGMT_START";
export const ACTIVATE_USERMGMT_FAILED = "ACTIVATE_USERMGMT_FAILED";

export const DEACTIVATE_USERMGMT = "DEACTIVATE_USERMGMT";
export const DEACTIVATE_USERMGMT_START = "DEACTIVATE_USERMGMT_START";
export const DEACTIVATE_USERMGMT_FAILED = "DEACTIVATE_USERMGMT_FAILED";

export const RESEND_INVITE_USERMGMT = "RESEND_INVITE_USERMGMT";
export const RESEND_INVITE_USERMGMT_START = "RESEND_INVITE_USERMGMT_START";
export const RESEND_INVITE_USERMGMT_FAILED = "RESEND_INVITE_USERMGMT_FAILED";

export const GET_USER_ACTIVITY_HISTORY = "GET_USER_ACTIVITY_HISTORY";
export const GET_USER_ACTIVITY_HISTORY_START = "GET_USER_ACTIVITY_HISTORY_START";
export const GET_USER_ACTIVITY_HISTORY_FAILED = "GET_USER_ACTIVITY_HISTORY_FAILED";

export const GET_COMPANY_USERMGMTS_DASHBOARD_STATS = "GET_COMPANY_USERMGMTS_DASHBOARD_STATS";
export const GET_COMPANY_USERMGMTS_DASHBOARD_STATS_START = "GET_COMPANY_USERMGMTS_DASHBOARD_STATS_START";
export const GET_COMPANY_USERMGMTS_DASHBOARD_STATS_FAILED = "GET_COMPANY_USERMGMTS_DASHBOARD_STATS_FAILED";
