import { Outlet } from "react-router-dom";
import Footer from "components/Footer";

const NullLayout = () => {
    return (
        <div>
            
            <div className="w-full h-16 fixed z-10 top-0 right-0 left-0 flex bg-white border border-b-gray-200">
                <div className="container my-auto">
                
                    <a href="/" className="text-lg font-noto flex items-center">
                        <div className="w-8 h-8 mr-2">
                            <img src="/brand-logo.png" className="object-contain" alt="Vetiva" />
                        </div>
                        VTL Staff Share Scheme
                    </a>
                    
                </div>
            </div>


            <div className="mt-16 min-h-[500px]">
                <Outlet />
            </div>

            <Footer />
            
        </div>
    )
}

export default NullLayout
