import { toast } from 'react-toastify';

export const errorReducer = (payload) => {
  if (payload?.message === "Validation Failed") {
    const errors = payload.error;
    if (errors.length > 0){
      errors.map((error) => 
        toast.error(error.message)
      )
    }
  }
  else if (payload?.message !== undefined){
      toast.error(payload?.message);
  }
  else {
      toast.error("An error occurred. Please try again later.");
  }
};


export default errorReducer;