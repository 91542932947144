import * as actions from "./actionTypes";
import * as config from "data/config";
import ObjectToQueryString from 'utils/ObjectToQueryString';

export function getEmployeesDashboardStats() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiEmployeeDashboardStats,
            method: "get",
            data: {},
            onStart: actions.GET_EMPLOYEES_DASHBOARD_STATS_START,
            onSuccess: actions.GET_EMPLOYEES_DASHBOARD_STATS,
            onError: actions.GET_EMPLOYEES_DASHBOARD_STATS_FAILED,
        },
    }
}

export function getEmployees(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCompany + "/" + id + "/employees" + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_EMPLOYEES_START,
            onSuccess: actions.GET_EMPLOYEES,
            onError: actions.GET_EMPLOYEES_FAILED,
        },
    }
}

export function getEmployee(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiEmployee}/${id}`,
            method: "get",
            data: {},
            onStart: actions.GET_EMPLOYEE_START,
            onSuccess: actions.GET_EMPLOYEE,
            onError: actions.GET_EMPLOYEE_FAILED,
        },
    }
}

export function getEmployeeScheme(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiEmployee}/${id}/employee-scheme`,
            method: "get",
            data: {},
            onStart: actions.GET_EMPLOYEE_SCHEME_START,
            onSuccess: actions.GET_EMPLOYEE_SCHEME,
            onError: actions.GET_EMPLOYEE_SCHEME_FAILED,
        },
    }
}

export function getEmployeeDashboard(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiEmployee}/${id}/total-share-metric`,
            method: "get",
            data: {},
            onStart: actions.GET_EMPLOYEE_DASHBOARD_START,
            onSuccess: actions.GET_EMPLOYEE_DASHBOARD,
            onError: actions.GET_EMPLOYEE_DASHBOARD_FAILED,
        },
    }
}

export function getEmployeeMetrics(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiEmployee}/${id}/share-metrics`,
            method: "get",
            data: {},
            onStart: actions.GET_EMPLOYEE_METRICS_START,
            onSuccess: actions.GET_EMPLOYEE_METRICS,
            onError: actions.GET_EMPLOYEE_METRICS_FAILED,
        },
    }
}

export function getEmployeeBenefitStatement(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiEmployee}/${id}/employee-benefit-statement`,
            method: "get",
            data: {},
            onStart: actions.GET_EMPLOYEE_BENEFIT_STATEMENT_START,
            onSuccess: actions.GET_EMPLOYEE_BENEFIT_STATEMENT,
            onError: actions.GET_EMPLOYEE_BENEFIT_STATEMENT_FAILED,
        },
    }
}

export function createStep1(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiEmployee,
            method: "put",
            data: params,
            onStart: actions.CREATE_EMPLOYEE_STEP_1_START,
            onSuccess: actions.CREATE_EMPLOYEE_STEP_1,
            onError: actions.CREATE_EMPLOYEE_STEP_1_FAILED,
        },
    }
}

export function createStep1Reset() {
    return {
        type: actions.CREATE_EMPLOYEE_STEP_1_RESET,
        payload: {},
    }
}

export function createStep2(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiEmployee,
            method: "put",
            data: params,
            onStart: actions.CREATE_EMPLOYEE_STEP_2_START,
            onSuccess: actions.CREATE_EMPLOYEE_STEP_2,
            onError: actions.CREATE_EMPLOYEE_STEP_2_FAILED,
        },
    }
}

export function createStep2Reset() {
    return {
        type: actions.CREATE_EMPLOYEE_STEP_2_RESET,
        payload: {},
    }
}

export function createStep3(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiEmployee}/${id}/employee-scheme`,
            method: "post",
            data: params,
            onStart: actions.CREATE_EMPLOYEE_STEP_3_START,
            onSuccess: actions.CREATE_EMPLOYEE_STEP_3,
            onError: actions.CREATE_EMPLOYEE_STEP_3_FAILED,
        },
    }
}

export function createStep3Reset() {
    return {
        type: actions.CREATE_EMPLOYEE_STEP_3_RESET,
        payload: {},
    }
}

export function createStep4(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiEmployee,
            method: "put",
            data: params,
            onStart: actions.CREATE_EMPLOYEE_STEP_4_START,
            onSuccess: actions.CREATE_EMPLOYEE_STEP_4,
            onError: actions.CREATE_EMPLOYEE_STEP_4_FAILED,
        },
    }
}

export function createStep4Reset() {
    return {
        type: actions.CREATE_EMPLOYEE_STEP_4_RESET,
        payload: {},
    }
}

export function uploadEmployees(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiEmployee}/upload`,
            method: "post",
            data: params,
            onStart: actions.UPLOAD_EMPLOYEE_START,
            onSuccess: actions.UPLOAD_EMPLOYEE,
            onError: actions.UPLOAD_EMPLOYEE_FAILED,
            // customHeaders: {'content-type':'multipart/form-data'},
        },
    }
}

export function uploadEmployeesReset() {
    return {
        type: actions.UPLOAD_EMPLOYEE_RESET,
        payload: {},
    }
}

export function activateEmployee(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiEmployee}/activate/${id}`,
            method: "put",
            data: {},
            onStart: actions.ACTIVATE_EMPLOYEE_START,
            onSuccess: actions.ACTIVATE_EMPLOYEE,
            onError: actions.ACTIVATE_EMPLOYEE_FAILED,
        },
    }
}

export function activateBulkEmployees(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiEmployee}/bulk-status-update`,
            method: "post",
            data: params,
            onStart: actions.ACTIVATE_EMPLOYEE_START,
            onSuccess: actions.ACTIVATE_EMPLOYEE,
            onError: actions.ACTIVATE_EMPLOYEE_FAILED,
        },
    }
}

export function activateBulkEmployeesReset() {
    return {
        type: actions.ACTIVATE_EMPLOYEE_RESET,
        payload: {},
    }
}

export function deactivateEmployee(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiEmployee}/deactivate/${id}`,
            method: "put",
            data: {},
            onStart: actions.DEACTIVATE_EMPLOYEE_START,
            onSuccess: actions.DEACTIVATE_EMPLOYEE,
            onError: actions.DEACTIVATE_EMPLOYEE_FAILED,
        },
    }
}

export function deactivateBulkEmployees(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiEmployee}/bulk-status-update`,
            method: "post",
            data: params,
            onStart: actions.DEACTIVATE_EMPLOYEE_START,
            onSuccess: actions.DEACTIVATE_EMPLOYEE,
            onError: actions.DEACTIVATE_EMPLOYEE_FAILED,
        },
    }
}

export function deactivateBulkEmployeesReset() {
    return {
        type: actions.DEACTIVATE_EMPLOYEE_RESET,
        payload: {},
    }
}

export function exitEmployee(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiEmployee}/exit/${id}`,
            method: "put",
            data: {},
            onStart: actions.EXIT_EMPLOYEE_START,
            onSuccess: actions.EXIT_EMPLOYEE,
            onError: actions.EXIT_EMPLOYEE_FAILED,
        },
    }
}

export function resendInvite(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiEmployee}/${id}/resend-invite`,
            method: "put",
            data: {},
            onStart: actions.RESEND_INVITE_EMPLOYEE_START,
            onSuccess: actions.RESEND_INVITE_EMPLOYEE,
            onError: actions.RESEND_INVITE_EMPLOYEE_FAILED,
        },
    }
}

export function saveShareTransferInstruction(params) {
    return {
        type: actions.SAVE_SHARE_TRANSFER_INSTRUCTION,
        payload: params,
    }
}

export function sendShareTransferInstruction(employeeId, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiShareTransferInstruction}/employee/${employeeId}`,
            method: "post",
            data: params,
            onStart: actions.SEND_SHARE_TRANSFER_INSTRUCTION_START,
            onSuccess: actions.SEND_SHARE_TRANSFER_INSTRUCTION,
            onError: actions.SEND_SHARE_TRANSFER_INSTRUCTION_FAILED,
        },
    }
}

export function sendShareTransferInstructionReset() {
    return {
        type: actions.SEND_SHARE_TRANSFER_INSTRUCTION_RESET,
        payload: {},
    }
}

export function shareSaleInstruction(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiShareSaleInstruction + "/allocation-balance" + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.SHARE_SALE_INSTRUCTION_START,
            onSuccess: actions.SHARE_SALE_INSTRUCTION,
            onError: actions.SHARE_SALE_INSTRUCTION_FAILED,
        },
    }
}

export function shareSaleInstructionMultiple(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiShareSaleInstruction + "/allocation-balance" + ObjectToQueryString(params),
            method: "get",
            data: {},
            additionalResponse: {...params},
            onSuccess: actions.SHARE_SALE_INSTRUCTION_MULTIPLE,
            onError: actions.SHARE_SALE_INSTRUCTION_MULTIPLE_FAILED,
        },
    }
}

export function shareSaleInstructionMultipleReset() {
    return {
        type: actions.SHARE_SALE_INSTRUCTION_MULTIPLE_RESET,
        payload: {},
    }
}

export function saveShareSaleInstruction(params) {
    return {
        type: actions.SAVE_SHARE_SALE_INSTRUCTION,
        payload: params,
    }
}

export function sendShareSaleInstruction(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiShareSaleInstruction}`,
            method: "post",
            data: params,
            onStart: actions.SEND_SHARE_SALE_INSTRUCTION_START,
            onSuccess: actions.SEND_SHARE_SALE_INSTRUCTION,
            onError: actions.SEND_SHARE_SALE_INSTRUCTION_FAILED,
        },
    }
}

export function sendShareSaleInstructionReset() {
    return {
        type: actions.SEND_SHARE_SALE_INSTRUCTION_RESET,
        payload: {},
    }
}

export function sendEmailBroadcast(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiEmailBroadcast}`,
            method: "post",
            data: params,
            onStart: actions.SEND_EMAIL_BROADCAST_START,
            onSuccess: actions.SEND_EMAIL_BROADCAST,
            onError: actions.SEND_EMAIL_BROADCAST_FAILED,
        },
    }
}

export function sendEmailBroadcastReset() {
    return {
        type: actions.SEND_EMAIL_BROADCAST_RESET,
        payload: {},
    }
}

export function getDividendHistory(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiDividendHistory + id + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_DIVIDEND_HISTORY_START,
            onSuccess: actions.GET_DIVIDEND_HISTORY,
            onError: actions.GET_DIVIDEND_HISTORY_FAILED,
        },
    }
}

export function getShareSalesHistory(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiShareSaleHistory + id + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_SHARE_SALES_HISTORY_START,
            onSuccess: actions.GET_SHARE_SALES_HISTORY,
            onError: actions.GET_SHARE_SALES_HISTORY_FAILED,
        },
    }
}

export function getShareTransferHistory(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiShareTransferHistory + id + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_SHARE_TRANSFER_HISTORY_START,
            onSuccess: actions.GET_SHARE_TRANSFER_HISTORY,
            onError: actions.GET_SHARE_TRANSFER_HISTORY_FAILED,
        },
    }
}

export function createAdmin(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCompany + "/" + id + "/invite-admin",
            method: "post",
            data: params,
            onStart: actions.CREATE_ADMINMGMT_START,
            onSuccess: actions.CREATE_ADMINMGMT,
            onError: actions.CREATE_ADMINMGMT_FAILED,
        },
    }
}

export function createAdminReset() {
    return {
        type: actions.CREATE_ADMINMGMT_RESET,
        payload: {},
    }
}

export function editAdmin(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiUserMgmt}/${id}/update`,
            method: "post",
            data: params,
            onStart: actions.EDIT_ADMINMGMT_START,
            onSuccess: actions.EDIT_ADMINMGMT,
            onError: actions.EDIT_ADMINMGMT_FAILED,
        },
    }
}

export function editAdminReset() {
    return {
        type: actions.EDIT_ADMINMGMT_RESET,
        payload: {},
    }
}