import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, ButtonGroupCheckbox, ButtonGroupSelector, DocPicker, FileUploadPreview, Input, SelectInput } from "components/ui";
import { ShareSaleOrTransfer, AllocationTypes, DividendTypes, SchemeAccess } from "data/constants/company-settings";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dateFormatRemoveISO } from "utils/dataTypes";
import { Access } from "data/constants/company-settings";
import validate from "utils/validate";
import { removeNull, valueToInt, nullToEmpty } from "utils/misc";
import { toast } from "react-toastify";
import ButtonLoader from "components/ButtonLoader";
import { PageContent } from "components/PageContent";
import PageOverlayCover from "components/PageOverlayCover";
import SubSchemesTags from "./subscheme/subschemes-tags";
import { jsonToFormData } from "utils/jsonToFormData";
// import jsonToFormData from "json-form-data";
// import { jsonToFormDataOptions } from "utils/jsonToFormDataOptions";
import Maybe from "components/Maybe";
import * as companyAction from "store/entities/company/action";


const setSchemeAccess = (access:boolean, schemeAccess:any[]) => {
  const accessResponse = schemeAccess.map(scheme => {
    let newScheme:any = {};
    if (access === scheme.value){
      newScheme = {...scheme, selected: true}
    }
    else{
      newScheme = {...scheme, selected: false}
    }
    return newScheme;
  })
  return accessResponse;
}


const allSharesAllocations = [
  "Shares",
  "Units",
  "Phantom shares",
]


export interface CompanyCreateSettingsProps {}

export function CompanyCreateSettings(props: CompanyCreateSettingsProps) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyLoading, company, createCompanyLoading3, createCompany3 } = useSelector((s: any) => s.entities.company);
  


  const [allocationTypes, setAllocationTypes] = useState(AllocationTypes);
  const [shareSaleOrTransfer, setShareSaleOrTransfer] = useState(ShareSaleOrTransfer);
  const [dividendTypes, setDividendTypes] = useState(DividendTypes);
  const [showCustomSharesAllocation, setShowCustomSharesAllocation] = useState(false);
  const [showWaitingPageCover, setShowWaitingPageCover] = useState(false);
  const [successLink, setSuccessLink] = useState("/trustee/company/form/"+id+"/preview");
  const [showFileUploadSupportingDoc, setShowFileUploadSupportingDoc] = useState(true);

  const [companyForm, setCompanyForm] = useState<any>({
    SharesAllocation: "Shares",
    CanEmployeeInitiateShareSale: true,
    DividendTypes: [],
    SupportingDocument: "",
    SchemeTypes: [],
  })


  
  const [schemeTypeShareAwards, setSchemeTypeShareAwards] = useState<any>({
    status: false,
    id: null,
    name: "Access to Share Awards",
    startDate: "",
    endDate: "",
    hasSubSchemeAccess: true,
    schemePropertyType: 0,
    schemeDetail: {
      numberOfAllocation: 0,
      dateOfAllocation: "",
    },
    subSchemes: [],
  })
  
  const SchemeAccessShareAwards = setSchemeAccess(schemeTypeShareAwards.hasSubSchemeAccess, SchemeAccess);
  


  const [schemeTypeShareOptions, setSchemeTypeShareOptions] = useState<any>({
    status: false,
    id: null,
    name: "Access to Share Options",
    startDate: "",
    endDate: "",
    hasSubSchemeAccess: true,
    schemePropertyType: 1,
    schemeDetail: {
      numberOfAllocation: 0,
      dateOfAllocation: "",
      strikePrice: "",
      valuation: "",
      exercisePeriodStartDate: "",
      exercisePeriodEndDate: "",
      expirationDate: "",
    },
    subSchemes: [],
  })
  
  const SchemeAccessShareOptions = setSchemeAccess(schemeTypeShareOptions.hasSubSchemeAccess, SchemeAccess);
  


  const [schemeTypeSharePurchase, setSchemeTypeSharePurchase] = useState<any>({
    status: false,
    id: null,
    name: "Access to Share Purchase",
    startDate: "",
    endDate: "",
    hasSubSchemeAccess: true,
    schemePropertyType: 2,
    schemeDetail: {
      numberOfAllocation: 0,
      dateOfAllocation: "",
      purchasePrice: "",
      valuation: "",
    },
    subSchemes: [],
  })
  
  const SchemeAccessSharePurchase = setSchemeAccess(schemeTypeSharePurchase.hasSubSchemeAccess, SchemeAccess);
  


  const [schemeTypeShareLTIP, setSchemeTypeShareLTIP] = useState<any>({
    status: false,
    id: null,
    name: "Access to Long Term Incentice Plan (LTIP)",
    startDate: "",
    endDate: "",
    hasSubSchemeAccess: true,
    schemePropertyType: 3,
    schemeDetail: {
      numberOfAllocation: 0,
      dateOfAllocation: "",
    },
    subSchemes: [],
  })
  
  const SchemeAccessShareLTIP = setSchemeAccess(schemeTypeShareLTIP.hasSubSchemeAccess, SchemeAccess);
  


  const [schemeTypeShareOthers, setSchemeTypeShareOthers] = useState<any>({
    status: false,
    id: null,
    name: "Access to Others",
    startDate: "",
    endDate: "",
    hasSubSchemeAccess: true,
    schemePropertyType: 4,
    schemeDetail: {
      numberOfAllocation: 0,
      dateOfAllocation: "",
      otherSchemeName: "",
    },
    subSchemes: [],
  })
  
  const SchemeAccessShareOthers = setSchemeAccess(schemeTypeShareOthers.hasSubSchemeAccess, SchemeAccess);
  


  const updateAllocationTypes = () => {
    if (allSharesAllocations.includes(companyForm.SharesAllocation)){
      const newAllocationTypes = allocationTypes.map(type => {
        let eachAllocationType:any = {};
        if (companyForm.SharesAllocation === type.value){
          eachAllocationType = {...type, selected: true}
        }
        else{
          eachAllocationType = {...type, selected: false}
        }
        return eachAllocationType;
      })
      setAllocationTypes(newAllocationTypes)
    }
  }


  const updateShareSaleOrTransfer = () => {
    const newShareSaleOrTransfer = shareSaleOrTransfer.map(type => {
      let eachShareSaleOrTransfer:any = {};
      if (companyForm.CanEmployeeInitiateShareSale === type.value){
        eachShareSaleOrTransfer = {...type, selected: true}
      }
      else{
        eachShareSaleOrTransfer = {...type, selected: false}
      }
      return eachShareSaleOrTransfer;
    })
    setShareSaleOrTransfer(newShareSaleOrTransfer)
  }



  const updateDividendTypes = (data:any) => {
    const newDividendTypes = dividendTypes.map((type:any) => {
      let eachDividendTypes:any = {};
      if (data.DividendTypes.includes(type.value)){
        eachDividendTypes = {...type, selected: true}
      }
      else{
        eachDividendTypes = {...type, selected: false}
      }
      return eachDividendTypes;
    })
    setDividendTypes(newDividendTypes);
  }

  
  const allocationToggle = (e:any) => {
    let newShowCustomSharesAllocation = false;
    if (e === "Others"){
      e = "";
      newShowCustomSharesAllocation = true;
    }
    setShowCustomSharesAllocation(newShowCustomSharesAllocation);
    setCompanyForm({...companyForm, SharesAllocation: e})
  }

  
  const checkSharesAllocationCustomName = () => {
    if (allSharesAllocations.includes(companyForm.SharesAllocation)){
      setShowCustomSharesAllocation(false);
    }
    else{
      const newAllocationTypes = allocationTypes.map(type => {
        let eachAllocationType:any = {};
        if (type.value === "Others"){
          eachAllocationType = {...type, selected: true}
        }
        else{
          eachAllocationType = {...type, selected: false}
        }
        return eachAllocationType;
      })
      setAllocationTypes(newAllocationTypes);
      setShowCustomSharesAllocation(true);
    }
  }


  const removeEmptySubScheme = (data:any) => {
    if (data.subSchemes !== undefined){
      if (data.subSchemes.length === 0){
        const newData:any = {...data};
        delete newData["subSchemes"];
        return newData;
      }
    }
    return data;
  }


  const cleanupSubschemeSelection = (data:any) => {
    const newData:any = {...data};
    if (data.hasSubSchemeAccess === true){
      // delete newData["schemeDetail"];
      delete newData["schemeDetail"]["percentageVesting"];
      delete newData["schemeDetail"]["vestingPeriod"];
      delete newData["schemeDetail"]["period"];
      delete newData["schemeDetail"]["vestingDate"];
      return newData;
    }
    else if (data.hasSubSchemeAccess === false){
      delete newData["subSchemes"];
      return newData;
    }

    return data;
  }


  const removeEmptyHoldingPeriod = (data:any) => {
    const newData:any = {...data};
    if (data.startDate === ""){
      delete newData["startDate"];
    }
    if (data.endDate === ""){
      delete newData["endDate"];
    }
    return newData;
  }


  const cleanupData = (data:any) => {
    let newData = data;
    newData = cleanupSubschemeSelection(data);
    newData = removeEmptySubScheme(data);
    newData = removeEmptyHoldingPeriod(data);

    return newData;
  }


  const gotoSubScheme = (link:string) => {
    if (createCompanyLoading3 === false){
      setSuccessLink(link);
      setShowWaitingPageCover(true);
      submitForm("subscheme");
    }
  }


  const setAllocationAccessSelection = (allocation:any) => {
    const access = Access.map(type => {
      let newAccess:any = {};
      if (allocation.vestingPeriod === type.value){
        newAccess = {...type, selected: true}
      }
      else{
        newAccess = {...type, selected: false}
      }
      return newAccess;
    })
    return access;
  }

  
  const submitForm = async (e: React.FormEvent | string) => {
    if (e !== "subscheme" && typeof e !== "string"){
      e.preventDefault();
      setSuccessLink("/trustee/company/form/"+id+"/preview");
    }

    let payloadError = false;
    let params:any = {...companyForm};

    if (schemeTypeShareAwards.status === true){
      params = {
        ...params,
        SchemeTypes: [
          ...params.SchemeTypes,
          removeNull(cleanupData(schemeTypeShareAwards)),
        ]
      }
    }

    if (schemeTypeShareOptions.status === true){
      params = {
        ...params,
        SchemeTypes: [
          ...params.SchemeTypes,
          removeNull(cleanupData(schemeTypeShareOptions)),
        ]
      }
    }

    if (schemeTypeSharePurchase.status === true){
      params = {
        ...params,
        SchemeTypes: [
          ...params.SchemeTypes,
          removeNull(cleanupData(schemeTypeSharePurchase)),
        ]
      }
    }

    if (schemeTypeShareLTIP.status === true){
      params = {
        ...params,
        SchemeTypes: [
          ...params.SchemeTypes,
          removeNull(cleanupData(schemeTypeShareLTIP)),
        ]
      }
    }

    if (schemeTypeShareOthers.status === true){
      params = {
        ...params,
        SchemeTypes: [
          ...params.SchemeTypes,
          removeNull(cleanupData(schemeTypeShareOthers)),
        ]
      }
    }

    if (e !== "subscheme"){
      if (params.SchemeTypes.length === 0){
        payloadError = true;
        toast.error("Please select one or more scheme types");
      }

      if (params.DividendTypes.length === 0){
        payloadError = true;
        toast.error("Please select one or more dividend types");
      }
    }

    // const errors:any = validate(companyForm);
    // if (errors){
    //   for (var x in errors) {
    //     toast.error(errors[x]);
    //   };
    //   return;
    // }
    // else{
    //   alert("all good")
    //   // dispatch(companyAction.createStep3(id, companyForm));
    // }

    
    // console.log("step 3 params", params);
    
    if (payloadError === false){
      const formDataParams = jsonToFormData(params);
      dispatch(companyAction.createStep3(id, formDataParams));
    }
  }

  
  useEffect(() => {
    if (createCompany3.data){
      dispatch(companyAction.createStep3Reset());
      navigate(successLink);
    }

    if (createCompanyLoading3 === false){
      setShowWaitingPageCover(false);
    }

  }, [createCompany3]);


  const populateForm = () => {
    if (company.data?.admin?.userInfo){
      const {organizationShareSetting} = company.data;
      if (organizationShareSetting !== null){

        const companyForm = {
          SharesAllocation: organizationShareSetting.sharesAllocation,
          CanEmployeeInitiateShareSale: organizationShareSetting.canEmployeeInitiateShareSale,
          DividendTypes: organizationShareSetting.dividendTypes,
          SupportingDocument: organizationShareSetting.supportingDocumentObject,
          SchemeTypes: [],
        }
        setCompanyForm(companyForm);
        checkSharesAllocationCustomName();
        updateAllocationTypes();
        updateShareSaleOrTransfer();
        updateDividendTypes(companyForm);

        if (organizationShareSetting.supportingDocumentObject !== undefined && organizationShareSetting.supportingDocumentObject.length !== 0){
          setShowFileUploadSupportingDoc(false);
        }

        const schemeTypeShareAwardsDb = organizationShareSetting.schemeTypes.filter((schemeType:any) => schemeType.schemePropertyType === 0);        
        if (schemeTypeShareAwardsDb.length === 1){
          const schemeTypeShareAwards = {
            status: true,
            id: schemeTypeShareAwardsDb[0].id,
            name: schemeTypeShareAwardsDb[0].name,
            startDate: dateFormatRemoveISO(schemeTypeShareAwardsDb[0].startDate),
            endDate: dateFormatRemoveISO(schemeTypeShareAwardsDb[0].endDate),
            hasSubSchemeAccess: schemeTypeShareAwardsDb[0].hasSubSchemeAccess,
            schemePropertyType: schemeTypeShareAwardsDb[0].schemePropertyType,
            schemeDetail: {
              numberOfAllocation: schemeTypeShareAwardsDb[0].schemeDetail?.numberOfAllocation,
              dateOfAllocation: dateFormatRemoveISO(schemeTypeShareAwardsDb[0].schemeDetail?.dateOfAllocation),
              percentageVesting: nullToEmpty(schemeTypeShareAwardsDb[0].schemeDetail?.percentageVesting),
              period: schemeTypeShareAwardsDb[0].schemeDetail?.period,
              vestingDate: dateFormatRemoveISO(schemeTypeShareAwardsDb[0].schemeDetail?.vestingDate),
              vestingPeriod: schemeTypeShareAwardsDb[0].schemeDetail?.vestingPeriod,
            },
            subSchemes: schemeTypeShareAwardsDb[0].subSchemes,
          }
          setSchemeTypeShareAwards(schemeTypeShareAwards);
        }
        
        const schemeTypeShareOptionsDb = organizationShareSetting.schemeTypes.filter((schemeType:any) => schemeType.schemePropertyType === 1);        
        if (schemeTypeShareOptionsDb.length === 1){
          const schemeTypeShareOptions = {
            status: true,
            id: schemeTypeShareOptionsDb[0].id,
            name: schemeTypeShareOptionsDb[0].name,
            startDate: dateFormatRemoveISO(schemeTypeShareOptionsDb[0].startDate),
            endDate: dateFormatRemoveISO(schemeTypeShareOptionsDb[0].endDate),
            hasSubSchemeAccess: schemeTypeShareOptionsDb[0].hasSubSchemeAccess,
            schemePropertyType: schemeTypeShareOptionsDb[0].schemePropertyType,
            schemeDetail: {
              numberOfAllocation: schemeTypeShareOptionsDb[0].schemeDetail?.numberOfAllocation,
              dateOfAllocation: dateFormatRemoveISO(schemeTypeShareOptionsDb[0].schemeDetail?.dateOfAllocation),
              strikePrice: schemeTypeShareOptionsDb[0].schemeDetail?.strikePrice,
              valuation: schemeTypeShareOptionsDb[0].schemeDetail?.valuation,
              exercisePeriodStartDate: dateFormatRemoveISO(schemeTypeShareOptionsDb[0].schemeDetail?.exercisePeriodStartDate),
              exercisePeriodEndDate: dateFormatRemoveISO(schemeTypeShareOptionsDb[0].schemeDetail?.exercisePeriodEndDate),
              expirationDate: dateFormatRemoveISO(schemeTypeShareOptionsDb[0].schemeDetail?.expirationDate),
              percentageVesting: nullToEmpty(schemeTypeShareOptionsDb[0].schemeDetail?.percentageVesting),
              period: schemeTypeShareOptionsDb[0].schemeDetail?.period,
              vestingDate: dateFormatRemoveISO(schemeTypeShareOptionsDb[0].schemeDetail?.vestingDate),
              vestingPeriod: schemeTypeShareOptionsDb[0].schemeDetail?.vestingPeriod,
            },
            subSchemes: schemeTypeShareOptionsDb[0].subSchemes,
          }
          setSchemeTypeShareOptions(schemeTypeShareOptions);
        }
        
        const schemeTypeSharePurchaseDb = organizationShareSetting.schemeTypes.filter((schemeType:any) => schemeType.schemePropertyType === 2);        
        if (schemeTypeSharePurchaseDb.length === 1){
          const schemeTypeSharePurchase = {
            status: true,
            id: schemeTypeSharePurchaseDb[0].id,
            name: schemeTypeSharePurchaseDb[0].name,
            startDate: dateFormatRemoveISO(schemeTypeSharePurchaseDb[0].startDate),
            endDate: dateFormatRemoveISO(schemeTypeSharePurchaseDb[0].endDate),
            hasSubSchemeAccess: schemeTypeSharePurchaseDb[0].hasSubSchemeAccess,
            schemePropertyType: schemeTypeSharePurchaseDb[0].schemePropertyType,
            schemeDetail: {
              numberOfAllocation: schemeTypeSharePurchaseDb[0].schemeDetail?.numberOfAllocation,
              dateOfAllocation: dateFormatRemoveISO(schemeTypeSharePurchaseDb[0].schemeDetail?.dateOfAllocation),
              purchasePrice: schemeTypeSharePurchaseDb[0].schemeDetail?.purchasePrice,
              valuation: schemeTypeSharePurchaseDb[0].schemeDetail?.valuation,
              percentageVesting: nullToEmpty(schemeTypeSharePurchaseDb[0].schemeDetail?.percentageVesting),
              period: schemeTypeSharePurchaseDb[0].schemeDetail?.period,
              vestingDate: dateFormatRemoveISO(schemeTypeSharePurchaseDb[0].schemeDetail?.vestingDate),
              vestingPeriod: schemeTypeSharePurchaseDb[0].schemeDetail?.vestingPeriod,
            },
            subSchemes: schemeTypeSharePurchaseDb[0].subSchemes,
          }
          setSchemeTypeSharePurchase(schemeTypeSharePurchase);
        }
        
        const schemeTypeShareLTIPDb = organizationShareSetting.schemeTypes.filter((schemeType:any) => schemeType.schemePropertyType === 3);        
        if (schemeTypeShareLTIPDb.length === 1){
          const schemeTypeShareLTIP = {
            status: true,
            id: schemeTypeShareLTIPDb[0].id,
            name: schemeTypeShareLTIPDb[0].name,
            startDate: dateFormatRemoveISO(schemeTypeShareLTIPDb[0].startDate),
            endDate: dateFormatRemoveISO(schemeTypeShareLTIPDb[0].endDate),
            hasSubSchemeAccess: schemeTypeShareLTIPDb[0].hasSubSchemeAccess,
            schemePropertyType: schemeTypeShareLTIPDb[0].schemePropertyType,
            schemeDetail: {
              numberOfAllocation: schemeTypeShareLTIPDb[0].schemeDetail?.numberOfAllocation,
              dateOfAllocation: dateFormatRemoveISO(schemeTypeShareLTIPDb[0].schemeDetail?.dateOfAllocation),
              percentageVesting: nullToEmpty(schemeTypeShareLTIPDb[0].schemeDetail?.percentageVesting),
              period: schemeTypeShareLTIPDb[0].schemeDetail?.period,
              vestingDate: dateFormatRemoveISO(schemeTypeShareLTIPDb[0].schemeDetail?.vestingDate),
              vestingPeriod: schemeTypeShareLTIPDb[0].schemeDetail?.vestingPeriod,
            },
            subSchemes: schemeTypeShareLTIPDb[0].subSchemes,
          }
          setSchemeTypeShareLTIP(schemeTypeShareLTIP);
        }
        
        const schemeTypeShareOthersDb = organizationShareSetting.schemeTypes.filter((schemeType:any) => schemeType.schemePropertyType === 4);        
        if (schemeTypeShareOthersDb.length === 1){
          const schemeTypeShareOthers = {
            status: true,
            id: schemeTypeShareOthersDb[0].id,
            name: schemeTypeShareOthersDb[0].name,
            startDate: dateFormatRemoveISO(schemeTypeShareOthersDb[0].startDate),
            endDate: dateFormatRemoveISO(schemeTypeShareOthersDb[0].endDate),
            hasSubSchemeAccess: schemeTypeShareOthersDb[0].hasSubSchemeAccess,
            schemePropertyType: schemeTypeShareOthersDb[0].schemePropertyType,
            schemeDetail: {
              otherSchemeName: schemeTypeShareOthersDb[0].schemeDetail?.otherSchemeName,
              numberOfAllocation: schemeTypeShareOthersDb[0].schemeDetail?.numberOfAllocation,
              dateOfAllocation: dateFormatRemoveISO(schemeTypeShareOthersDb[0].schemeDetail?.dateOfAllocation),
              percentageVesting: nullToEmpty(schemeTypeShareOthersDb[0].schemeDetail?.percentageVesting),
              period: schemeTypeShareOthersDb[0].schemeDetail?.period,
              vestingDate: dateFormatRemoveISO(schemeTypeShareOthersDb[0].schemeDetail?.vestingDate),
              vestingPeriod: schemeTypeShareOthersDb[0].schemeDetail?.vestingPeriod,
            },
            subSchemes: schemeTypeShareOthersDb[0].subSchemes,
          }
          setSchemeTypeShareOthers(schemeTypeShareOthers);
        }
        
      }
    }
  }
  

  useEffect(() => {
    dispatch(companyAction.getCompany(id));
  }, []);

  
  useEffect(() => {
    populateForm();
  }, [company]);

  
  return (
    <div>
      

      <PageOverlayCover condition={showWaitingPageCover} text="Please wait..." />


      <FormWizardNavBar showSteps={true} currentStep={3} totalSteps={5} closeUrl="/trustee/company" />


      <div className="container pb-10 mt-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Company settings
          </div>
          <p className="mt-2 text-fade text-sm">
            Setup company settings
          </p>
        </div>

        <div className="mt-12">
          

          <PageContent loading={companyLoading} data={company.data || undefined} />

          <Maybe condition={companyLoading === false && company.data && Object.keys(company.data).length > 0}>
            <div>
              <form onSubmit={submitForm}>

                
                <div>
                  <div className="flex flex-wrap mb-8">
                    <div className="w-full">
                      <label className="block">Type of allocation</label>
                      <span className="block text-sm text-fade mb-3">
                        Kindly select the applicable type of allocation
                      </span>
                      <ButtonGroupSelector selectOptions={allocationTypes} onValueChange={(e:any) => allocationToggle(e)} />

                      {showCustomSharesAllocation === true &&
                        <div className="w-full mt-3">
                          <Input label="Enter custom name" type="text" value={companyForm.SharesAllocation} onChange={(e:any) => setCompanyForm({...companyForm, SharesAllocation: e.target.value})} />
                        </div>
                      }

                    </div>
                  </div>
                  <div className="flex flex-wrap mb-8">
                    <div className="w-full">
                      <label className="block">Scheme type</label>
                      <span className="block text-sm text-fade mb-6">
                        Kindly select the applicable scheme type(s)
                      </span>
                      <div className="space-y-6">

                        <Accordion label="Access to Share Awards" isOpen={schemeTypeShareAwards.status} onChange={(e:any) => setSchemeTypeShareAwards({...schemeTypeShareAwards, status: e})}>
                          <div className="bg-gray-100 p-5">
                            <div className="block text-sm">
                              Holding period
                            </div>

                            <div className="mt-4 grid grid-cols-2 gap-6">
                              <div className="w-full">
                                <Input type="date" label="Start date" value={schemeTypeShareAwards.startDate} onChange={(e:any) => setSchemeTypeShareAwards({...schemeTypeShareAwards, startDate: e.target.value})} />
                              </div>
                              <div className="w-full">
                                <Input type="date" label="End date" value={schemeTypeShareAwards.endDate} onChange={(e:any) => setSchemeTypeShareAwards({...schemeTypeShareAwards, endDate: e.target.value})} />
                              </div>
                            </div>

                            <div className="mt-6">
                              <ButtonGroupSelector selectOptions={SchemeAccessShareAwards} onValueChange={(e:any) => setSchemeTypeShareAwards({...schemeTypeShareAwards, hasSubSchemeAccess: e})} />
                            </div>

                            <Maybe condition={schemeTypeShareAwards.hasSubSchemeAccess}>
                              <SubSchemesTags
                                id={id}
                                data={schemeTypeShareAwards}
                                gotoSubScheme={gotoSubScheme}
                              />

                              <span onClick={() => gotoSubScheme("/trustee/company/form/" + id + "/company-subscheme/" + (schemeTypeShareAwards.schemePropertyType+1) + "/share-awards")} className="text-vetiva-brown flex items-center hover:underline w-max cursor-pointer">
                                Click here to configure Share Awards SubScheme <FontAwesomeIcon className="ml-1" icon="chevron-right" />
                              </span>
                            </Maybe>

                            <Maybe condition={!schemeTypeShareAwards.hasSubSchemeAccess}>
                              <div className="mt-6 grid grid-cols-1 gap-6">
                                <div className="w-full">
                                  <Input type="number" label="Number of allocation" value={schemeTypeShareAwards.schemeDetail.numberOfAllocation} onChange={(e:any) => setSchemeTypeShareAwards({...schemeTypeShareAwards, schemeDetail: {...schemeTypeShareAwards.schemeDetail, numberOfAllocation:e.target.value}})} />
                                </div>
                                <div className="w-full">
                                  <Input type="date" label="Date of allocation" value={schemeTypeShareAwards.schemeDetail.dateOfAllocation} onChange={(e:any) => setSchemeTypeShareAwards({...schemeTypeShareAwards, schemeDetail: {...schemeTypeShareAwards.schemeDetail, dateOfAllocation:e.target.value}})} />
                                </div>
                                <div className="w-full">
                                  <Input type="number" label="Percentage for vesting" value={schemeTypeShareAwards.schemeDetail.percentageVesting} onChange={(e:any) => setSchemeTypeShareAwards({...schemeTypeShareAwards, schemeDetail: {...schemeTypeShareAwards.schemeDetail, percentageVesting: e.target.value}})} />
                                </div>
                                <div className="w-full">
                                  <div className="font-bold">
                                    Vesting period
                                  </div>
                                  <div className="text-sm text-fade mb-3">
                                    Select type
                                  </div>
                                  <div>
                                    <ButtonGroupSelector selectOptions={setAllocationAccessSelection(schemeTypeShareAwards.schemeDetail)} onValueChange={(e:any) => setSchemeTypeShareAwards({...schemeTypeShareAwards, schemeDetail: {...schemeTypeShareAwards.schemeDetail, vestingPeriod: e}})} />
                                  </div>
                                </div>
                                <Maybe condition={schemeTypeShareAwards.schemeDetail.vestingPeriod === "Fixed period"}>
                                  <div className="w-full">
                                    <SelectInput label="Vesting period" value={schemeTypeShareAwards.schemeDetail.period} onChange={(e:any) => setSchemeTypeShareAwards({...schemeTypeShareAwards, schemeDetail: {...schemeTypeShareAwards.schemeDetail, period: valueToInt(e.target.value)}})}>
                                      <option value={0} disabled>Select period</option>
                                      <option value={1}>Every 1 year</option>
                                      <option value={2}>Every 2 years</option>
                                      <option value={3}>Every 3 years</option>
                                      <option value={4}>Every 4 years</option>
                                      <option value={5}>Every 5 years</option>
                                      <option value={6}>Every 6 years</option>
                                      <option value={7}>Every 7 years</option>
                                      <option value={8}>Every 8 years</option>
                                      <option value={9}>Every 9 years</option>
                                      <option value={10}>Every 10 years</option>
                                    </SelectInput>
                                  </div>
                                </Maybe>
                                <Maybe condition={schemeTypeShareAwards.schemeDetail.vestingPeriod === "Recurring date"}>
                                  <div className="w-full">
                                    <Input type="date" label="Vesting date" value={schemeTypeShareAwards.schemeDetail.vestingDate} onChange={(e:any) => setSchemeTypeShareAwards({...schemeTypeShareAwards, schemeDetail: {...schemeTypeShareAwards.schemeDetail, vestingDate: e.target.value}})} />
                                  </div>
                                </Maybe>
                              </div>
                            </Maybe>

                          </div>
                        </Accordion>


                        <Accordion label="Access to Share Options Scheme" isOpen={schemeTypeShareOptions.status} onChange={(e:any) => setSchemeTypeShareOptions({...schemeTypeShareOptions, status: e})}>
                          <div className="bg-gray-100 p-5">

                            <div className="w-full">
                              <Input label="Strike price" type={"text"} value={schemeTypeShareOptions.schemeDetail.strikePrice} onChange={(e:any) => setSchemeTypeShareOptions({...schemeTypeShareOptions, schemeDetail: {...schemeTypeShareOptions.schemeDetail, strikePrice:e.target.value}})} />
                            </div>

                            <div className="w-full mt-6">
                              <Input label="Valuation" type={"text"} value={schemeTypeShareOptions.schemeDetail.valuation} onChange={(e:any) => setSchemeTypeShareOptions({...schemeTypeShareOptions, schemeDetail: {...schemeTypeShareOptions.schemeDetail, valuation:e.target.value}})} />
                            </div>

                            <div className="block text-sm mt-6">
                              Exercise period
                            </div>

                            <div className="mt-4 grid grid-cols-2 gap-6">
                              <div className="w-full">
                                <Input type="date" label="Start date" value={schemeTypeShareOptions.schemeDetail.exercisePeriodStartDate} onChange={(e:any) => setSchemeTypeShareOptions({...schemeTypeShareOptions, schemeDetail: {...schemeTypeShareOptions.schemeDetail, exercisePeriodStartDate:e.target.value}})} />
                              </div>
                              <div className="w-full">
                                <Input type="date" label="End date" value={schemeTypeShareOptions.schemeDetail.exercisePeriodEndDate} onChange={(e:any) => setSchemeTypeShareOptions({...schemeTypeShareOptions, schemeDetail: {...schemeTypeShareOptions.schemeDetail, exercisePeriodEndDate:e.target.value}})} />
                              </div>
                            </div>

                            <div className="w-full mt-6">
                              <Input type="date" label="Expiration date" value={schemeTypeShareOptions.schemeDetail.expirationDate} onChange={(e:any) => setSchemeTypeShareOptions({...schemeTypeShareOptions, schemeDetail: {...schemeTypeShareOptions.schemeDetail, expirationDate:e.target.value}})} />
                            </div>

                            <div className="block text-sm mt-6">
                              Holding period
                            </div>

                            <div className="mt-6 grid grid-cols-2 gap-6">
                              <div className="w-full">
                                <Input type="date" label="Start date" value={schemeTypeShareOptions.startDate} onChange={(e:any) => setSchemeTypeShareOptions({...schemeTypeShareOptions, startDate: e.target.value})} />
                              </div>
                              <div className="w-full">
                                <Input type="date" label="End date" value={schemeTypeShareOptions.endDate} onChange={(e:any) => setSchemeTypeShareOptions({...schemeTypeShareOptions, endDate: e.target.value})} />
                              </div>
                            </div>

                            <div className="mt-6">
                              <ButtonGroupSelector selectOptions={SchemeAccessShareOptions} onValueChange={(e:any) => setSchemeTypeShareOptions({...schemeTypeShareOptions, hasSubSchemeAccess: e})} />
                            </div>

                            <Maybe condition={schemeTypeShareOptions.hasSubSchemeAccess}>
                              <SubSchemesTags
                                id={id}
                                data={schemeTypeShareOptions}
                                gotoSubScheme={gotoSubScheme}
                              />
                              
                              <span onClick={() => gotoSubScheme("/trustee/company/form/" + id + "/company-subscheme/" + (schemeTypeShareOptions.schemePropertyType+1) + "/share-options")} className="text-vetiva-brown flex items-center hover:underline w-max cursor-pointer">
                                Click here to configure Share Options SubScheme <FontAwesomeIcon className="ml-1" icon="chevron-right" />
                              </span>
                            </Maybe>

                            <Maybe condition={!schemeTypeShareOptions.hasSubSchemeAccess}>
                              <div className="mt-6 grid grid-cols-1 gap-6">
                                <div className="w-full">
                                  <Input type="number" label="Number of allocation" value={schemeTypeShareOptions.schemeDetail.numberOfAllocation} onChange={(e:any) => setSchemeTypeShareOptions({...schemeTypeShareOptions, schemeDetail: {...schemeTypeShareOptions.schemeDetail, numberOfAllocation:e.target.value}})} />
                                </div>
                                <div className="w-full">
                                  <Input type="date" label="Date of allocation" value={schemeTypeShareOptions.schemeDetail.dateOfAllocation} onChange={(e:any) => setSchemeTypeShareOptions({...schemeTypeShareOptions, schemeDetail: {...schemeTypeShareOptions.schemeDetail, dateOfAllocation:e.target.value}})} />
                                </div>
                                <div className="w-full">
                                  <Input type="number" label="Percentage for vesting" value={schemeTypeShareOptions.schemeDetail.percentageVesting} onChange={(e:any) => setSchemeTypeShareOptions({...schemeTypeShareOptions, schemeDetail: {...schemeTypeShareOptions.schemeDetail, percentageVesting: e.target.value}})} />
                                </div>
                                <div className="w-full">
                                  <div className="font-bold">
                                    Vesting period
                                  </div>
                                  <div className="text-sm text-fade mb-3">
                                    Select type
                                  </div>
                                  <div>
                                    <ButtonGroupSelector selectOptions={setAllocationAccessSelection(schemeTypeShareOptions.schemeDetail)} onValueChange={(e:any) => setSchemeTypeShareOptions({...schemeTypeShareOptions, schemeDetail: {...schemeTypeShareOptions.schemeDetail, vestingPeriod: e}})} />
                                  </div>
                                </div>
                                <Maybe condition={schemeTypeShareOptions.schemeDetail.vestingPeriod === "Fixed period"}>
                                  <div className="w-full">
                                    <SelectInput label="Vesting period" value={schemeTypeShareOptions.schemeDetail.period} onChange={(e:any) => setSchemeTypeShareOptions({...schemeTypeShareOptions, schemeDetail: {...schemeTypeShareOptions.schemeDetail, period: valueToInt(e.target.value)}})}>
                                      <option value={0} disabled>Select period</option>
                                      <option value={1}>Every 1 year</option>
                                      <option value={2}>Every 2 years</option>
                                      <option value={3}>Every 3 years</option>
                                      <option value={4}>Every 4 years</option>
                                      <option value={5}>Every 5 years</option>
                                      <option value={6}>Every 6 years</option>
                                      <option value={7}>Every 7 years</option>
                                      <option value={8}>Every 8 years</option>
                                      <option value={9}>Every 9 years</option>
                                      <option value={10}>Every 10 years</option>
                                    </SelectInput>
                                  </div>
                                </Maybe>
                                <Maybe condition={schemeTypeShareOptions.schemeDetail.vestingPeriod === "Recurring date"}>
                                  <div className="w-full">
                                    <Input type="date" label="Vesting date" value={schemeTypeShareOptions.schemeDetail.vestingDate} onChange={(e:any) => setSchemeTypeShareOptions({...schemeTypeShareOptions, schemeDetail: {...schemeTypeShareOptions.schemeDetail, vestingDate: e.target.value}})} />
                                  </div>
                                </Maybe>
                              </div>
                            </Maybe>

                          </div>
                        </Accordion>
                            

                        <Accordion label="Access to Share Purchase Scheme" isOpen={schemeTypeSharePurchase.status} onChange={(e:any) => setSchemeTypeSharePurchase({...schemeTypeSharePurchase, status: e})}>
                          <div className="bg-gray-100 p-5">
                            <div className="block text-sm">
                              Holding period
                            </div>

                            <div className="mt-6 grid grid-cols-2 gap-6">
                              <div className="w-full">
                                <Input type="date" label="Start date" value={schemeTypeSharePurchase.startDate} onChange={(e:any) => setSchemeTypeSharePurchase({...schemeTypeSharePurchase, startDate: e.target.value})} />
                              </div>
                              <div className="w-full">
                                <Input type="date" label="End date" value={schemeTypeSharePurchase.endDate} onChange={(e:any) => setSchemeTypeSharePurchase({...schemeTypeSharePurchase, endDate: e.target.value})} />
                              </div>
                            </div>

                            <div className="w-full mt-6">
                              <Input label="Purchase price" type={"text"} value={schemeTypeSharePurchase.schemeDetail.purchasePrice} onChange={(e:any) => setSchemeTypeSharePurchase({...schemeTypeSharePurchase, schemeDetail: {...schemeTypeSharePurchase.schemeDetail, purchasePrice:e.target.value}})} />
                            </div>

                            <div className="w-full mt-6">
                              <Input label="Valuation" type={"text"} value={schemeTypeSharePurchase.schemeDetail.valuation} onChange={(e:any) => setSchemeTypeSharePurchase({...schemeTypeSharePurchase, schemeDetail: {...schemeTypeSharePurchase.schemeDetail, valuation:e.target.value}})} />
                            </div>

                            <div className="mt-6">
                              <ButtonGroupSelector selectOptions={SchemeAccessSharePurchase} onValueChange={(e:any) => setSchemeTypeSharePurchase({...schemeTypeSharePurchase, hasSubSchemeAccess: e})} />
                            </div>

                            <Maybe condition={schemeTypeSharePurchase.hasSubSchemeAccess}>
                              <SubSchemesTags
                                id={id}
                                data={schemeTypeSharePurchase}
                                gotoSubScheme={gotoSubScheme}
                              />
                              
                              <span onClick={() => gotoSubScheme("/trustee/company/form/" + id + "/company-subscheme/" + (schemeTypeSharePurchase.schemePropertyType+1) + "/share-purchase")} className="text-vetiva-brown flex items-center hover:underline w-max cursor-pointer">
                                Click here to configure Share Purchase SubScheme <FontAwesomeIcon className="ml-1" icon="chevron-right" />
                              </span>
                            </Maybe>

                            <Maybe condition={!schemeTypeSharePurchase.hasSubSchemeAccess}>
                              <div className="mt-6 grid grid-cols-1 gap-6">
                                <div className="w-full">
                                  <Input type="number" label="Number of allocation" value={schemeTypeSharePurchase.schemeDetail.numberOfAllocation} onChange={(e:any) => setSchemeTypeSharePurchase({...schemeTypeSharePurchase, schemeDetail: {...schemeTypeSharePurchase.schemeDetail, numberOfAllocation:e.target.value}})} />
                                </div>
                                <div className="w-full">
                                  <Input type="date" label="Date of allocation" value={schemeTypeSharePurchase.schemeDetail.dateOfAllocation} onChange={(e:any) => setSchemeTypeSharePurchase({...schemeTypeSharePurchase, schemeDetail: {...schemeTypeSharePurchase.schemeDetail, dateOfAllocation:e.target.value}})} />
                                </div>
                                <div className="w-full">
                                  <Input type="number" label="Percentage for vesting" value={schemeTypeSharePurchase.schemeDetail.percentageVesting} onChange={(e:any) => setSchemeTypeSharePurchase({...schemeTypeSharePurchase, schemeDetail: {...schemeTypeSharePurchase.schemeDetail, percentageVesting: e.target.value}})} />
                                </div>
                                <div className="w-full">
                                  <div className="font-bold">
                                    Vesting period
                                  </div>
                                  <div className="text-sm text-fade mb-3">
                                    Select type
                                  </div>
                                  <div>
                                    <ButtonGroupSelector selectOptions={setAllocationAccessSelection(schemeTypeSharePurchase.schemeDetail)} onValueChange={(e:any) => setSchemeTypeSharePurchase({...schemeTypeSharePurchase, schemeDetail: {...schemeTypeSharePurchase.schemeDetail, vestingPeriod: e}})} />
                                  </div>
                                </div>
                                <Maybe condition={schemeTypeSharePurchase.schemeDetail.vestingPeriod === "Fixed period"}>
                                  <div className="w-full">
                                    <SelectInput label="Vesting period" value={schemeTypeSharePurchase.schemeDetail.period} onChange={(e:any) => setSchemeTypeSharePurchase({...schemeTypeSharePurchase, schemeDetail: {...schemeTypeSharePurchase.schemeDetail, period: valueToInt(e.target.value)}})}>
                                      <option value={0} disabled>Select period</option>
                                      <option value={1}>Every 1 year</option>
                                      <option value={2}>Every 2 years</option>
                                      <option value={3}>Every 3 years</option>
                                      <option value={4}>Every 4 years</option>
                                      <option value={5}>Every 5 years</option>
                                      <option value={6}>Every 6 years</option>
                                      <option value={7}>Every 7 years</option>
                                      <option value={8}>Every 8 years</option>
                                      <option value={9}>Every 9 years</option>
                                      <option value={10}>Every 10 years</option>
                                    </SelectInput>
                                  </div>
                                </Maybe>
                                <Maybe condition={schemeTypeSharePurchase.schemeDetail.vestingPeriod === "Recurring date"}>
                                  <div className="w-full">
                                    <Input type="date" label="Vesting date" value={schemeTypeSharePurchase.schemeDetail.vestingDate} onChange={(e:any) => setSchemeTypeSharePurchase({...schemeTypeSharePurchase, schemeDetail: {...schemeTypeSharePurchase.schemeDetail, vestingDate: e.target.value}})} />
                                  </div>
                                </Maybe>
                              </div>
                            </Maybe>

                          </div>
                        </Accordion>


                        <Accordion label="Access to Long Term Incentice Plan (LTIP)" isOpen={schemeTypeShareLTIP.status} onChange={(e:any) => setSchemeTypeShareLTIP({...schemeTypeShareLTIP, status: e})}>
                          <div className="bg-gray-100 p-5">
                            <div className="block text-sm">
                              Holding period
                            </div>

                            <div className="mt-4 grid grid-cols-2 gap-6">
                              <div className="w-full">
                                <Input type="date" label="Start date" value={schemeTypeShareLTIP.startDate} onChange={(e:any) => setSchemeTypeShareLTIP({...schemeTypeShareLTIP, startDate: e.target.value})} />
                              </div>
                              <div className="w-full">
                                <Input type="date" label="End date" value={schemeTypeShareLTIP.endDate} onChange={(e:any) => setSchemeTypeShareLTIP({...schemeTypeShareLTIP, endDate: e.target.value})} />
                              </div>
                            </div>

                            <div className="mt-6">
                              <ButtonGroupSelector selectOptions={SchemeAccessShareLTIP} onValueChange={(e:any) => setSchemeTypeShareLTIP({...schemeTypeShareLTIP, hasSubSchemeAccess: e})} />
                            </div>

                            <Maybe condition={schemeTypeShareLTIP.hasSubSchemeAccess}>
                              <SubSchemesTags
                                id={id}
                                data={schemeTypeShareLTIP}
                                gotoSubScheme={gotoSubScheme}
                              />
                              
                              <span onClick={() => gotoSubScheme("/trustee/company/form/" + id + "/company-subscheme/" + (schemeTypeShareLTIP.schemePropertyType+1) + "/share-ltip")} className="text-vetiva-brown flex items-center hover:underline w-max cursor-pointer">
                                Click here to configure Long Term Incentice Plan (LTIP) SubScheme <FontAwesomeIcon className="ml-1" icon="chevron-right" />
                              </span>
                            </Maybe>

                            <Maybe condition={!schemeTypeShareLTIP.hasSubSchemeAccess}>
                              <div className="mt-6 grid grid-cols-1 gap-6">
                                <div className="w-full">
                                  <Input type="number" label="Number of allocation" value={schemeTypeShareLTIP.schemeDetail.numberOfAllocation} onChange={(e:any) => setSchemeTypeShareLTIP({...schemeTypeShareLTIP, schemeDetail: {...schemeTypeShareLTIP.schemeDetail, numberOfAllocation:e.target.value}})} />
                                </div>
                                <div className="w-full">
                                  <Input type="date" label="Date of allocation" value={schemeTypeShareLTIP.schemeDetail.dateOfAllocation} onChange={(e:any) => setSchemeTypeShareLTIP({...schemeTypeShareLTIP, schemeDetail: {...schemeTypeShareLTIP.schemeDetail, dateOfAllocation:e.target.value}})} />
                                </div>
                                <div className="w-full">
                                  <Input type="number" label="Percentage for vesting" value={schemeTypeShareLTIP.schemeDetail.percentageVesting} onChange={(e:any) => setSchemeTypeShareLTIP({...schemeTypeShareLTIP, schemeDetail: {...schemeTypeShareLTIP.schemeDetail, percentageVesting: e.target.value}})} />
                                </div>
                                <div className="w-full">
                                  <div className="font-bold">
                                    Vesting period
                                  </div>
                                  <div className="text-sm text-fade mb-3">
                                    Select type
                                  </div>
                                  <div>
                                    <ButtonGroupSelector selectOptions={setAllocationAccessSelection(schemeTypeShareLTIP.schemeDetail)} onValueChange={(e:any) => setSchemeTypeShareLTIP({...schemeTypeShareLTIP, schemeDetail: {...schemeTypeShareLTIP.schemeDetail, vestingPeriod: e}})} />
                                  </div>
                                </div>
                                <Maybe condition={schemeTypeShareLTIP.schemeDetail.vestingPeriod === "Fixed period"}>
                                  <div className="w-full">
                                    <SelectInput label="Vesting period" value={schemeTypeShareLTIP.schemeDetail.period} onChange={(e:any) => setSchemeTypeShareLTIP({...schemeTypeShareLTIP, schemeDetail: {...schemeTypeShareLTIP.schemeDetail, period: valueToInt(e.target.value)}})}>
                                      <option value={0} disabled>Select period</option>
                                      <option value={1}>Every 1 year</option>
                                      <option value={2}>Every 2 years</option>
                                      <option value={3}>Every 3 years</option>
                                      <option value={4}>Every 4 years</option>
                                      <option value={5}>Every 5 years</option>
                                      <option value={6}>Every 6 years</option>
                                      <option value={7}>Every 7 years</option>
                                      <option value={8}>Every 8 years</option>
                                      <option value={9}>Every 9 years</option>
                                      <option value={10}>Every 10 years</option>
                                    </SelectInput>
                                  </div>
                                </Maybe>
                                <Maybe condition={schemeTypeShareLTIP.schemeDetail.vestingPeriod === "Recurring date"}>
                                  <div className="w-full">
                                    <Input type="date" label="Vesting date" value={schemeTypeShareLTIP.schemeDetail.vestingDate} onChange={(e:any) => setSchemeTypeShareLTIP({...schemeTypeShareLTIP, schemeDetail: {...schemeTypeShareLTIP.schemeDetail, vestingDate: e.target.value}})} />
                                  </div>
                                </Maybe>
                              </div>
                            </Maybe>

                          </div>
                        </Accordion>


                        <Accordion label="Access to Others" isOpen={schemeTypeShareOthers.status} onChange={(e:any) => setSchemeTypeShareOthers({...schemeTypeShareOthers, status: e})}>
                          <div className="bg-gray-100 p-5">

                            <div>
                              <Input type="text" label="Scheme name" value={schemeTypeShareOthers.schemeDetail.otherSchemeName} onChange={(e:any) => setSchemeTypeShareOthers({...schemeTypeShareOthers, schemeDetail: {...schemeTypeShareOthers.schemeDetail, otherSchemeName:e.target.value}})} />
                            </div>

                            <div className="mt-6 block text-sm">
                              Holding period
                            </div>

                            <div className="mt-4 grid grid-cols-2 gap-6">
                              <div className="w-full">
                                <Input type="date" label="Start date" value={schemeTypeShareOthers.startDate} onChange={(e:any) => setSchemeTypeShareOthers({...schemeTypeShareOthers, startDate: e.target.value})} />
                              </div>
                              <div className="w-full">
                                <Input type="date" label="End date" value={schemeTypeShareOthers.endDate} onChange={(e:any) => setSchemeTypeShareOthers({...schemeTypeShareOthers, endDate: e.target.value})} />
                              </div>
                            </div>

                            <div className="mt-6">
                              <ButtonGroupSelector selectOptions={SchemeAccessShareOthers} onValueChange={(e:any) => setSchemeTypeShareOthers({...schemeTypeShareOthers, hasSubSchemeAccess: e})} />
                            </div>

                            <Maybe condition={schemeTypeShareOthers.hasSubSchemeAccess}>
                              <SubSchemesTags
                                id={id}
                                data={schemeTypeShareOthers}
                                gotoSubScheme={gotoSubScheme}
                              />
                              
                              <span onClick={() => gotoSubScheme("/trustee/company/form/" + id + "/company-subscheme/" + (schemeTypeShareOthers.schemePropertyType+1) + "/share-others")} className="text-vetiva-brown flex items-center hover:underline w-max cursor-pointer">
                                Click here to configure&nbsp;
                                {schemeTypeShareOthers.schemeDetail.otherSchemeName === "" ? "Others" : schemeTypeShareOthers.schemeDetail.otherSchemeName} 
                                &nbsp;SubScheme <FontAwesomeIcon className="ml-1" icon="chevron-right" />
                              </span>
                            </Maybe>

                            <Maybe condition={!schemeTypeShareOthers.hasSubSchemeAccess}>
                              <div className="mt-6 grid grid-cols-1 gap-6">
                                <div className="w-full">
                                  <Input type="number" label="Number of allocation" value={schemeTypeShareOthers.schemeDetail.numberOfAllocation} onChange={(e:any) => setSchemeTypeShareOthers({...schemeTypeShareOthers, schemeDetail: {...schemeTypeShareOthers.schemeDetail, numberOfAllocation: e.target.value}})} />
                                </div>
                                <div className="w-full">
                                  <Input type="date" label="Date of allocation" value={schemeTypeShareOthers.schemeDetail.dateOfAllocation} onChange={(e:any) => setSchemeTypeShareOthers({...schemeTypeShareOthers, schemeDetail: {...schemeTypeShareOthers.schemeDetail, dateOfAllocation: e.target.value}})} />
                                </div>
                                <div className="w-full">
                                  <Input type="number" label="Percentage for vesting" value={schemeTypeShareOthers.schemeDetail.percentageVesting} onChange={(e:any) => setSchemeTypeShareOthers({...schemeTypeShareOthers, schemeDetail: {...schemeTypeShareOthers.schemeDetail, percentageVesting: e.target.value}})} />
                                </div>
                                <div className="w-full">
                                  <div className="font-bold">
                                    Vesting period
                                  </div>
                                  <div className="text-sm text-fade mb-3">
                                    Select type
                                  </div>
                                  <div>
                                    <ButtonGroupSelector selectOptions={setAllocationAccessSelection(schemeTypeShareOthers.schemeDetail)} onValueChange={(e:any) => setSchemeTypeShareOthers({...schemeTypeShareOthers, schemeDetail: {...schemeTypeShareOthers.schemeDetail, vestingPeriod: e}})} />
                                  </div>
                                </div>
                                <Maybe condition={schemeTypeShareOthers.schemeDetail.vestingPeriod === "Fixed period"}>
                                  <div className="w-full">
                                    <SelectInput label="Vesting period" value={schemeTypeShareOthers.schemeDetail.period} onChange={(e:any) => setSchemeTypeShareOthers({...schemeTypeShareOthers, schemeDetail: {...schemeTypeShareOthers.schemeDetail, period: valueToInt(e.target.value)}})}>
                                      <option value={0} disabled>Select period</option>
                                      <option value={1}>Every 1 year</option>
                                      <option value={2}>Every 2 years</option>
                                      <option value={3}>Every 3 years</option>
                                      <option value={4}>Every 4 years</option>
                                      <option value={5}>Every 5 years</option>
                                      <option value={6}>Every 6 years</option>
                                      <option value={7}>Every 7 years</option>
                                      <option value={8}>Every 8 years</option>
                                      <option value={9}>Every 9 years</option>
                                      <option value={10}>Every 10 years</option>
                                    </SelectInput>
                                  </div>
                                </Maybe>
                                <Maybe condition={schemeTypeShareOthers.schemeDetail.vestingPeriod === "Recurring date"}>
                                  <div className="w-full">
                                    <Input type="date" label="Vesting date" value={schemeTypeShareOthers.schemeDetail.vestingDate} onChange={(e:any) => setSchemeTypeShareOthers({...schemeTypeShareOthers, schemeDetail: {...schemeTypeShareOthers.schemeDetail, vestingDate: e.target.value}})} />
                                  </div>
                                </Maybe>
                              </div>
                            </Maybe>

                          </div>
                        </Accordion>

                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap mb-8">
                    <div className="w-full">
                      <label className="block">Employee access to share sale or transfer:</label>
                      <div className="mt-2">
                        <ButtonGroupSelector selectOptions={shareSaleOrTransfer} onValueChange={(e:any) => setCompanyForm({...companyForm, CanEmployeeInitiateShareSale: e})} />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap mb-8">
                    <div className="w-full">
                      <label className="block">Company dividend type</label>
                      <span className="block text-sm text-fade mb-3">
                        Select type of dividend to be paid
                      </span>
                      <ButtonGroupCheckbox selectOptions={dividendTypes} onValueChange={(e:any) => setCompanyForm({...companyForm, DividendTypes: e})} />
                    </div>
                  </div>

                  <div className="flex flex-wrap mb-8">
                    <div className="w-full">
                      <label className="block">Upload Document</label>

                        <div className="bg-gray-100 p-6 mt-3">
                          <h5 className="block text-subtitle2 font-bold">Supporting document</h5>
                          <p className="block text-sm text-fade mb-5">Upload document</p>

                          <Maybe condition={showFileUploadSupportingDoc === false}>
                            {company.data?.organizationShareSetting?.supportingDocumentObject.length !== 0 &&
                              <FileUploadPreview
                                className="bg-white"
                                file={company.data?.organizationShareSetting?.supportingDocumentObject[0] || undefined}
                                allowDismiss={true}
                                cancelUpload={() => {setCompanyForm({...companyForm, SupportingDocument: null}); setShowFileUploadSupportingDoc(true)}}
                              />
                            }
                          </Maybe>

                          <Maybe condition={showFileUploadSupportingDoc === true}>
                              <DocPicker onValueChange={(e:any) => setCompanyForm({...companyForm, SupportingDocument: e[0]})} />
                          </Maybe>
                          
                        </div>
                    </div>
                  </div>
                </div>


                <div className="flex justify-end space-x-3 mt-6 mb-6">
                  <Link to={"/trustee/company/form/"+id+"/admin-details"} className="btn btn-gold">
                    Back
                  </Link>
                  <ButtonLoader type="submit" loading={createCompanyLoading3} className="btn btn-brown">
                    Continue
                  </ButtonLoader>
                </div>

              </form>
            </div>
          </Maybe>

        </div>
      </div>


    </div>
  );
}

export default CompanyCreateSettings;
