import { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonGroupSelector, DocPicker, FileUploadPreview, FormDrawer, SelectInput } from "components/ui";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import { AlphaNumberTypes } from "data/constants/company-settings";
import { iterateNumberArray, numberToAlpha } from "utils/number-alpha-array";
import BatchDefinitionForm from "./batch-definition-form";
import SubSchemeTemplate from "./subscheme-template";
import { jsonToFormData } from "utils/jsonToFormData";
// import jsonToFormData from "json-form-data";
// import { jsonToFormDataOptions } from "utils/jsonToFormDataOptions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "components/ButtonLoader";
import PageOverlayCover from "components/PageOverlayCover";
import DialogBox from "utils/dialogBox";
import { PageContent } from "components/PageContent";
import Maybe from "components/Maybe";
import { removeNull } from "utils/misc";
import * as companyAction from "store/entities/company/action";




const setSubSchemeAplhaNumberTypes = (name:string | undefined, alphaNumberTypes:any[]) => {
  if (name === undefined){
    return alphaNumberTypes;
  }

  let nameType:string;
  const nameTypeValue = parseInt(name.split(" ")[1]);
  if (isNaN(nameTypeValue)){
    nameType = "Alphabets";
  }
  else{
    nameType = "Numbers";
  }

  const alphaNumberResponse = alphaNumberTypes.map(type => {
    let newAlphaNumberType:any = {};
    if (nameType === type.value){
      newAlphaNumberType = {...type, selected: true}
    }
    else{
      newAlphaNumberType = {...type, selected: false}
    }
    return newAlphaNumberType;
  })

  return alphaNumberResponse;
}

const schemeTypeFullname = (id:any) => {
  const enumSchemeTypeIndex:any = {
    0: "Share Awards",
    1: "Share Options",
    2: "Share Purchase",
    3: "Long Term Incentice Plan (LTIP)",
    4: "Others",
  }

  if (id in enumSchemeTypeIndex){
    return enumSchemeTypeIndex[id];
  }
  else{
    return id;
  }
}



export interface CompanyCreateSubschemeSettingsProps {}

export function CompanyCreateSubschemeSettings(props: CompanyCreateSubschemeSettingsProps) {
  const { id, schemeTypeIndexPlus, schemeType } = useParams();
  const schemeTypeIndex = (schemeTypeIndexPlus !== undefined ? +schemeTypeIndexPlus-1 : -1)
  const dispatch = useDispatch();
  const { companyLoading, company, createCompanyLoading31, createCompany31 } = useSelector((s: any) => s.entities.company);

  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [aplhaNumberTypesToUse, setAplhaNumberTypesToUse] = useState<any>([]);
  const [allocationNameType, setAllocationNameType] = useState("Alphabets");
  const [subSchemeToUse, setSubSchemeToUse] = useState<any>([]);
  const [schemeTypeToUse, setSchemeTypeToUse] = useState<any>([]);
  const [quantity, setQuantity] = useState(1);
  const [showWaitingPageCover, setShowWaitingPageCover] = useState(false);
  const [showFileUploadTrustDeed, setShowFileUploadTrustDeed] = useState(true);


  const [subSchemes, setSubSchemes] = useState<any>({
    id: null,
    schemeTypeId: "",
    trustDeed: [],
    batches: [],
  });


  const [allocationSample] = useState<any>({
    "id": null,
    "numberOfAllocation": "",
    "dateOfAllocation": "",
    "percentageVesting": "",
    "vestingPeriod": "Fixed period",
    "vestingDate": "",
    "period": 0,
    "batchId": id,
    "schemeRule": [],
  });
  

  const [batchSample] = useState<any>({
    "id": null,
    "name": "",
    "allocations": [
      {...allocationSample},
    ]
  });
  


  const [subSchemeBatch, setSubSchemeBatch] = useState<any>([]);
  // const [subSchemeBatch, setSubSchemeBatch] = useState<any>([
  //   {
  //     "id": null,
  //     "name": "Batch A",
  //     "allocations": [
  //       {
  //         "id": null,
  //         "numberOfAllocation": 11,
  //         "dateOfAllocation": "2022-11-12",
  //         "percentageVesting": 21,
  //         "vestingPeriod": "Recurring date",
  //         "vestingDate": "2022-11-11",
  //         "period": 2,
  //         "batchId": id,
  //         "schemeRule": ""
  //       },
  //     ]
  //   },
  // ]);
  

  const [subSchemeGroup, setSubSchemeGroup] = useState<any>([]);
  // const [subSchemeGroup, setSubSchemeGroup] = useState<any>([
  //   {
  //     "id": null,
  //     "name": "Group 1",
  //     "allocations": [
  //       {
  //         "id": null,
  //         "numberOfAllocation": 10,
  //         "dateOfAllocation": "2022-11-11",
  //         "percentageVesting": 20,
  //         "vestingPeriod": "Recurring date",
  //         "vestingDate": "2022-11-01",
  //         "period": 2,
  //         "batchId": id,
  //         "schemeRule": ""
  //       },
  //     ]
  //   },
  //   {
  //     "id": null,
  //     "name": "Group 2",
  //     "allocations": [
  //       {
  //         "id": null,
  //         "numberOfAllocation": 161,
  //         "dateOfAllocation": "2022-06-11",
  //         "percentageVesting": 61,
  //         "vestingPeriod": "Recurring date",
  //         "vestingDate": "2022-06-01",
  //         "period": 6,
  //         "batchId": id,
  //         "schemeRule": ""
  //       },
  //     ]
  //   },
  //   {
  //     "id": null,
  //     "name": "Group 3",
  //     "allocations": [
  //       {
  //         "id": null,
  //         "numberOfAllocation": 30,
  //         "dateOfAllocation": "2022-11-12",
  //         "percentageVesting": 40,
  //         "vestingPeriod": "Fixed period",
  //         "vestingDate": "2022-11-02",
  //         "period": 5,
  //         "batchId": id,
  //         "schemeRule": ""
  //       },
  //       {
  //         "id": null,
  //         "numberOfAllocation": 50,
  //         "dateOfAllocation": "2022-11-18",
  //         "percentageVesting": 60,
  //         "vestingPeriod": "Recurring date",
  //         "vestingDate": "2022-11-19",
  //         "period": 7,
  //         "batchId": id,
  //         "schemeRule": ""
  //       },
  //     ]
  //   },
  // ]);


  const [subSchemeCategory, setSubSchemeCategory] = useState<any>([]);
  // const [subSchemeCategory, setSubSchemeCategory] = useState<any>([
  //   {
  //     "id": null,
  //     "name": "Category A",
  //     "allocations": [
  //       {
  //         "id": null,
  //         "numberOfAllocation": 10,
  //         "dateOfAllocation": "2022-11-11",
  //         "percentageVesting": 20,
  //         "vestingPeriod": "Recurring date",
  //         "vestingDate": "2022-11-01",
  //         "period": 2,
  //         "batchId": id,
  //         "schemeRule": ""
  //       },
  //     ]
  //   },
  //   {
  //     "id": null,
  //     "name": "Category B",
  //     "allocations": [
  //       {
  //         "id": null,
  //         "numberOfAllocation": 30,
  //         "dateOfAllocation": "2022-11-12",
  //         "percentageVesting": 40,
  //         "vestingPeriod": "Fixed period",
  //         "vestingDate": "2022-11-02",
  //         "period": 5,
  //         "batchId": id,
  //         "schemeRule": ""
  //       },
  //       {
  //         "id": null,
  //         "numberOfAllocation": 50,
  //         "dateOfAllocation": "2022-11-18",
  //         "percentageVesting": 60,
  //         "vestingPeriod": "Fixed period",
  //         "vestingDate": "2022-11-19",
  //         "period": 7,
  //         "batchId": id,
  //         "schemeRule": ""
  //       },
  //     ]
  //   },
  // ]);



  const closeModal = async () => {
    const result = await DialogBox({
      theme: "red",
      content: "If you proceed, you will lose all unsaved data and this cannot be recovered",
      buttonYes: "Yes, exit and lose unsaved data",
      buttonNo: "Don't exit",
    });
    if (result) {
      handleClick("")
    }
  }



  const handleClick = (type: string) => { 
    let subSchemeToUse: any = [];
    let quantity: number;
    let aplhaNumberTypesToUse: any = [];
    let selectedAplhaNumberTypesToUse: any;

    if (type === "Batch"){
      subSchemeToUse = subSchemeBatch;
      aplhaNumberTypesToUse = setSubSchemeAplhaNumberTypes(subSchemeBatch[0]?.name, AlphaNumberTypes);
    }
    else if (type === "Group"){
      subSchemeToUse = subSchemeGroup;
      aplhaNumberTypesToUse = setSubSchemeAplhaNumberTypes(subSchemeGroup[0]?.name, AlphaNumberTypes);;
    }
    else if (type === "Category"){
      subSchemeToUse = subSchemeCategory;
      aplhaNumberTypesToUse = setSubSchemeAplhaNumberTypes(subSchemeCategory[0]?.name, AlphaNumberTypes);;
    }
    
    if (subSchemeToUse.length > 0){
      quantity = subSchemeToUse.length;
    }
    else{
      quantity = 1;
      subSchemeToUse = [
        {
          ...batchSample,
          "name": (displayLabelName(0, undefined, type)),
        }
      ];
    }

    if (type !== ""){
      selectedAplhaNumberTypesToUse = aplhaNumberTypesToUse.filter((option:any) => option.selected === true)[0].value;
    }

    // console.log("start subSchemeToUse", subSchemeToUse);
    
    setIsOpen(type !== "");
    setModalType(type);
    setAplhaNumberTypesToUse(aplhaNumberTypesToUse);
    setAllocationNameType(selectedAplhaNumberTypesToUse);
    setSubSchemeToUse(subSchemeToUse);
    setQuantity(quantity);
  };


  
  const displayLabelName = (index: number, nameType = allocationNameType, labelType = modalType) => {
    let labelName: any;
    const indicator = nameType === "Numbers"? index + 1 : numberToAlpha(index + 1);
    labelName = labelType + " " + indicator;
    return labelName;
  }



  const updateAllocationNameType = async (nameType: string) => {
    let subSchemeToUseCopy = subSchemeToUse.map((ele:any, i:number) => {
      return {...ele, name: displayLabelName(i, nameType)}
    });
    setSubSchemeToUse(subSchemeToUseCopy);
    setAllocationNameType(nameType);
  }



  const updateQuantity = async (e: any) => {
    const newQuantity = parseInt(e.target.value);
    let subSchemeToUseCopy = [...subSchemeToUse];
    
    if (newQuantity > subSchemeToUse.length){
      const subSchemeToAdd = newQuantity - subSchemeToUse.length;
      for (let i = 0; i < subSchemeToAdd; i++){

        const batchSampleCopy = {
          ...batchSample,
          "name": (displayLabelName((subSchemeToUse.length + i))),
        };
        subSchemeToUseCopy.push(batchSampleCopy);

      }
    }
    else if (newQuantity < subSchemeToUse.length){
      const subSchemeToRemove = newQuantity - subSchemeToUse.length;
      subSchemeToUseCopy = subSchemeToUseCopy.slice(0, subSchemeToRemove);
    }

    setQuantity(newQuantity);
    setSubSchemeToUse(subSchemeToUseCopy);
  }


  const getOtherSubSchemes = () => {
    if (modalType === "Batch"){
      return [...subSchemeGroup, ...subSchemeCategory];
    }
    else if (modalType === "Group"){
      return [...subSchemeBatch, ...subSchemeCategory];
    }
    else if (modalType === "Category"){
      return [...subSchemeBatch, ...subSchemeGroup];
    }
    else{
      return [...subSchemeBatch, ...subSchemeGroup, ...subSchemeCategory];
    }
  }



  const removeNullFromSchemeDetails = (data: any) => {
    let newData:any = {}
    if (data !== null){
      Object.keys(data).map((key:any) => {
        if (data[key] !== null){
          newData[key] = data[key];
        }
        return null;
      })
    }
    return newData;
  }



  const submitFormBatches = async (e:React.FormEvent) => {
    e.preventDefault();
    // console.log("company", company);
    
    let errors = 0;
    subSchemeToUse.map((data:any) => {
      data.allocations.map((allocation:any, index:number) => {

        if (allocation.numberOfAllocation === ""){
          errors++;
          toast.error("Please enter the number of allocation for " + data.name + " (Allocation " + (index+1) + ")");
        }
        
        if (allocation.dateOfAllocation === ""){
          errors++;
          toast.error("Please enter the data of allocation for " + data.name + " (Allocation " + (index+1) + ")");
        }
        
        if (allocation.percentageVesting === ""){
          errors++;
          toast.error("Please enter the percentage for vesting for " + data.name + " (Allocation " + (index+1) + ")");
        }
        
        if (allocation.vestingPeriod === "Fixed period" && allocation.period === ""){
          errors++;
          toast.error("Please select the period for vesting for " + data.name + " (Allocation " + (index+1) + ")");
        }

        if (allocation.vestingPeriod === "Recurring date" && allocation.vestingDate === ""){
          errors++;
          toast.error("Please enter the vesting date for " + data.name + " (Allocation " + (index+1) + ")");
        }

        if (allocation.schemeRule === ""){
          // document upload validation
          // errors++;
          // toast.error("Please upload the scheme rule document for " + data.name + " (Allocation " + (index+1) + ")");
        }
        
        return null;
      });
      return null;
    });

    if (errors === 0){
      submitForm(subSchemes);
    }
  }


  const submitFormTrustDeed = () => {
    const params = {
      ...subSchemes,
      // trustDeed: file
    };
    setShowWaitingPageCover(true);
    submitForm(params);
  }


  const submitForm = (subSchemeParams:any) => {
    const {organizationShareSetting} = company.data;
    const subSchemeToSubmit = organizationShareSetting.schemeTypes.map((schemeType:any) => {
      let schemeTypeToSubmit:any = {}

      if (schemeType.schemePropertyType === schemeTypeIndex){
        const allSubSchemes = [{
          ...subSchemeParams,
          batches: [...subSchemeToUse, ...getOtherSubSchemes()],
        }]
        schemeTypeToSubmit = {...schemeType, subSchemes: allSubSchemes};
      }
      else{
        schemeTypeToSubmit = schemeType;
      }
      
      const schemeDetailWithoutNull = removeNullFromSchemeDetails(schemeTypeToSubmit.schemeDetail);
      schemeTypeToSubmit = {...schemeTypeToSubmit, schemeDetail: schemeDetailWithoutNull};
      return schemeTypeToSubmit;
    })
    
    const params = {
      CanEmployeeInitiateShareSale: organizationShareSetting.canEmployeeInitiateShareSale,
      DividendTypes: organizationShareSetting.dividendTypes,
      SchemeTypes: removeNull(subSchemeToSubmit),
      SharesAllocation: organizationShareSetting.sharesAllocation,
      SupportingDocument: organizationShareSetting.supportingDocumentObject,
    }

    // console.log("step 3.1 params", params);
    const formDataParams = jsonToFormData(params);
    dispatch(companyAction.createStep31(id, formDataParams));
  }


  useEffect(() => {
    if (createCompany31.success && createCompany31.success === true){
      toast.success("Subscheme updated successfully");
      handleClick("");
      dispatch(companyAction.createStep31Reset());
      dispatch(companyAction.getCompany(id));
    }

    if (createCompanyLoading31 === false){
      setShowWaitingPageCover(false);
      setSubSchemeToUse([]);
    }

  }, [createCompany31]);



  const changeAllocationKeys = (data: any) => {
    const newAllocation = data.allocations.map((allocation:any) => {
      return {...allocation, schemeRule: allocation.schemeRuleObj};
    })
    return {...data, allocations: newAllocation};
  }


  const populateForm = () => {
    if (company.data?.organizationShareSetting?.schemeTypes){
      
      const schemeTypeToUse = company.data.organizationShareSetting.schemeTypes.filter((schemeType:any) => schemeType.schemePropertyType === schemeTypeIndex);
      if (schemeTypeToUse.length > 0){

        if (schemeTypeToUse.length > 1){
          toast.error("Error: Multiple configurations for " + schemeTypeFullname(schemeTypeIndex) + " detected");
        }

        const selectedSchemeType = schemeTypeToUse[0];
        setSchemeTypeToUse([selectedSchemeType]);
        setSubSchemes({...subSchemes, schemeTypeId: selectedSchemeType.id});

        if (selectedSchemeType.subSchemes.length > 0){
          setSubSchemes({
            ...subSchemes,
            id: selectedSchemeType.subSchemes[0].id,
            schemeTypeId: selectedSchemeType.id,
            batches: selectedSchemeType.subSchemes[0].batches,
            trustDeed: selectedSchemeType.subSchemes[0].trustDeedObject,
          });

          if (selectedSchemeType.subSchemes[0].trustDeedObject.length !== 0){
            setShowFileUploadTrustDeed(false);
          }

          const allBatches = selectedSchemeType.subSchemes[0].batches;
          if (allBatches.length > 0){
            let newSubSchemeBatch:any = [];
            let newSubSchemeGroup:any = [];
            let newSubSchemeCategory:any = [];
            
            allBatches.map((data:any) => {
              const newData = changeAllocationKeys(data);              
              if (data.name.includes("Batch")){
                newSubSchemeBatch.push(newData);
              }
              else if (data.name.includes("Group")){
                newSubSchemeGroup.push(newData);
              }
              else if (data.name.includes("Category")){
                newSubSchemeCategory.push(newData);
              }
              return null;
            })

            setSubSchemeBatch(newSubSchemeBatch);
            setSubSchemeGroup(newSubSchemeGroup);
            setSubSchemeCategory(newSubSchemeCategory);
          }
        }
        
      }
      
    }
  }


  useEffect(() => {
    dispatch(companyAction.getCompany(id));
  }, []);

  
  useEffect(() => {
    if (company.success && company.success === true){
      populateForm();
    }
  }, [company]);


  return (
    <div>


      <PageOverlayCover condition={showWaitingPageCover} text="Please wait..." />
      

      <FormWizardNavBar showSteps={true} currentStep={3} totalSteps={5} closeUrl="/trustee/company" />
      
      
      <div className="bg-gray-100 min-h-screen">
        <div className="border-b border-b-gray-300 shadow-none p-2 bg-white">
          <div className="container max-w-2xl mx-auto">
            <Link to={"/trustee/company/form/" + id + "/company-settings"} className="text-vetiva-brown hover:underline">
              <FontAwesomeIcon className="mr-2" icon="chevron-left" />
              Back to scheme settings
            </Link>
            <h2 className="text-h6 font-bold">{schemeTypeFullname(schemeTypeIndex)}</h2>
          </div>
        </div>

        <div className="container max-w-2xl mx-auto py-10">
          <PageContent loading={companyLoading} data={company.data || undefined} />

          <Maybe condition={companyLoading === false && company.data && Object.keys(company.data).length > 0}>
            
            <Maybe condition={schemeTypeToUse.length > 0}>
              <form>
                <div className="w-full">
                  <label>
                    Company access to Subscheme
                  </label>
                  <div className="block text-sm text-fade">
                    Select sub-scheme type
                  </div>
                </div>

                <div className="mt-6 space-y-6">
                  <div>
                    <SubSchemeTemplate label="Batch" count={subSchemeBatch.length} onItemClick={()=>handleClick("Batch") } />
                  </div>
                  <div>
                    <SubSchemeTemplate label="Group" count={subSchemeGroup.length} onItemClick={()=>handleClick("Group") } />
                  </div>
                  <div>
                    <SubSchemeTemplate label="Category" count={subSchemeCategory.length} onItemClick={()=>handleClick("Category") } />
                  </div>
                </div>

                <div className="mt-6">
                  <div>
                    <label className="font-bold">Trust Deed</label>
                    <span className="block text-sm text-fade mb-3">
                      Upload Document
                    </span>
                  </div>
                  <div>

                    <Maybe condition={showFileUploadTrustDeed === false}>
                      <Maybe condition={subSchemes.trustDeed.length > 0}>
                        <FileUploadPreview
                          className="bg-white"
                          file={subSchemes.trustDeed[0] || undefined}
                          allowDismiss={true}
                          cancelUpload={() => {setSubSchemes({...subSchemes, trustDeed: []}); setShowFileUploadTrustDeed(true)}} />
                      </Maybe>
                    </Maybe>
                    <Maybe condition={showFileUploadTrustDeed === true}>
                      <DocPicker onValueChange={(e:any) => setSubSchemes({...subSchemes, trustDeed: e[0] || []})} />
                      <Maybe condition={subSchemes.trustDeed !== null && subSchemes.trustDeed !== undefined}>
                        <div className="mt-4">
                          <ButtonLoader type="button" loading={createCompanyLoading31} onClick={submitFormTrustDeed} className="btn btn-brown">
                            Upload document
                          </ButtonLoader>
                        </div>
                      </Maybe>
                    </Maybe>

                  </div>
                </div>
              </form>
            </Maybe>

            <Maybe condition={schemeTypeToUse.length <= 0}>
              <div className="max-w-sm mx-auto">
                <div className="table-info">
                  <FontAwesomeIcon icon="exclamation-circle" />
                  <div className="mt-4 font-bold uppercase text-2xl">
                    Scheme settings
                    <br />
                    not activated
                  </div>
                  <div className="mt-4">
                    Please activate scheme settings (<span className="font-bold">{schemeTypeFullname(schemeTypeIndex)}</span>) for this company,
                    in order to configure its <span className="whitespace-nowrap">sub-scheme</span> settings.
                  </div>
                  <div className="mt-8 space-y-2">
                    <Link to={"/trustee/company/form/"+id+"/company-settings"} className="btn btn-block btn-lg btn-vetiva-brown">
                      Goto scheme type settings
                    </Link>
                  </div>
                </div>
              </div>
            </Maybe>

          </Maybe>
        </div>

      </div>


      <Maybe condition={companyLoading === false && company.data && Object.keys(company.data).length > 0}>
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} closeDialog={closeModal}>
          <form onSubmit={submitFormBatches} noValidate autoComplete="off" className="h-full">
            <div className="fixed h-20 flex items-center w-full top-0 bg-vetiva-black text-white">
              <div className="px-6">
                <div className="text-xl">
                  {modalType} settings
                </div>
              </div>
            </div>

            <div className="w-full p-6 absolute top-20 bottom-16 overflow-y-auto">

              <div>
                <label className="block mb-3">{modalType} Name</label>
                <ButtonGroupSelector selectOptions={aplhaNumberTypesToUse} onValueChange={(e:string) => updateAllocationNameType(e)} />
              </div>

              <div className="mt-6">
                <SelectInput label="Select quantity" type={"text"} value={quantity} onChange={(e:any) => updateQuantity(e)}>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </SelectInput>
              </div>

              <div className="mt-6">
                {iterateNumberArray(quantity).map((item, index) => (
                  <Fragment key={index}>
                    <BatchDefinitionForm
                      isOpen={isOpen}
                      label={subSchemeToUse[index]?.name}
                      index={index}
                      allocationSample={allocationSample}
                      subSchemeToUse={subSchemeToUse}
                      setSubSchemeToUse={setSubSchemeToUse} />
                  </Fragment>
                ))}
              </div>
              
            </div>

            <div className="w-full fixed bottom-0 p-3 bg-white flex space-x-3 items-center justify-end">
              <button type="button" className="btn btn-gold" onClick={() => closeModal()}>
                Cancel
              </button>
              <ButtonLoader type="submit" loading={createCompanyLoading31} className="btn btn-brown">
                Submit
              </ButtonLoader>
            </div>
          </form>
        </FormDrawer>
      </Maybe>



    </div>
  );
}

export default CompanyCreateSubschemeSettings;
