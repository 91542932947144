import { confirmAlert } from 'react-confirm-alert';
import IconDialogPrompt from "assets/images/icons/dialog-prompt.gif";
// import { ReactComponent as IconDialogPrompt } from "assets/images/icons/notificationBell.svg";
import 'react-confirm-alert/src/react-confirm-alert.css';


const options = {
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name"
};


const DialogBox = async (props) => {
    
    let theme = "blue-500";
    let icon = IconDialogPrompt;
    let title = "Are you sure?";
    let content = "Please confirm that you want to carry out this action";
    let buttonYes = "Yes, continue";
    let buttonYesClass = "text-white btn-vetiva-brown";
    let buttonNo = "Cancel";
    let buttonNoClass = "";
    
    // console.log("props", props);
    if (props){
        if (props.theme){
            ({theme} = props);
        }
        if (props.icon){
            ({icon} = props);
        }
        if (props.title){
            ({title} = props);
        }
        if (props.content){
            ({content} = props);
        }
        if (props.buttonYes){
            ({buttonYes} = props);
        }
        if (props.buttonYesClass){
            ({buttonYesClass} = props);
        }
        if (props.buttonNo){
            ({buttonNo} = props);
        }
        if (props.buttonNoClass){
            ({buttonNoClass} = props);
        }
    }

    // const bgColor = "bg-" + theme;
    // const bgColor = "bg-vetiva-brown";

    let myPromise = new Promise(function(resolve, reject) {
        confirmAlert({
            ...options,
            customUI: ({ onClose }) => {
                return (
                    <div className="dialog-box w-96 p-8 bg-white rounded-md shadow-lg text-center overflow-hidden">
                        <div className="h-36">
                            <img src={icon} alt="prompt" className={"object-contain mx-auto"} />
                        </div>
                        <div className="mt-2 font-bold text-lg sm:text-xl">
                            {title}
                        </div>
                        <div className="mt-1 text-fade">
                            {content}
                        </div>
                        <div className="mt-6 space-y-3 justify-between">
                            {/* <button type="button" className={"btn btn-block btn-lg text-white " + bgColor} onClick={() => {resolve(true); onClose();}} autoFocus={true}> */}
                            <button type="button" className={"btn btn-block btn-lg " + buttonYesClass} onClick={() => {resolve(true); onClose();}} autoFocus={true}>
                                {buttonYes}
                            </button>
                            <button type="button" className={"btn btn-block btn-lg " + buttonNoClass} onClick={() => {resolve(false); onClose();}}>
                                {buttonNo}
                            </button>
                        </div>
                    </div>
                );
            }
        })
    });

    return myPromise;
};


export default DialogBox;