import { Access } from "data/constants/company-settings";

export const removeSchemeTypeNamePrefix = (name) => {
  let response = name;
  if (name !== undefined && name !== ""){
    if (name.toLowerCase().includes("access to ")){
      response = name.substring(10);
    }
  }
  return response;
}


export const getCustomSchemeName = (schemeType) => {
  let response = "";
  if (schemeType !== undefined && Object.keys(schemeType).length > 0){
    if (schemeType.name){
      if (schemeType.name.toLowerCase() === "access to others"){
        response = schemeType.schemeDetail?.otherSchemeName;
      }
      else{
        response = schemeType.name;
      }
    }
  }
  return response;
}


export const valueToInt = (value) => {
  if (value !== ""){
    return parseInt(value);
  }
  else{
    return value;
  }
}


export const nullToEmpty = (value) => {
  if (value !== null){
    return value;
  }
  else{
    return "";
  }
}


export const setAllocationAccessSelection = (allocation) => {
  const access = Access.map(type => {
    let newAccess = {};
    if (allocation.vestingPeriod === type.value){
      newAccess = {...type, selected: true}
    }
    else{
      newAccess = {...type, selected: false}
    }
    return newAccess;
  })
  return access;
}


/*
export const removeNull = (obj) => {
  if (Array.isArray(obj)) { 
    return obj
        .map(v => (v && typeof v === 'object') ? removeNull(v) : v)
        .filter(v => !(v == null)); 
  } else { 
    return Object.entries(obj)
        .map(([k, v]) => [k, v && typeof v === 'object' ? removeNull(v) : v])
        .reduce((a, [k, v]) => (v == null ? a : (a[k]=v, a)), {});
  }
}
*/


const isFileList = (obj) => {
  if (Blob && obj instanceof Blob) {
    return true;
  }
  return false;
}


export const removeNull = (obj) => {
  if (Array.isArray(obj)) { 
    return obj
        .map(v => (v && typeof v === 'object' && !isFileList(v)) ? removeNull(v) : v)
        .filter(v => !(v == null)); 
  } else { 
    return Object.entries(obj)
        .map(([k, v]) => [k, v && typeof v === 'object' && !isFileList(v) ? removeNull(v) : v])
        .reduce((a, [k, v]) => (v == null ? a : (a[k]=v, a)), {});
  }
}


export const removeUndefined = (obj) => {
  if (Array.isArray(obj)) { 
    return obj
        .map(v => (v && typeof v === 'object' && !isFileList(v)) ? removeUndefined(v) : v)
        .filter(v => !(v == undefined)); 
  } else { 
    return Object.entries(obj)
        .map(([k, v]) => [k, v && typeof v === 'object' && !isFileList(v) ? removeUndefined(v) : v])
        .reduce((a, [k, v]) => (v == undefined ? a : (a[k]=v, a)), {});
  }
}