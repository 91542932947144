import Maybe from "./Maybe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageContent } from "./PageContent";
import { DoughnutChart } from "components/ui/charts/doughnut-chart";
import NumberFormat from "utils/numberFormat";
import iconTeam from "assets/images/icons/team.svg";

interface OverviewDashboardProps {
    name: string,
    loading: any,
    data: any,
}


export const OverviewDashboard = (props: OverviewDashboardProps) => {
    const {name, loading, data} = props;

    const getLoadingText = (name:string) => {
        return (<span>Loading<br />{name}<br />metrics</span>)
    }
    
    return (
        <>

            <Maybe condition={loading === true}>
                <div className="py-10">
                    <PageContent loading={loading} data={data.data || undefined} loadingText={getLoadingText(name)} emptyText="Info not found" errorText="Info not found" />
                </div>
            </Maybe>

            <Maybe condition={!loading && data.data !== undefined}>
                <div className="p-6 rounded bg-gray-100">
                    <div>
                        <div className="font-bold text-xl text-vetiva-brown">
                            {name}
                        </div>
                        <div className="hidden text-gray-500">
                            Enrollment rate (monthly):
                            <span className="text-green-500 ml-1">
                                12.5%
                                <FontAwesomeIcon icon="caret-up" className="text-lg relative top-0.5 left-1" />
                            </span>
                        </div>
                    </div>
                    <div>

                        <div className="w-48 h-48 mt-6 mx-auto">
                            
                            <div className="relative">
                                <div className="absolute w-full h-full flex items-center justify-center">
                                    <div className="text-center">
                                    <div className="w-10 mx-auto">
                                        <img src={iconTeam} alt="Group" className="object-contain" />
                                    </div>
                                    <div>
                                        Total Members
                                    </div>
                                    <div>
                                        {NumberFormat(data.data?.totalSchemeMembers, "no-kobo")}
                                    </div>
                                    </div>
                                </div>
                                <Maybe condition={data.data?.totalSharesUnAllocatedToMembers === 0 && data.data?.totalSharesUnAllocatedToMembers === 0}>
                                    <DoughnutChart data={[0,1]} />
                                </Maybe>
                                <Maybe condition={data.data?.totalSharesUnAllocatedToMembers !== 0 || data.data?.totalSharesUnAllocatedToMembers !== 0}>
                                    <DoughnutChart data={[
                                        Math.round(data.data?.totalSharesAllocatedToMembers) || 0,
                                        Math.round(data.data?.totalSharesUnAllocatedToMembers) || 0,
                                    ]} />
                                </Maybe>
                            </div>
                        </div>

                        <div className="mt-6 space-y-3 text-gray-500">
                        <div className="flex space-x-2 justify-between">
                            <div>
                                Total shares allocated
                            </div>
                            <div className="text-vetiva-black">
                                {NumberFormat(data.data?.totalSharesAllocated, "no-kobo") || "---"}
                            </div>
                        </div>
                        <div className="flex space-x-2 justify-between">
                            <div>
                                Scheme members
                            </div>
                            <div className="text-vetiva-black">
                                {NumberFormat(data.data?.totalSchemeMembers, "no-kobo") || "---"}
                            </div>
                        </div>
                        <div className="flex space-x-2 justify-between">
                            <div>
                                Shares allocated to members
                            </div>
                            <div className="text-vetiva-black">
                                {NumberFormat(data.data?.totalSharesAllocatedToMembers, "no-kobo") || "---"}
                            </div>
                        </div>
                        <div className="flex space-x-2 justify-between">
                            <div>
                                Shares unallocated to members
                            </div>
                            <div className="text-vetiva-black">
                                {NumberFormat(data.data?.totalSharesUnAllocatedToMembers, "no-kobo") || "---"}
                            </div>
                        </div>
                        </div>

                    </div>
                </div>
            </Maybe>

        </>
    )
}
