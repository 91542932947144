import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Maybe from "components/Maybe";
import { useEffect, useState } from "react";

export interface SubSchemesTagsProps {
  id: string | undefined;
  data: any;
  gotoSubScheme: any;
}

export function SubSchemesTags(props: SubSchemesTagsProps) {
  const {id, data, gotoSubScheme} = props;

  const [subSchemeBatch, setSubSchemeBatch] = useState<any>([]);
  const [subSchemeGroup, setSubSchemeGroup] = useState<any>([]);
  const [subSchemeCategory, setSubSchemeCategory] = useState<any>([]);

  useEffect(() => {
    let subSchemeBatch:any = [];
    let subSchemeGroup:any = [];
    let subSchemeCategory:any = [];
    
    data.subSchemes[0]?.batches.map((each:any) => {
      if (each.name.includes("Batch")){
        subSchemeBatch.push(each);
      }
      else if (each.name.includes("Group")){
        subSchemeGroup.push(each);
      }
      else if (each.name.includes("Category")){
        subSchemeCategory.push(each);
      }
      return null;
    })

    setSubSchemeBatch(subSchemeBatch);
    setSubSchemeGroup(subSchemeGroup);
    setSubSchemeCategory(subSchemeCategory);
  }, [data])
  
  
  return (
    <>
      {data.subSchemes.length > 0 &&
        <div className="mt-3 mb-2 flex space-x-2">

          {subSchemeBatch.length > 0 &&
            <label className="label bg-gray-300 cursor-pointer" onClick={() => gotoSubScheme("/trustee/company/form/" + id + "/company-subscheme/" + (data.schemePropertyType+1) + "/share-awards")}>
              Batch
              <FontAwesomeIcon icon="check" className="ml-2" />
            </label>
          }

          {subSchemeGroup.length > 0 &&
            <label className="label bg-gray-300 cursor-pointer" onClick={() => gotoSubScheme("/trustee/company/form/" + id + "/company-subscheme/" + (data.schemePropertyType+1) + "/share-awards")}>
              Group
              <FontAwesomeIcon icon="check" className="ml-2" />
            </label>
          }

          {subSchemeCategory.length > 0 &&
            <label className="label bg-gray-300 cursor-pointer" onClick={() => gotoSubScheme("/trustee/company/form/" + id + "/company-subscheme/" + (data.schemePropertyType+1) + "/share-awards")}>
              Category
              <FontAwesomeIcon icon="check" className="ml-2" />
            </label>
          }

        </div>
      }

      <Maybe condition={data.subSchemes.length <= 0}>
        <span className="mt-6 block text-sm mb-2">
          No settings detected
        </span>
      </Maybe>
    </>
  );
}

export default SubSchemesTags;
