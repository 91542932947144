import { combineReducers } from "redux";
import overviewReducer from "./overview/reducer";
import companyReducer from "./company/reducer";
import employeeReducer from "./employee/reducer";
import userMgmtReducer from "./userMgmt/reducer";

export default combineReducers({
    overview: overviewReducer,
    company: companyReducer,
    employee: employeeReducer,
    userMgmt: userMgmtReducer,
});