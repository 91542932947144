import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import * as auth from "store/auth/user/action";
import { useEffect } from "react";

const useLoginInfo = () => {
    const dispatch = useDispatch();
    
    
    let userInfoDecoded: any = {};
    const { userLogin, userInfo: userInfoRaw } = useSelector((s: any) => s.auth.userPersist);
    if (userInfoRaw.accessToken){
        userInfoDecoded = jwt_decode(userInfoRaw.accessToken);
    }
    // console.log(userInfoDecoded);


    const defaultUserLogin = "SCHEME_COMMITTEE";
    let loginUserAs = defaultUserLogin;
    let logoutClicked = 0;
    let logoutUrl = "/login";
    

    let userInfo:any = {
        userType: "",
    }


    const checkTokenExpiry = (userInfoDecoded:any) => {
        const tokenExpiryValue = (parseInt(userInfoDecoded.exp) * 1000);
        const currentTime = Date.now();

        if (currentTime > tokenExpiryValue){
            // console.log("logout this user");
            logoutUser();
        }
    }
    
    
    const logoutUser = (params:any = {}) => {
        logoutClicked++;
        if (userInfoDecoded.RolesStr && userInfoDecoded.RolesStr === "TRUSTEE"){
            logoutUrl = "/trustee/login";
        }
        dispatch(auth.logoutUser(params));
    }


    useEffect(() => {
        if (logoutClicked > 0){
            if (userLogin === false){
                window.location.href = logoutUrl;
            }
        }
        // eslint-disable-next-line
    }, [userLogin]);

    
    if (userInfoRaw.userViewWhenDuo !== undefined){
        if (userInfoRaw.userViewWhenDuo === "employer"){
            loginUserAs = "SCHEME_COMMITTEE";
        }
        else if (userInfoRaw.userViewWhenDuo === "employee"){
            loginUserAs = "SCHEME_MEMBER";
        }
    }

    
    if (userInfoRaw.accessToken){
        checkTokenExpiry(userInfoDecoded);
        
        userInfo = {
            _id: userInfoDecoded.UserId,
            
            firstName: userInfoDecoded.FirstName,
            lastName: userInfoDecoded.LastName,
            name: userInfoDecoded.FirstName + " " + userInfoDecoded.LastName,
            
            email: userInfoDecoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"],
            phoneNumber: userInfoDecoded.PhoneNumber,
            
            organizationId: userInfoDecoded.OrganizationId,
            employeeId: userInfoDecoded.EmployeeId,
            userType: userInfoDecoded.RolesStr,
            userTypeDefault: "",
        }

        if (userInfo.userType === "SCHEME_MEMBER,SCHEME_COMMITTEE" || userInfo.userType === "SCHEME_COMMITTEE,SCHEME_MEMBER"){
            userInfo = {
                ...userInfo,
                userType: loginUserAs,
                userTypeDefault: userInfo.userType,
            }
        }
    }

    
    return { userLogin, userInfo, logoutUser };

}


export default useLoginInfo;
