import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useUrlNavigation from "hooks/useUrlNavigation";

export interface SearchBarProps {
  placeholder?: string,
  name?: string,
}

export function SearchBar(props: SearchBarProps) {
  const {placeholder, name} = props;

  let searchName = "search";
  if (name !== undefined){
    searchName = name;
  }

  const {urlQuery} = useUrlNavigation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let searchQuery = searchParams.get(searchName);
  searchQuery = searchQuery !== null ? searchQuery : "";
  const [search, setSearch] = useState(searchQuery);

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    navigate(urlQuery(searchName + "=" + search));
  }

  return (
    <form onSubmit={submitForm} className="w-full max-w-xs">
      <div className="form-group">
        <div className="form-group-icon h-10 rounded-full border border-gray-300">
            <input type="search" placeholder={placeholder} value={search} onChange={(e:any) => setSearch(e.target.value)} className="h-full form-input-icon text-sm" />
            <button type="submit" className="w-12 hover:bg-gray-200 flex">
              <FontAwesomeIcon icon="search" className="form-group-icon-attach h-4 text-gray-400 m-auto" />
            </button>
        </div>
      </div>
  </form>
  );
}

export default SearchBar;
