import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { TimeDateBlock } from "components/time-date-block";
import { RfcSearchBox } from "components/rfc-search-box";
import { SearchBar } from "components/SearchBar";
import { Pagination } from "components/Pagination";
import { TableContent } from "components/TableContent";
import { DateFormat } from "utils/dateFormat";
import { renderStatusColor } from "utils/statusColor";
import NumberFormat from "utils/numberFormat";
import useUrlNavigation from "hooks/useUrlNavigation";
import { FormDrawer, TabBarToggle, SelectInput, Input } from "components/ui";
// import iconSuspend from "assets/images/icons/suspend.svg";
import iconDisabled from "assets/images/icons/disabled.svg";
import iconFilter from "assets/images/icons/filter_list.svg";
import iconSendDark from "assets/images/icons/send-dark.svg";
import iconEdit from "assets/images/icons/edit.svg";
import { nameInitialsCompanyName } from "utils/nameInitials";
import { dateFormatSubmit } from "utils/dataTypes";
import Maybe from "components/Maybe";
import DialogBox from "utils/dialogBox";
import * as companyAction from "store/entities/company/action";


export interface CompanyListProps {}

export function CompanyList(props: CompanyListProps) {
  
  
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {urlQuery} = useUrlNavigation();
  const [searchParams] = useSearchParams();
  const { companiesDashboardStatsLoading, companiesDashboardStats, companiesLoading, companies, companyActivateSuccess, companyDeactivateSuccess } = useSelector((s: any) => s.entities.company);


  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [companyParams, setCompanyParams] = useState<any>({
    "PageNumber": 1,
    "PageSize": 20,
    "type": searchParams.get("type") || "",
    "dateCreated": searchParams.get("dateCreated") || "",
    "status": searchParams.get("status") || "",
    "lastActivity": searchParams.get("lastActivity") || "",
  });

  const [filterForm, setFilterForm] = useState<any>({
    "dateCreated": searchParams.get("dateCreated") || "",
    "status": searchParams.get("status") || "",
    "lastActivity": searchParams.get("lastActivity") || "",
  });


  const toggleFormDrawerFilter = (action: boolean) => { 
    setIsOpenFilter(action);
  };


  const getDashboardStats = () => {
    dispatch(companyAction.getCompaniesDashboardStats());
  }


  const getCompanies = () => {
    let params = {...companyParams};

    const page = searchParams.get("page");
    if (page !== null){
      params = {...params, PageNumber: parseInt(page)}
    }
    else{
      params = {...params, PageNumber: 1}
    }

    const limit = searchParams.get("limit");
    if (limit !== null){
      params = {...params, PageSize: parseInt(limit)}
    }
    else{
      params = {...params, PageSize: 20}
    }

    const search = searchParams.get("search");
    if (search !== null){
      params = {...params, search}
    }
    else{
      if (params.search){
        delete params["search"];
      }
    }

    const type = searchParams.get("type");
    if (type !== null){
      params = {...params, type}
    }
    else{
      if (params.type){
        delete params["type"];
      }
    }

    const dateCreated = searchParams.get("dateCreated");
    if (dateCreated !== null){
      params = {...params, dateCreated: dateFormatSubmit(dateCreated)}
    }
    else{
      if (params.dateCreated){
        delete params["dateCreated"];
      }
    }

    const status = searchParams.get("status");
    if (status !== null){
      params = {...params, status}
    }
    else{
      if (params.status){
        delete params["status"];
      }
    }

    const lastActivity = searchParams.get("lastActivity");
    if (lastActivity !== null){
      params = {...params, lastActivity: dateFormatSubmit(lastActivity)}
    }
    else{
      if (params.lastActivity){
        delete params["lastActivity"];
      }
    }

    setCompanyParams(params);
    dispatch(companyAction.getCompanies(params));
  }


  const submitFilterForm = async (e: React.FormEvent) => {
    e.preventDefault();

    let url = "";
    for (let i in filterForm){
      if (url !== ""){
        url += "&";
      }
      url += i + "=" + filterForm[i];
    }

    navigate(urlQuery(url));
    toggleFormDrawerFilter(false);
  }


  const activateCompany = async (company:any) => {
    const result = await DialogBox({
      buttonYes: "Yes, activate company",
    });
    if (result) {
      dispatch(companyAction.activateCompany(company.id));
    }
  }


  const deactivateCompany = async (company:any) => {
    const result = await DialogBox({
        theme: "red",
        buttonYes: "Yes, deactivate company",
    });
    if (result) {
      dispatch(companyAction.deactivateCompany(company.id));
    }
  }


  const resendInvite = async (company:any) => {
    const result = await DialogBox({
        buttonYes: "Yes, resend invite",
    });
    if (result) {
      dispatch(companyAction.resendInvite(company.id));
    }
  }


  useEffect(() => {
    getDashboardStats();
    getCompanies();

  }, [location]);


  useEffect(() => {
    if (companyActivateSuccess === true || companyDeactivateSuccess === true){
      getDashboardStats();
      getCompanies();
    }
    
  }, [companyActivateSuccess, companyDeactivateSuccess]);


  return (
    <div className="pb-10">


      <div className="bg-vetiva-black">
        <div className="container pt-16 pb-24 flex flex-wrap">
          <div className="w-full xl:w-3/12">
              <div className="h-full flex flex-col justify-between">
                  <div className="overflow-hidden">
                      <TimeDateBlock />
                  </div>

                  <div>
                      <RfcSearchBox />
                  </div>
              </div>
          </div>
          <div className="w-full xl:w-9/12">
            
            <div className="ml-10">
              <div className="w-full flex space-x-4">
                <div className="w-[85%] h-36 p-6 bg-white flex items-center rounded-lg">
                  <div className="flex-grow flex">
                    <div className="mx-auto">
                      <div className="text-sm text-gray-500">
                        Total companies
                      </div>
                      <div className="mt-1 text-2xl text-vetiva-brown">
                        <Maybe condition={companiesDashboardStatsLoading}>
                          <FontAwesomeIcon icon="spinner" spin />
                        </Maybe>
                        <Maybe condition={!companiesDashboardStatsLoading}>
                          {NumberFormat(companiesDashboardStats?.data?.total, "no-kobo") || "---"}
                        </Maybe>
                      </div>
                    </div>
                  </div>
                  <div className="w-6 flex-shrink-0 flex items-center justify-center">/</div>
                  <div className="flex-grow flex">
                    <div className="mx-auto">
                      <div className="text-sm text-green-500">
                        Active
                      </div>
                      <div className="mt-1 text-2xl">
                        <Maybe condition={companiesDashboardStatsLoading}>
                          <FontAwesomeIcon icon="spinner" spin />
                        </Maybe>
                        <Maybe condition={!companiesDashboardStatsLoading}>
                          {NumberFormat(companiesDashboardStats?.data?.active, "no-kobo") || "---"}
                        </Maybe>
                      </div>
                    </div>
                  </div>
                  <div className="w-6 flex-shrink-0 flex items-center justify-center">/</div>
                  <div className="flex-grow flex">
                    <div className="mx-auto">
                      <div className="text-sm text-fade">
                        Draft
                      </div>
                      <div className="mt-1 text-2xl">
                        <Maybe condition={companiesDashboardStatsLoading}>
                          <FontAwesomeIcon icon="spinner" spin />
                        </Maybe>
                        <Maybe condition={!companiesDashboardStatsLoading}>
                          {NumberFormat(companiesDashboardStats?.data?.draft, "no-kobo") || "---"}
                        </Maybe>
                      </div>
                    </div>
                  </div>
                  <div className="w-6 flex-shrink-0 flex items-center justify-center">/</div>
                  <div className="flex-grow flex">
                    <div className="mx-auto">
                      <div className="text-sm text-vetiva-brown">
                        Pending
                      </div>
                      <div className="mt-1 text-2xl">
                        <Maybe condition={companiesDashboardStatsLoading}>
                          <FontAwesomeIcon icon="spinner" spin />
                        </Maybe>
                        <Maybe condition={!companiesDashboardStatsLoading}>
                          {NumberFormat(companiesDashboardStats?.data?.pending, "no-kobo") || "---"}
                        </Maybe>
                      </div>
                    </div>
                  </div>
                  <div className="w-6 flex-shrink-0 flex items-center justify-center">/</div>
                  <div className="flex-grow flex">
                    <div className="mx-auto">
                      <div className="text-sm text-red-500">
                        Deactivated
                      </div>
                      <div className="mt-1 text-2xl">
                        <Maybe condition={companiesDashboardStatsLoading}>
                          <FontAwesomeIcon icon="spinner" spin />
                        </Maybe>
                        <Maybe condition={!companiesDashboardStatsLoading}>
                          {NumberFormat(companiesDashboardStats?.data?.deactivated, "no-kobo") || "---"}
                        </Maybe>
                      </div>
                    </div>
                  </div>
                </div>

                <Link to="/trustee/company/create" className="w-[15%] h-36 p-2 flex flex-col justify-center items-center space-y-1 text-white bg-vetiva-brown hover:bg-vetiva-brown-darker rounded-lg">
                  <FontAwesomeIcon icon="plus" className="text-3xl" />
                  <div className="text-center text-sm">
                    Add Company
                  </div>
                </Link>
              </div>

            </div>
            
          </div>
        </div>
      </div>





      <FormDrawer isOpen={isOpenFilter} setIsOpen={setIsOpenFilter}>
        <form onSubmit={submitFilterForm} className="h-full">
          <div className="fixed h-20 flex items-center w-full top-0 bg-vetiva-black text-white">
            <div className="px-6">
              <div className="text-xl">
                Filter
              </div>
              <div className="text-gray-400 text-xs">
                Filter across table using the parameters shown below
              </div>
            </div>
          </div>

          <div className="w-full p-6 absolute top-20 bottom-16 overflow-y-auto">
            
            <div className="flex flex-col space-y-6">
              <div>
                <Input type="date" label="Date" value={filterForm.dateCreated} onChange={(e:any) => setFilterForm({...filterForm, dateCreated: e.target.value})} />
              </div>
              <div>
                <SelectInput label="Status" value={filterForm.status} onChange={(e:any) => setFilterForm({...filterForm, status: e.target.value})}>
                  <option value="">All</option>
                  <option value="Active">Active</option>
                  <option value="Draft">Draft</option>
                  <option value="Pending">Pending</option>
                  <option value="Deactivated">Deactivated</option>
                </SelectInput>
              </div>
              <div>
                <Input type="date" label="Last activity" value={filterForm.lastActivity} onChange={(e:any) => setFilterForm({...filterForm, lastActivity: e.target.value})} />
              </div>
            </div>

          </div>

          <div className="w-full fixed bottom-0 p-3 bg-white flex space-x-3 items-center justify-end">
            <button type="button" onClick={() => toggleFormDrawerFilter(false)} className="btn btn-gold">
              Cancel
            </button>
            <button type="submit" className="btn btn-brown">
              Show results
            </button>
          </div>
        </form>
      </FormDrawer>






      <div className="container -mt-16">
        <div className="w-full">
          <div>
            <div className="text-white mb-2 font-bold text-sm">
              Companies
            </div>

            <div className="p-4 bg-white shadow-md rounded-lg">
              <div className="w-full flex justify-between">
                <div className="w-[50%]">
                  
                  <div className="w-max">
                    <TabBarToggle data={[
                      {name: "All", urlKey: "type", urlValue: "", active: (companyParams.type === "" ? true : false)},
                      {name: "Single scheme", urlKey: "type", urlValue: "single-scheme", active: (companyParams.type === "single-scheme" ? true : false)},
                      {name: "Sub-scheme", urlKey: "type", urlValue: "sub-scheme", active: (companyParams.type === "sub-scheme" ? true : false)},
                    ]} />
                  </div>

                </div>

                <div className="w-full flex space-x-4 justify-end">
                  <SearchBar placeholder="Search companies" />
                  <div onClick={() => toggleFormDrawerFilter(true)} className="w-10 h-10 flex flex-shrink-0 rounded-full border border-gray-300 cursor-pointer hover:bg-gray-100">
                    <img src={iconFilter} alt="Filter" className="m-auto" />
                  </div>
                </div>
              </div>
              
              <div>

                <div className="mt-3 table-container">
                  <table className="table table-auto table-rounded table-border">
                      <thead>
                          <tr>
                              <th>Company</th>
                              <th>RC number</th>
                              <th>Type of allocation</th>
                              <th>Date added</th>
                              <th>Status</th>
                              <th>Last activity</th>
                              <th></th>
                          </tr>
                      </thead>
                      <tbody>

                        <TableContent loading={companiesLoading} data={companies} colspan={7} />
                        

                        {companies?.data?.map((company: any, index: number) =>
                          <tr key={index}>
                            <td onClick={() => navigate("/trustee/company/"+(company.id))} className="cursor-pointer">
                              <div className="flex items-center space-x-2">
                                <div className={"w-9 h-9 text-white text-sm text-center flex-shrink-0 flex items-center justify-center rounded-full overflow-hidden " + (company.logoObject !== null ? "bg-black":"bg-vetiva-purple")}>
                                  <Maybe condition={company.logoObject !== null}>
                                    <img src={company.logoObject?.url} alt="company logo" className="object-contain" />
                                  </Maybe>
                                  <Maybe condition={company.logoObject === null}>
                                    {nameInitialsCompanyName(company.name)}
                                  </Maybe>
                                </div>
                                <div className="text-sm">
                                  <div>
                                    {company.name}
                                  </div>
                                  <div className="text-gray-400 -mt-0.5">
                                    {company.admin?.userInfo?.email}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td onClick={() => navigate("/trustee/company/"+(company.id))} className="cursor-pointer">
                              {company.rcNumber}
                            </td>
                            <td onClick={() => navigate("/trustee/company/"+(company.id))} className="cursor-pointer">
                              {NumberFormat(company.organizationShareSetting?.sharesAllocation)}
                            </td>
                            <td onClick={() => navigate("/trustee/company/"+(company.id))} className="cursor-pointer">
                              {DateFormat(company.createdAt)}
                            </td>
                            <td onClick={() => navigate("/trustee/company/"+(company.id))} className="cursor-pointer">
                              <Maybe condition={company.isDisabled === false}>
                                <div className={"label " + renderStatusColor(company.status)}>
                                  {company.status}
                                </div>
                              </Maybe>
                              <Maybe condition={company.isDisabled === true}>
                                <div className={"label " + renderStatusColor("deactivated")}>
                                  Deactivated
                                </div>
                              </Maybe>
                            </td>
                            <td onClick={() => navigate("/trustee/company/"+(company.id))} className="cursor-pointer">
                              {DateFormat(company.updatedAt)}
                            </td>
                            <td>
                              <Menu>
                                <Menu.Button className="w-7 h-7 rounded-sm flex bg-white">
                                  <FontAwesomeIcon icon="ellipsis-v" className="m-auto" />
                                </Menu.Button>
                                <Menu.Items className="dropdown">
                                  <Menu.Item>
                                    <Link to={"/trustee/company/form/"+company.id+"/company-details"} className="dropdown-item flex">
                                      <img src={iconEdit} alt="Edit" className="w-4 h-4 mr-3" />
                                      Edit
                                    </Link>
                                  </Menu.Item>
                                  
                                  <Maybe condition={company.isDisabled === true}>
                                    <Menu.Item>
                                      <div onClick={() => activateCompany(company)} className="dropdown-item flex">
                                        <FontAwesomeIcon icon={["far", "check-circle"]} className="w-4 h-4 mr-3 text-gray-400" />
                                        Activate
                                      </div>
                                    </Menu.Item>
                                  </Maybe>

                                  <Maybe condition={company.isDisabled === false}>
                                    <Menu.Item>
                                      <div onClick={() => deactivateCompany(company)} className="dropdown-item flex">
                                        <img src={iconDisabled} alt="Disable" className="w-4 h-4 mr-3" />
                                        Deactivate
                                      </div>
                                    </Menu.Item>
                                  </Maybe>

                                  <Menu.Item>
                                    <div onClick={() => resendInvite(company)} className="dropdown-item flex">
                                      <img src={iconSendDark} alt="Resend Invite" className="w-4 h-4 mr-3" />
                                      Resend Invite
                                    </div>
                                  </Menu.Item>

                                  {/* 
                                  <Menu.Item>
                                    <div className="dropdown-item flex">
                                      <img src={iconSuspend} alt="Suspend" className="w-4 h-4 mr-3" />
                                      Suspend
                                    </div>
                                  </Menu.Item>
                                   */}
                                </Menu.Items>
                              </Menu>
                            </td>
                          </tr>
                        )}

                      </tbody>
                  </table>
                </div>

                <Pagination meta={companies?.meta} />

              </div>

            </div>
          </div>
        </div>
      </div>



    </div>
  );
}

export default CompanyList;
