import { ISelectOptions } from "data/interfaces";
import { useEffect, useState } from "react";

export interface ButtonGroupSelectorProps {
  selectOptions: ISelectOptions[];
  onValueChange?: any;
  defaultProps?: any;
  allowValueChange?: boolean;
}

export function ButtonGroupSelector(props: ButtonGroupSelectorProps) {
  const { selectOptions, onValueChange, defaultProps, allowValueChange } = props;
  const [items, setItems] = useState(selectOptions);

  const active = "text-vetiva-brown bg-white rounded-md border border-vetiva-brown";
  const inActive = "text-gray-400 bg-gray-100 rounded-md border border-gray-300";

  const handleClick = (data: any, index: number) => {
    if (allowValueChange === undefined || allowValueChange === true){
      setItems(
        items.map((obj, i) => {
          if (index === i) {
            return { ...obj, selected: true };
          }
          return { ...obj, selected: false };
        })
      );
      onValueChange(data)
    }
  }

  useEffect(() => {
    setItems(selectOptions);
  }, [selectOptions])
  

  return (
    <div className="inline-flex flex-wrap">
      {items.map((item, i) => (
        <div key={i}>
          <input {...defaultProps} type="hidden" />
          <button
            onClick={() => handleClick(item?.value, i)}
            type="button"
            className={`mr-4 py-3 px-4 mb-4 md:mb-0 text-sm font-medium ${
              item?.selected ? active : inActive
            }`}
          >
            <svg
              className="inline mr-2 -mt-0.5 fill-inherit"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.246 5.01616L7.00915 11.9039C6.95391 11.9654 6.87872 12 6.80028 12C6.72183 12 6.64664 11.9654 6.5914 11.9039L3.42003 8.40155C3.36433 8.34054 3.33301 8.2575 3.33301 8.17087C3.33301 8.08425 3.36433 8.0012 3.42003 7.9402L3.8319 7.48535C3.88713 7.42384 3.96233 7.38925 4.04077 7.38925C4.11921 7.38925 4.19441 7.42384 4.24965 7.48535L6.79733 10.2989L12.4164 4.09346C12.5328 3.96885 12.7177 3.96885 12.8341 4.09346L13.246 4.55481C13.3017 4.61582 13.333 4.69886 13.333 4.78549C13.333 4.87212 13.3017 4.95516 13.246 5.01616Z"
                fill={item?.selected?"#967843":"#8491A5"}
              />
            </svg>

            {item?.label}
          </button>
        </div>
      ))}
    </div>
  );
}

//hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700

export default ButtonGroupSelector;
