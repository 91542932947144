import * as actions from "./actionTypes";
import { toast } from 'react-toastify';
import errorReducer from "utils/errorReducer";

const initialState = {
    employeesDashboardStatsLoading: false,
    employeesDashboardStats: {},

    employeesLoading: false,
    employees: {},

    employeeLoading: false,
    employee: {},

    employeeSchemeLoading: false,
    employeeScheme: {},

    employeeDashboardLoading: false,
    employeeDashboard: [],

    employeeMetricsLoading: false,
    employeeMetrics: [],

    employeeBenefitStatementLoading: false,
    employeeBenefitStatement: {},

    createEmployeeLoading1: false,
    createEmployee1: {},
    createEmployeeLoading2: false,
    createEmployee2: {},
    createEmployeeLoading3: false,
    createEmployee3: {},
    createEmployeeLoading4: false,
    createEmployee4: {},

    uploadEmployeesLoading: false,
    uploadEmployees: {},

    employeeActivateLoading: false,
    employeeActivateSuccess: false,
    employeeActivateResponse: {},
    employeeDeactivateLoading: false,
    employeeDeactivateSuccess: false,
    employeeDeactivateResponse: {},
    employeeExitLoading: false,
    employeeExitSuccess: false,
    employeeResendInviteLoading: false,
    employeeResendInviteSuccess: false,

    createAdminMgmtLoading: false,
    createAdminMgmt: {},
    editAdminMgmtLoading: false,
    editAdminMgmt: {},

    savedShareTransferInstruction: {},
    shareTransferInstructionLoading: false,
    shareTransferInstruction: {},
    sendShareTransferInstructionLoading: false,
    sendShareTransferInstruction: {},

    savedShareSaleInstruction: {},
    shareSaleInstructionLoading: false,
    shareSaleInstruction: {},
    shareSaleInstructionMultiple: [],
    sendShareSaleInstructionLoading: false,
    sendShareSaleInstruction: {},

    sendEmailBroadcastLoading: false,
    sendEmailBroadcast: {},

    dividendHistoryLoading: false,
    dividendHistory: {},
    shareSalesHistoryLoading: false,
    shareSalesHistory: {},
    shareTransferHistoryLoading: false,
    shareTransferHistory: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_EMPLOYEES_DASHBOARD_STATS:
            state = {
                ...state,
                employeesDashboardStatsLoading: false,
                employeesDashboardStats: { ...action.payload },
            }
            return state;


        case actions.GET_EMPLOYEES_DASHBOARD_STATS_START:
            state = {
                ...state,
                employeesDashboardStatsLoading: true,
                employeesDashboardStats: {},
            }
            return state;


        case actions.GET_EMPLOYEES_DASHBOARD_STATS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeesDashboardStatsLoading: false,
                employeesDashboardStats: { ...action.payload },
            }
            return state;
    

        case actions.GET_EMPLOYEES:
            state = {
                ...state,
                employeesLoading: false,
                employees: { ...action.payload },
            }
            return state;


        case actions.GET_EMPLOYEES_START:
            state = {
                ...state,
                employeesLoading: true,
                employees: {},
            }
            return state;


        case actions.GET_EMPLOYEES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeesLoading: false,
                employees: { ...action.payload },
            }
            return state;
    

        case actions.GET_EMPLOYEE:
            state = {
                ...state,
                employeeLoading: false,
                employee: { ...action.payload },
            }
            return state;


        case actions.GET_EMPLOYEE_START:
            state = {
                ...state,
                employeeLoading: true,
                employee: {},
            }
            return state;


        case actions.GET_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeLoading: false,
                employee: { ...action.payload },
            }
            return state;


        case actions.GET_EMPLOYEE_SCHEME:
            state = {
                ...state,
                employeeSchemeLoading: false,
                employeeScheme: action.payload,
            }
            return state;


        case actions.GET_EMPLOYEE_SCHEME_START:
            state = {
                ...state,
                employeeSchemeLoading: true,
                employeeScheme: {},
            }
            return state;


        case actions.GET_EMPLOYEE_SCHEME_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeSchemeLoading: false,
                employeeScheme: action.payload,
            }
            return state;
    

        case actions.GET_EMPLOYEE_DASHBOARD:
            state = {
                ...state,
                employeeDashboardLoading: false,
                employeeDashboard: action.payload,
            }
            return state;


        case actions.GET_EMPLOYEE_DASHBOARD_START:
            state = {
                ...state,
                employeeDashboardLoading: true,
                employeeDashboard: [],
            }
            return state;


        case actions.GET_EMPLOYEE_DASHBOARD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeDashboardLoading: false,
                employeeDashboard: action.payload,
            }
            return state;
    

        case actions.GET_EMPLOYEE_METRICS:
            state = {
                ...state,
                employeeMetricsLoading: false,
                employeeMetrics: action.payload,
            }
            return state;


        case actions.GET_EMPLOYEE_METRICS_START:
            state = {
                ...state,
                employeeMetricsLoading: true,
                employeeMetrics: [],
            }
            return state;


        case actions.GET_EMPLOYEE_METRICS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeMetricsLoading: false,
                employeeMetrics: action.payload,
            }
            return state;
    

        case actions.GET_EMPLOYEE_BENEFIT_STATEMENT:
            state = {
                ...state,
                employeeBenefitStatementLoading: false,
                employeeBenefitStatement: action.payload,
            }
            return state;


        case actions.GET_EMPLOYEE_BENEFIT_STATEMENT_START:
            state = {
                ...state,
                employeeBenefitStatementLoading: true,
                employeeBenefitStatement: {},
            }
            return state;


        case actions.GET_EMPLOYEE_BENEFIT_STATEMENT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeBenefitStatementLoading: false,
                employeeBenefitStatement: action.payload,
            }
            return state;
    

        case actions.CREATE_EMPLOYEE_STEP_1:
            state = {
                ...state,
                createEmployeeLoading1: false,
                createEmployee1: { ...action.payload },
            }
            return state;


        case actions.CREATE_EMPLOYEE_STEP_1_START:
            state = {
                ...state,
                createEmployeeLoading1: true,
                createEmployee1: {},
            }
            return state;


        case actions.CREATE_EMPLOYEE_STEP_1_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                createEmployeeLoading1: false,
                createEmployee1: { ...action.payload },
            }
            return state;


        case actions.CREATE_EMPLOYEE_STEP_1_RESET:
            state = {
                ...state,
                createEmployeeLoading1: false,
                createEmployee1: {},
            }
            return state;


        case actions.CREATE_EMPLOYEE_STEP_2:
            state = {
                ...state,
                createEmployeeLoading2: false,
                createEmployee2: { ...action.payload },
            }
            return state;


        case actions.CREATE_EMPLOYEE_STEP_2_START:
            state = {
                ...state,
                createEmployeeLoading2: true,
                createEmployee2: {},
            }
            return state;


        case actions.CREATE_EMPLOYEE_STEP_2_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                createEmployeeLoading2: false,
                createEmployee2: { ...action.payload },
            }
            return state;


        case actions.CREATE_EMPLOYEE_STEP_2_RESET:
            state = {
                ...state,
                createEmployeeLoading2: false,
                createEmployee2: {},
            }
            return state;
    

        case actions.CREATE_EMPLOYEE_STEP_3:
            state = {
                ...state,
                createEmployeeLoading3: false,
                createEmployee3: { ...action.payload },
            }
            return state;


        case actions.CREATE_EMPLOYEE_STEP_3_START:
            state = {
                ...state,
                createEmployeeLoading3: true,
                createEmployee3: {},
            }
            return state;


        case actions.CREATE_EMPLOYEE_STEP_3_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                createEmployeeLoading3: false,
                createEmployee3: { ...action.payload },
            }
            return state;


        case actions.CREATE_EMPLOYEE_STEP_3_RESET:
            state = {
                ...state,
                createEmployeeLoading3: false,
                createEmployee3: {},
            }
            return state;
    

        case actions.CREATE_EMPLOYEE_STEP_4:
            state = {
                ...state,
                createEmployeeLoading4: false,
                createEmployee4: { ...action.payload },
            }
            return state;


        case actions.CREATE_EMPLOYEE_STEP_4_START:
            state = {
                ...state,
                createEmployeeLoading4: true,
                createEmployee4: {},
            }
            return state;


        case actions.CREATE_EMPLOYEE_STEP_4_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                createEmployeeLoading4: false,
                createEmployee4: { ...action.payload },
            }
            return state;


        case actions.CREATE_EMPLOYEE_STEP_4_RESET:
            state = {
                ...state,
                createEmployeeLoading4: false,
                createEmployee4: {},
            }
            return state;
    

        case actions.UPLOAD_EMPLOYEE:
            state = {
                ...state,
                uploadEmployeesLoading: false,
                uploadEmployees: action.payload,
            }
            return state;


        case actions.UPLOAD_EMPLOYEE_START:
            state = {
                ...state,
                uploadEmployeesLoading: true,
                uploadEmployees: {},
            }
            return state;


        case actions.UPLOAD_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                uploadEmployeesLoading: false,
                uploadEmployees: action.payload,
            }
            return state;


        case actions.UPLOAD_EMPLOYEE_RESET:
            state = {
                ...state,
                uploadEmployeesLoading: false,
                uploadEmployees: {},
            }
            return state;
    
            
        case actions.ACTIVATE_EMPLOYEE:
            toast.success("Employee(s) Activated");
            // toast.success(action.payload.message);
            state = {
                ...state,
                employeeActivateLoading: false,
                employeeActivateSuccess: true,
                employeeActivateResponse: action.payload,
                reloadPage: true,
            }
            return state;


        case actions.ACTIVATE_EMPLOYEE_START:
            toast("Activating Employee(s)");
            state = {
                ...state,
                employeeActivateLoading: true,
                employeeActivateSuccess: false,
                employeeActivateResponse: {},
            }
            return state;


        case actions.ACTIVATE_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeActivateLoading: false,
                employeeActivateSuccess: false,
                employeeActivateResponse: action.payload,
            }
            return state;


        case actions.ACTIVATE_EMPLOYEE_RESET:
            state = {
                ...state,
                employeeActivateLoading: false,
                employeeActivateSuccess: false,
                employeeActivateResponse: {},
            }
            return state;


        case actions.DEACTIVATE_EMPLOYEE:
            toast.success("Employee(s) Deactivated");
            // toast.success(action.payload.message);
            state = {
                ...state,
                employeeDeactivateLoading: false,
                employeeDeactivateSuccess: true,
                employeeDeactivateResponse: action.payload,
                reloadPage: true,
            }
            return state;


        case actions.DEACTIVATE_EMPLOYEE_START:
            toast("Deactivating Employee(s)");
            state = {
                ...state,
                employeeDeactivateLoading: true,
                employeeDeactivateSuccess: false,
                employeeDeactivateResponse: {},
            }
            return state;


        case actions.DEACTIVATE_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeDeactivateLoading: false,
                employeeDeactivateSuccess: false,
                employeeDeactivateResponse: action.payload,
            }
            return state;
    
        
        case actions.DEACTIVATE_EMPLOYEE_RESET:
            state = {
                ...state,
                employeeDeactivateLoading: false,
                employeeDeactivateSuccess: false,
                employeeDeactivateResponse: {},
            }
            return state;

            
        case actions.EXIT_EMPLOYEE:
            toast.success("Employee Exited");
            // toast.success(action.payload.message);
            state = {
                ...state,
                employeeExitLoading: false,
                employeeExitSuccess: true,
                reloadPage: true,
            }
            return state;


        case actions.EXIT_EMPLOYEE_START:
            toast("Exiting Employee");
            state = {
                ...state,
                employeeExitLoading: true,
                employeeExitSuccess: false,
            }
            return state;


        case actions.EXIT_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeExitLoading: false,
                employeeExitSuccess: false,
            }
            return state;
    

        case actions.RESEND_INVITE_EMPLOYEE:
            toast.success("Invite sent");
            state = {
                ...state,
                employeeResendInviteLoading: false,
                employeeResendInviteSuccess: true,
                reloadPage: true,
            }
            return state;


        case actions.RESEND_INVITE_EMPLOYEE_START:
            toast("Resending invite...");
            state = {
                ...state,
                employeeResendInviteLoading: true,
                employeeResendInviteSuccess: false,
            }
            return state;


        case actions.RESEND_INVITE_EMPLOYEE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                employeeResendInviteLoading: false,
                employeeResendInviteSuccess: false,
            }
            return state;
    
    
        case actions.SHARE_TRANSFER_INSTRUCTION:
            state = {
                ...state,
                shareTransferInstructionLoading: false,
                shareTransferInstruction: action.payload,
            }
            return state;


        case actions.SHARE_TRANSFER_INSTRUCTION_START:
            state = {
                ...state,
                shareTransferInstructionLoading: true,
                shareTransferInstruction: {},
            }
            return state;


        case actions.SHARE_TRANSFER_INSTRUCTION_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                shareTransferInstructionLoading: false,
                shareTransferInstruction: {},
            }
            return state;


        case actions.SAVE_SHARE_TRANSFER_INSTRUCTION:
            state = {
                ...state,
                savedShareTransferInstruction: action.payload,
            }
            return state;

            
        case actions.SEND_SHARE_TRANSFER_INSTRUCTION:
            toast.success("Share transfer instruction sent successfully");
            state = {
                ...state,
                sendShareTransferInstructionLoading: false,
                sendShareTransferInstruction: action.payload,
            }
            return state;


        case actions.SEND_SHARE_TRANSFER_INSTRUCTION_START:
            state = {
                ...state,
                sendShareTransferInstructionLoading: true,
                sendShareTransferInstruction: {},
            }
            return state;


        case actions.SEND_SHARE_TRANSFER_INSTRUCTION_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                sendShareTransferInstructionLoading: false,
                sendShareTransferInstruction: action.payload,
            }
            return state;


        case actions.SEND_SHARE_TRANSFER_INSTRUCTION_RESET:
            state = {
                ...state,
                sendShareTransferInstructionLoading: false,
                sendShareTransferInstruction: {},
            }
            return state;


        case actions.SHARE_SALE_INSTRUCTION:
            state = {
                ...state,
                shareSaleInstructionLoading: false,
                shareSaleInstruction: action.payload,
            }
            return state;


        case actions.SHARE_SALE_INSTRUCTION_START:
            state = {
                ...state,
                shareSaleInstructionLoading: true,
                shareSaleInstruction: {},
            }
            return state;


        case actions.SHARE_SALE_INSTRUCTION_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                shareSaleInstructionLoading: false,
                shareSaleInstruction: {},
            }
            return state;
        
    
        case actions.SHARE_SALE_INSTRUCTION_MULTIPLE:
            state = {
                ...state,
                shareSaleInstructionMultiple: [
                    ...state.shareSaleInstructionMultiple,
                    action.payload,
                ]
            }
            return state;


        case actions.SHARE_SALE_INSTRUCTION_MULTIPLE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                shareSaleInstructionMultiple: [
                    ...state.shareSaleInstructionMultiple,
                    action.payload,
                ]
            }
            return state;


        case actions.SHARE_SALE_INSTRUCTION_MULTIPLE_RESET:
            state = {
                ...state,
                shareSaleInstructionMultiple: [],
            }
            return state;
    

        case actions.SAVE_SHARE_SALE_INSTRUCTION:
            state = {
                ...state,
                savedShareSaleInstruction: action.payload,
            }
            return state;

    
        case actions.SEND_SHARE_SALE_INSTRUCTION:
            toast.success("Share sale instruction sent successfully");
            state = {
                ...state,
                sendShareSaleInstructionLoading: false,
                sendShareSaleInstruction: action.payload,
            }
            return state;


        case actions.SEND_SHARE_SALE_INSTRUCTION_START:
            state = {
                ...state,
                sendShareSaleInstructionLoading: true,
                sendShareSaleInstruction: {},
            }
            return state;


        case actions.SEND_SHARE_SALE_INSTRUCTION_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                sendShareSaleInstructionLoading: false,
                sendShareSaleInstruction: action.payload,
            }
            return state;
    

        case actions.SEND_SHARE_SALE_INSTRUCTION_RESET:
            state = {
                ...state,
                sendShareSaleInstructionLoading: false,
                sendShareSaleInstruction: {},
            }
            return state;

    
        case actions.SEND_EMAIL_BROADCAST:
            state = {
                ...state,
                sendEmailBroadcastLoading: false,
                sendEmailBroadcast: action.payload,
            }
            return state;


        case actions.SEND_EMAIL_BROADCAST_START:
            state = {
                ...state,
                sendEmailBroadcastLoading: true,
                sendEmailBroadcast: {},
            }
            return state;


        case actions.SEND_EMAIL_BROADCAST_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                sendEmailBroadcastLoading: false,
                sendEmailBroadcast: action.payload,
            }
            return state;
    

        case actions.SEND_EMAIL_BROADCAST_RESET:
            state = {
                ...state,
                sendEmailBroadcastLoading: false,
                sendEmailBroadcast: {},
            }
            return state;


        case actions.GET_DIVIDEND_HISTORY:
            state = {
                ...state,
                dividendHistoryLoading: false,
                dividendHistory: action.payload,
            }
            return state;


        case actions.GET_DIVIDEND_HISTORY_START:
            state = {
                ...state,
                dividendHistoryLoading: true,
                dividendHistory: {},
            }
            return state;


        case actions.GET_DIVIDEND_HISTORY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                dividendHistoryLoading: false,
                dividendHistory: action.payload,
            }
            return state;


        case actions.GET_SHARE_SALES_HISTORY:
            state = {
                ...state,
                shareSalesHistoryLoading: false,
                shareSalesHistory: action.payload,
            }
            return state;


        case actions.GET_SHARE_SALES_HISTORY_START:
            state = {
                ...state,
                shareSalesHistoryLoading: true,
                shareSalesHistory: {},
            }
            return state;


        case actions.GET_SHARE_SALES_HISTORY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                shareSalesHistoryLoading: false,
                shareSalesHistory: action.payload,
            }
            return state;


        case actions.GET_SHARE_TRANSFER_HISTORY:
            state = {
                ...state,
                shareTransferHistoryLoading: false,
                shareTransferHistory: action.payload,
            }
            return state;


        case actions.GET_SHARE_TRANSFER_HISTORY_START:
            state = {
                ...state,
                shareTransferHistoryLoading: true,
                shareTransferHistory: {},
            }
            return state;


        case actions.GET_SHARE_TRANSFER_HISTORY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                shareTransferHistoryLoading: false,
                shareTransferHistory: action.payload,
            }
            return state;


        case actions.CREATE_ADMINMGMT:
            state = {
                ...state,
                createAdminMgmtLoading: false,
                createAdminMgmt: { ...action.payload },
            }
            return state;


        case actions.CREATE_ADMINMGMT_START:
            state = {
                ...state,
                createAdminMgmtLoading: true,
                createAdminMgmt: {},
            }
            return state;


        case actions.CREATE_ADMINMGMT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                createAdminMgmtLoading: false,
                createAdminMgmt: { ...action.payload },
            }
            return state;


        case actions.CREATE_ADMINMGMT_RESET:
            state = {
                ...state,
                createAdminMgmtLoading: false,
                createAdminMgmt: {},
            }
            return state;


        case actions.EDIT_ADMINMGMT:
            toast.success("Admin Updated");
            state = {
                ...state,
                editAdminMgmtLoading: false,
                editAdminMgmt: { ...action.payload },
            }
            return state;
            
        
        case actions.EDIT_ADMINMGMT_START:
            state = {
                ...state,
                editAdminMgmtLoading: true,
                editAdminMgmt: {},
            }
            return state;
            
            
        case actions.EDIT_ADMINMGMT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                editAdminMgmtLoading: false,
                editAdminMgmt: { ...action.payload },
            }
            return state;

            
        default:
            return state;
    }
}