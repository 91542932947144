import ReactPDF, { PDFViewer } from '@react-pdf/renderer';
import { Document, Page, Text, View, Font, Link, StyleSheet } from '@react-pdf/renderer';
// import { Link } from "react-router-dom";
// import logo from "assets/images/icons/logo.svg";




export const StatementOfBeneficialInterestSample = () => {

    // const download = () => {
    //     ReactPDF.render(<MyDocument />, `./example.pdf`);
    // }

    return (
        <div>

            {/* 
            <div className="fixed top-0 left-0 right-0 z-20 text-white bg-vetiva-black border-b border-white border-opacity-5">
                <div className="container w-full h-16 flex justify-between items-center">
                    <Link to="/" className="flex items-center">
                        <div className="w-8 h-8 mr-1">
                            <img src={logo} alt="Vetiva" className="w-full h-full object-contain" />
                        </div>
                        <div className="text-lg font-normal font-noto leading-4">
                            VTL Staff Share Scheme
                        </div>
                    </Link>
                    
                    <div className="flex items-center gap-5 relative">
                        <button type="button" onClick={download} className="btn btn-vetiva-brown">Download</button>
                        
                    </div>
                </div>
            </div>
            */}

            <PDFViewer className="w-full min-h-screen">
                <MyDocument />
            </PDFViewer>

        </div>
    )
}





Font.register({
    family: 'Lato',
    src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
    family: 'Lato Italic',
    src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
    family: 'Lato Bold',
    src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});
    
const styles = StyleSheet.create({
    page: {
        // width: "700px",
        fontFamily: "Lato",
        fontSize: "9px",
        padding: "30px 0",
        backgroundColor: '#FFFFFF',
    },
    row: {
        width: "100%",
        flexDirection: "row",
        flexGrow: 1
    },
    column: {
        flexGrow: 1,
        height: '100%',
        flexDirection: 'column',
    },
    tableCell: {
        padding: "5px 10px",
    },

    date: {
        padding: "0 30px",
        fontFamily: "Lato Bold",
        textAlign: "right",
    },
    title: {
        padding: "5px",
        marginTop: "5px",
        color: "#FFFFFF",
        fontFamily: "Lato Bold",
        textAlign: "center",
        backgroundColor: "#767070",
    },
    columnLayout: {
        marginTop: "20px",
        padding: "0 30px",
        flexDirection: "row",
    },
    sectionFlexGrow: {
        paddingRight: "20px",
        flexGrow: 1,
    },
    userInfoData: {
        width: "25%",
        paddingRight: "7%",
        flexShrink: 0,
    },
    sectionFlexShrink: {
        width: "300px",
        // margin: 10,
        // padding: 10,
        flexShrink: 0,
    },
    footer: {
        padding: "20px 30px 0",
        marginTop: "20px",
        textAlign: "center",
        fontFamily: 'Lato Italic',
        borderTop: "2px solid #000",
    },
});


const MyDocument = () => {
    const title = "Statement of Beneficial Interest";
    const author = "Vetiva";
    const subject = "Employee name - Statement of Beneficial Interest";

    return (
        <Document title={title} author={author} subject={subject}>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.date}>
                    <Text>Date: 26 August 2022</Text>
                </View>

                <View style={styles.title}>
                    <Text>Statement of Beneficial Interest</Text>
                </View>

                <View style={styles.columnLayout}>
                    <View style={styles.sectionFlexGrow}>
                        <View style={{width: "100%"}}>

                            <View style={styles.row}>
                                <View style={styles.userInfoData}>
                                    <Text style={{marginBottom: "5px", fontFamily: "Lato Bold"}}>Surname:</Text>
                                    <Text>XXXXXXXXXXX</Text>
                                </View>
                                <View style={styles.userInfoData}>
                                    <Text style={{marginBottom: "5px", fontFamily: "Lato Bold"}}>First name:</Text>
                                    <Text>XXXXXXXXXXX</Text>
                                </View>
                                <View style={styles.userInfoData}>
                                    <Text style={{marginBottom: "5px", fontFamily: "Lato Bold"}}>Staff ID:</Text>
                                    <Text>XXXXXXXXXXX</Text>
                                </View>
                                <View style={styles.userInfoData}>
                                    <Text style={{marginBottom: "5px", fontFamily: "Lato Bold"}}>Allocation Date:</Text>
                                    <Text>xx/xx/xxxx</Text>
                                </View>
                            </View>

                            <View style={{...styles.row, marginTop: "20px"}}>
                                <View style={styles.userInfoData}>
                                    <Text style={{marginBottom: "5px", fontFamily: "Lato Bold"}}>Email address:</Text>
                                    <Text style={{marginBottom: "5px", fontFamily: "Lato Bold"}}>Mobile No:</Text>
                                </View>
                                <View>
                                    <Text style={{marginBottom: "5px"}}>xxxxxxx</Text>
                                    <Text style={{marginBottom: "5px"}}>xxxxxxx</Text>
                                </View>
                            </View>

                            <View style={{marginTop: "20px", border: "2px solid #000"}}>
                                <View style={{...styles.row, fontFamily: "Lato Bold"}}>
                                    <View style={{...styles.tableCell, width: "25%"}}>
                                        <Text></Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>Initial Allocation</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>Bonus shares</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>Shares sold to date</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>Current balance</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>Value at date</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={{...styles.tableCell, width: "25%", fontFamily: "Lato Bold"}}>
                                        <Text>Sub-Scheme 1 Allocation</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={{...styles.tableCell, width: "25%", fontFamily: "Lato Bold"}}>
                                        <Text>Sub-Scheme 1 Allocation</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                </View>
                                <View style={{...styles.row, fontFamily: "Lato Bold"}}>
                                    <View style={{...styles.tableCell, width: "25%"}}>
                                        <Text>Total</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                </View>
                            </View>

                            <View style={{...styles.columnLayout, marginTop: "20px", padding: "0"}}>
                                <View style={{...styles.sectionFlexGrow, width: "50%"}}>

                                    <View style={{fontFamily: "Lato Bold"}}>
                                        <Text>Details of Next of Kin:</Text>
                                    </View>

                                    <View style={{marginTop: "10px"}}>
                                        <View style={styles.row}>
                                            <View style={{...styles.userInfoData, width: "40%"}}>
                                                <Text style={{marginBottom: "5px"}}>Name:</Text>
                                            </View>
                                            <View>
                                                <Text style={{marginBottom: "5px"}}>xxxxxxx</Text>
                                            </View>
                                        </View>
                                        <View style={styles.row}>
                                            <View style={{...styles.userInfoData, width: "40%"}}>
                                                <Text style={{marginBottom: "5px"}}>Contact Address:</Text>
                                            </View>
                                            <View>
                                                <Text style={{marginBottom: "5px"}}>xxxxxxx</Text>
                                            </View>
                                        </View>
                                        <View style={styles.row}>
                                            <View style={{...styles.userInfoData, width: "40%"}}>
                                                <Text style={{marginBottom: "5px"}}>Mobile No:</Text>
                                            </View>
                                            <View>
                                                <Text style={{marginBottom: "5px"}}>xxxxxxx</Text>
                                            </View>
                                        </View>
                                        <View style={styles.row}>
                                            <View style={{...styles.userInfoData, width: "40%"}}>
                                                <Text style={{marginBottom: "5px"}}>Email address:</Text>
                                            </View>
                                            <View>
                                                <Text style={{marginBottom: "5px"}}>xxxxxxx</Text>
                                            </View>
                                        </View>
                                    </View>

                                </View>
                                <View style={styles.sectionFlexShrink}>

                                    <View style={{fontFamily: "Lato Bold"}}>
                                        <Text>Bank Details:</Text>
                                    </View>

                                    <View style={{marginTop: "10px"}}>
                                        <View style={styles.row}>
                                            <View style={{...styles.userInfoData, width: "40%"}}>
                                                <Text style={{marginBottom: "5px"}}>Bank Name:</Text>
                                            </View>
                                            <View>
                                                <Text style={{marginBottom: "5px"}}>xxxxxxx</Text>
                                            </View>
                                        </View>
                                        <View style={styles.row}>
                                            <View style={{...styles.userInfoData, width: "40%"}}>
                                                <Text style={{marginBottom: "5px"}}>Account Number:</Text>
                                            </View>
                                            <View>
                                                <Text style={{marginBottom: "5px"}}>xxxxxxx</Text>
                                            </View>
                                        </View>
                                    </View>

                                </View>
                            </View>

                        </View>
                    </View>
                    <View style={styles.sectionFlexShrink}>
                        <View style={{width: "100%"}}>
                            <View style={{border: "2px solid #000"}}>
                                <View style={{...styles.title, margin: 0, color: "#000", backgroundColor: "#dbdbdb", fontFamily: "Lato Bold"}}>
                                    <Text>Dividends History</Text>
                                </View>
                                <View style={{...styles.row, fontFamily: "Lato Bold"}}>
                                    <View style={{...styles.tableCell, width: "33%"}}>
                                        <Text>Date</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                        <Text>DPS</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                        <Text>Div. Paid (N)</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={{...styles.tableCell, width: "33%"}}>
                                        <Text>Nov 2021</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={{...styles.tableCell, width: "33%"}}>
                                        <Text>Nov 2021</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={{...styles.tableCell, width: "33%"}}>
                                        <Text>Nov 2021</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={{...styles.tableCell, width: "33%"}}>
                                        <Text>Nov 2021</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={{...styles.tableCell, width: "33%"}}>
                                        <Text>Nov 2021</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={{...styles.tableCell, width: "33%"}}>
                                        <Text>Nov 2021</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                </View>
                                <View style={{...styles.row, fontFamily: "Lato Bold"}}>
                                    <View style={{...styles.tableCell, width: "33%"}}>
                                        <Text></Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                        <Text>Total</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                </View>
                            </View>

                            <View style={{marginTop: "20px", border: "2px solid #000"}}>
                                <View style={{...styles.title, margin: 0, color: "#000", backgroundColor: "#dbdbdb", fontFamily: "Lato Bold"}}>
                                    <Text>Share Sale History</Text>
                                </View>
                                <View style={styles.row}>
                                    <View style={{...styles.tableCell, width: "33%"}}>
                                        <Text>[date] Share sale I</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={{...styles.tableCell, width: "33%"}}>
                                        <Text>[date] Share sale II</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={{...styles.tableCell, width: "33%"}}>
                                        <Text>[date] Share sale III</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                        <Text>xxxx</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.footer}>
                    <Text>
                        Disclosure/Disclaimer: All information is provided as at <Text>26 August 2022</Text>. Kindly advise all exceptions to Vetiva immeidiately. All changes in email or physical address should be promptly advised to <Link src="mailto:trustees@vetiva.com">trustees@vetiva.com</Link>
                    </Text>
                </View>
            </Page>
        </Document>
    )
}



export default StatementOfBeneficialInterestSample;
