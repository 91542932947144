import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PDF from "./pdf";
import Maybe from "components/Maybe";
import { PageContent } from "components/PageContent";
import * as companyAction from "store/entities/company/action";
import * as employeeAction from "store/entities/employee/action";


export const StatementOfBeneficialInterest = () => {
    let { companyId, employeeId } = useParams();
    const dispatch = useDispatch();

    const { companyLoading, company } = useSelector((s: any) => s.entities.company);
    const { employeeBenefitStatementLoading, employeeBenefitStatement, employeeSchemeLoading, employeeScheme } = useSelector((s: any) => s.entities.employee);
    
    const getCompany = () => {
        dispatch(companyAction.getCompany(companyId));
    }

    const getEmployee = () => {
        dispatch(employeeAction.getEmployeeBenefitStatement(employeeId));
        dispatch(employeeAction.getEmployeeScheme(employeeId));
    }

    const getLoading = () => {
        if (companyLoading || employeeBenefitStatementLoading || employeeSchemeLoading){
            return true;
        }
        return false;
    }

    useEffect(() => {
        getCompany();
        getEmployee();
    }, []);


    return (
        <>

            <Maybe condition={getLoading() === true}>
                <div className="min-h-screen flex justify-center items-center bg-white">
                    <PageContent loading={getLoading()} data={employeeBenefitStatement || undefined} loadingText="Loading document" emptyText="Data not found" />
                </div>
            </Maybe>

            <Maybe condition={getLoading() === false}>
                <PDF
                    company={company}
                    employeeScheme={employeeScheme}
                    employeeBenefitStatement={employeeBenefitStatement}
                />
            </Maybe>

        </>
    )
}




export default StatementOfBeneficialInterest;
