import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import IconCheck from "assets/images/icons/new-password-success.gif";

const ActivateAccountSuccess = () => {
    
    const [searchParams] = useSearchParams();
    const [userType] = useState(searchParams.get("userType") || "");
    const [subdomain] = useState(searchParams.get("subdomain") || "");
    const [loginRoute, setLoginRoute] = useState("/login");


    useEffect(() => {
        let newLoginRoute = loginRoute;
        if (userType === "trustee"){
            newLoginRoute = "/trustee/login";
        }
        if (subdomain !== ""){
            newLoginRoute += "?subdomain=" + subdomain;
        }
        setLoginRoute(newLoginRoute)
    }, [])


    return (
        <div className="max-w-md px-8 pb-10 pt-32 m-auto text-center">

            <div className="h-32 md:h-48 -mt-10">
                <img src={IconCheck} alt="Vetiva success" className="h-full object-contain mx-auto" />
            </div>
            <div className="mt-4 font-semibold text-2xl">
                Account activated
            </div>
            <div className="mt-2 text-gray-500">
                You can now login to your account using your email address and your new password.
            </div>

            <div className="mt-8 flex flex-col space-y-2">
                <Link to={loginRoute} className="btn btn-block btn-lg btn-vetiva-brown">
                    Back to Login
                </Link>
            </div>

        </div>
    )
}

export default ActivateAccountSuccess;
