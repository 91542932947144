
export const GET_EMPLOYEES_DASHBOARD_STATS = "GET_EMPLOYEES_DASHBOARD_STATS";
export const GET_EMPLOYEES_DASHBOARD_STATS_START = "GET_EMPLOYEES_DASHBOARD_STATS_START";
export const GET_EMPLOYEES_DASHBOARD_STATS_FAILED = "GET_EMPLOYEES_DASHBOARD_STATS_FAILED";

export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_EMPLOYEES_START = "GET_EMPLOYEES_START";
export const GET_EMPLOYEES_FAILED = "GET_EMPLOYEES_FAILED";

export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const GET_EMPLOYEE_START = "GET_EMPLOYEE_START";
export const GET_EMPLOYEE_FAILED = "GET_EMPLOYEE_FAILED";

export const GET_EMPLOYEE_SCHEME = "GET_EMPLOYEE_SCHEME";
export const GET_EMPLOYEE_SCHEME_START = "GET_EMPLOYEE_SCHEME_START";
export const GET_EMPLOYEE_SCHEME_FAILED = "GET_EMPLOYEE_SCHEME_FAILED";

export const GET_EMPLOYEE_DASHBOARD = "GET_EMPLOYEE_DASHBOARD";
export const GET_EMPLOYEE_DASHBOARD_START = "GET_EMPLOYEE_DASHBOARD_START";
export const GET_EMPLOYEE_DASHBOARD_FAILED = "GET_EMPLOYEE_DASHBOARD_FAILED";

export const GET_EMPLOYEE_METRICS = "GET_EMPLOYEE_METRICS";
export const GET_EMPLOYEE_METRICS_START = "GET_EMPLOYEE_METRICS_START";
export const GET_EMPLOYEE_METRICS_FAILED = "GET_EMPLOYEE_METRICS_FAILED";

export const GET_EMPLOYEE_BENEFIT_STATEMENT = "GET_EMPLOYEE_BENEFIT_STATEMENT";
export const GET_EMPLOYEE_BENEFIT_STATEMENT_START = "GET_EMPLOYEE_BENEFIT_STATEMENT_START";
export const GET_EMPLOYEE_BENEFIT_STATEMENT_FAILED = "GET_EMPLOYEE_BENEFIT_STATEMENT_FAILED";

export const CREATE_EMPLOYEE_STEP_1 = "CREATE_EMPLOYEE_STEP_1";
export const CREATE_EMPLOYEE_STEP_1_START = "CREATE_EMPLOYEE_STEP_1_START";
export const CREATE_EMPLOYEE_STEP_1_FAILED = "CREATE_EMPLOYEE_STEP_1_FAILED";
export const CREATE_EMPLOYEE_STEP_1_RESET = "CREATE_EMPLOYEE_STEP_1_RESET";

export const CREATE_EMPLOYEE_STEP_2 = "CREATE_EMPLOYEE_STEP_2";
export const CREATE_EMPLOYEE_STEP_2_START = "CREATE_EMPLOYEE_STEP_2_START";
export const CREATE_EMPLOYEE_STEP_2_FAILED = "CREATE_EMPLOYEE_STEP_2_FAILED";
export const CREATE_EMPLOYEE_STEP_2_RESET = "CREATE_EMPLOYEE_STEP_2_RESET";

export const CREATE_EMPLOYEE_STEP_3 = "CREATE_EMPLOYEE_STEP_3";
export const CREATE_EMPLOYEE_STEP_3_START = "CREATE_EMPLOYEE_STEP_3_START";
export const CREATE_EMPLOYEE_STEP_3_FAILED = "CREATE_EMPLOYEE_STEP_3_FAILED";
export const CREATE_EMPLOYEE_STEP_3_RESET = "CREATE_EMPLOYEE_STEP_3_RESET";

export const CREATE_EMPLOYEE_STEP_4 = "CREATE_EMPLOYEE_STEP_4";
export const CREATE_EMPLOYEE_STEP_4_START = "CREATE_EMPLOYEE_STEP_4_START";
export const CREATE_EMPLOYEE_STEP_4_FAILED = "CREATE_EMPLOYEE_STEP_4_FAILED";
export const CREATE_EMPLOYEE_STEP_4_RESET = "CREATE_EMPLOYEE_STEP_4_RESET";

export const UPLOAD_EMPLOYEE = "UPLOAD_EMPLOYEE";
export const UPLOAD_EMPLOYEE_START = "UPLOAD_EMPLOYEE_START";
export const UPLOAD_EMPLOYEE_FAILED = "UPLOAD_EMPLOYEE_FAILED";
export const UPLOAD_EMPLOYEE_RESET = "UPLOAD_EMPLOYEE_RESET";

export const ACTIVATE_EMPLOYEE = "ACTIVATE_EMPLOYEE";
export const ACTIVATE_EMPLOYEE_START = "ACTIVATE_EMPLOYEE_START";
export const ACTIVATE_EMPLOYEE_FAILED = "ACTIVATE_EMPLOYEE_FAILED";
export const ACTIVATE_EMPLOYEE_RESET = "ACTIVATE_EMPLOYEE_RESET";

export const DEACTIVATE_EMPLOYEE = "DEACTIVATE_EMPLOYEE";
export const DEACTIVATE_EMPLOYEE_START = "DEACTIVATE_EMPLOYEE_START";
export const DEACTIVATE_EMPLOYEE_FAILED = "DEACTIVATE_EMPLOYEE_FAILED";
export const DEACTIVATE_EMPLOYEE_RESET = "DEACTIVATE_EMPLOYEE_RESET";

export const EXIT_EMPLOYEE = "EXIT_EMPLOYEE";
export const EXIT_EMPLOYEE_START = "EXIT_EMPLOYEE_START";
export const EXIT_EMPLOYEE_FAILED = "EXIT_EMPLOYEE_FAILED";

export const RESEND_INVITE_EMPLOYEE = "RESEND_INVITE_EMPLOYEE";
export const RESEND_INVITE_EMPLOYEE_START = "RESEND_INVITE_EMPLOYEE_START";
export const RESEND_INVITE_EMPLOYEE_FAILED = "RESEND_INVITE_EMPLOYEE_FAILED";

export const SHARE_TRANSFER_INSTRUCTION = "SHARE_TRANSFER_INSTRUCTION";
export const SHARE_TRANSFER_INSTRUCTION_START = "SHARE_TRANSFER_INSTRUCTION_START";
export const SHARE_TRANSFER_INSTRUCTION_FAILED = "SHARE_TRANSFER_INSTRUCTION_FAILED";

export const SAVE_SHARE_TRANSFER_INSTRUCTION = "SAVE_SHARE_TRANSFER_INSTRUCTION";
export const SEND_SHARE_TRANSFER_INSTRUCTION = "SEND_SHARE_TRANSFER_INSTRUCTION";
export const SEND_SHARE_TRANSFER_INSTRUCTION_START = "SEND_SHARE_TRANSFER_INSTRUCTION_START";
export const SEND_SHARE_TRANSFER_INSTRUCTION_FAILED = "SEND_SHARE_TRANSFER_INSTRUCTION_FAILED";
export const SEND_SHARE_TRANSFER_INSTRUCTION_RESET = "SEND_SHARE_TRANSFER_INSTRUCTION_RESET";

export const SHARE_SALE_INSTRUCTION = "SHARE_SALE_INSTRUCTION";
export const SHARE_SALE_INSTRUCTION_START = "SHARE_SALE_INSTRUCTION_START";
export const SHARE_SALE_INSTRUCTION_FAILED = "SHARE_SALE_INSTRUCTION_FAILED";

export const SHARE_SALE_INSTRUCTION_MULTIPLE = "SHARE_SALE_INSTRUCTION_MULTIPLE";
export const SHARE_SALE_INSTRUCTION_MULTIPLE_FAILED = "SHARE_SALE_INSTRUCTION_MULTIPLE_FAILED";
export const SHARE_SALE_INSTRUCTION_MULTIPLE_RESET = "SHARE_SALE_INSTRUCTION_MULTIPLE_RESET";

export const SAVE_SHARE_SALE_INSTRUCTION = "SAVE_SHARE_SALE_INSTRUCTION";
export const SEND_SHARE_SALE_INSTRUCTION = "SEND_SHARE_SALE_INSTRUCTION";
export const SEND_SHARE_SALE_INSTRUCTION_START = "SEND_SHARE_SALE_INSTRUCTION_START";
export const SEND_SHARE_SALE_INSTRUCTION_FAILED = "SEND_SHARE_SALE_INSTRUCTION_FAILED";
export const SEND_SHARE_SALE_INSTRUCTION_RESET = "SEND_SHARE_SALE_INSTRUCTION_RESET";

export const SEND_EMAIL_BROADCAST = "SEND_EMAIL_BROADCAST";
export const SEND_EMAIL_BROADCAST_START = "SEND_EMAIL_BROADCAST_START";
export const SEND_EMAIL_BROADCAST_FAILED = "SEND_EMAIL_BROADCAST_FAILED";
export const SEND_EMAIL_BROADCAST_RESET = "SEND_EMAIL_BROADCAST_RESET";

export const GET_DIVIDEND_HISTORY = "GET_DIVIDEND_HISTORY";
export const GET_DIVIDEND_HISTORY_START = "GET_DIVIDEND_HISTORY_START";
export const GET_DIVIDEND_HISTORY_FAILED = "GET_DIVIDEND_HISTORY_FAILED";

export const GET_SHARE_SALES_HISTORY = "GET_SHARE_SALES_HISTORY";
export const GET_SHARE_SALES_HISTORY_START = "GET_SHARE_SALES_HISTORY_START";
export const GET_SHARE_SALES_HISTORY_FAILED = "GET_SHARE_SALES_HISTORY_FAILED";

export const GET_SHARE_TRANSFER_HISTORY = "GET_SHARE_TRANSFER_HISTORY";
export const GET_SHARE_TRANSFER_HISTORY_START = "GET_SHARE_TRANSFER_HISTORY_START";
export const GET_SHARE_TRANSFER_HISTORY_FAILED = "GET_SHARE_TRANSFER_HISTORY_FAILED";

export const CREATE_ADMINMGMT = "CREATE_ADMINMGMT";
export const CREATE_ADMINMGMT_START = "CREATE_ADMINMGMT_START";
export const CREATE_ADMINMGMT_FAILED = "CREATE_ADMINMGMT_FAILED";
export const CREATE_ADMINMGMT_RESET = "CREATE_ADMINMGMT_RESET";

export const EDIT_ADMINMGMT = "EDIT_ADMINMGMT";
export const EDIT_ADMINMGMT_START = "EDIT_ADMINMGMT_START";
export const EDIT_ADMINMGMT_FAILED = "EDIT_ADMINMGMT_FAILED";
export const EDIT_ADMINMGMT_RESET = "EDIT_ADMINMGMT_RESET";
