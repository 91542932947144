import * as actions from "./actionTypes";
import { toast } from 'react-toastify';
import errorReducer from "utils/errorReducer";

const initialState = {
    companiesDashboardStatsLoading: false,
    companiesDashboardStats: {},

    companiesLoading: false,
    companies: {},

    companyLoading: false,
    company: {},

    createCompanyLoading1: false,
    createCompany1: {},
    createCompanyLoading2: false,
    createCompany2: {},
    createCompanyLoading3: false,
    createCompany3: {},
    createCompanyLoading31: false,
    createCompany31: {},

    companyActivateLoading: false,
    companyActivateSuccess: false,
    companyDeactivateLoading: false,
    companyDeactivateSuccess: false,
    companyResendInviteLoading: false,
    companyResendInviteSuccess: false,

    companyEmployeeDashboardStatsLoading: false,
    companyEmployeeDashboardStats: {},
    adminsLoading: false,
    admins: {},
    companyAdminDashboardStatsLoading: false,
    companyAdminDashboardStats: {},

    companyUploadsLoading: false,
    companyUploads: {},
    companyUploadSalesLoading: false,
    companyUploadSales: {},
    companyUploadBonusLoading: false,
    companyUploadBonus: {},
    companyUploadDividendLoading: false,
    companyUploadDividend: {},
    companyUploadTransferLoading: false,
    companyUploadTransfer: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_COMPANIES_DASHBOARD_STATS:
            state = {
                ...state,
                companiesDashboardStatsLoading: false,
                companiesDashboardStats: action.payload,
            }
            return state;


        case actions.GET_COMPANIES_DASHBOARD_STATS_START:
            state = {
                ...state,
                companiesDashboardStatsLoading: true,
                companiesDashboardStats: {},
            }
            return state;


        case actions.GET_COMPANIES_DASHBOARD_STATS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                companiesDashboardStatsLoading: false,
                companiesDashboardStats: action.payload,
            }
            return state;
    

        case actions.GET_COMPANIES:
            state = {
                ...state,
                companiesLoading: false,
                companies: action.payload,
            }
            return state;


        case actions.GET_COMPANIES_START:
            state = {
                ...state,
                companiesLoading: true,
                companies: {},
            }
            return state;


        case actions.GET_COMPANIES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                companiesLoading: false,
                companies: action.payload,
            }
            return state;
    

        case actions.GET_COMPANY:
            state = {
                ...state,
                companyLoading: false,
                company: action.payload,
            }
            return state;


        case actions.GET_COMPANY_START:
            state = {
                ...state,
                companyLoading: true,
                company: {},
            }
            return state;


        case actions.GET_COMPANY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                companyLoading: false,
                company: action.payload,
            }
            return state;
    

        case actions.CREATE_COMPANY_STEP_1:
            state = {
                ...state,
                createCompanyLoading1: false,
                createCompany1: action.payload,
            }
            return state;


        case actions.CREATE_COMPANY_STEP_1_START:
            state = {
                ...state,
                createCompanyLoading1: true,
                createCompany1: {},
            }
            return state;


        case actions.CREATE_COMPANY_STEP_1_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                createCompanyLoading1: false,
                createCompany1: action.payload,
            }
            return state;


        case actions.CREATE_COMPANY_STEP_1_RESET:
            state = {
                ...state,
                createCompanyLoading1: false,
                createCompany1: {},
            }
            return state;


        case actions.CREATE_COMPANY_STEP_2:
            state = {
                ...state,
                createCompanyLoading2: false,
                createCompany2: action.payload,
            }
            return state;


        case actions.CREATE_COMPANY_STEP_2_START:
            state = {
                ...state,
                createCompanyLoading2: true,
                createCompany2: {},
            }
            return state;


        case actions.CREATE_COMPANY_STEP_2_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                createCompanyLoading2: false,
                createCompany2: action.payload,
            }
            return state;


        case actions.CREATE_COMPANY_STEP_2_RESET:
            state = {
                ...state,
                createCompanyLoading2: false,
                createCompany2: {},
            }
            return state;
    

        case actions.CREATE_COMPANY_STEP_3:
            state = {
                ...state,
                createCompanyLoading3: false,
                createCompany3: action.payload,
            }
            return state;


        case actions.CREATE_COMPANY_STEP_3_START:
            state = {
                ...state,
                createCompanyLoading3: true,
                createCompany3: {},
            }
            return state;


        case actions.CREATE_COMPANY_STEP_3_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                createCompanyLoading3: false,
                createCompany3: action.payload,
            }
            return state;


        case actions.CREATE_COMPANY_STEP_3_RESET:
            state = {
                ...state,
                createCompanyLoading3: false,
                createCompany3: {},
            }
            return state;
    

        case actions.CREATE_COMPANY_STEP_31:
            state = {
                ...state,
                createCompanyLoading31: false,
                createCompany31: action.payload,
            }
            return state;


        case actions.CREATE_COMPANY_STEP_31_START:
            state = {
                ...state,
                createCompanyLoading31: true,
                createCompany31: {},
            }
            return state;


        case actions.CREATE_COMPANY_STEP_31_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                createCompanyLoading31: false,
                createCompany31: action.payload,
            }
            return state;


        case actions.CREATE_COMPANY_STEP_31_RESET:
            state = {
                ...state,
                createCompanyLoading31: false,
                createCompany31: {},
            }
            return state;

            
        case actions.ACTIVATE_COMPANY:
            toast.success("Company Activated");
            // toast.success(action.payload.message);
            state = {
                ...state,
                companyActivateLoading: false,
                companyActivateSuccess: true,
                reloadPage: true,
            }
            return state;


        case actions.ACTIVATE_COMPANY_START:
            toast("Activating Company");
            state = {
                ...state,
                companyActivateLoading: true,
                companyActivateSuccess: false,
            }
            return state;


        case actions.ACTIVATE_COMPANY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                companyActivateLoading: false,
                companyActivateSuccess: false,
            }
            return state;


        case actions.DEACTIVATE_COMPANY:
            toast.success("Company Deactivated");
            // toast.success(action.payload.message);
            state = {
                ...state,
                companyDeactivateLoading: false,
                companyDeactivateSuccess: true,
                reloadPage: true,
            }
            return state;


        case actions.DEACTIVATE_COMPANY_START:
            toast("Deactivating Company");
            state = {
                ...state,
                companyDeactivateLoading: true,
                companyDeactivateSuccess: false,
            }
            return state;


        case actions.DEACTIVATE_COMPANY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                companyDeactivateLoading: false,
                companyDeactivateSuccess: false,
            }
            return state;
    

        case actions.RESEND_INVITE_COMPANY:
            toast.success("Invite sent");
            state = {
                ...state,
                companyResendInviteLoading: false,
                companyResendInviteSuccess: true,
                reloadPage: true,
            }
            return state;


        case actions.RESEND_INVITE_COMPANY_START:
            toast("Resending invite...");
            state = {
                ...state,
                companyResendInviteLoading: true,
                companyResendInviteSuccess: false,
            }
            return state;


        case actions.RESEND_INVITE_COMPANY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                companyResendInviteLoading: false,
                companyResendInviteSuccess: false,
            }
            return state;


        case actions.GET_COMPANY_EMPLOYEE_DASHBOARD_STATS:
            state = {
                ...state,
                companyEmployeeDashboardStatsLoading: false,
                companyEmployeeDashboardStats: action.payload,
            }
            return state;


        case actions.GET_COMPANY_EMPLOYEE_DASHBOARD_STATS_START:
            state = {
                ...state,
                companyEmployeeDashboardStatsLoading: true,
                companyEmployeeDashboardStats: {},
            }
            return state;


        case actions.GET_COMPANY_EMPLOYEE_DASHBOARD_STATS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                companyEmployeeDashboardStatsLoading: false,
                companyEmployeeDashboardStats: action.payload,
            }
            return state;
    

        case actions.GET_COMPANY_ADMIN:
            state = {
                ...state,
                adminsLoading: false,
                admins: action.payload,
            }
            return state;


        case actions.GET_COMPANY_ADMIN_START:
            state = {
                ...state,
                adminsLoading: true,
                admins: {},
            }
            return state;


        case actions.GET_COMPANY_ADMIN_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                adminsLoading: false,
                admins: action.payload,
            }
            return state;
    

        case actions.GET_COMPANY_UPLOADS:
            state = {
                ...state,
                companyUploadsLoading: false,
                companyUploads: action.payload,
            }
            return state;


        case actions.GET_COMPANY_UPLOADS_START:
            state = {
                ...state,
                companyUploadsLoading: true,
                companyUploads: {},
            }
            return state;


        case actions.GET_COMPANY_UPLOADS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                companyUploadsLoading: false,
                companyUploads: action.payload,
            }
            return state;
    

        case actions.COMPANY_UPLOAD_SALES:
            state = {
                ...state,
                companyUploadSalesLoading: false,
                companyUploadSales: action.payload,
            }
            return state;


        case actions.COMPANY_UPLOAD_SALES_START:
            state = {
                ...state,
                companyUploadSalesLoading: true,
                companyUploadSales: {},
            }
            return state;


        case actions.COMPANY_UPLOAD_SALES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                companyUploadSalesLoading: false,
                companyUploadSales: action.payload,
            }
            return state;
    

        case actions.COMPANY_UPLOAD_SALES_RESET:
            state = {
                ...state,
                companyUploadSalesLoading: false,
                companyUploadSales: {},
            }
            return state;
    

        case actions.COMPANY_UPLOAD_BONUS:
            state = {
                ...state,
                companyUploadBonusLoading: false,
                companyUploadBonus: action.payload,
            }
            return state;


        case actions.COMPANY_UPLOAD_BONUS_START:
            state = {
                ...state,
                companyUploadBonusLoading: true,
                companyUploadBonus: {},
            }
            return state;


        case actions.COMPANY_UPLOAD_BONUS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                companyUploadBonusLoading: false,
                companyUploadBonus: action.payload,
            }
            return state;
    

        case actions.COMPANY_UPLOAD_BONUS_RESET:
            state = {
                ...state,
                companyUploadBonusLoading: false,
                companyUploadBonus: {},
            }
            return state;
    

        case actions.COMPANY_UPLOAD_DIVIDEND:
            state = {
                ...state,
                companyUploadDividendLoading: false,
                companyUploadDividend: action.payload,
            }
            return state;


        case actions.COMPANY_UPLOAD_DIVIDEND_START:
            state = {
                ...state,
                companyUploadDividendLoading: true,
                companyUploadDividend: {},
            }
            return state;


        case actions.COMPANY_UPLOAD_DIVIDEND_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                companyUploadDividendLoading: false,
                companyUploadDividend: action.payload,
            }
            return state;

        
        case actions.COMPANY_UPLOAD_DIVIDEND_RESET:
            state = {
                ...state,
                companyUploadDividendLoading: false,
                companyUploadDividend: {},
            }
            return state;
    

        case actions.COMPANY_UPLOAD_TRANSFER:
            state = {
                ...state,
                companyUploadTransferLoading: false,
                companyUploadTransfer: action.payload,
            }
            return state;


        case actions.COMPANY_UPLOAD_TRANSFER_START:
            state = {
                ...state,
                companyUploadTransferLoading: true,
                companyUploadTransfer: {},
            }
            return state;


        case actions.COMPANY_UPLOAD_TRANSFER_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                companyUploadTransferLoading: false,
                companyUploadTransfer: action.payload,
            }
            return state;
    

        case actions.COMPANY_UPLOAD_TRANSFER_RESET:
            state = {
                ...state,
                companyUploadTransferLoading: false,
                companyUploadTransfer: {},
            }
            return state;
    

        default:
            return state;
    }
}