import { PDFViewer } from '@react-pdf/renderer';
import { Document, Page, Text, View, Font, Image, Link, StyleSheet } from '@react-pdf/renderer';
import logo from "assets/images/icons/logo.png";
import moment from "moment";
import CurrencyFormat from "utils/currencyFormat";
import { doubleDigit } from "utils/dataTypes";
import { DateMiniFormat, monthNames } from "utils/dateFormat";
import NumberFormat from "utils/numberFormat";



export const PDF = (props: any) => {
    return (
        <div>
            <PDFViewer className="w-full min-h-screen">
                <MyDocument {...props} />
            </PDFViewer>
        </div>
    )
}





Font.register({
    family: 'Lato',
    src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
    family: 'Lato Italic',
    src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
    family: 'Lato Bold',
    src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});
    
const styles = StyleSheet.create({
    page: {
        // width: "700px",
        fontFamily: "Lato",
        fontSize: "9px",
        padding: "30px 0",
        backgroundColor: '#FFFFFF',
    },
    row: {
        width: "100%",
        flexDirection: "row",
        flexGrow: 1
    },
    column: {
        flexGrow: 1,
        height: '100%',
        flexDirection: 'column',
    },
    tableCell: {
        padding: "5px 10px",
    },

    
    header: {
        width: "100%",
        // height: "20px",
        padding: "0 30px",
        marginTop: "0px",
        flexDirection: "row",
        justifyContent: "space-between",
        // backgroundColor: "#f00",
    },
    companyLogo: {
        width: "150px",
        height: "35px",
        flexShrink: 0,
        margin: "auto",
        // marginRight: "5px",
        // backgroundColor: "#ff0",
    },
    companyLogoImage: {
        maxWidth: "300px",
        height: "35px",
        margin: "auto 0",
    },

    companyInfo: {
        width: "100%",
        margin: "0 20px",
        fontSize: "10px",
        flexGrow: 1,
        textAlign: "center",
        fontFamily: "Lato Bold",
        textTransform: "uppercase",
        // backgroundColor: "#f0f",
    },
    companyInfoText: {
        textAlign: "center",
        // backgroundColor: "#ff0",
    },
    companyInfoDivider: {
        width: "100%",
        height: "1px",
        margin: "5px 0px",
        backgroundColor: "#000",
    },
    vetiva: {
        width: "150px",
        height: "35px",
        flexShrink: 0,
        margin: "auto",
        flexDirection: "row",
        // backgroundColor: "#00f",
    },
    vetivaLogo: {
        maxWidth: "300px",
        height: "20px",
        margin: "auto 0",
    },
    vetivaName: {
        fontFamily: "Lato Bold",
        margin: "auto 0 auto 5px",
        textTransform: "uppercase",
    },
    vetivaNameMain: {
        fontSize: "14px",
    },
    vetivaNameSub: {
        fontSize: "6px",
    },

    date: {
        padding: "0 30px",
        marginTop: "5px",
        fontFamily: "Lato Bold",
        textAlign: "right",
        // position: "relative",
        // top: "10px",
    },
    title: {
        padding: "5px",
        marginTop: "5px",
        color: "#FFFFFF",
        fontFamily: "Lato Bold",
        textAlign: "center",
        backgroundColor: "#767070",
    },
    columnLayout: {
        marginTop: "20px",
        padding: "0 30px",
        flexDirection: "row",
    },
    sectionFlexGrow: {
        paddingRight: "20px",
        flexGrow: 1,
    },
    userInfoData: {
        width: "25%",
        paddingRight: "7%",
        flexShrink: 0,
    },
    sectionFlexShrink: {
        width: "300px",
        flexShrink: 0,
    },
    footer: {
        padding: "20px 30px 0",
        marginTop: "20px",
        textAlign: "center",
        fontFamily: 'Lato Italic',
        borderTop: "2px solid #000",
    },
});





const MyDocument = (props:any) => {
    const {company, employeeScheme, employeeBenefitStatement} = props;


    let allocationBenefitHistory = employeeBenefitStatement?.data?.allocationBenefitHistory || [];
    let employee = employeeBenefitStatement?.data?.employee || {};
    let dividendHistory = employeeBenefitStatement?.data?.dividendHistory || [];
    let shareSalesHistory = employeeBenefitStatement?.data?.shareSalesHistory || [];


    const fullName = (employee?.userInfo?.firstName || "-") + " " + (employee?.userInfo?.lastName || "-");
    const title = "Statement of Beneficial Interest";
    const author = "Vetiva";
    const subject = fullName + " - Statement of Beneficial Interest";
    const dayFull = doubleDigit(moment().date()) + " " + monthNames[moment().month()] + ", " + moment().year();


    let totalDividendHistory = 0;
    dividendHistory?.map((dividend:any) => totalDividendHistory += (parseInt(dividend.dividendAmount) || 0));

    let totalShareSale = 0;
    shareSalesHistory?.map((shareSale:any) => totalShareSale += (parseInt(shareSale.amountSold) || 0));


    let totalSchemeInitialAllocation = 0;
    let totalSchemeBonusShares = 0;
    let totalSchemeSharesSold = 0;
    let totalSchemeCurrentBalance = 0;
    let totalSchemeVestedPortion = 0;
    allocationBenefitHistory?.map((benefit:any) => {
        totalSchemeInitialAllocation += (parseInt(benefit.initialAllocation) || 0);
        totalSchemeBonusShares += (parseInt(benefit.bonusShares) || 0);
        totalSchemeSharesSold += (parseInt(benefit.sharesSoldToDate) || 0);
        totalSchemeCurrentBalance += (parseInt(benefit.currentBalance) || 0);
        totalSchemeVestedPortion += (parseInt(benefit.vestedPortion) || 0);
        return null;
    });


    let oldestEmployeeAllocation = null;
    let allEmployeeAllocations:any = [];
    employeeScheme?.data?.employeeSubSchemes?.map((subScheme:any) => (
        subScheme.batches.map((batch:any) => (
            batch.allocations.map((allocation:any) => (
                allEmployeeAllocations.push(allocation.dateOfAllocation)
            ))
        ))
    ))
    employeeScheme?.data?.noSubschemeSchemeTypeDetailsResponses?.map((subScheme:any) => (
        allEmployeeAllocations.push(subScheme.dateOfAllocation)
    ))
    if (allEmployeeAllocations.length > 0){
        oldestEmployeeAllocation = allEmployeeAllocations.reduce((c:any, n:any) => 
            Date.parse(n) < Date.parse(c) ? n : c
        );
    }
    
    
    // console.log(company?.data?.logoObject?.url);


    return (
        <Document title={title} author={author} subject={subject}>
            <Page size="A4" orientation="landscape" style={styles.page}>
                
                <View style={styles.header}>
                    <View style={styles.companyLogo}>
                        <Image style={styles.companyLogoImage} src={company?.data?.logoObject?.url || " "} />
                        {/* <Image style={styles.companyLogoImage} src="https://picsum.photos/id/237/400/200" /> */}
                        {/* <Image style={styles.companyLogoImage} src="https://vtlstaging.blob.core.windows.net/vetivaupload/5346c30f-5546-45c0-9a13-93614c910864" /> */}
                        {/* <Image style={styles.companyLogoImage} src={{ uri: "https://vtlstaging.blob.core.windows.net/vetivaupload/5346c30f-5546-45c0-9a13-93614c910864", method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} /> */}
                    </View>
                    <View style={styles.companyInfo}>
                        <Text style={styles.companyInfoText}>{company?.data?.name}</Text>
                        <View style={styles.companyInfoDivider}></View>
                        <Text style={styles.companyInfoText}>Employee Equity Benefit Trust Scheme</Text>
                    </View>
                    <View style={styles.vetiva}>
                        <Image style={styles.vetivaLogo} src={logo} />
                        <View style={styles.vetivaName}>
                            <Text style={styles.vetivaNameMain}>Vetiva</Text>
                            <Text style={styles.vetivaNameSub}>Trustee Limited</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.date}>
                    <Text>Date: {dayFull}</Text>
                </View>

                <View style={styles.title}>
                    <Text>Statement of Beneficial Interest</Text>
                </View>

                <View style={styles.columnLayout}>
                    <View style={styles.sectionFlexGrow}>
                        <View style={{width: "100%"}}>

                            <View style={styles.row}>
                                <View style={styles.userInfoData}>
                                    <Text style={{marginBottom: "5px", fontFamily: "Lato Bold"}}>Surname:</Text>
                                    <Text>{employee?.userInfo?.lastName || "-"}</Text>
                                </View>
                                <View style={styles.userInfoData}>
                                    <Text style={{marginBottom: "5px", fontFamily: "Lato Bold"}}>First name:</Text>
                                    <Text>{employee?.userInfo?.firstName || "-"}</Text>
                                </View>
                                <View style={styles.userInfoData}>
                                    <Text style={{marginBottom: "5px", fontFamily: "Lato Bold"}}>Staff ID:</Text>
                                    <Text>{employee?.employeeNumber || "-"}</Text>
                                </View>
                                <View style={styles.userInfoData}>
                                    <Text style={{marginBottom: "5px", fontFamily: "Lato Bold"}}>Allocation Date:</Text>
                                    <Text>{DateMiniFormat(oldestEmployeeAllocation) || "-"}</Text>
                                </View>
                            </View>

                            <View style={{...styles.row, marginTop: "20px"}}>
                                <View style={styles.userInfoData}>
                                    <Text style={{marginBottom: "5px", fontFamily: "Lato Bold"}}>Email address:</Text>
                                    <Text style={{marginBottom: "5px", fontFamily: "Lato Bold"}}>Mobile No:</Text>
                                </View>
                                <View>
                                    <Text style={{marginBottom: "5px"}}>{employee?.userInfo?.email || "-"}</Text>
                                    <Text style={{marginBottom: "5px"}}>{employee?.userInfo?.phoneNumber || "-"}</Text>
                                </View>
                            </View>

                            <View style={{marginTop: "20px", border: "2px solid #000"}}>
                                <View style={{...styles.row, fontFamily: "Lato Bold"}}>
                                    <View style={{...styles.tableCell, width: "25%"}}>
                                        <Text></Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>Initial Allocation</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>Bonus shares</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>Shares sold to date</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>Current balance</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>Vested Portion</Text>
                                    </View>
                                </View>
                                
                                {allocationBenefitHistory?.map((benefit:any, index:number) =>
                                    <View style={styles.row} key={index}>
                                        <View style={{...styles.tableCell, width: "25%", paddingRight: "50px", fontFamily: "Lato Bold"}}>
                                            <Text>{benefit?.allocationTitle}</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "15%"}}>
                                            <Text>{NumberFormat(benefit?.initialAllocation)}</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "15%"}}>
                                            <Text>{NumberFormat(benefit?.bonusShares)}</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "15%"}}>
                                            <Text>{NumberFormat(benefit?.sharesSoldToDate)}</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "15%"}}>
                                            <Text>{NumberFormat(benefit?.currentBalance)}</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "15%"}}>
                                            <Text>{NumberFormat(benefit?.vestedPortion)}</Text>
                                        </View>
                                    </View>
                                )}

                                {allocationBenefitHistory?.length <= 0 &&
                                    <View style={styles.row}>
                                        <View style={{...styles.tableCell, width: "25%", fontFamily: "Lato Bold"}}>
                                            <Text>-</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "15%"}}>
                                            <Text>-</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "15%"}}>
                                            <Text>-</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "15%"}}>
                                            <Text>-</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "15%"}}>
                                            <Text>-</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "15%"}}>
                                            <Text>-</Text>
                                        </View>
                                    </View>
                                }
                                
                                <View style={{...styles.row, fontFamily: "Lato Bold"}}>
                                    <View style={{...styles.tableCell, width: "25%"}}>
                                        <Text>Total</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>{NumberFormat(totalSchemeInitialAllocation) || "-"}</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>{NumberFormat(totalSchemeBonusShares) || "-"}</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>{NumberFormat(totalSchemeSharesSold) || "-"}</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>{NumberFormat(totalSchemeCurrentBalance) || "-"}</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "15%"}}>
                                        <Text>{NumberFormat(totalSchemeVestedPortion) || "-"}</Text>
                                    </View>
                                </View>
                            </View>

                            <View style={{...styles.columnLayout, marginTop: "20px", padding: "0"}}>
                                <View style={{...styles.sectionFlexGrow, width: "70%"}}>

                                    <View style={{fontFamily: "Lato Bold"}}>
                                        <Text>Details of Next of Kin:</Text>
                                    </View>

                                    <View style={{marginTop: "10px"}}>
                                        <View style={styles.row}>
                                            <View style={{...styles.userInfoData, width: "40%"}}>
                                                <Text style={{marginBottom: "5px"}}>Name:</Text>
                                            </View>
                                            <View>
                                                <Text style={{marginBottom: "5px"}}>
                                                    {employee?.alternateBenefiaryLasName || "-"} {employee?.alternateBenefiaryFirstName || "-"}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={styles.row}>
                                            <View style={{...styles.userInfoData, width: "40%"}}>
                                                <Text style={{marginBottom: "5px"}}>Mobile No:</Text>
                                            </View>
                                            <View>
                                                <Text style={{marginBottom: "5px"}}>{employee?.alternateBenefiaryPhoneNumber || "-"}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.row}>
                                            <View style={{...styles.userInfoData, width: "40%"}}>
                                                <Text style={{marginBottom: "5px"}}>Email address:</Text>
                                            </View>
                                            <View>
                                                <Text style={{marginBottom: "5px"}}>{employee?.alternateBenefiaryEmail || "-"}</Text>
                                            </View>
                                        </View>
                                    </View>

                                </View>
                                <View style={styles.sectionFlexShrink}>

                                    <View style={{fontFamily: "Lato Bold"}}>
                                        <Text>Bank Details:</Text>
                                    </View>

                                    <View style={{marginTop: "10px"}}>
                                        <View style={styles.row}>
                                            <View style={{...styles.userInfoData, width: "40%"}}>
                                                <Text style={{marginBottom: "5px"}}>Bank Name:</Text>
                                            </View>
                                            <View>
                                                <Text style={{marginBottom: "5px"}}>{employee?.bankName || "-"}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.row}>
                                            <View style={{...styles.userInfoData, width: "40%"}}>
                                                <Text style={{marginBottom: "5px"}}>Account Number:</Text>
                                            </View>
                                            <View>
                                                <Text style={{marginBottom: "5px"}}>{employee?.accountNumber || "-"}</Text>
                                            </View>
                                        </View>
                                    </View>

                                </View>
                            </View>

                        </View>
                    </View>
                    <View style={styles.sectionFlexShrink}>
                        <View style={{width: "100%"}}>

                            <View style={{border: "2px solid #000"}}>
                                <View style={{...styles.title, margin: 0, color: "#000", backgroundColor: "#dbdbdb", fontFamily: "Lato Bold"}}>
                                    <Text>Dividends History</Text>
                                </View>

                                <View style={{...styles.row, fontFamily: "Lato Bold"}}>
                                    <View style={{...styles.tableCell, width: "33%"}}>
                                        <Text>Date</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                        <Text>DPS</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                        <Text>Div. Paid (N)</Text>
                                    </View>
                                </View>

                                {dividendHistory?.length > 0 && dividendHistory?.map((dividend: any, index: number) =>
                                    <View style={styles.row} key={index}>
                                        <View style={{...styles.tableCell, width: "33%"}}>
                                            <Text>{DateMiniFormat(dividend.dateOfDividend) || "-"}</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "33%"}}>
                                            <Text>{NumberFormat(dividend.pricePerShare) || "-"}</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                            <Text>{NumberFormat(dividend.dividendAmount) || "-"}</Text>
                                        </View>
                                    </View>
                                )}

                                {dividendHistory?.length <= 0 &&
                                    <View style={styles.row}>
                                        <View style={{...styles.tableCell, width: "33%"}}>
                                            <Text>-</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                            <Text>-</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                            <Text>-</Text>
                                        </View>
                                    </View>
                                }

                                <View style={{...styles.row, fontFamily: "Lato Bold"}}>
                                    <View style={{...styles.tableCell, width: "33%"}}>
                                        <Text></Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                        <Text>Total</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                        <Text>{CurrencyFormat(totalDividendHistory, undefined, " ") || "-"}</Text>
                                    </View>
                                </View>
                            </View>

                            <View style={{marginTop: "20px", border: "2px solid #000"}}>
                                <View style={{...styles.title, margin: 0, color: "#000", backgroundColor: "#dbdbdb", fontFamily: "Lato Bold"}}>
                                    <Text>Share Sale History</Text>
                                </View>

                                <View style={{...styles.row, fontFamily: "Lato Bold"}}>
                                    <View style={{...styles.tableCell, width: "33%"}}>
                                        <Text>Date & Name</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                        <Text>Price</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                        <Text>Amount</Text>
                                    </View>
                                </View>

                                {shareSalesHistory?.length > 0 && shareSalesHistory?.map((shareSale: any, index: number) =>
                                    <View style={styles.row} key={index}>
                                        <View style={{...styles.tableCell, width: "33%"}}>
                                            <Text>
                                                {DateMiniFormat(shareSale.dateOfSale) || "-"}
                                            </Text>
                                            <Text>
                                                {shareSale.name || "-"}
                                            </Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "33%"}}>
                                            <Text>
                                                {(shareSale.currency || "") + " " + NumberFormat(shareSale.price) || "-"}
                                            </Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                            <Text>
                                                {(shareSale.currency || "") + " " + NumberFormat(shareSale.amountSold) || "-"}
                                            </Text>
                                        </View>
                                    </View>
                                )}
                                
                                {shareSalesHistory?.length <= 0 &&
                                    <View style={styles.row}>
                                        <View style={{...styles.tableCell, width: "33%"}}>
                                            <Text>-</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                            <Text>-</Text>
                                        </View>
                                        <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                            <Text>-</Text>
                                        </View>
                                    </View>
                                }

                                <View style={{...styles.row, fontFamily: "Lato Bold"}}>
                                    <View style={{...styles.tableCell, width: "33%"}}>
                                        <Text></Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "center"}}>
                                        <Text>Total</Text>
                                    </View>
                                    <View style={{...styles.tableCell, width: "33%", textAlign: "right"}}>
                                        <Text>{NumberFormat(totalShareSale) || "-"}</Text>
                                    </View>
                                </View>
                            </View>

                        </View>
                    </View>
                </View>

                <View style={styles.footer}>
                    <Text>
                        Disclosure/Disclaimer: All information is provided as at <Text>{dayFull}</Text>. Kindly advise all exceptions to Vetiva immeidiately. All changes in email or physical address should be promptly advised to <Link src="mailto:trustees@vetiva.com">trustees@vetiva.com</Link>
                    </Text>
                </View>
            </Page>
        </Document>
    )
}



export default PDF;
