export const AllocationTypes = [
    { label: "Shares", value: "Shares", selected: true },
    { label: "Units", value: "Units", selected: false },
    { label: "Phantom shares", value: "Phantom shares", selected: false },
    { label: "Others", value: "Others", selected: false },
];

export const SchemeAccess = [
    { label: "Has Subscheme access", value: true, selected: true },
    { label: "No Subscheme access", value: false, selected: false },
];

export const SchemeTypes = [
    { label: "Share awards", value: "award", selected: false },
    { label: "Share options", value: "option", selected: false },
];

export const Access = [
    { label: "Fixed period", value: "Fixed period", selected: true },
    { label: "Recurring date", value: "Recurring date", selected: false },
];

export const ShareSaleOrTransfer = [
    { label: "Yes, can access", value: true, selected: true },
    { label: "No, cannot access", value: false, selected: false },
];

export const DividendTypes = [
    { label: "Interim Dividend", value: "Interim Dividend", selected: false },
    { label: "Final Dividend", value: "Final Dividend", selected: false },
];

export const AlphaNumberTypes = [
    { label: "Alphabets", value: "Alphabets", selected: true },
    { label: "Numbers", value: "Numbers", selected: false },
];