import { useEffect, useState } from "react";
import { BatchAccordion, ButtonGroupSelector, DocPicker, FileUploadPreview, Input, SelectInput } from "components/ui";
import { Access } from "data/constants/company-settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Maybe from "components/Maybe";
import { dateFormatRemoveISO } from "utils/dataTypes";
import { valueToInt } from "utils/misc";

export interface BatchDefinitionFormProps {
  isOpen: boolean;
  label: string;
  index: number;
  allocationSample: any;
  subSchemeToUse: any;
  setSubSchemeToUse: any;
}

export function BatchDefinitionForm(props: BatchDefinitionFormProps) {
  const { isOpen, label, index, allocationSample, subSchemeToUse, setSubSchemeToUse } = props;
  
  const [showFileUploadSchemeRule, setShowFileUploadSchemeRule] = useState<any>({});

  
  const addAllocation = () => {
    const subSchemeToUseUpdated = subSchemeToUse.map((data:any, i:number) => {
      if (i === index){
        const allocationSampleToUse = {...allocationSample};
        const newAllocations = [...data.allocations, allocationSampleToUse];
        return {...data, allocations: newAllocations}
      }
      else{
        return data;
      }
    })
    setSubSchemeToUse(subSchemeToUseUpdated)
    updateShowFileUploadSchemeRule(subSchemeToUseUpdated);
  }


  const removeAllocation = (elementIndex: number) => {
    const subSchemeToUseUpdated = subSchemeToUse.map((data:any, i:number) => {
      if (i === index){
        const newAllocations = [...data.allocations];
        newAllocations.splice(elementIndex, 1);
        return {...data, allocations: newAllocations}
      }
      else{
        return data;
      }
    })
    setSubSchemeToUse(subSchemeToUseUpdated)
    updateShowFileUploadSchemeRule(subSchemeToUseUpdated);
  }

  
  const setAllocationAccessSelection = (allocation:any) => {
    const access = Access.map(type => {
      let newAccess:any = {};
      if (allocation.vestingPeriod === type.value){
        newAccess = {...type, selected: true}
      }
      else{
        newAccess = {...type, selected: false}
      }
      return newAccess;
    })
    return access;
  }


  const updateAllocation = (allocationIndex:number, name:string, value:any) => {
    const subSchemeToUseUpdated = subSchemeToUse.map((data:any, i:number) => {
      if (i === index){
        const newAllocations = data.allocations.map((allocation:any, j:number) => {
          if (j === allocationIndex){
            return {...allocation, [name]: value}
          }
          else{
            return allocation;
          }
        })
        return {...data, allocations: newAllocations}
      }
      else{
        return data;
      }
    })

    setSubSchemeToUse(subSchemeToUseUpdated)
  }


  const updateShowFileUploadSchemeRule = (newSubSchemeToUse = undefined) => {
    // console.log("start new");

    let objectToUse = subSchemeToUse;
    if (newSubSchemeToUse !== undefined){
      objectToUse = newSubSchemeToUse;
    }
    
    let newShowFileUploadSchemeRule = {};
    objectToUse[index]?.allocations.map((allocation:any, allocationIndex:number) => {
      if (allocation.schemeRuleObj && allocation.schemeRuleObj?.length > 0){
        newShowFileUploadSchemeRule = {...newShowFileUploadSchemeRule, [allocationIndex]: false}
      }
      else{
        newShowFileUploadSchemeRule = {...newShowFileUploadSchemeRule, [allocationIndex]: true}
      }
      return null;
    })
    setShowFileUploadSchemeRule(newShowFileUploadSchemeRule);
  }


  useEffect(() => {
    updateShowFileUploadSchemeRule();
  }, [isOpen]);


  // console.log("subSchemeToUse", subSchemeToUse[index]);
  // console.log("showFileUploadSchemeRule", showFileUploadSchemeRule);


  return (
    <div className="mt-6">
      <BatchAccordion label={label} isOpen={false}>

        {subSchemeToUse[index]?.allocations.map((allocation:any, allocationIndex:number) => (
          <div key={allocationIndex} className={`relative px-4 pt-1 pb-10 bg-vetiva-gold-lighter border-t first:border-none border-gray-400`}>


            <Maybe condition={allocationIndex > 0}>
              <div className="flex justify-end">
                <span className="w-8 h-8 flex mt-1 mb-2 hover:bg-red-100 cursor-pointer" onClick={() => removeAllocation(allocationIndex)}>
                  <FontAwesomeIcon icon="trash" className="text-[#FF0000] m-auto" />
                </span>
              </div>
            </Maybe>


            <div>
              <div className="bg-vetiva-gold-lighter">

                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <Input type="number" label="Number of allocation" value={allocation.numberOfAllocation} onChange={(e:any) => updateAllocation(allocationIndex, "numberOfAllocation", valueToInt(e.target.value))} />
                  </div>
                  <div>
                    <Input type="date" label="Date of allocation" value={dateFormatRemoveISO(allocation.dateOfAllocation)} onChange={(e:any) => updateAllocation(allocationIndex, "dateOfAllocation", e.target.value)} />
                  </div>
                </div>

                <div className="mt-2">
                  <Input type="number" label="Percentage for vesting" value={allocation.percentageVesting} onChange={(e:any) => updateAllocation(allocationIndex, "percentageVesting", valueToInt(e.target.value))} />
                </div>

                <div className="mt-6">
                  <div className="font-bold">
                    Vesting period
                  </div>
                  <div className="text-sm text-fade mb-3">
                    Select type
                  </div>
                  <div>
                    <ButtonGroupSelector selectOptions={setAllocationAccessSelection(allocation)} onValueChange={(e:any) => updateAllocation(allocationIndex, "vestingPeriod", e)} />
                  </div>
                </div>

                <Maybe condition={allocation.vestingPeriod === "Fixed period"}>
                  <div className="mt-6">
                    <SelectInput label="Vesting period" value={allocation.period} onChange={(e:any) => updateAllocation(allocationIndex, "period", valueToInt(e.target.value))}>
                      <option value={0} disabled>Select period</option>
                      <option value={1}>Every 1 year</option>
                      <option value={2}>Every 2 years</option>
                      <option value={3}>Every 3 years</option>
                      <option value={4}>Every 4 years</option>
                      <option value={5}>Every 5 years</option>
                      <option value={6}>Every 6 years</option>
                      <option value={7}>Every 7 years</option>
                      <option value={8}>Every 8 years</option>
                      <option value={9}>Every 9 years</option>
                      <option value={10}>Every 10 years</option>
                    </SelectInput>
                  </div>
                </Maybe>

                <Maybe condition={allocation.vestingPeriod === "Recurring date"}>
                  <div className="mt-6">
                    <Input type="date" label="Vesting date" value={dateFormatRemoveISO(allocation.vestingDate)} onChange={(e:any) => updateAllocation(allocationIndex, "vestingDate", e.target.value)} />
                  </div>
                </Maybe>

                <div className="mt-6">
                  <div className="font-bold">
                    Scheme rule
                  </div>
                  <div className="text-sm text-fade mb-3">
                    Upload file
                  </div>
                  <div>

                    <Maybe condition={showFileUploadSchemeRule[allocationIndex] === false}>
                      <Maybe condition={allocation.schemeRuleObj && allocation.schemeRuleObj?.length > 0}>
                        <FileUploadPreview
                          className="bg-white"
                          file={allocation.schemeRuleObj ? allocation?.schemeRuleObj[0] || undefined : undefined}
                          allowDismiss={true}
                          cancelUpload={() => {updateAllocation(allocationIndex, "schemeRule", []); setShowFileUploadSchemeRule({...showFileUploadSchemeRule, [allocationIndex]: true})}}
                        />
                      </Maybe>
                    </Maybe>
                    <Maybe condition={showFileUploadSchemeRule[allocationIndex] === true}>
                      <DocPicker onValueChange={(e:any) => updateAllocation(allocationIndex, "schemeRule", e[0] || [])} />
                    </Maybe>

                  </div>
                </div>

              </div>
            </div>


          </div>
        ))}

        <div className="flex justify-end items-center bg-vetiva-gold-lighter text-vetiva-brown text-caption pb-6 px-4">
          <span className="cursor-pointer hover:underline" onClick={addAllocation}>
            <FontAwesomeIcon icon="plus" className="mr-1" />
            ADD MORE
          </span>
        </div>

      </BatchAccordion>
    </div>
  );
}

export default BatchDefinitionForm;
