import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";

const initialState = {
    banksLoading: false,
    banks: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_BANKS:
            state = {
                ...state,
                banksLoading: false,
                banks: action.payload,
            }
            return state;


        case actions.GET_BANKS_START:
            state = {
                ...state,
                banksLoading: true,
                banks: {},
            }
            return state;


        case actions.GET_BANKS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                banksLoading: false,
                banks: action.payload,
            }
            return state;


        default:
            return state;
    }
}