import { Link } from "react-router-dom";
import useBackButton from "hooks/useBackButton";
import Icon401 from "assets/images/icons/401.jpg";
import useLoginInfo from "hooks/useLoginInfo";
import Maybe from "components/Maybe";


const Error404 = () => {
    const {goBack} = useBackButton();
    const {userLogin, logoutUser} = useLoginInfo();

    return (
        <div className="w-screen min-h-[500px] flex">
            <div className="px-8 pt-20 m-auto text-center max-w-md">

                <div className="h-32 md:h-56">
                    <img src={Icon401} alt="Vetiva 404" className="h-full object-contain mx-auto" />
                </div>
                <div className="mt-8 font-clashgrotesk font-semibold text-2xl sm:text-3xl">
                    401 - Unauthorized
                </div>
                <div className="mt-4 text-gray-500">
                    Sorry, you are not permitted to access this page.
                    Please contact us if you feel this is an error from us.
                </div>

                <div className="mt-8 space-y-2">
                    <button onClick={() => goBack()} className="btn btn-block btn-lg btn-vetiva-brown">
                        Back to previous page
                    </button>

                    <Maybe condition={userLogin}>
                        <button type="button" onClick={logoutUser} className="btn btn-block btn-lg btn-transparent-black">
                            Login as another user
                        </button>
                    </Maybe>

                    <Maybe condition={!userLogin}>
                        <Link to="/login" className="btn btn-block btn-lg btn-transparent-black">
                            Login to your account
                        </Link>
                    </Maybe>
                </div>

            </div>
        </div>
    )
}

export default Error404
