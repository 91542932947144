import { Outlet } from "react-router-dom";
import HeaderCompany from "components/HeaderCompany";
import Footer from "components/Footer";

const CompanyLayout = () => {
    return (
        <>
            <HeaderCompany />

            <div className="mt-16 min-h-[500px] text-sm">
                <Outlet />
            </div>

            <Footer />
        </>
    )
}

export default CompanyLayout
