export interface InputProps {
  label: string;
  value?: any;
  type?: any;
  onChange?: any;
  leftIcon?: any;
  rightIcon?: any;
  rightIconClicked?:any;
  disabled?: boolean;
  defaultProps?: any;
}

export function Input(props: InputProps) {
  const { label, value, type, onChange, leftIcon, rightIcon, rightIconClicked, disabled, defaultProps } = props;
  
  
  return (
    <div className="relative w-full h-12 flex items-center">

      {leftIcon &&
        <div className="w-5 h-5 absolute left-3 text-gray-500">
          {leftIcon}
        </div>
      }

      {rightIcon &&
        <div className={"w-5 h-5 absolute right-3 text-gray-500 " + (rightIconClicked && "cursor-pointer")} onClick={rightIconClicked || null}>
          {rightIcon}
        </div>
      }

      <input
        type={type || "text"}
        onChange={onChange}
        placeholder=" "
        value={value}
        readOnly={disabled}
        {...defaultProps}
        className={`
        peer 
        w-full 
        h-full 
        ${disabled ? 'bg-gray-200' : 'bg-white'}
        ${leftIcon ? 'pl-10' : 'pl-3'}
        ${rightIcon ? 'pr-10' : 'pr-3'}
        text-blue-gray-700 
        font-normal 
        outline outline-0 
        focus:ring-transparent
        focus:outline-0 
        disabled:bg-blue-gray-50 
        disabled:border-0 
        transition-all 
        placeholder-shown:border-2
        placeholder-shown:border-blue-gray-200 
        placeholder-shown:border-t-blue-gray-200 
        border-2 focus:border-2 
        border-gray-200
        border-t-transparent_
        focus:border-t-transparent
        appearance-none
        text-sm 
        py-2.5 
        rounded 
        focus:border-vetiva-brown ...`}
      />
      
      <label
        className={`
        flex 
        w-full 
        h-full 
        select-none 
        pointer-events-none 
        absolute 
        left-0
        ${leftIcon ? 'pl-7 peer-focus:pl-0 [&:not(peer-empty)]:pl-0 peer-placeholder-shown:pl-7' : 'pl-0'}
        ${leftIcon ? 'pr-7 peer-focus:pr-0 [&:not(peer-empty)]:pr-0 peer-placeholder-shown:pr-7' : 'pr-0'}
        font-normal 
        peer-placeholder-shown:text-fade 
        leading-tight 
        peer-focus:leading-tight 
        peer-disabled:text-transparent 
        peer-disabled:peer-placeholder-shown:text-blue-gray-500 
        transition-all 
        -top-1.5 
        peer-placeholder-shown:text-sm text-[11px] 
        peer-focus:text-[11px] before:content[' '] 
        before:block 
        before:box-border 
        before:w-2.5 
        before:h-1.5 
        before:mt-[6.5px] 
        before:mr-1 
        peer-placeholder-shown:before:border-transparent 
        before:rounded 
        before:border-t-2
        peer-focus:before:border-t-2 
        before:border-l-2
        peer-focus:before:border-l-2 
        before:pointer-events-none 
        before:transition-all 
        peer-disabled:before:border-transparent 
        after:content[' '] 
        after:block 
        after:flex-grow 
        after:box-border 
        after:w-2.5 
        after:h-1.5 
        after:mt-[6.5px] 
        after:ml-1 
        peer-placeholder-shown:after:border-transparent 
        after:rounded 
        after:border-t-2
        peer-focus:after:border-t-2 
        after:border-r-2
        peer-focus:after:border-r-2 
        after:pointer-events-none 
        after:transition-all 
        peer-disabled:after:border-transparent 
        peer-placeholder-shown:leading-[4.25] 
        text-blue-gray-400 peer-focus:text-vetiva-brown ... 
        before:border-blue-gray-200 
        peer-focus:before:border-vetiva-brown ... 
        after:border-blue-gray-200 
        peer-focus:after:border-vetiva-brown ...`}
      >
        {label}
      </label>

    </div>
  );
}

export default Input;
