import { FormWizardNavBar } from "components/form-wizard-navbar";
import { Link, useParams } from "react-router-dom";
import iconHome from "assets/images/icons/home.svg";
import useLoginInfo from "hooks/useLoginInfo";

export interface CompanyEmployeeInstructionTypeProps {}

export function CompanyEmployeeInstructionType(props: CompanyEmployeeInstructionTypeProps) {
  const {userInfo} = useLoginInfo();
  let { companyId, employeeId, schemeTypeId } = useParams();
  let employeeDetailsUrl = "/trustee/company/"+companyId+"/employee/"+employeeId;
  let sendInstructionSelectionUrl = "/trustee/company/"+companyId+"/employee/"+employeeId;

  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    employeeDetailsUrl = "/company/employees/"+employeeId;
    sendInstructionSelectionUrl = employeeDetailsUrl;
  }
  else if (userInfo.userType === "SCHEME_MEMBER"){
    companyId = userInfo.organizationId;
    employeeId = userInfo.employeeId;
    employeeDetailsUrl = "/member/overview";
    sendInstructionSelectionUrl = "/member";
  }
  

  return (
    <div>

      <FormWizardNavBar showSteps={false} closeUrl={employeeDetailsUrl} />

      <div className="container py-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Send Instruction
          </div>
          <p className="mt-2 text-fade text-sm">
            Select instruction type
          </p>
        </div>

        <div className="mt-12 mx-auto grid grid-cols-2 gap-6 sm:gap-10 max-w-md">
          
          <Link to={sendInstructionSelectionUrl + "/instruction/" + schemeTypeId + "/transfer"} className="shadow-md px-4 py-8 rounded-md hover:bg-gray-100">
            <div>
              <img src={iconHome} alt="home" />
            </div>
            <div className="mt-4 text-lg">
              Share Transfer
            </div>
            <div className="mt-1 text-sm text-fade">
              This is a short description for this option, nothing much
            </div>
          </Link>

          <Link to={sendInstructionSelectionUrl + "/instruction/" + schemeTypeId + "/sales"} className="shadow-md px-4 py-8 rounded-md hover:bg-gray-100">
            <div>
              <img src={iconHome} alt="home" />
            </div>
            <div className="mt-4 text-lg">
              Share Sales
            </div>
            <div className="mt-1 text-sm text-fade">
              This is a short description for this option, nothing much
            </div>
          </Link>

        </div>
      </div>

    </div>
  );
}

export default CompanyEmployeeInstructionType;
