import * as actions from "./actionTypes";
import { toast } from 'react-toastify';
import errorReducer from "utils/errorReducer";

const initialState = {
    loginLoading: false,
    // loggedIn: false,     // moved to persist
    // data: {},            // moved to persist
    
    forgotPasswordLoading: false,
    forgotPassword: false,
    forgotPasswordForm: {},
    
    resetPasswordLoading: false,
    resetPassword: false,

    verifyTokenLoading: false,
    verifyToken: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.LOGIN_USER:
            // console.log("login success");
            state = {
                ...state,
                loginLoading: false,
            }
            return state;


        case actions.LOGIN_USER_START:
            state = {
                ...state,
                loginLoading: true,
            }
            return state;


        case actions.LOGIN_USER_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                loginLoading: false,
            }
            return state;

            
        case actions.FORGOT_PASSWORD:
            toast.success("A password reset link has been sent to your email address");
            state = {
                ...state,
                forgotPasswordLoading: false,
                forgotPassword: true,
                forgotPasswordForm: {
                    email: action.payload.email
                }
            }
            return state;


        case actions.FORGOT_PASSWORD_START:
            state = {
                ...state,
                forgotPasswordLoading: true,
                forgotPassword: false,
            }
            return state;


        case actions.FORGOT_PASSWORD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                forgotPasswordLoading: false,
                forgotPassword: false,
            }
            return state;


        case actions.FORGOT_PASSWORD_RESET:
            state = {
                ...state,
                forgotPassword: false,
            }
            return state;


        case actions.RESET_PASSWORD:
            // toast.success("Password reset successful");
            state = {
                ...state,
                resetPasswordLoading: false,
                resetPassword: true,
            }
            return state;


        case actions.RESET_PASSWORD_START:
            state = {
                ...state,
                resetPasswordLoading: true,
                resetPassword: false,
            }
            return state;


        case actions.RESET_PASSWORD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                resetPasswordLoading: false,
                resetPassword: false,
            }
            return state;


        case actions.RESET_PASSWORD_RESET:
            state = {
                ...state,
                resetPasswordLoading: false,
                resetPassword: false,
            }
            return state;


        case actions.VERIFY_TOKEN:
            // toast.success("Password reset successful");
            state = {
                ...state,
                verifyTokenLoading: false,
                verifyToken: {...action.payload},
            }
            return state;


        case actions.VERIFY_TOKEN_START:
            state = {
                ...state,
                verifyTokenLoading: true,
                verifyToken: {},
            }
            return state;


        case actions.VERIFY_TOKEN_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                verifyTokenLoading: false,
                verifyToken: {...action.payload},
            }
            return state;


        case actions.VERIFY_TOKEN_RESET:
            state = {
                ...state,
                verifyToken: {},
            }
            return state;


        default:
            return state;
    }
}