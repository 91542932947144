import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import useLoginInfo from "hooks/useLoginInfo";


const Index = () => {
    
    const navigate = useNavigate();
    const {userLogin, userInfo} = useLoginInfo();
    // console.log(userInfo);
    
    
    let url = "/login";
    
    if (userLogin){
        if (userInfo.userType === "TRUSTEE"){
            url = "/trustee";
        }
        else if (userInfo.userType === "SCHEME_COMMITTEE"){
            url = "/company";
        }
        else if (userInfo.userType === "SCHEME_MEMBER"){
            url = "/member";
        }
        else{
            url = "/401";
        }
    }

    useEffect(() => {
        navigate(url, {replace: true});
    })

    return (
        <div></div>
    )
}

export default Index