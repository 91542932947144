let currentStep = 0;

export interface StepperNavigationProps {
  currentStep?: number,
  totalSteps?: number,
}


function stepStyleCircle(step:number) {
  if (step <= currentStep){
    return "bg-vetiva-brown border-vetiva-brown";
  }
  else{
    return "bg-gray-300 border-gray-300";
  }
}


function stepStyleDivider(step:number) {
  if (step < currentStep){
    return "border-vetiva-brown";
  }
  else{
    return "border-gray-300";
  }
}


export function StepperNavigation(props: StepperNavigationProps) {
  currentStep = props.currentStep || 0;

  return (
    <>
      <div className="p-0">
        <div className="flex items-center text-sm">

          <div className="flex items-center text-white relative">
            <div className={"rounded-full transition duration-500 ease-in-out h-6 w-6 flex justify-center items-center border-2 " + stepStyleCircle(1)}>
              <div className="text-center text-caption">1</div>
            </div>
          </div>

          {props.totalSteps && props.totalSteps >= 2 &&
            <>
              <div className={"flex-auto border-t-2 transition duration-500 ease-in-out " + stepStyleDivider(1)}></div>
              <div className="flex items-center text-white relative">
                <div className={"rounded-full transition duration-500 ease-in-out h-6 w-6 flex justify-center items-center border-2 " + stepStyleCircle(2)}>
                  <div className="text-center text-caption">2</div>
                </div>
              </div>
            </>
          }

          {props.totalSteps && props.totalSteps >= 3 &&
            <>
              <div className={"flex-auto border-t-2 transition duration-500 ease-in-out " + stepStyleDivider(2)}></div>
              <div className="flex items-center text-white relative">
                <div className={"rounded-full transition duration-500 ease-in-out h-6 w-6 flex justify-center items-center border-2 " + stepStyleCircle(3)}>
                  <div className="text-center text-caption">3</div>
                </div>
              </div>
            </>
          }

          {props.totalSteps && props.totalSteps >= 4 &&
            <>
              <div className={"flex-auto border-t-2 transition duration-500 ease-in-out " + stepStyleDivider(3)}></div>
              <div className="flex items-center text-white relative">
                <div className={"rounded-full transition duration-500 ease-in-out h-6 w-6 flex justify-center items-center border-2 " + stepStyleCircle(4)}>
                  <div className="text-center text-caption">4</div>
                </div>
              </div>
            </>
          }

          {props.totalSteps && props.totalSteps >= 5 &&
            <>
              <div className={"flex-auto border-t-2 transition duration-500 ease-in-out " + stepStyleDivider(4)}></div>
              <div className="flex items-center text-white relative">
                <div className={"rounded-full transition duration-500 ease-in-out h-6 w-6 flex justify-center items-center border-2 " + stepStyleCircle(5)}>
                  <div className="text-center text-caption">5</div>
                </div>
              </div>
            </>
          }

          {props.totalSteps && props.totalSteps >= 6 &&
            <>
              <div className={"flex-auto border-t-2 transition duration-500 ease-in-out " + stepStyleDivider(5)}></div>
              <div className="flex items-center text-white relative">
                <div className={"rounded-full transition duration-500 ease-in-out h-6 w-6 flex justify-center items-center border-2 " + stepStyleCircle(6)}>
                  <div className="text-center text-caption">6</div>
                </div>
              </div>
            </>
          }

        </div>
      </div>
    </>
  );
}

export default StepperNavigation;
