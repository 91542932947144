import useBackButton from "hooks/useBackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Terms = () => {
    const {goBack} = useBackButton();

    return (
        <div className="bg-gray-100 pt-10 pb-16">
            <div className="container">
                
                <div className="max-w-5xl mx-auto">
                    <button onClick={() => goBack()} className="text-vetiva-brown font-bold cursor-pointer">
                        <FontAwesomeIcon icon="angle-left" className="mr-2" />
                        Back
                    </button>
                </div>

                <div className="mt-8 max-w-3xl mx-auto flex flex-col space-y-6 leading-7 text-justify">
                    <div className="flex flex-col space-y-1">
                        <div className="font-semibold">
                            1. Introduction
                        </div>
                        <div>
                            Vetiva Capital Management Limited (including its subsidiaries hereinafter referred to as “Vetiva”), as a
                            data collector/controller, is committed to conducting its business in accordance with the Nigeria Data
                            Protection Regulation (NDPR), EU General Data Protection Regulation (GDPR), and other international
                            instruments concerning the protection of personal data and privacy of individuals privacy to ensure
                            compliance with the Data Protection requirements. Non-compliance may expose Vetiva to complaints,
                            regulatory actions, fines or/and reputational damage.
                        </div>
                    </div>
                    <div className="flex flex-col space-y-1">
                        <div className="font-semibold">
                            2. Purpose
                        </div>
                        <div>
                            The purpose of this policy is to ensure that Vetiva processes personal data in a way that is consistent with
                            all data protection and privacy guidelines, to protect the “rights and freedoms” of individuals, and to
                            ensure that personal data is not processed without their knowledge, and, wherever possible, that it is
                            processed with their consent.
                            The Vetiva’s Data Protection Policy is also designed to inform all stakeholders about their obligation to
                            protect the privacy and security of personal data when collecting, storing, using personal data that is
                            needed in order to carry out our business while complying with Data Protection Regulations and
                            standards.
                        </div>
                    </div>
                    <div className="flex flex-col space-y-1">
                        <div className="font-semibold">
                            3. Rational/Scope
                        </div>
                        <div>
                            By this policy, Vetiva sets forth how it shall process and manage personal data collected in the normal
                            course of business. Any data provided are handled in a confidential manner to ensure that the content
                            and service being offered are tailored to specific requests, needs and interests.
                            The Vetiva policy applies to all employees, contractors, vendors and third parties that are responsible for
                            processing of personal data on behalf of Vetiva.
                            This policy also applies to the whole or part processing of personal data by automated means (i.e. by
                            laptop/computer) and non-au
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Terms