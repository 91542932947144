import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import validate from "utils/validate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "components/ui";
import ButtonLoader from "components/ButtonLoader";
import {ReactComponent as IconLock } from "assets/images/icons/lock.svg";
import Maybe from "components/Maybe";
import useLoginInfo from "hooks/useLoginInfo";
import * as user from "store/auth/user/action";


const ActivateAccount = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {logoutUser} = useLoginInfo();
    const { verifyTokenLoading, verifyToken, resetPasswordLoading, resetPassword } = useSelector((s: any) => s.auth.user);

    const [viewPassword, setViewPassword] = useState(false);
    const [token] = useState(searchParams.get("token") || "");
    const [userType] = useState(searchParams.get("user") || "");
    const [subdomain] = useState(searchParams.get("subdomain") || "");
    const [resetPasswordForm, setResetPasswordForm] = useState({
        token,
        password: "",
        confirmPassword: "",
    })


    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();

        const {token, ...passwordForm} = resetPasswordForm

        const errors:any = validate(passwordForm);
        if (errors){
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            dispatch(user.resetPassword(resetPasswordForm));
        }
    }
    
    
    useEffect(() => {
        dispatch(user.resetResetPassword());
        if (token !== ""){
            dispatch(user.verifyToken(token));
        }
        
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (resetPassword === true){
            dispatch(user.resetResetPassword());
            logoutUser({redirect: false});
            navigate("/activate-account/success?userType=" + userType + "&subdomain=" + subdomain);
        }
        // eslint-disable-next-line
    }, [resetPassword]);

    
    return (
        <div className="w-full max-w-md lg:w-96 mx-auto md:mx-0">


            <Maybe condition={token !== ""}>

                <Maybe condition={verifyTokenLoading}>
                    <div className="text-center">
                        <div className="h-24">
                            <FontAwesomeIcon icon="spinner" spin className="h-full object-contain mx-auto" />
                        </div>
                        <div className="mt-8 font-semibold text-2xl">
                            Verifying...
                        </div>
                        <div className="mt-2 text-gray-500">
                            Please wait while we verify your access token
                        </div>
                    </div>
                </Maybe>


                <Maybe condition={verifyTokenLoading === false && verifyToken?.success === false}>
                    <div className="text-center">
                        <div className="h-24">
                            <FontAwesomeIcon icon="times-circle" className="h-full object-contain mx-auto text-red-500" />
                        </div>
                        <div className="mt-8 font-semibold text-2xl">
                            Invalid Access
                        </div>
                        <div className="mt-2 text-gray-500">
                            Sorry, your access token is invalid. Please contact us to request for a new activation link.
                        </div>
                    </div>
                </Maybe>


                <Maybe condition={verifyTokenLoading === false && verifyToken?.success === true}>
                    <div>
                        <div className="text-2xl font-semibold">
                            Set new Password
                        </div>
                        <div className="mt-1 text-sm">
                            Enter your new password to proceed
                        </div>

                        <div>
                            <form onSubmit={submitForm}>
                                <div className="mt-10">
                                
                                    <div className="mb-5">
                                        <Input type={viewPassword ? "text" : "password"}
                                            label="Password" value={resetPasswordForm.password} onChange={(e:any) => setResetPasswordForm({...resetPasswordForm, password: e.target.value})}
                                            leftIcon={<IconLock className="object-contain" />}
                                            rightIcon={<FontAwesomeIcon icon={viewPassword ? "eye" : "eye-slash"} className="text-gray-400" />}
                                            rightIconClicked={() => setViewPassword(!viewPassword)}
                                        />
                                    </div>

                                    <div className="mb-5">
                                        <Input type={viewPassword ? "text" : "password"}
                                            label="Confirm Password" value={resetPasswordForm.confirmPassword} onChange={(e:any) => setResetPasswordForm({...resetPasswordForm, confirmPassword: e.target.value})}
                                            leftIcon={<IconLock className="object-contain" />}
                                            rightIcon={<FontAwesomeIcon icon={viewPassword ? "eye" : "eye-slash"} className="text-gray-400" />}
                                            rightIconClicked={() => setViewPassword(!viewPassword)}
                                        />
                                    </div>

                                    <ButtonLoader type="submit" loading={resetPasswordLoading} className="mt-8 btn btn-lg btn-block btn-vetiva-brown">
                                        Activate Account
                                    </ButtonLoader>

                                    <div className="mt-3 text-center text-sm font-semibold text-gray-500">
                                        Already activated your account?&nbsp;
                                        <Link to="/login" className="text-vetiva-brown hover:underline">Login</Link>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </Maybe>

            </Maybe>


            <Maybe condition={token === "" || token === undefined}>
                <div className="text-center">
                    <div className="h-24">
                        <FontAwesomeIcon icon="exclamation-circle" className="h-full object-contain mx-auto text-red-500" />
                    </div>
                    <div className="mt-8 font-semibold text-2xl">
                        Token not found
                    </div>
                    <div className="mt-2 text-gray-500">
                        Sorry, your access token could not be found. Please recheck the url and try again.
                    </div>
                </div>
            </Maybe>


        </div>
    )
}

export default ActivateAccount;
