import { useEffect, useState } from "react";
import moment from "moment";


/* eslint-disable-next-line */
export interface TimeDateBlockProps {}

const dayOfWeek:any = {
  "0": "Sunday", "1": "Monday", "2": "Tuesday", "3": "Wednesday", "4": "Thursday", "5": "Friday", "6": "Saturday", "7": "Sunday"
}

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
]

export function TimeDateBlock(props: TimeDateBlockProps) {

  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [day, setDay] = useState("");
  const [dayFull, setDayFull] = useState("");


  const doubleDigit = (number: any) => {
    if (number <= 9){
      return "0" + number
    }
    return number;
  }


  const getMoment = () => {
    setHour(moment().hour());
    setMinute(moment().minute());
    setDay(moment().day().toString());

    const dayFull = doubleDigit(moment().date()) + " " + monthNames[moment().month()] + ", " + moment().year();
    setDayFull(dayFull);
  }


  useEffect(() => {
    getMoment();
    
    setInterval(() => {
      getMoment();
    }, 1000);
  }, [])


  return (
    <div className="flex items-center justify-between text-white">
      <div className="font-verdana text-5xl font-medium">
        {doubleDigit(hour)}:{doubleDigit(minute)}
      </div>
      <div className="relative top-0.5 text-base tracking-wider font-arial-light">
        <div>{dayOfWeek[day]}</div>
        <div>{dayFull}</div>
      </div>
    </div>
  );
}

export default TimeDateBlock;
