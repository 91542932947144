import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import validate from "utils/validate";
import { Input } from "components/ui";
import ButtonLoader from "components/ButtonLoader";
import {ReactComponent as IconMail } from "assets/images/icons/mail-gray.svg";
import * as user from "store/auth/user/action";


const ForgotPassword = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { forgotPasswordLoading, forgotPassword } = useSelector((s: any) => s.auth.user);

    const [forgotPasswordForm, setForgotPasswordForm] = useState({
        email: "",
    })


    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();

        const errors:any = validate(forgotPasswordForm);
        if (errors){
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            dispatch(user.forgotPassword(forgotPasswordForm));
        }
    }


    useEffect(() => {
        if (forgotPassword === true){
            navigate("/forgot-password/success");
        }
        // eslint-disable-next-line
    }, [forgotPassword]);

    
    return (
        <div className="w-full max-w-md lg:w-96 mx-auto md:mx-0">

            <div className="text-2xl font-semibold">
                Forgot Password
            </div>
            <div className="mt-1 text-sm">
                Enter your email address to proceed
            </div>


            <div>
                <form onSubmit={submitForm}>
                    <div className="mt-10">
                    
                        <div className="mb-5">
                            <Input type="email" label="Email Address" value={forgotPasswordForm.email} onChange={(e:any) => setForgotPasswordForm({...forgotPasswordForm, email: e.target.value})} leftIcon={<IconMail />} />
                        </div>

                        <ButtonLoader type="submit" loading={forgotPasswordLoading} className="mt-8 btn btn-lg btn-block btn-vetiva-brown">
                            Send reset link
                        </ButtonLoader>

                        <div className="mt-4 text-center text-sm text-gray-500 font-semibold">
                            Remember your password?&nbsp;
                            <Link to="/login" className="text-vetiva-brown hover:underline">Login</Link>

                            <div className="mt-2 md:mt-1"></div>

                            By continuing, you agree to our&nbsp;
                            <Link to="/terms" className="text-vetiva-brown hover:underline">Terms of service</Link>
                        </div>

                    </div>
                </form>
            </div>

        </div>
    )
}

export default ForgotPassword;
