
export const pluralize = (word, count = 0, suffix='') => {   
    if (((word !== undefined) && (word !== '')) && ((count !== null) && (count !== ''))){
        if (count > 1){
            if ((suffix !== undefined) && (suffix !== '')){
                return word + suffix;
            }
            else{
                return word + "s";
            }
        }
        else{
            return word;
        }
    }
    else{
        return null;
    }
};

export const pluralizeFull = (count, wordSingular, wordPlural) => {   
    if (isNaN(count)){
        return wordSingular;
    }

    if (count > 1){
        return wordPlural;
    }
    else{
        return wordSingular;
    }
};

export default pluralize;