import { Outlet } from "react-router-dom";
import Header from "components/Header";
import Footer from "components/Footer";

const GeneralLayout = () => {
    return (
        <>
            <Header />

            <div className="mt-16 min-h-[500px] text-sm">
                <Outlet />
            </div>

            <Footer />
        </>
    )
}

export default GeneralLayout
