import { useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import useLoginInfo from "hooks/useLoginInfo";

import GeneralLayout from './components/layouts/GeneralLayout';
import CompanyLayout from './components/layouts/CompanyLayout';
import MemberLayout from './components/layouts/MemberLayout';
import AuthLayout from './components/layouts/AuthLayout';
import NullLayout from './components/layouts/NullLayout';
import AuthenticationLayer from './components/layouts/AuthenticationLayer';

import Error401 from './pages/errors/Error401';
import Error404 from './pages/errors/Error404';

import Index from './pages/index';
import Login from './pages/auth/login';
import AdminLogin from './pages/auth/adminLogin';
import ForgotPassword from './pages/auth/forgotPassword';
import ForgotPasswordSuccess from './pages/auth/forgotPasswordSuccess';
import ResetPassword from './pages/auth/resetPassword';
import ResetPasswordSuccess from './pages/auth/resetPasswordSuccess';
import ActivateAccount from './pages/auth/activateAccount';
import ActivateAccountSuccess from './pages/auth/activateAccountSuccess';

import SwitchView from './pages/general/switchView';
import Terms from './pages/general/terms';

import TrusteeOverview from './pages/trustee/overview';
import CompanyList from './pages/trustee/company/list';
import { CompanyCreateCompany, CompanyCreateAdmin, CompanyCreateSettings, CompanyCreateSubschemeSettings, CompanyCreatePreview, CompanyCreateSuccess } from "./pages/trustee/company/create_edit";
import { CompanyEmployeesList, CompanyEmployeesCreateOptions, CompanyEmployeesCreateBulkUpload, CompanyEmployeesCreateBulkSuccess } from "./pages/trustee/company/employees";
import { CompanyEmployeesCreateSinglePersonal, CompanyEmployeesCreateSingleOfficial, CompanyEmployeesCreateSingleScheme, CompanyEmployeesCreateSingleBeneficiary, CompanyEmployeesCreateSinglePreview, CompanyEmployeesCreateSingleSuccess } from "./pages/trustee/company/employees";
import { CompanyEmployeeDetails, StatementOfBeneficialInterest, StatementOfBeneficialInterestSample, CompanyEmployeeSchemeDetails, CompanyEmployeeInstructionScheme, CompanyEmployeeInstructionType, CompanyEmployeeInstructionTransfer, CompanyEmployeeInstructionTransferAmount, CompanyEmployeeInstructionTransferPreview, CompanyEmployeeInstructionTransferSuccess, CompanyEmployeeInstructionSales, CompanyEmployeeInstructionSalesPreview, CompanyEmployeeInstructionSalesSuccess } from "./pages/trustee/company/employees";
import UsersMgmtList from './pages/trustee/user-mgmt/list';

// import CompanyIndex from './pages/company/index';
import CompanyOverview from './pages/company/overview';
import CompanyOverviewDetails from './pages/company/overview/details';
import CompanyUsersMgmtList from './pages/company/user-mgmt/list';

// import MemberIndex from './pages/member/index';


import { library } from '@fortawesome/fontawesome-svg-core';
// import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas, far);


const withRouter = (Component: any) => {
    function ComponentWithRouterProp(props: any) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }
    return ComponentWithRouterProp;
}

const ScrollToTopComponent = () => {
    const mounted = useRef(false);
    useEffect(() => {
        if (!mounted.current) {
            //componentDidMount
            mounted.current = true
        } else {
            //componentDidUpdate
            window.scrollTo(0, 0)
        }
    })
    return null
}
export const ScrollToTop = withRouter(ScrollToTopComponent)



function App() {
    const {userLogin} = useLoginInfo();

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                
                
                <Route path="/" element={<Index />} />
                

                <Route element={<AuthLayout />}>
                    <Route element={<AuthenticationLayer userAuth="loggedOut" />}>
                        <Route path="/login" element={<Login />} />
                        <Route path="/admin" element={<Navigate to="/admin/login" replace />} />
                        <Route path="/admin/login" element={<AdminLogin />} />
                        <Route path="/trustee/login" element={<AdminLogin />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/forgot-password/success" element={<ForgotPasswordSuccess />} />
                    </Route>

                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/reset-password/success" element={<ResetPasswordSuccess />} />
                    <Route path="/activate-account/" element={<ActivateAccount />} />
                    <Route path="/activate-account/success" element={<ActivateAccountSuccess />} />
                </Route>


                <Route path="/trustee">
                    {!userLogin &&
                        <Route path="" element={<Navigate to="/trustee/login" replace />} />
                    }
                    {userLogin &&
                        <Route path="" element={<Navigate to="/trustee/overview" replace />} />
                    }

                    <Route element={<AuthenticationLayer userAuth="loggedIn" userType={["TRUSTEE"]} />}>
                        
                        <Route element={<GeneralLayout />}>
                            <Route path="overview" element={<TrusteeOverview />} />
                        </Route>

                        <Route path="company">
                            <Route element={<GeneralLayout />}>
                                <Route path="" element={<CompanyList />} />
                            </Route>

                            <Route path="create" element={<CompanyCreateCompany />} />
                            
                            <Route path="form/:id">
                                <Route path="company-details" element={<CompanyCreateCompany />} />
                                <Route path="admin-details" element={<CompanyCreateAdmin />} />
                                <Route path="company-settings" element={<CompanyCreateSettings />} />
                                <Route path="company-subscheme/:schemeTypeIndexPlus/:schemeType" element={<CompanyCreateSubschemeSettings />} />
                                <Route path="preview" element={<CompanyCreatePreview />} />
                                <Route path="success" element={<CompanyCreateSuccess />} />
                            </Route>


                            <Route path=":companyId">
                                <Route element={<GeneralLayout />}>
                                    <Route path="" element={<CompanyEmployeesList />} />
                                </Route>

                                <Route path="create">
                                    <Route path="" element={<CompanyEmployeesCreateOptions />} />
                                    <Route path="personal" element={<CompanyEmployeesCreateSinglePersonal />} />
                                    <Route path="bulk/upload" element={<CompanyEmployeesCreateBulkUpload />} />
                                    <Route path="bulk/success" element={<CompanyEmployeesCreateBulkSuccess />} />
                                </Route>

                                <Route path="form/:id">
                                    <Route path="" element={<CompanyEmployeesCreateSinglePersonal />} />
                                    <Route path="personal" element={<CompanyEmployeesCreateSinglePersonal />} />
                                    <Route path="official" element={<CompanyEmployeesCreateSingleOfficial />} />
                                    <Route path="scheme" element={<CompanyEmployeesCreateSingleScheme />} />
                                    <Route path="beneficiary" element={<CompanyEmployeesCreateSingleBeneficiary />} />
                                    <Route path="preview" element={<CompanyEmployeesCreateSinglePreview />} />
                                    <Route path="success" element={<CompanyEmployeesCreateSingleSuccess />} />
                                </Route>

                                <Route path="employee/:employeeId">
                                    <Route element={<GeneralLayout />}>
                                        <Route path="" element={<CompanyEmployeeDetails />} />
                                        <Route path="scheme/:schemeTypeId" element={<CompanyEmployeeSchemeDetails />} />
                                    </Route>

                                    <Route path="edit">
                                        <Route path="" element={<CompanyEmployeesCreateSinglePersonal />} />
                                    </Route>

                                    <Route path="instruction">
                                        <Route path="" element={<CompanyEmployeeInstructionScheme />} />
                                        <Route path=":schemeTypeId" element={<CompanyEmployeeInstructionType />} />
                                        <Route path=":schemeTypeId/transfer" element={<CompanyEmployeeInstructionTransfer />} />
                                        <Route path=":schemeTypeId/transfer-preview" element={<CompanyEmployeeInstructionTransferPreview />} />
                                        <Route path=":schemeTypeId/transfer-amount" element={<CompanyEmployeeInstructionTransferAmount />} />
                                        <Route path=":schemeTypeId/transfer-success" element={<CompanyEmployeeInstructionTransferSuccess />} />
                                        <Route path=":schemeTypeId/sales" element={<CompanyEmployeeInstructionSales />} />
                                        <Route path=":schemeTypeId/sales-preview" element={<CompanyEmployeeInstructionSalesPreview />} />
                                        <Route path=":schemeTypeId/sales-success" element={<CompanyEmployeeInstructionSalesSuccess />} />
                                    </Route>
                                </Route>
                            </Route>
                            

                        </Route>

                        <Route element={<GeneralLayout />}>
                            <Route path="users" element={<UsersMgmtList />} />
                        </Route>

                    </Route>

                </Route>


                <Route path="/company">
                    {!userLogin &&
                        <Route path="" element={<Navigate to="/login" replace />} />
                    }
                    {userLogin &&
                        <Route path="" element={<Navigate to="/company/overview" replace />} />
                    }

                    <Route element={<AuthenticationLayer userAuth="loggedIn" userType={["SCHEME_COMMITTEE"]} />}>
                        <Route element={<CompanyLayout />}>
                            <Route path="overview" element={<CompanyOverview />} />
                            <Route path="overview/scheme/:schemeTypeId" element={<CompanyOverviewDetails />} />
                            <Route path="users" element={<CompanyUsersMgmtList />} />
                        </Route>

                        <Route path="employees">
                            <Route element={<CompanyLayout />}>
                                <Route path="" element={<CompanyEmployeesList />} />
                            </Route>

                            <Route path="create">
                                <Route path="" element={<CompanyEmployeesCreateOptions />} />
                                <Route path="personal" element={<CompanyEmployeesCreateSinglePersonal />} />
                                <Route path="bulk/upload" element={<CompanyEmployeesCreateBulkUpload />} />
                                <Route path="bulk/success" element={<CompanyEmployeesCreateBulkSuccess />} />
                            </Route>

                            <Route path="form/:id">
                                <Route path="" element={<CompanyEmployeesCreateSinglePersonal />} />
                                <Route path="personal" element={<CompanyEmployeesCreateSinglePersonal />} />
                                <Route path="official" element={<CompanyEmployeesCreateSingleOfficial />} />
                                <Route path="scheme" element={<CompanyEmployeesCreateSingleScheme />} />
                                <Route path="beneficiary" element={<CompanyEmployeesCreateSingleBeneficiary />} />
                                <Route path="preview" element={<CompanyEmployeesCreateSinglePreview />} />
                                <Route path="success" element={<CompanyEmployeesCreateSingleSuccess />} />
                            </Route>

                            <Route path=":employeeId">
                                <Route element={<CompanyLayout />}>
                                    <Route path="" element={<CompanyEmployeeDetails />} />
                                    <Route path="scheme/:schemeTypeId" element={<CompanyEmployeeSchemeDetails />} />
                                </Route>

                                <Route path="edit">
                                    <Route path="" element={<CompanyEmployeesCreateSinglePersonal />} />
                                </Route>

                                <Route path="instruction">
                                    <Route path="" element={<CompanyEmployeeInstructionScheme />} />
                                    <Route path=":schemeTypeId" element={<CompanyEmployeeInstructionType />} />
                                    <Route path=":schemeTypeId/transfer" element={<CompanyEmployeeInstructionTransfer />} />
                                    <Route path=":schemeTypeId/transfer-preview" element={<CompanyEmployeeInstructionTransferPreview />} />
                                    <Route path=":schemeTypeId/transfer-amount" element={<CompanyEmployeeInstructionTransferAmount />} />
                                    <Route path=":schemeTypeId/transfer-success" element={<CompanyEmployeeInstructionTransferSuccess />} />
                                    <Route path=":schemeTypeId/sales" element={<CompanyEmployeeInstructionSales />} />
                                    <Route path=":schemeTypeId/sales-preview" element={<CompanyEmployeeInstructionSalesPreview />} />
                                    <Route path=":schemeTypeId/sales-success" element={<CompanyEmployeeInstructionSalesSuccess />} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>


                <Route path="/member">
                    {!userLogin &&
                        <Route path="" element={<Navigate to="/login" replace />} />
                    }
                    {userLogin &&
                        <Route path="" element={<Navigate to="/member/overview" replace />} />
                    }

                    <Route element={<AuthenticationLayer userAuth="loggedIn" userType={["SCHEME_MEMBER"]} />}>
                        <Route element={<MemberLayout />}>
                            <Route path="overview" element={<CompanyEmployeeDetails />} />
                            <Route path="scheme/:schemeTypeId" element={<CompanyEmployeeSchemeDetails />} />
                        </Route>
                        <Route path="statement-of-beneficiary-interest/:companyId/:employeeId" element={<StatementOfBeneficialInterest />} />
                        <Route path="instruction">
                            <Route path="" element={<CompanyEmployeeInstructionScheme />} />
                            <Route path=":schemeTypeId" element={<CompanyEmployeeInstructionType />} />
                            <Route path=":schemeTypeId/transfer" element={<CompanyEmployeeInstructionTransfer />} />
                            <Route path=":schemeTypeId/transfer-preview" element={<CompanyEmployeeInstructionTransferPreview />} />
                            <Route path=":schemeTypeId/transfer-amount" element={<CompanyEmployeeInstructionTransferAmount />} />
                            <Route path=":schemeTypeId/transfer-success" element={<CompanyEmployeeInstructionTransferSuccess />} />
                            <Route path=":schemeTypeId/sales" element={<CompanyEmployeeInstructionSales />} />
                            <Route path=":schemeTypeId/sales-preview" element={<CompanyEmployeeInstructionSalesPreview />} />
                            <Route path=":schemeTypeId/sales-success" element={<CompanyEmployeeInstructionSalesSuccess />} />
                        </Route>
                    </Route>
                </Route>


                
                <Route path="/" element={<NullLayout />}>
                    <Route path="/switch-view" element={<SwitchView />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/401" element={<Error401 />} />
                    <Route path="/404" element={<Error404 />} />
                </Route>


                <Route path="statement-of-beneficiary-interest" element={<StatementOfBeneficialInterestSample />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
                

            </Routes>
        </BrowserRouter>
    );
}

export default App;