import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import iconsuccess from "assets/images/icons/success.gif";
import useLoginInfo from "hooks/useLoginInfo";

export interface CompanyEmployeesCreateSingleSuccessProps {}

export function CompanyEmployeesCreateSingleSuccess(props: CompanyEmployeesCreateSingleSuccessProps) {
  let { companyId, id } = useParams();
  let navigationBaseUrl = "/trustee/company/"+companyId;
  const {userInfo} = useLoginInfo();
  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    navigationBaseUrl = "/company/employees"
  }

  return (
    <div className="bg-white min-h-screen">

      <FormWizardNavBar showSteps={true} currentStep={6} totalSteps={6} closeUrl={navigationBaseUrl} />

      <div className="container mt-20 max-w-2xl mx-auto text-center">
        
          <div className="h-48">
            <img src={iconsuccess} alt="Success" className="object-contain" />
          </div>

          <div className="mt-4 text-2xl">
            Successful!
          </div>
          <div className="mt-1 text-gray-400">
            You have successfully added
          </div>

          <div>
            <div className="mt-6 flex flex-wrap space-x-6 justify-center">
              <Link to={navigationBaseUrl + "/create"} className="underline text-vetiva-brown">
                Add new employee
              </Link>
              <Link to={navigationBaseUrl + "/employee/" + id} className="underline text-vetiva-brown">
                View employee profile
              </Link>
            </div>
            <div className="mt-6">
              <Link to={navigationBaseUrl} className="underline text-vetiva-brown">
                Go to company's page
              </Link>
            </div>
          </div>

      </div>

    </div>
  );
}

export default CompanyEmployeesCreateSingleSuccess;
