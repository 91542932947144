import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TimeDateBlock } from "components/time-date-block";
import { PieChart } from "components/ui/charts/pie-chart";
import iconBuilding from "assets/images/icons/building-alt.svg";
import iconShares from "assets/images/icons/shares-gray.svg";
import iconGroup from "assets/images/icons/group.svg";
import Maybe from "components/Maybe";
import NumberFormat from "utils/numberFormat";
import { PageContent } from "components/PageContent";
import { OverviewDashboardCompany } from "components/OverviewDashboardCompany";
import useLoginInfo from "hooks/useLoginInfo";
import CompanyLogoName from "components/CompanyLogoName";
import * as overviewAction from "store/entities/overview/action";
import * as companyAction from "store/entities/company/action";


const Index = () => {
    const {userInfo} = useLoginInfo();
    const companyIdDefault = userInfo.organizationId;

        
    const location = useLocation();
    const dispatch = useDispatch();
    const { shareDistributionLoading, shareDistribution, schemeMetricsAwardsLoading, schemeMetricsAwards, schemeMetricsOptionsLoading, schemeMetricsOptions, schemeMetricsPurchaseLoading, schemeMetricsPurchase, schemeMetricsLtipLoading, schemeMetricsLtip, schemeMetricsOthersLoading, schemeMetricsOthers } = useSelector((s: any) => s.entities.overview);
    const { companyLoading, company } = useSelector((s: any) => s.entities.company);


    const getCompanyShareDistribution = (id:string) => {
        dispatch(overviewAction.getCompanyShareDistribution(id));
    }


    const getCompanyDetails = (id:string) => {
        dispatch(companyAction.getCompany(id));
    }


    const getCompanySchemeTypeInfo = () => {
        dispatch(overviewAction.resetAllSchemeMetrics());

        const id = companyIdDefault;
        if (id !== "" && id !== null){
                
            const schemeTypes = company.data?.organizationShareSetting?.schemeTypes;
            schemeTypes && schemeTypes.map((schemeType:any) => {
                if (schemeType.name === "Access to Share Awards"){
                    dispatch(overviewAction.getSchemeMetricAwards(id));
                }
                else if (schemeType.name === "Access to Share Options"){
                    dispatch(overviewAction.getSchemeMetricOptions(id));
                }
                else if (schemeType.name === "Access to Share Purchase"){
                    dispatch(overviewAction.getSchemeMetricPurchase(id));
                }
                else if (schemeType.name === "Access to Long Term Incentice Plan (LTIP)"){
                    dispatch(overviewAction.getSchemeMetricLtip(id));
                }
                else if (schemeType.name === "Access to Others"){
                    dispatch(overviewAction.getSchemeMetricOthers(id));
                }
                return null;
            })

        }
    }


    const isSchemeTypesLoading = () => {
        if (
            schemeMetricsAwardsLoading === true ||
            schemeMetricsOptionsLoading === true ||
            schemeMetricsPurchaseLoading === true ||
            schemeMetricsLtipLoading === true ||
            schemeMetricsOthersLoading === true
        ){
            return true;
        }
        else{
            return false;
        }
    }


    const valueZero = (value:any) => {
        if (value === undefined || value === null || value === ""){
            return 0;
        }
        else{
            return parseInt(value);
        }
    }


    useEffect(() => {
        if (companyIdDefault !== "" && companyIdDefault !== null){
            getCompanyShareDistribution(companyIdDefault);
            getCompanyDetails(companyIdDefault);
        }
    }, [location]);


    useEffect(() => {
        if (company.success && company.success === true && company.data !== undefined){
            getCompanySchemeTypeInfo();
        }
    }, [company]);


    return (
        <div className="pb-10">

            <div className="bg-vetiva-black">
                <div className="container pt-16 pb-24 flex flex-wrap">
                    <div className="w-full xl:w-3/12">
                        <div className="h-full flex flex-col justify-between">
                            <div className="overflow-hidden">
                                <TimeDateBlock />
                            </div>
                        </div>
                    </div>
                    <div className="w-full xl:w-9/12">
                        <div className="ml-10">

                            <div className="-mt-12 mb-3">
                                <CompanyLogoName company={company} />
                            </div>

                            <div className="w-full flex space-x-4">
                                <div className="w-[60%] h-36 p-6 bg-white flex items-center rounded-lg">
                                    <div className="flex-grow flex">
                                        <div>
                                            <div className="h-12 text-left">
                                                <img src={iconShares} alt="Bonus Shares" className="object-contain w-12" />
                                            </div>
                                            <div className="mt-1 text-sm text-gray-500">
                                                Total shares alloted
                                            </div>
                                            <div className="text-2xl">
                                                <Maybe condition={isSchemeTypesLoading() === true}>
                                                    <FontAwesomeIcon icon="spinner" spin />
                                                </Maybe>
                                                <Maybe condition={isSchemeTypesLoading() === false}>
                                                    {NumberFormat(
                                                        valueZero(schemeMetricsAwards?.data?.totalSharesAllocatedToMembers) +
                                                        valueZero(schemeMetricsOptions?.data?.totalSharesAllocatedToMembers) +
                                                        valueZero(schemeMetricsPurchase?.data?.totalSharesAllocatedToMembers) +
                                                        valueZero(schemeMetricsLtip?.data?.totalSharesAllocatedToMembers) +
                                                        valueZero(schemeMetricsOthers?.data?.totalSharesAllocatedToMembers) +

                                                        valueZero(schemeMetricsAwards?.data?.totalSharesUnAllocatedToMembers) +
                                                        valueZero(schemeMetricsOptions?.data?.totalSharesUnAllocatedToMembers) +
                                                        valueZero(schemeMetricsPurchase?.data?.totalSharesUnAllocatedToMembers) +
                                                        valueZero(schemeMetricsLtip?.data?.totalSharesUnAllocatedToMembers) +
                                                        valueZero(schemeMetricsOthers?.data?.totalSharesUnAllocatedToMembers)
                                                    , "no-kobo") || "---"}
                                                </Maybe>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-6 flex-shrink-0 flex items-center justify-center">/</div>
                                    <div className="flex-grow flex">
                                        <div>
                                            <div className="h-12 text-left">
                                                <img src={iconShares} alt="Total shares allocated" className="object-contain w-12" />
                                            </div>
                                            <div className="mt-1 text-sm text-gray-500">
                                                Total shares allocated
                                            </div>
                                            <div className="text-2xl">
                                                <Maybe condition={isSchemeTypesLoading() === true}>
                                                    <FontAwesomeIcon icon="spinner" spin />
                                                </Maybe>
                                                <Maybe condition={isSchemeTypesLoading() === false}>
                                                    {NumberFormat(
                                                        valueZero(schemeMetricsAwards?.data?.totalSharesAllocatedToMembers) +
                                                        valueZero(schemeMetricsOptions?.data?.totalSharesAllocatedToMembers) +
                                                        valueZero(schemeMetricsPurchase?.data?.totalSharesAllocatedToMembers) +
                                                        valueZero(schemeMetricsLtip?.data?.totalSharesAllocatedToMembers) +
                                                        valueZero(schemeMetricsOthers?.data?.totalSharesAllocatedToMembers)
                                                    , "no-kobo") || "---"}
                                                </Maybe>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-6 flex-shrink-0 flex items-center justify-center">/</div>
                                    <div className="flex-grow flex">
                                        <div>
                                            <div className="h-12 text-left">
                                                <img src={iconShares} alt="Total shares unallocated" className="object-contain w-12" />
                                            </div>
                                            <div className="mt-1 text-sm text-gray-500">
                                                Total shares unallocated
                                            </div>
                                            <div className="text-2xl">
                                                <Maybe condition={isSchemeTypesLoading() === true}>
                                                    <FontAwesomeIcon icon="spinner" spin />
                                                </Maybe>
                                                <Maybe condition={isSchemeTypesLoading() === false}>
                                                    {NumberFormat(
                                                        valueZero(schemeMetricsAwards?.data?.totalSharesUnAllocatedToMembers) +
                                                        valueZero(schemeMetricsOptions?.data?.totalSharesUnAllocatedToMembers) +
                                                        valueZero(schemeMetricsPurchase?.data?.totalSharesUnAllocatedToMembers) +
                                                        valueZero(schemeMetricsLtip?.data?.totalSharesUnAllocatedToMembers) +
                                                        valueZero(schemeMetricsOthers?.data?.totalSharesUnAllocatedToMembers)
                                                    , "no-kobo") || "---"}
                                                </Maybe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-[40%] h-36 p-6 bg-white flex items-center rounded-lg">
                                    <div className="flex-grow flex">
                                        <div>
                                            <div className="h-12 text-left">
                                                <img src={iconBuilding} alt="Total scheme" className="object-contain w-12" />
                                            </div>
                                            <div className="mt-1 text-sm text-gray-500">
                                                Total scheme
                                            </div>
                                            <div className="text-2xl">
                                                <Maybe condition={isSchemeTypesLoading() === true}>
                                                    <FontAwesomeIcon icon="spinner" spin />
                                                </Maybe>
                                                <Maybe condition={isSchemeTypesLoading() === false}>
                                                    {NumberFormat(company.data?.organizationShareSetting?.schemeTypes.length, "no-kobo") || "---"}
                                                </Maybe>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-6 flex-shrink-0 flex items-center justify-center">/</div>
                                    <div className="flex-grow flex">
                                        <div>
                                            <div className="h-12 text-left">
                                                <img src={iconGroup} alt="Scheme members" className="object-contain w-12" />
                                            </div>
                                            <div className="mt-1 text-sm text-gray-500">
                                                Scheme members
                                            </div>
                                            <div className="text-2xl">
                                                <Maybe condition={isSchemeTypesLoading() === true}>
                                                    <FontAwesomeIcon icon="spinner" spin />
                                                </Maybe>
                                                <Maybe condition={isSchemeTypesLoading() === false}>
                                                    {NumberFormat(
                                                        valueZero(schemeMetricsAwards?.data?.totalSchemeMembers) +
                                                        valueZero(schemeMetricsOptions?.data?.totalSchemeMembers) +
                                                        valueZero(schemeMetricsPurchase?.data?.totalSchemeMembers) +
                                                        valueZero(schemeMetricsLtip?.data?.totalSchemeMembers) +
                                                        valueZero(schemeMetricsOthers?.data?.totalSchemeMembers)
                                                    , "no-kobo") || "---"}
                                                </Maybe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container flex -mt-16">
                <div className="w-full md:w-3/12">
                    <div className="text-white mb-2 font-bold text-sm">
                        Share distribution
                    </div>

                    <div className="p-6 bg-white shadow-md rounded-lg">

                        <PageContent loading={shareDistributionLoading} data={shareDistribution.data || undefined} emptyText="Info not found" errorText="Info not found" />
                        
                        <Maybe condition={!shareDistributionLoading}>
                            <Maybe condition={shareDistribution.data !== undefined}>
                                <div className="w-48 h-48 mx-auto">
                                    <PieChart data={[
                                        Math.round(shareDistribution.data?.shareAwardPercentage) || 0,
                                        Math.round(shareDistribution.data?.shareOptionPercentage) || 0,
                                        Math.round(shareDistribution.data?.sharePurchasePercentage) || 0,
                                        Math.round(shareDistribution.data?.longTermIncentivePercentage) || 0,
                                        Math.round(shareDistribution.data?.othersPercentage) || 0,
                                    ]} />
                                </div>
                            </Maybe>

                            <div className="mt-6 space-y-6 text-gray-600">
                                <div className="flex space-x-2 justify-between">
                                    <div>
                                        Share award
                                    </div>
                                    <div className="font-bold text-right">
                                        <Maybe condition={shareDistribution.data?.totalShareAwardAllocated !== undefined}>
                                            {NumberFormat(shareDistribution.data?.totalShareAwardAllocated, "no-kobo")} ({Math.round(shareDistribution.data?.shareAwardPercentage)}%)
                                        </Maybe>
                                        <Maybe condition={shareDistribution.data?.totalShareAwardAllocated === undefined}>
                                            ---
                                        </Maybe>
                                    </div>
                                </div>
                                <div className="flex space-x-2 justify-between">
                                    <div>
                                        Share option
                                    </div>
                                    <div className="font-bold text-right">
                                        <Maybe condition={shareDistribution.data?.totalShareOptionAllocated !== undefined}>
                                            {NumberFormat(shareDistribution.data?.totalShareOptionAllocated, "no-kobo")} ({Math.round(shareDistribution.data?.shareOptionPercentage)}%)
                                        </Maybe>
                                        <Maybe condition={shareDistribution.data?.totalShareOptionAllocated === undefined}>
                                            ---
                                        </Maybe>
                                    </div>
                                </div>
                                <div className="flex space-x-2 justify-between">
                                    <div>
                                        Share purchase
                                    </div>
                                    <div className="font-bold text-right">
                                        <Maybe condition={shareDistribution.data?.totalSharePurchaseAllocated !== undefined}>
                                            {NumberFormat(shareDistribution.data?.totalSharePurchaseAllocated, "no-kobo")} ({Math.round(shareDistribution.data?.sharePurchasePercentage)}%)
                                        </Maybe>
                                        <Maybe condition={shareDistribution.data?.totalSharePurchaseAllocated === undefined}>
                                            ---
                                        </Maybe>
                                    </div>
                                </div>
                                <div className="flex space-x-2 justify-between">
                                    <div>
                                        Long term incentive
                                    </div>
                                    <div className="font-bold text-right">
                                        <Maybe condition={shareDistribution.data?.totalLongTermIncentiveAllocated !== undefined}>
                                            {NumberFormat(shareDistribution.data?.totalLongTermIncentiveAllocated, "no-kobo")} ({Math.round(shareDistribution.data?.longTermIncentivePercentage)}%)
                                        </Maybe>
                                        <Maybe condition={shareDistribution.data?.totalLongTermIncentiveAllocated === undefined}>
                                            ---
                                        </Maybe>
                                    </div>
                                </div>
                                <div className="flex space-x-2 justify-between">
                                    <div>
                                        Others
                                    </div>
                                    <div className="font-bold text-right">
                                        <Maybe condition={shareDistribution.data?.totalOthersAllocated !== undefined}>
                                            {NumberFormat(shareDistribution.data?.totalOthersAllocated, "no-kobo")} ({Math.round(shareDistribution.data?.othersPercentage)}%)
                                        </Maybe>
                                        <Maybe condition={shareDistribution.data?.totalOthersAllocated === undefined}>
                                            ---
                                        </Maybe>
                                    </div>
                                </div>
                            </div>
                        </Maybe>

                    </div>
                </div>

                <div className="w-full md:w-9/12">
                    <div className="ml-10">
                        <div className="text-white mb-2 font-bold text-sm">
                            Scheme details
                        </div>

                        <div>
                            <PageContent loading={companyLoading} data={company.data || undefined} loadingText="Loading company information..." emptyText="Info not found" errorText="Info not found" />

                            <Maybe condition={!companyLoading}>
                                <OverviewDashboardCompany name="Share Award" loading={schemeMetricsAwardsLoading} data={schemeMetricsAwards} />
                                <OverviewDashboardCompany name="Share Option" loading={schemeMetricsOptionsLoading} data={schemeMetricsOptions} />
                                <OverviewDashboardCompany name="Share Purchase" loading={schemeMetricsPurchaseLoading} data={schemeMetricsPurchase} />
                                <OverviewDashboardCompany name="Long-term Incentive" loading={schemeMetricsLtipLoading} data={schemeMetricsLtip} />
                                <OverviewDashboardCompany name="Others" loading={schemeMetricsOthersLoading} data={schemeMetricsOthers} />
                            </Maybe>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default Index