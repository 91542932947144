import * as actions from "./actionTypes";
import * as config from "data/config";
// import ObjectToQueryString from 'utils/ObjectToQueryString';

export function getDashboardStats() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiOverviewDashboardStats,
            method: "get",
            data: {},
            onStart: actions.GET_DASHBOARD_STATS_START,
            onSuccess: actions.GET_DASHBOARD_STATS,
            onError: actions.GET_DASHBOARD_STATS_FAILED,
        },
    }
}

export function getCompanyShareDistribution(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCompany}/${id}/get-shares-distribution`,
            method: "get",
            data: {},
            onStart: actions.GET_SHARE_DISTRIBUTION_START,
            onSuccess: actions.GET_SHARE_DISTRIBUTION,
            onError: actions.GET_SHARE_DISTRIBUTION_FAILED,
        },
    }
}

export function getSchemeMetricAwards(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCompany}/${id}/share-award-metric`,
            method: "get",
            data: {},
            onStart: actions.GET_SCHEME_METRIC_AWARDS_START,
            onSuccess: actions.GET_SCHEME_METRIC_AWARDS,
            onError: actions.GET_SCHEME_METRIC_AWARDS_FAILED,
        },
    }
}

export function getSchemeMetricOptions(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCompany}/${id}/share-options-metric`,
            method: "get",
            data: {},
            onStart: actions.GET_SCHEME_METRIC_OPTIONS_START,
            onSuccess: actions.GET_SCHEME_METRIC_OPTIONS,
            onError: actions.GET_SCHEME_METRIC_OPTIONS_FAILED,
        },
    }
}

export function getSchemeMetricPurchase(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCompany}/${id}/share-purchase-metric`,
            method: "get",
            data: {},
            onStart: actions.GET_SCHEME_METRIC_PURCHASE_START,
            onSuccess: actions.GET_SCHEME_METRIC_PURCHASE,
            onError: actions.GET_SCHEME_METRIC_PURCHASE_FAILED,
        },
    }
}

export function getSchemeMetricLtip(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCompany}/${id}/get-long-term-inception-plan-metric`,
            method: "get",
            data: {},
            onStart: actions.GET_SCHEME_METRIC_LTIP_START,
            onSuccess: actions.GET_SCHEME_METRIC_LTIP,
            onError: actions.GET_SCHEME_METRIC_LTIP_FAILED,
        },
    }
}

export function getSchemeMetricOthers(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCompany}/${id}/get-other-metric`,
            method: "get",
            data: {},
            onStart: actions.GET_SCHEME_METRIC_OTHERS_START,
            onSuccess: actions.GET_SCHEME_METRIC_OTHERS,
            onError: actions.GET_SCHEME_METRIC_OTHERS_FAILED,
        },
    }
}

export function resetAllSchemeMetrics() {
    return {
        type: actions.RESET_SCHEME_METRIC,
        payload: {},
    }
}
