import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";

const initialState = {
    countriesLoading: false,
    countries: [],
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_COUNTRIES:
            state = {
                ...state,
                countriesLoading: false,
                countries: [...action.payload.data?.docs]
            }
            return state;


        case actions.GET_COUNTRIES_START:
            state = {
                ...state,
                countriesLoading: true,
            }
            return state;


        case actions.GET_COUNTRIES_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                countriesLoading: false,
                countries: [],
            }
            return state;


        default:
            return state;
    }
}