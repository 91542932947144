import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useUrlNavigation from "hooks/useUrlNavigation";
import { ReactComponent as SearchArrowForwardIcon } from "assets/images/icons/searchArrowForwardIcon.svg";

/* eslint-disable-next-line */
export interface RfcSearchBoxProps {}

export function RfcSearchBox(props: RfcSearchBoxProps) {
  const {urlQuery} = useUrlNavigation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let searchQuery = searchParams.get("search");
  searchQuery = searchQuery !== null ? searchQuery : "";
  const [search, setSearch] = useState(searchQuery);

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    navigate("/trustee/company" + urlQuery("search=" + search));
  }

  return (
    <form onSubmit={submitForm}>
      <div className="relative">
        <button type="submit" className="w-10 h-full absolute top-0 right-0 cursor-pointer">
          <SearchArrowForwardIcon />
        </button>
        <input
          type="search"
          value={search}
          onChange={(e:any) => setSearch(e.target.value)}
          placeholder="Search RC Number"
          className="w-full h-12 pl-4 pr-12 py-1 border placeholder-white focus:outline-none focus:ring-2 focus:border-transparent text-white bg-gray-500 border-gray-700 rounded ring-white focus:ring-white"
        />
      </div>
    </form>
  );
}

export default RfcSearchBox;
