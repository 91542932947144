import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ReactToolTip from 'react-tooltip';
// import iconInfo from "assets/images/icons/info.svg";
import iconSend from "assets/images/icons/send.svg";
import iconShares from "assets/images/icons/shares.svg";
import iconFilter from "assets/images/icons/filter_list.svg";
import NumberFormat from "utils/numberFormat";
import Maybe from "components/Maybe";
import useUrlNavigation from "hooks/useUrlNavigation";
import CurrencyFormat, { CurrencySymbol } from "utils/currencyFormat";
import Pagination from "components/Pagination";
import DateFormat, { DateMiniFormat } from "utils/dateFormat";
import { FormDrawer, FileUploadPreview, Input } from "components/ui";
import { TimeDateBlock } from "components/time-date-block";
import { UserInfoCard } from "components/user-info-card";
import { nameInitials } from "utils/nameInitials";
import { PageContent } from "components/PageContent";
import { removeSchemeTypeNamePrefix } from "utils/misc";
import { pluralizeFull } from "utils/pluralize";
import { dateFormatSubmit } from "utils/dataTypes";
import { TableContent } from "components/TableContent";
import iconDownload from "assets/images/icons/download.svg";
import useLoginInfo from "hooks/useLoginInfo";
import CompanyLogoName from "components/CompanyLogoName";
import * as companyAction from "store/entities/company/action";
import * as employeeAction from "store/entities/employee/action";


const GetVariables = () => {
  const {userInfo} = useLoginInfo();
  let { companyId, employeeId } = useParams();
  let navigationBaseUrl = "/trustee/company/"+companyId;
  let navigationForwardUrl = navigationBaseUrl+"/employee/"+employeeId;
  
  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    navigationBaseUrl = "/company/employees";
    navigationForwardUrl = navigationBaseUrl + "/" + employeeId;
  }
  else if (userInfo.userType === "SCHEME_MEMBER"){
    companyId = userInfo.organizationId;
    employeeId = userInfo.employeeId;
    navigationBaseUrl = "/member";
    navigationForwardUrl = "/member";
  }

  return { userInfo, companyId, employeeId, navigationBaseUrl, navigationForwardUrl };
}



export interface CompanyEmployeeDetailsProps {}

export function CompanyEmployeeDetails(props: CompanyEmployeeDetailsProps) {
  const { userInfo, companyId, employeeId, navigationBaseUrl, navigationForwardUrl } = GetVariables();

  
  const dispatch = useDispatch();
  const { companyLoading, company } = useSelector((s: any) => s.entities.company);
  const { employeeLoading, employee: {data: employee}, employeeSchemeLoading, employeeDashboardLoading, employeeDashboard, employeeMetricsLoading, employeeMetrics } = useSelector((s: any) => s.entities.employee);


  const [isOpen, setIsOpen] = useState(false);
  const [formDrawerTitle, setFormDrawerTitle] = useState('');
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);


  const toggleFormDrawerProfile = (action: boolean) => { 
    setIsOpenProfile(action);
  };

  const toggleFormDrawer = (title: string) => { 
    setFormDrawerTitle(title);
    setIsOpen(!isOpen);
  };

  const toggleFormDrawerFilter = (action: boolean) => { 
    setIsOpenFilter(action);
  };


  const getDashboardStats = () => {
    dispatch(employeeAction.getEmployeeDashboard(employeeId));
  }
  

  const getCompany = () => {
    dispatch(companyAction.getCompany(companyId));
  }


  const getEmployee = () => {
    dispatch(employeeAction.getEmployee(employeeId));
    dispatch(employeeAction.getEmployeeMetrics(employeeId));
    dispatch(employeeAction.getEmployeeScheme(employeeId));
  }


  const getScheme = (id:string) => {
    const filteredSchemeType = company.data?.organizationShareSetting?.schemeTypes.filter((schemeType:any) => schemeType.id === id);
    return filteredSchemeType;
  }


  const getSchemeName = (schemeType:any) => {
    if (schemeType !== undefined){
      if (schemeType.length === 1){
        if (schemeType[0].name !== "Access to Others"){
          return schemeType[0].name;
        }
        else{
          return schemeType[0].schemeDetail?.otherSchemeName;
        }
      }
    }
    return "-";
  }


  const showSendInstruction = () => {
    const access = company.data?.organizationShareSetting?.canEmployeeInitiateShareSale;
    if (userInfo.userType !== "TRUSTEE"){
      if (access === true){
        return true;
      }
      return false;
    }
    return true;
  }


  useEffect(() => {
    getDashboardStats();
    getCompany();
    getEmployee();
  }, []);

  
  return (
    <div className="pb-10 bg-gray-100 min-h-screen">



      <div className="bg-vetiva-black">
        <div className="container pt-16 pb-24 flex flex-wrap">
          <div className="w-full xl:w-3/12">
            <div className="h-full flex xl:flex-col justify-between xl:justify-start">
              <TimeDateBlock />
              
            </div>
          </div>
          <div className="w-full xl:w-9/12">
            
            <div className="ml-10">

              {userInfo.userType !== "SCHEME_MEMBER" &&
                <div className="flex">
                  <Link to={navigationBaseUrl} className="w-8 h-7 flex mr-3 my-auto bg-gray-700 justify-center rounded group hover:bg-white">
                    <FontAwesomeIcon icon="angle-left" className="text-xl text-white my-auto group-hover:text-vetiva-brown" />
                  </Link>
                  <div className="text-2xl">
                    <Link to={navigationBaseUrl} className="text-vetiva-brown mr-2 hover:underline">
                      <Maybe condition={companyLoading}>
                        <FontAwesomeIcon icon="spinner" spin className="text-vetiva-brown" />
                      </Maybe>
                      <Maybe condition={!companyLoading}>
                        {company.data?.name}
                      </Maybe>
                    </Link>
                    <span className="text-gray-300">
                      /&nbsp;
                      <Maybe condition={employeeLoading}>
                        <FontAwesomeIcon icon="spinner" spin className="text-vetiva-brown" />
                      </Maybe>
                      <Maybe condition={!employeeLoading}>
                        {employee?.userInfo?.firstName} {employee?.userInfo?.lastName}
                      </Maybe>
                    </span>
                  </div>
                </div>
              }

              {userInfo.userType === "SCHEME_MEMBER" &&
                <div className="-mt-12 mb-3">
                  <CompanyLogoName company={company} />
                </div>
              }

              <div className="mt-3">

                <div className="flex space-x-4">

                  <div className="h-36 p-6 bg-white flex-grow flex items-center rounded-lg">
                    <div>
                      <div className="h-12 text-left">
                        <img src={iconShares} alt="Shares" className="object-contain w-12" />
                      </div>
                      <div className="mt-1 text-sm text-gray-500">
                        Total Allocation
                      </div>
                      <div className="text-2xl">
                        <Maybe condition={employeeDashboardLoading}>
                          <FontAwesomeIcon icon="spinner" spin />
                        </Maybe>
                        <Maybe condition={!employeeDashboardLoading}>
                          {NumberFormat(employeeDashboard?.data?.totalShareAllocation, "no-kobo") || "---"}
                        </Maybe>
                      </div>
                    </div>
                  </div>

                  <div className="h-36 p-6 bg-white flex-grow flex items-center rounded-lg">
                    <div>
                      <div className="h-12 text-left">
                        <img src={iconShares} alt="Shares" className="object-contain w-12" />
                      </div>
                      <div className="mt-1 text-sm text-gray-500">
                        Current Balance
                      </div>
                      <div className="text-2xl">
                        <Maybe condition={employeeDashboardLoading}>
                          <FontAwesomeIcon icon="spinner" spin />
                        </Maybe>
                        <Maybe condition={!employeeDashboardLoading}>
                          {NumberFormat(employeeDashboard?.data?.currentShareBalance, "no-kobo") || "---"}
                        </Maybe>
                      </div>
                    </div>
                  </div>

                  <div className="h-36 p-6 bg-white flex-grow flex items-center rounded-lg">
                    <div>
                      <div className="h-12 text-left">
                        <img src={iconShares} alt="Shares" className="object-contain w-12" />
                      </div>
                      <div className="mt-1 text-sm text-gray-500">
                        Bonus Shares
                      </div>
                      <div className="text-2xl">
                        <Maybe condition={employeeDashboardLoading}>
                          <FontAwesomeIcon icon="spinner" spin />
                        </Maybe>
                        <Maybe condition={!employeeDashboardLoading}>
                          {NumberFormat(employeeDashboard?.data?.totalBonusShares, "no-kobo") || "---"}
                        </Maybe>
                      </div>
                    </div>
                  </div>

                  {showSendInstruction() === true &&
                    <Link to={navigationForwardUrl + "/instruction"} className="w-32 h-36 p-2 flex-shrink-0 flex flex-col justify-center items-center text-white bg-vetiva-brown hover:bg-vetiva-brown-darker rounded-lg text-sm">
                      <div className="text-center -mt-5">
                        <img src={iconSend} alt="Send" className="mx-auto object-contain w-6" />
                        Send Instructions
                      </div>
                    </Link>
                  }

                </div>

              </div>
            </div>
            
          </div>
        </div>
      </div>



      
      <EmployeeDetailsModal
        isOpenProfile={isOpenProfile}
        setIsOpenProfile={setIsOpenProfile}
        toggleFormDrawerProfile={(e:boolean) => toggleFormDrawerProfile(e)}
      />



      <DividendSalesHistory
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isOpenFilter={isOpenFilter}
        setIsOpenFilter={setIsOpenFilter}
        formDrawerTitle={formDrawerTitle}
        toggleFormDrawer={(e:string) => toggleFormDrawer(e)}
        toggleFormDrawerFilter={(e:boolean) => toggleFormDrawerFilter(e)}
      />



      <Maybe condition={employeeLoading || employeeSchemeLoading}>
        <PageContent loading={employeeLoading} data={employee || undefined} emptyText="Employee not found" />
      </Maybe>

      

      <Maybe condition={!employeeLoading && !employeeSchemeLoading}>
        <div className="container flex -mt-16">
          <div className="w-full xl:w-4/12">
            <div className="p-6 bg-[#f9f6f1] shadow-md rounded-lg">
              <div className="text-center">
                <div className="w-16 h-16 text-white text-lg mx-auto text-center flex-shrink-0 flex items-center justify-center rounded-full bg-vetiva-purple">
                  {nameInitials(employee?.userInfo?.firstName, employee?.userInfo?.lastName)}
                </div>
                <div className="mt-4 text-xl font-bold">
                  {employee?.userInfo?.firstName} {employee?.userInfo?.lastName}
                </div>
                <div className="mt-3">
                  <label className="label bg-[#ece4d5] text-vetiva-brown font-normal rounded-md" style={{padding: "6px 20px"}}>
                    Employee ID: {employee?.employeeNumber}
                  </label>
                </div>
              </div>
              <div className="p-4 mt-8 text-sm border border-vetiva-brown border-opacity-10 rounded-md break-words">
                <div className="w-full flex flex-wrap justify-between space-x-2">
                {/* <div className="w-full"> */}
                  <div className="text-gray-400">
                    Email address:
                  </div>
                  <div className="text-gray-700 font-bold text-right">
                    {employee?.userInfo?.email}
                  </div>
                </div>
                <div className="mt-2 w-full flex flex-wrap justify-between space-x-2">
                  <div className="text-gray-400">
                    Phone number:
                  </div>
                  <div className="text-gray-700 font-bold text-right">
                    {employee?.userInfo?.phoneNumber}
                  </div>
                </div>
              </div>
              <div onClick={() => toggleFormDrawerProfile(true)} className="pt-4 text-base text-center text-vetiva-brown hover:underline cursor-pointer">
                View full profile
              </div>
            </div>

            <div onClick={() => toggleFormDrawer('Dividend History')} className="px-6 py-4 mt-5 font-bold flex justify-between text-vetiva-brown bg-white hover:bg-gray-100 shadow-md rounded-lg cursor-pointer text-sm">
              <div>
                Dividend history
              </div>
              <FontAwesomeIcon icon="angle-right" />
            </div>

            <div onClick={() => toggleFormDrawer('Share Sales History')} className="px-6 py-4 mt-5 font-bold flex justify-between text-vetiva-brown bg-white hover:bg-gray-100 shadow-md rounded-lg cursor-pointer text-sm">
              <div>
                Share sales history
              </div>
              <FontAwesomeIcon icon="angle-right" />
            </div>

            <div onClick={() => toggleFormDrawer('Share Transfer History')} className="px-6 py-4 mt-5 font-bold flex justify-between text-vetiva-brown bg-white hover:bg-gray-100 shadow-md rounded-lg cursor-pointer text-sm">
              <div>
                Share transfer history
              </div>
              <FontAwesomeIcon icon="angle-right" />
            </div>

            <Maybe condition={userInfo.userType === "SCHEME_MEMBER"}>
              <Link to={"/member/statement-of-beneficiary-interest/" + companyId + "/" + employeeId} target="_blank" className="px-6 py-4 mt-5 font-bold flex justify-start text-vetiva-brown bg-white hover:bg-gray-100 shadow-md rounded-lg cursor-pointer text-sm">
                <div className="w-10 h-5 text-left flex-shrink-0 -ml-3">
                  <img src={iconDownload} alt="download" className="object-contain" />
                </div>
                <div>
                  Download statement of beneficial interest
                </div>
              </Link>
            </Maybe>
          </div>

          <div className="w-full xl:w-8/12">
            <div className="ml-10 space-y-5">

              <Maybe condition={employeeMetricsLoading === true || employeeMetrics?.data === undefined || employeeMetrics?.data?.length === 0}>
                <div className="h-[487px] flex justify-center items-center bg-white shadow-md rounded-lg">
                  <PageContent loading={employeeMetricsLoading} data={employeeMetrics?.data || undefined} emptyText="Employee metrics not found" />
                </div>
              </Maybe>
                  
              <Maybe condition={employeeMetricsLoading === false && employeeMetrics?.data !== undefined}>
                <Maybe condition={employeeMetrics?.data && employeeMetrics?.data?.length > 0}>
                  {employeeMetrics?.data?.map((employeeMetric:any) =>
                    // <Link key={employeeMetric.id} to={navigationForwardUrl + "/scheme/" + employeeMetric.schemeType?.id} className="block bg-white shadow-md rounded-lg bg-vetiva-palm transform transition-all hover:scale-105">
                    <Link key={employeeMetric.id} to={navigationForwardUrl + "/scheme/" + employeeMetric.schemeType?.id} className="block bg-white shadow-md rounded-lg bg-vetiva-palm">
                      <div className="p-4 text-sm flex justify-between border-b border-gray-200">
                        <div className="flex font-bold items-center">
                          {getSchemeName(getScheme(employeeMetric.schemeType?.id))}
                          {/* {employeeMetric.schemeType?.name !== "Access to Others" ? employeeMetric.schemeType?.name : "custom"} */}

                          {/* 
                          <img src={iconInfo} alt="Shares" className="w-4 ml-2" data-tip data-for={employeeMetric.id} />
                          
                          <ReactToolTip id={employeeMetric.id} place="bottom" className="tooltip-vetiva" arrowColor="white">
                            <div className="py-2">
                              <div className="text-base">
                                {removeSchemeTypeNamePrefix(employeeMetric.schemeType?.name)}
                              </div>
                              <div>
                                Holding period:&nbsp;
                                <span className="font-bold">
                                  {DateMiniFormat(employeeMetric.schemeType?.holdingStartDate)} - {DateMiniFormat(employeeMetric.schemeType?.holdingEndDate)}
                                </span>
                              </div>
                            </div>
                          </ReactToolTip>
                           */}
                        </div>
                        <div>
                          {/* 
                          <div className="font-semibold flex space-x-2">
                            <div>
                              Holding period:
                            </div>
                            <div className="text-vetiva-brown">
                              {DateMiniFormat(employeeMetric.schemeType?.holdingStartDate)} - {DateMiniFormat(employeeMetric.schemeType?.holdingEndDate)}
                            </div>
                          </div>
                          */}
                          <div className="btn btn-brown btn-sm">
                            View details
                            <FontAwesomeIcon icon="angle-right" className="ml-2" />
                          </div>
                        </div>
                      </div>
                      <div className="p-4 flex space-x-4">
                        <div className="w-48 flex-shrink-0">
                          
                          <div className="w-40 mt-4 mx-auto">
                            <div data-tip data-for={employeeMetric.id + "-shares-all"} className="w-32 h-32 font-bold text-xl flex items-center justify-center text-white bg-vetiva-brown rounded-full border-2 border-white shadow-lg">
                              {employeeMetric.availableSharePercentage}%
                            </div>
                            <div data-tip data-for={employeeMetric.id + "-shares-transferred"} className="w-16 h-16 -mt-16 mr-0 relative z-10 mx-auto font-bold flex items-center justify-center text-white bg-black rounded-full border-2 border-white shadow-lg">
                              {employeeMetric.sharesTransferPercentage}%
                            </div>
                            <div data-tip data-for={employeeMetric.id + "-shares-sold"} className="w-16 h-16 -mt-16 mr-0 relative z-10 -top-12 mx-auto font-bold flex items-center justify-center bg-vetiva-gold rounded-full border-2 border-white shadow-lg">
                              {employeeMetric.sharesSoldPercentage}%
                            </div>

                            <ReactToolTip id={employeeMetric.id + "-shares-all"} place="bottom">
                              <div className="py-2">
                                <div className="text-base">
                                  Percentage of Shares Available
                                </div>
                                <div>
                                  {employeeMetric.availableSharePercentage}%
                                </div>
                              </div>
                            </ReactToolTip>

                            <ReactToolTip id={employeeMetric.id + "-shares-sold"} place="bottom">
                              <div className="py-2">
                                <div className="text-base">
                                  Percentage of Shares Sold
                                </div>
                                <div>
                                  {employeeMetric.sharesSoldPercentage}%
                                </div>
                              </div>
                            </ReactToolTip>

                            <ReactToolTip id={employeeMetric.id + "-shares-transferred"} place="bottom">
                              <div className="py-2">
                                <div className="text-base">
                                  Percentage of Shares Transferred
                                </div>
                                <div>
                                  {employeeMetric.sharesTransferPercentage}%
                                </div>
                              </div>
                            </ReactToolTip>
                          </div>

                          <div className="mt-6">
                            <div className="w-max mx-auto text-sm">
                              <div>
                                <div className="h-5 flex space-x-2">
                                  <div className="w-1 h-full bg-vetiva-brown"></div>
                                  <div className="text-gray-400">
                                    Available shares
                                  </div>
                                </div>
                              </div>
                              <div className="mt-2">
                                <div className="h-5 flex space-x-2">
                                  <div className="w-1 h-full bg-vetiva-gold"></div>
                                  <div className="text-gray-400">
                                    Shares sold
                                  </div>
                                </div>
                              </div>
                              <div className="mt-2">
                                <div className="h-5 flex space-x-2">
                                  <div className="w-1 h-full bg-black"></div>
                                  <div className="text-gray-400">
                                    Shares transferred
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="flex-grow">
                          
                          {/* 
                          <div className="h-28 p-2 bg-[#f9f6f1] flex items-center rounded-lg">
                            <div className="flex-grow flex">
                              <div className="mx-auto">
                                <div className="text-sm font-bold text-gray-500">
                                  Total allocation
                                </div>
                                <div className="text-2xl text-vetiva-brown">
                                  {NumberFormat(employeeMetric.totalShareAllocation, "no-kobo")}
                                </div>
                              </div>
                            </div>
                            <div className="w-6 flex-shrink-0 text-gray-300 text-4xl font-normal flex items-center justify-center">/</div>
                            <div className="flex-grow flex">
                              <div className="mx-auto">
                                <div className="text-sm font-bold text-gray-500">
                                  Current Balance
                                </div>
                                <div className="text-2xl text-vetiva-brown">
                                  {NumberFormat(employeeMetric.currentShareBalance, "no-kobo")}
                                </div>
                              </div>
                            </div>
                            <div className="w-6 flex-shrink-0 text-gray-300 text-4xl font-normal flex items-center justify-center">/</div>
                            <div className="flex-grow flex">
                              <div className="mx-auto">
                                <div className="text-sm font-bold text-gray-500">
                                  Value at date
                                </div>
                                <div className="text-2xl text-vetiva-brown">
                                  {NumberFormat(employeeMetric.shareValueAsAtDate, "no-kobo")}
                                </div>
                              </div>
                            </div>
                          </div>
                          */}

                          <div className="bg-[#f9f6f1] rounded-lg">
                          <div className="w-max h-28 p-2 flex items-center">
                            <div className="px-4 flex">
                            <div className="mx-auto">
                                <div className="text-sm font-bold text-gray-500">
                                  Total allocation
                                </div>
                                <div className="text-2xl text-vetiva-brown">
                                  {NumberFormat(employeeMetric.totalShareAllocation, "no-kobo")}
                                </div>
                              </div>
                            </div>
                            <div className="w-4 flex-shrink-0 text-gray-300 text-4xl font-normal flex items-center justify-center">/</div>
                            <div className="px-6 flex">
                              <div className="mx-auto">
                                <div className="text-sm font-bold text-gray-500">
                                  Current Balance
                                </div>
                                <div className="text-2xl text-vetiva-brown">
                                  {NumberFormat(employeeMetric.currentShareBalance, "no-kobo")}
                                </div>
                              </div>
                            </div>
                            <div className="w-4 flex-shrink-0 text-gray-300 text-4xl font-normal flex items-center justify-center">/</div>
                            <div className="px-6 flex">
                              <div className="mx-auto">
                                <div className="text-sm font-bold text-gray-500">
                                  Value at date
                                </div>
                                <div className="text-2xl text-vetiva-brown">
                                  {NumberFormat(employeeMetric.shareValueAsAtDate, "no-kobo")}
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>

                          <div className="mt-4 w-max h-28 p-2 bg-[#f8f9fd] flex items-center rounded-lg">
                            <div className="px-4 flex">
                              <div className="mx-auto">
                                <div className="text-sm font-bold text-gray-500">
                                  Bonus shares
                                </div>
                                <div className="text-2xl">
                                  {NumberFormat(employeeMetric.bonusShares, "no-kobo")}
                                </div>
                              </div>
                            </div>
                            <div className="w-4 flex-shrink-0 text-gray-300 text-4xl font-normal flex items-center justify-center">/</div>
                            <div className="px-6 flex">
                              <div className="mx-auto">
                                <div className="text-sm font-bold text-gray-500">
                                  Shares sold to date
                                </div>
                                <div className="text-2xl">
                                  {NumberFormat(employeeMetric.shareSoldAsAtDate, "no-kobo")}
                                </div>
                              </div>
                            </div>
                            <div className="w-4 flex-shrink-0 text-gray-300 text-4xl font-normal flex items-center justify-center">/</div>
                            <div className="px-6 flex">
                              <div className="mx-auto">
                                <div className="text-sm font-bold text-gray-500">
                                  Shares transferred <br />
                                  to date
                                </div>
                                <div className="text-2xl">
                                  {NumberFormat(employeeMetric.shareTransferedAsAtDate, "no-kobo")}
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </Link>
                  )}
                </Maybe>
              </Maybe>

            </div>
          </div>
        </div>
      </Maybe>



    </div>
  );
}





const EmployeeDetailsModal = ({isOpenProfile, setIsOpenProfile, toggleFormDrawerProfile}:any) => {
  const { userInfo, employeeId, navigationBaseUrl } = GetVariables();

  const { company } = useSelector((s: any) => s.entities.company);
  const { employee: {data: employee}, employeeScheme } = useSelector((s: any) => s.entities.employee);


  const getScheme = (id:string) => {
    const filteredSchemeType = company.data?.organizationShareSetting?.schemeTypes.filter((schemeType:any) => schemeType.id === id);
    return filteredSchemeType;
  }
  
  
  return(
    <FormDrawer isOpen={isOpenProfile} setIsOpen={setIsOpenProfile}>
      <div className="h-full">
        <div className="fixed h-32 w-full top-0 bg-vetiva-black">
          <UserInfoCard
            name={employee?.userInfo?.firstName + " " + employee?.userInfo?.lastName}
            tel={employee?.userInfo?.phoneNumber}
            email={employee?.userInfo?.email}
            idNumber={employee?.employeeNumber}
            iconUrl={
              userInfo.userType === "SCHEME_MEMBER" ? "" :
              userInfo.userType === "SCHEME_COMMITTEE" ? navigationBaseUrl+"/form/"+employeeId :
              navigationBaseUrl+"/form/"+employeeId}
          />
        </div>

        <div className="w-full absolute top-32 bottom-16 overflow-y-auto">
          <div className="p-6">
                          
            <div>
              <div className="text-gray-400 text-xs font-bold">
                OFFICIAL DETAILS
              </div>
              <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6 break-words">
                <div>
                  <div className="text-gray-400">
                    Email address
                  </div>
                  <div className="font-bold">
                    {employee?.userInfo?.email || "-"}
                  </div>
                </div>
                <div>
                  <div className="text-gray-400">
                    Grade Category
                  </div>
                  <div className="font-bold">
                    {employee?.userInfo?.gradeCategory || "-"}
                  </div>
                </div>
                <div>
                  <div className="text-gray-400">
                    Date of hire
                  </div>
                  <div className="font-bold">
                    {DateFormat(employee?.dateOfHire || "-")}
                  </div>
                </div>
                <div>
                  <div className="text-gray-400">
                    Number of Units
                  </div>
                  <div className="font-bold">
                    {NumberFormat(employee?.numberOfShareUnits || "-")}
                  </div>
                </div>
              </div>
            </div>

            <div className="h-px bg-gray-300 my-10"></div>

            <div>
              <div className="text-gray-400 text-xs font-bold">
                BANKS DETAILS
              </div>
              <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6 break-words">
                <div>
                  <div className="text-gray-400">
                    Bank Name
                  </div>
                  <div className="font-bold">
                    {employee?.bankName || "-"}
                  </div>
                </div>
                <div>
                  <div className="text-gray-400">
                    Account Number
                  </div>
                  <div className="font-bold">
                    {employee?.accountNumber || "-"}
                  </div>
                </div>
              </div>
            </div>

            <div className="h-px bg-gray-300 my-10"></div>

            <div>
              <div className="text-gray-400 text-xs font-bold">
                ALTERNATE BENEFICIARY DETAILS
              </div>
              <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6 break-words">
                <div>
                  <div className="text-gray-400">
                    First name
                  </div>
                  <div className="font-bold">
                    {employee?.alternateBenefiaryFirstName || "-"}
                  </div>
                </div>
                <div>
                  <div className="text-gray-400">
                    Last name
                  </div>
                  <div className="font-bold">
                    {employee?.alternateBenefiaryLasName || "-"}
                  </div>
                </div>
                <div>
                  <div className="text-gray-400">
                    Phone number
                  </div>
                  <div className="font-bold">
                    {employee?.alternateBenefiaryPhoneNumber || "-"}
                  </div>
                </div>
                <div>
                  <div className="text-gray-400">
                    Email address
                  </div>
                  <div className="font-bold">
                    {employee?.alternateBenefiaryEmail || "-"}
                  </div>
                </div>
              </div>
            </div>

            <div className="h-px bg-gray-300 my-10"></div>

            <div>
              <div className="text-gray-400 text-xs font-bold">
                SCHEME SETTINGS
              </div>
              <div className="mt-6 grid grid-cols-1 gap-6 break-words">
                <div>
                  <div className="text-gray-400">
                    Allocation Type
                  </div>
                  <div className="pt-1 font-bold text-vetiva-brown">
                    <FontAwesomeIcon icon="check" className="inline mr-2 -mt-0.5" />
                    {company.data?.organizationShareSetting?.sharesAllocation}
                  </div>
                </div>
                <div>
                  <div className="text-gray-400">
                    Sub-scheme settings
                  </div>

                  <Maybe condition={employeeScheme.data?.schemeTypes.length > 0}>
                    {employeeScheme.data?.employeeSubSchemes.map((subScheme:any, indexSubScheme:number) => {
                      const companySchemeTypeAll = getScheme(subScheme.schemeTypeId);
                      const companySchemeType = companySchemeTypeAll ? companySchemeTypeAll[0] : "";
                      // console.log("companySchemeType", companySchemeType);

                      return (
                        <div key={indexSubScheme} className="mt-4 bg-[#F8F9FD] p-4 rounded-md">
                          {companySchemeType !== "" &&
                            <>
                              <div className="text-vetiva-brown font-bold">
                                <Maybe condition={companySchemeType?.name !== "Access to Others"}>
                                  {removeSchemeTypeNamePrefix(companySchemeType?.name) || "-"}
                                </Maybe>
                                <Maybe condition={companySchemeType?.name === "Access to Others"}>
                                  {companySchemeType?.schemeDetail?.otherSchemeName}
                                </Maybe>
                              </div>

                              <Maybe condition={subScheme.batches.length > 0}>
                                {subScheme.batches.map((batch:any, indexBatch:number) => (
                                  <div key={indexBatch} className="mt-4 border border-gray-300 rounded-md">
                                    <div className="p-2 text-sm bg-gray-300">
                                      {batch.name}
                                    </div>
                                    <div className="p-4 flex flex-col space-y-6">
                                      {batch.allocations.map((allocation:any, indexAllocation:number) => {
                                        const companyAllocationAll = companySchemeType?.subSchemes && companySchemeType?.subSchemes[0]?.batches?.filter((companyBatch:any) => companyBatch.name === batch.name)[0]?.allocations?.filter((companyAllocation:any) => companyAllocation?.id === allocation.employeeOrgAlloctionId);
                                        const companyAllocation = companyAllocationAll ? companyAllocationAll[0] : "";
                                        // console.log("companyAllocation", companyAllocation);

                                        return (
                                          <div key={indexAllocation} className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-6 break-words bg-[#F9F6F1]">
                                            <div>
                                              <div className="text-gray-400">
                                                Employee's Number of allocation
                                              </div>
                                              <div className="font-bold">
                                                {NumberFormat(allocation.numberOfAllocation, "no-kobo") || "-"}
                                              </div>
                                            </div>
                                            <div>
                                              <div className="text-gray-400">
                                                Employee's Date of allocation
                                              </div>
                                              <div className="font-bold">
                                                {DateFormat(allocation.dateOfAllocation) || "-"}
                                              </div>
                                            </div>
                                            <div>
                                              <div className="text-gray-400">
                                                Company's Number of allocation
                                              </div>
                                              <div className="font-bold">
                                                {NumberFormat(companyAllocation?.numberOfAllocation, "no-kobo") || "-"}
                                              </div>
                                            </div>
                                            <div>
                                              <div className="text-gray-400">
                                                Company's Date of allocation
                                              </div>
                                              <div className="font-bold">
                                                {DateFormat(companyAllocation?.dateOfAllocation) || "-"}
                                              </div>
                                            </div>
                                            <div>
                                              <div className="text-gray-400">
                                                Percentage for vesting
                                              </div>
                                              <div className="font-bold">
                                                {companyAllocation?.percentageVesting}%
                                              </div>
                                            </div>
                                            <div>
                                              <div className="text-gray-400">
                                                Vesting date
                                              </div>
                                              <div className="font-bold">
                                                <Maybe condition={companyAllocation?.vestingPeriod === "Fixed period"}>
                                                  {companyAllocation?.period} {pluralizeFull(companyAllocation?.period, "year", "years")}
                                                </Maybe>
                                                <Maybe condition={companyAllocation?.vestingPeriod === "Recurring date"}>
                                                  {DateFormat(companyAllocation?.vestingDate)}
                                                </Maybe>
                                              </div>
                                            </div>
                                            <div className="col-span-2">
                                              <FileUploadPreview
                                                className="bg-white"
                                                file={companyAllocation?.schemeRuleObj ? companyAllocation?.schemeRuleObj[0] || undefined : undefined}
                                              />
                                            </div>
                                          </div>
                                        )

                                      })}
                                    </div>
                                  </div>
                                ))}
                              </Maybe>

                              <Maybe condition={subScheme.batches.length === 0}>
                                <div className="page-info-italic py-10 bg-gray-100">
                                  No batches for this scheme type
                                </div>
                              </Maybe>

                              <div className="mt-4 border border-gray-300 rounded-md">
                                <div className="p-2 text-sm bg-gray-300">
                                  Trust Deed
                                </div>
                                <div className="p-4">
                                  <FileUploadPreview
                                    className="bg-white"
                                    file={companySchemeType?.subSchemes[0]?.trustDeedObject ? companySchemeType?.subSchemes[0]?.trustDeedObject[0] || undefined : undefined}
                                  />
                                </div>
                              </div>
                            </>
                          }

                          <Maybe condition={companySchemeType === ""}>
                            <div className="page-info-italic py-10 bg-gray-100">
                              Unable to fetch scheme information
                            </div>
                          </Maybe>
                        </div>
                      )

                    })}


                    {employeeScheme.data?.noSubschemeSchemeTypeDetailsResponses.map((subScheme:any, indexSubScheme:number) => {
                      const companySchemeTypeAll = getScheme(subScheme.schemeTypeId);
                      const companySchemeType = companySchemeTypeAll ? companySchemeTypeAll[0] : "";
                      // console.log("companySchemeType", companySchemeType);

                      return (
                        <div key={indexSubScheme} className="mt-4 bg-[#F8F9FD] p-4 rounded-md">
                          {companySchemeType !== "" &&
                            <>
                              <div className="text-vetiva-brown font-bold">
                                <Maybe condition={companySchemeType?.name !== "Access to Others"}>
                                  {removeSchemeTypeNamePrefix(companySchemeType?.name) || "-"}
                                </Maybe>
                                <Maybe condition={companySchemeType?.name === "Access to Others"}>
                                  {companySchemeType?.schemeDetail?.otherSchemeName}
                                </Maybe>
                              </div>

                              <div className="mt-4 border border-gray-300 rounded-md">
                                <div className="p-2 text-sm bg-gray-300"></div>
                                <div className="p-4 flex flex-col space-y-6">
                                  <div className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-6 break-words bg-[#F9F6F1]">
                                    <div>
                                      <div className="text-gray-400">
                                        Employee's Number of allocation
                                      </div>
                                      <div className="font-bold">
                                        {NumberFormat(subScheme.numberOfAllocation, "no-kobo") || "-"}
                                      </div>
                                    </div>
                                    <div>
                                      <div className="text-gray-400">
                                        Employee's Date of allocation
                                      </div>
                                      <div className="font-bold">
                                        {DateFormat(subScheme.dateOfAllocation) || "-"}
                                      </div>
                                    </div>
                                    <div>
                                      <div className="text-gray-400">
                                        Company's Number of allocation
                                      </div>
                                      <div className="font-bold">
                                        {NumberFormat(companySchemeType?.schemeDetail?.numberOfAllocation, "no-kobo") || "-"}
                                      </div>
                                    </div>
                                    <div>
                                      <div className="text-gray-400">
                                        Company's Date of allocation
                                      </div>
                                      <div className="font-bold">
                                        {DateFormat(companySchemeType?.schemeDetail?.dateOfAllocation) || "-"}
                                      </div>
                                    </div>
                                    <div>
                                      <div className="text-gray-400">
                                        Percentage for vesting
                                      </div>
                                      <div className="font-bold">
                                        {companySchemeType?.schemeDetail?.percentageVesting}%
                                      </div>
                                    </div>
                                    <div>
                                      <div className="text-gray-400">
                                        Vesting date
                                      </div>
                                      <div className="font-bold">
                                        <Maybe condition={companySchemeType?.schemeDetail?.vestingPeriod === "Fixed period"}>
                                          {companySchemeType?.schemeDetail?.period} {pluralizeFull(companySchemeType?.schemeDetail?.period, "year", "years")}
                                        </Maybe>
                                        <Maybe condition={companySchemeType?.schemeDetail?.vestingPeriod === "Recurring date"}>
                                          {DateFormat(companySchemeType?.schemeDetail?.vestingDate)}
                                        </Maybe>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          }

                          <Maybe condition={companySchemeType === ""}>
                            <div className="page-info-italic py-10 bg-gray-100">
                              Unable to fetch scheme information
                            </div>
                          </Maybe>
                        </div>
                      )

                    })}
                  </Maybe>

                  <Maybe condition={employeeScheme.data?.schemeTypes.length === 0}>
                    <div className="page-info-italic py-10 bg-gray-100">
                      No scheme types configured for this employee
                    </div>
                  </Maybe>

                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="w-full fixed bottom-0 p-3 bg-white flex space-x-3 items-center justify-end">
          <button type="button" onClick={() => toggleFormDrawerProfile(false)} className="btn btn-gold">
            Close
          </button>
        </div>
      </div>
    </FormDrawer>
  )
}






const DividendSalesHistory = ({isOpen, setIsOpen, isOpenFilter, setIsOpenFilter, formDrawerTitle, toggleFormDrawerFilter, toggleFormDrawer}:any) => {
  const { companyId, employeeId } = GetVariables();

  const navigate = useNavigate();
  const location = useLocation();
  const {urlQuery} = useUrlNavigation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { dividendHistoryLoading, dividendHistory, shareSalesHistoryLoading, shareSalesHistory, shareTransferHistoryLoading, shareTransferHistory } = useSelector((s: any) => s.entities.employee);


  const [historyParams, setHistoryParams] = useState<any>({
    "PageNumber": 1,
    "PageSize": 20,
    "search": searchParams.get("search") || "",
    "startDate": searchParams.get("startDate") || "",
    "endDate": searchParams.get("endDate") || "",
  });

  const [filterForm, setFilterForm] = useState<any>({
    "startDate": searchParams.get("startDate") || "",
    // "status": searchParams.get("status") || "",
    "search": searchParams.get("search") || "",
    "endDate": searchParams.get("endDate") || "",
  });
  

  const getHistoryParams = () => {
    let params = {...historyParams};

    const page = searchParams.get("page");
    if (page !== null){
      params = {...params, PageNumber: parseInt(page)}
    }
    else{
      params = {...params, PageNumber: 1}
    }

    const limit = searchParams.get("limit");
    if (limit !== null){
      params = {...params, PageSize: parseInt(limit)}
    }
    else{
      params = {...params, PageSize: 20}
    }

    const search = searchParams.get("search");
    if (search !== null){
      params = {...params, search}
    }
    else{
      if (params.search){
        delete params["search"];
      }
    }

    const startDate = searchParams.get("startDate");
    if (startDate !== null){
      params = {...params, startDate: dateFormatSubmit(startDate)}
    }
    else{
      if (params.startDate){
        delete params["startDate"];
      }
    }

    const endDate = searchParams.get("endDate");
    if (endDate !== null){
      params = {...params, endDate: dateFormatSubmit(endDate)}
    }
    else{
      if (params.endDate){
        delete params["endDate"];
      }
    }

    const status = searchParams.get("status");
    if (status !== null){
      params = {...params, status}
    }
    else{
      if (params.status){
        delete params["status"];
      }
    }

    setHistoryParams(params);
    return params;
  }


  const getDividendHistory = () => {
    const params = {
      ...getHistoryParams(),
      FilterResourceId: employeeId,
    }
    dispatch(employeeAction.getDividendHistory(companyId, params));
  }


  const getShareSalesHistory = () => {
    const params = {
      ...getHistoryParams(),
      FilterResourceId: employeeId,
    }
    dispatch(employeeAction.getShareSalesHistory(companyId, params));
  }


  const getShareTransferHistory = () => {
    const params = {
      ...getHistoryParams(),
      FilterResourceId: employeeId,
    }
    dispatch(employeeAction.getShareTransferHistory(companyId, params));
  }


  const submitFilterForm = async (e: React.FormEvent) => {
    e.preventDefault();

    let url = "";
    for (let i in filterForm){
      if (url !== ""){
        url += "&";
      }
      url += i + "=" + filterForm[i];
    }

    navigate(urlQuery(url));
    toggleFormDrawerFilter(false);
  }


  const clearFilter = () => {
    setFilterForm({
      "startDate": "",
      "endDate": "",
      "search": "",
    })
    let url = "startDate=&endDate=&search=";
    navigate(urlQuery(url));
  }


  useEffect(() => {
    if (isOpen === true){
      if (formDrawerTitle === "Dividend History"){
        getDividendHistory();
      }
      else if (formDrawerTitle === "Share Sales History"){
        getShareSalesHistory();
      }
      else if (formDrawerTitle === "Share Transfer History"){
        getShareTransferHistory();
      }
    }
  }, [location, isOpen]);

  
  return(
    <>


      
      <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="h-full">
          <div className="fixed h-20 flex items-center w-full top-0 bg-vetiva-black text-white">
            <div className="text-xl px-6">{formDrawerTitle}</div>
          </div>

          <div className="w-full absolute top-20 bottom-16 overflow-y-auto">
            
            <div className="p-6">
              <div className="flex space-x-4">
                <form onSubmit={submitFilterForm} className="form-group-icon h-10 rounded-full border border-gray-300">
                  <input type="search" value={filterForm.search} onChange={(e:any) => setFilterForm({...filterForm, search: e.target.value})} placeholder="Search" className="h-full form-input-icon text-sm" />
                  <label htmlFor="search">
                    <FontAwesomeIcon icon="search" className="form-group-icon-attach h-4 text-gray-400" />
                  </label>
                </form>
                <div onClick={() => toggleFormDrawerFilter(true)} className="w-10 h-10 flex flex-shrink-0 rounded-full border border-gray-300 cursor-pointer hover:bg-gray-100">
                  <img src={iconFilter} alt="Filter" className="m-auto" />
                </div>
              </div>
              <Maybe condition={filterForm.startDate !== "" || filterForm.search !== "" || filterForm.endDate !== ""}>
                <div className="mt-1">
                  <span onClick={clearFilter} className="text-red-500 cursor-pointer hover:underline">
                    <FontAwesomeIcon icon="times" className="mr-2" />
                    Clear filter
                  </span>
                </div>
              </Maybe>
            </div>

            <div className="px-6">

                <div className="table-container">
                  {formDrawerTitle === "Dividend History" &&
                    <table className="table table-auto table-rounded table-border">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>DPS</th>
                                <th>Div. paid</th>
                            </tr>
                        </thead>
                        <tbody>

                          <TableContent loading={dividendHistoryLoading} data={dividendHistory} colspan={3} />
                          
                          {dividendHistory.data?.map((dividend: any, index: number) =>
                            <tr key={index}>
                              <td>
                                {DateMiniFormat(dividend.dateOfDividend) || "-"}
                              </td>
                              <td>
                                {NumberFormat(dividend.pricePerShare) || "-"}
                              </td>
                              <td>
                                {CurrencyFormat(dividend.dividendAmount) || "-"}
                              </td>
                            </tr>
                          )}

                        </tbody>
                    </table>
                  }

                  {formDrawerTitle === "Share Sales History" &&
                    <table className="table table-auto table-rounded table-border">
                        <thead>
                            <tr>
                                <th>Date & Share Sales Name</th>
                                <th>Share Sales Price</th>
                                <th>
                                  Share Sales Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                          
                          <TableContent loading={shareSalesHistoryLoading} data={shareSalesHistory} colspan={3} />
                          
                          {shareSalesHistory.data?.map((shareSale: any, index: number) =>
                            <tr key={index}>
                              <td>
                                {DateMiniFormat(shareSale.dateOfSale) || "-"}
                                <br />
                                {shareSale.name || "-"}
                              </td>
                              <td>
                                {CurrencySymbol(shareSale.currency) + "" + NumberFormat(shareSale.price) || "-"}
                              </td>
                              <td>
                                {CurrencySymbol(shareSale.currency) + "" + NumberFormat(shareSale.amountSold) || "-"}
                              </td>
                            </tr>
                          )}

                        </tbody>
                    </table>
                  }

                  {formDrawerTitle === "Share Transfer History" &&
                    <table className="table table-auto table-rounded table-border">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Source</th>
                                <th>
                                  {/* Amt sold */}
                                  Shares transferred
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                          
                          <TableContent loading={shareTransferHistoryLoading} data={shareTransferHistory} colspan={3} />
                          
                          {shareTransferHistory.data?.map((shareHistory: any, index: number) =>
                            <tr key={index}>
                              <td>
                                {DateMiniFormat(shareHistory.dateOfTransfer) || "-"}
                              </td>
                              <td>
                                {shareHistory.schemeTypeName || "-"}
                              </td>
                              <td>
                                {CurrencyFormat(shareHistory.numberOfShareForTransfer) || "-"}
                              </td>
                            </tr>
                          )}

                        </tbody>
                    </table>
                  }
                </div>

                {formDrawerTitle === "Dividend History" &&
                  <Pagination meta={dividendHistory.meta} />
                }

                {formDrawerTitle === "Share Sales History" &&
                  <Pagination meta={shareSalesHistory.meta} />
                }

                {formDrawerTitle === "Share Transfer History" &&
                  <Pagination meta={shareTransferHistory.meta} />
                }

            </div>
          </div>


          <div className="w-full fixed bottom-0 p-3 bg-white flex space-x-3 items-center justify-end">
            <button type="button" onClick={() => toggleFormDrawer('')} className="btn btn-gold">
              Close
            </button>
          </div>
        </div>
      </FormDrawer>
      


      <FormDrawer isOpen={isOpenFilter} setIsOpen={setIsOpenFilter}>
        <form onSubmit={submitFilterForm} className="h-full">
          <div className="fixed h-20 flex items-center w-full top-0 bg-vetiva-black text-white">
            <div className="px-6">
              <div className="text-xl">
                Filter
              </div>
              <div className="text-gray-400 text-xs">
                Filter across table using the parameters shown below
              </div>
            </div>
          </div>

          <div className="w-full p-6 absolute top-20 bottom-16 overflow-y-auto">
            
            <div className="flex flex-col space-y-6">
              <div className="w-full">
                <Input type="date" label="Start Date" value={filterForm.startDate} onChange={(e:any) => setFilterForm({...filterForm, startDate: e.target.value})} />
              </div>

              <div className="w-full">
                <Input type="date" label="End Date" value={filterForm.endDate} onChange={(e:any) => setFilterForm({...filterForm, endDate: e.target.value})} />
              </div>

              <div className="w-full hidden">
                {formDrawerTitle === "Dividend History" &&
                  <Input type="date" label="Last activity" />
                }
                {formDrawerTitle === "Share Sales History" &&
                  <Input label="DPS" type={"number"} />
                }
                {formDrawerTitle === "Share Transfer History" &&
                  <Input label="DPS" type={"number"} />
                }
              </div>
            </div>

          </div>

          <div className="w-full fixed bottom-0 p-3 bg-white flex space-x-3 items-center justify-end">
            <button type="button" onClick={() => toggleFormDrawerFilter(false)} className="btn btn-gold">
              Cancel
            </button>
            <button type="submit" className="btn btn-brown">
              Show results
            </button>
          </div>
        </form>
      </FormDrawer>


    </>
  )
}



export default CompanyEmployeeDetails;
