import { useEffect } from "react";
import Maybe from "components/Maybe";
import { useFilePicker } from 'use-file-picker';
import getFileSize from "utils/getFileSize";
import FileUploadPreview from './file-upload-preview';

export interface FilePickerProps {
  content: any,
}

export function FilePicker(props: FilePickerProps) {
  const {content} = props;
  const [openFileSelector, { filesContent, loading, errors, plainFiles, clear }] = useFilePicker({
    accept: 'image/*',
    multiple: false,
  });

  // console.log("filesContent", filesContent);
  // console.log("errors", errors);
  // console.log("plainFiles", plainFiles);
  

  const cancelUpload = () => {
    clear();
  }


  useEffect(() => {
    if (plainFiles && plainFiles.length > 0){
      return content(plainFiles);
    }
  }, [plainFiles]);

  
  return (
    <>

      <Maybe condition={loading === true}>
        <div className="page-info-italic">
          Loading...
        </div>
      </Maybe>


      <Maybe condition={loading === false}>

        <Maybe condition={plainFiles && plainFiles.length > 0}>
          {plainFiles.map((file:any, index:number) => (
            <div key={index} className="mb-1">
              <FileUploadPreview name={file.name} size={getFileSize(plainFiles[index]?.size)} className="bg-gray-100" allowDismiss={true} cancelUpload={cancelUpload} />
            </div>
          ))}
        </Maybe>

        <Maybe condition={!plainFiles || plainFiles.length === 0}>
          <div className="flex justify-center items-center w-full">
            <label onClick={() => openFileSelector()} className="flex justify-start items-center w-full h-40 bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-100">
              <svg
                className="mx-9"
                width="96"
                height="96"
                viewBox="0 0 96 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="48"
                  cy="48"
                  r="47.5"
                  fill="white"
                  stroke="#B5BDC9"
                  strokeDasharray="8 8"
                />
                <path
                  d="M36 40.8016C36 41.6503 36.3371 42.4642 36.9373 43.0643C37.5374 43.6644 38.3513 44.0016 39.2 44.0016C40.0487 44.0016 40.8626 43.6644 41.4627 43.0643C42.0629 42.4642 42.4 41.6503 42.4 40.8016C42.4 39.9529 42.0629 39.1389 41.4627 38.5388C40.8626 37.9387 40.0487 37.6016 39.2 37.6016C38.3513 37.6016 37.5374 37.9387 36.9373 38.5388C36.3371 39.1389 36 39.9529 36 40.8016V40.8016Z"
                  stroke="#6B6C7E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M48.4794 46.0581L47.873 45.0869C47.8011 44.9719 47.7011 44.877 47.5824 44.8112C47.4638 44.7454 47.3303 44.7109 47.1946 44.7109C47.0589 44.7109 46.9255 44.7454 46.8068 44.8112C46.6881 44.877 46.5881 44.9719 46.5162 45.0869L42.3002 51.8421L40.5786 49.0869C40.5067 48.9719 40.4067 48.877 40.288 48.8112C40.1694 48.7454 40.0359 48.7109 39.9002 48.7109C39.7645 48.7109 39.6311 48.7454 39.5124 48.8112C39.3937 48.877 39.2937 48.9719 39.2218 49.0869L34.3994 56.8021H42.3994"
                  stroke="#6B6C7E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M43.9996 66.4016H31.1996C30.7753 66.4016 30.3683 66.233 30.0682 65.9329C29.7682 65.6329 29.5996 65.2259 29.5996 64.8016V31.2016C29.5996 30.7772 29.7682 30.3702 30.0682 30.0702C30.3683 29.7701 30.7753 29.6016 31.1996 29.6016H52.4684C52.8927 29.6017 53.2996 29.7703 53.5996 30.0704L59.5308 36.0016C59.8309 36.3016 59.9995 36.7085 59.9996 37.1328V42.4016"
                  stroke="#6B6C7E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M47.2002 56.7992C47.2002 59.3453 48.2116 61.7871 50.012 63.5874C51.8123 65.3878 54.2541 66.3992 56.8002 66.3992C59.3463 66.3992 61.7881 65.3878 63.5884 63.5874C65.3888 61.7871 66.4002 59.3453 66.4002 56.7992C66.4002 54.2531 65.3888 51.8113 63.5884 50.011C61.7881 48.2106 59.3463 47.1992 56.8002 47.1992C54.2541 47.1992 51.8123 48.2106 50.012 50.011C48.2116 51.8113 47.2002 54.2531 47.2002 56.7992Z"
                  stroke="#6B6C7E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M56.7998 61.6V52"
                  stroke="#6B6C7E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M56.8002 52L53.2002 55.6"
                  stroke="#6B6C7E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M56.7998 52L60.3998 55.6"
                  stroke="#6B6C7E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col justify-center items-start pt-5 pb-6">
                <p className="mb-2 text-sm text-black font-extrabold">Upload company logo</p>
                <p className="mb-2 text-sm text-gray-500">Click here to upload your logo</p>
                <p className="text-xs text-gray-500">Format (png, jpg, svg)</p>
              </div>
              {/* <input id="dropzone-file" type="file" className="hidden" /> */}
            </label>
          </div>
        </Maybe>

      </Maybe>
    </>
  );
}

export default FilePicker;
