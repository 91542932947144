import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import useLoginInfo from "hooks/useLoginInfo";
import iconSuccess from "assets/images/icons/success.gif";
import iconInfo from "assets/images/icons/info.svg";

export interface CompanyEmployeesCreateBulkSuccessProps {}

export function CompanyEmployeesCreateBulkSuccess(props: CompanyEmployeesCreateBulkSuccessProps) {
  let { companyId } = useParams();
  let navigationBaseUrl = "/trustee/company/"+companyId;
  const {userInfo} = useLoginInfo();

  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    navigationBaseUrl = "/company/employees"
  }

  const navigate = useNavigate();
  const { uploadEmployees } = useSelector((s: any) => s.entities.employee);

  
  useEffect(() => {
    if (uploadEmployees.success === undefined || uploadEmployees.success === false){
      navigate(navigationBaseUrl+"/create/bulk/upload");
    }
  }, [uploadEmployees, companyId, navigate]);


  return (
    <div className="bg-white min-h-screen">

      <FormWizardNavBar showSteps={true} currentStep={2} totalSteps={2} closeUrl={navigationBaseUrl} />


      <div className="container py-20 max-w-2xl mx-auto text-center">
        
          <div className="h-48">
            <img src={iconSuccess} alt="Success" className="object-contain" />
          </div>

          <div className="mt-4 text-2xl">
            Successful!
          </div>
          <div className="mt-1 text-gray-400">
            You have successfully added
          </div>


          {uploadEmployees.data && 
            <div className="mt-4 p-4 bg-gray-100 text-center max-w-sm mx-auto">
              <div className="flex justify-center">
                <img src={iconInfo} alt="info" className="w-6 h-6 mr-2 my-auto" />
                <div className="text-[#04A101]">
                  <span className="text-2xl">{uploadEmployees.data?.length}</span>/{uploadEmployees.data?.length + uploadEmployees.processErrors?.length} file(s) uploaded successfully
                </div>
              </div>
              <div className="p-4 mt-2 flex justify-between bg-white text-red-500 rounded">
                <div>
                  {uploadEmployees.processErrors?.length} upload(s) failed
                </div>
                {uploadEmployees.processErrors?.length > 0 &&
                  <div>
                    <a href={uploadEmployees?.downloadErrorUrl} target="_blank" rel="noreferrer" download className="underline text-vetiva-brown">
                      Download csv
                    </a>
                  </div>
                }
              </div>
            </div>
          }


          <div>
            <div className="mt-6 flex flex-wrap space-x-6 justify-center">
              <Link to={navigationBaseUrl+"/create"} className="underline text-vetiva-brown">
                Add new employee
              </Link>
              {/* 
              <Link to="/trustee/company/123/employee/123" className="underline text-vetiva-brown">
                View employee profile
              </Link>
               */}
              <Link to={navigationBaseUrl} className="underline text-vetiva-brown">
                Go to company’s page
              </Link>
            </div>
            {/* 
            <div className="mt-6">
              <Link to="/trustee/company/123" className="underline text-vetiva-brown">
                Go to company’s page
              </Link>
            </div>
             */}
          </div>

      </div>

    </div>
  );
}

export default CompanyEmployeesCreateBulkSuccess;
