import useLoginInfo from "hooks/useLoginInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const SwitchView = () => {
    const {logoutUser} = useLoginInfo();

    return (
        <div className="w-screen min-h-[500px] flex">
            <div className="px-8 pt-20 m-auto text-center max-w-md">

                <div className="h-20">
                    <FontAwesomeIcon icon="spinner" spin className="h-20" />
                </div>
                <div className="mt-8 font-clashgrotesk font-semibold text-2xl sm:text-3xl">
                    Switching...
                </div>
                <div className="mt-4 text-gray-500">
                    Please wait while we log you in.
                    <br />
                    This process takes a few seconds.
                    {/* If this is taking longer than usual, please click the "Goto overview" button below. */}
                </div>

                {/* 
                <div className="mt-8 space-y-2">
                    <a href="/" className="btn btn-block btn-lg btn-vetiva-brown">
                        Goto overview
                    </a>
                    <button type="button" onClick={logoutUser} className="btn btn-block btn-lg btn-transparent-black">
                        Logout
                    </button>
                </div>
                */}

            </div>
        </div>
    )
}

export default SwitchView