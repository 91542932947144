import Maybe from "./Maybe";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IPageOverlayCover {
    text?: string,
    condition: boolean,
}

const PageOverlayCover = (props: IPageOverlayCover) => {
    const {text, condition} = props;
    const textDisplay = text || "Please wait...";

    return (
        <Maybe condition={condition}>

            <div className="fixed z-50 inset-0 top-16 bg-white bg-opacity-80 flex">
            <div className="table-info m-auto">
                <FontAwesomeIcon icon="spinner" spin />
                <div className="font-bold">
                    {textDisplay}
                </div>
            </div>
            </div>

        </Maybe>
    )
}

export default PageOverlayCover
