import Maybe from "./Maybe";
import { nameInitialsCompanyName } from "utils/nameInitials";

interface CompanyLogoNameProps {
    company: any,
    loading?: boolean,
}


const CompanyLogoName = (props: CompanyLogoNameProps) => {
    const {company} = props;

    return (
        <div className="flex items-center space-x-2">
            <div className={"w-9 h-9 text-white text-sm text-center flex-shrink-0 flex items-center justify-center rounded-full overflow-hidden " + (company.data?.logoObject !== null ? "bg-black":"bg-vetiva-purple")}>
                <Maybe condition={company.data?.logoObject !== null}>
                    <img src={company.data?.logoObject?.url} alt="" className="object-contain" />
                </Maybe>
                <Maybe condition={company.data?.logoObject === null}>
                    {nameInitialsCompanyName(company.data?.name)}
                </Maybe>
            </div>
            <div className="text-2xl text-white">
                {company.data?.name}
            </div>
        </div>
    )
}

export default CompanyLogoName;