import { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';

const chartOptions = {
  plugins: {
      tooltip: {
          callbacks: {
              label: function(context:any){
                  var label = context.label,
                      currentValue = context.raw,
                      total = context.chart._metasets[context.datasetIndex].total;
                  var percentage = parseFloat((currentValue/total*100).toFixed(1));
                  // return label + ": " +currentValue + ' (' + percentage + ‘%)’;
                  return label + ": " + percentage + "%";
              }
          }
      }
  },
};

ChartJS.register(ArcElement, Tooltip);

interface PieChartProps {
  data: any;
}

export function PieChart(props:PieChartProps) {
  
  const [data, setData] = useState({
    labels: ['Share award','Share option','Share purchase', 'Long term incentive'],
    datasets: [
      {
        label: '# of Shares',
        data: [0, 0, 0, 0, 0],
        backgroundColor: [
          '#715B32',
          '#5a4828',
          '#bbae84',
          '#967842',
          '#000000',
        ],
        borderColor: [
          '#715B32',
          '#5a4828',
          '#bbae84',
          '#967842',
          '#000000',
        ],
        borderWidth: 1,
      },
    ],
  });


  useEffect(() => {
    if (props.data !== undefined){
      setData({
        ...data,
        datasets: [{
          ...data.datasets[0],
          data: props.data,
        }]
      })
    }
  }, [props.data]);
  

  return (
    <Pie data={data} options={chartOptions} />
  );
}
