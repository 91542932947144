import * as actions from "./actionTypes";
import { toast } from 'react-toastify';
import errorReducer from "utils/errorReducer";

const initialState = {
    updateProfileLoading: false,
    updateProfile: false,
    updatePasswordLoading: false,
    updatePassword: false,
};


export default function reducer(state = initialState, action){
    switch (action.type){

        case actions.UPDATE_PROFILE:
            toast.success(action.payload.message);
            state = {
                ...state,
                updateProfileLoading: false,
                updateProfile: true,
            }
            return state;

           
        case actions.UPDATE_PROFILE_START:
            state = {
                ...state,
                updateProfileLoading: true,
            }
            return state;


        case actions.UPDATE_PROFILE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                updateProfileLoading: false,
            }
            return state;


        case actions.UPDATE_PASSWORD:
            toast.success(action.payload.message);
            state = {
                ...state,
                updatePasswordLoading: false,
                updatePassword: true,
            }
            return state;

           
        case actions.UPDATE_PASSWORD_START:
            state = {
                ...state,
                updatePasswordLoading: true,
            }
            return state;


        case actions.UPDATE_PASSWORD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                updatePasswordLoading: false,
            }
            return state;


        default:
            return state;
    }
}