import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import ButtonLoader from "components/ButtonLoader";
import NumberFormat from "utils/numberFormat";
import useLoginInfo from "hooks/useLoginInfo";
import * as employeeAction from "store/entities/employee/action";


export interface CompanyEmployeeInstructionSalesPreviewProps {}

export function CompanyEmployeeInstructionSalesPreview(props: CompanyEmployeeInstructionSalesPreviewProps) {
  const {userInfo} = useLoginInfo();
  let { companyId, employeeId, schemeTypeId } = useParams();
  let employeeDetailsUrl = "/trustee/company/"+companyId+"/employee/"+employeeId;
  let sendInstructionSelectionUrl = "/trustee/company/"+companyId+"/employee/"+employeeId;
  
  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    employeeDetailsUrl = "/company/employees/"+employeeId;
    sendInstructionSelectionUrl = employeeDetailsUrl;
  }
  else if (userInfo.userType === "SCHEME_MEMBER"){
    companyId = userInfo.organizationId;
    employeeId = userInfo.employeeId;
    employeeDetailsUrl = "/member/overview";
    sendInstructionSelectionUrl = "/member";
  }


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { savedShareSaleInstruction, sendShareSaleInstructionLoading, sendShareSaleInstruction } = useSelector((s: any) => s.entities.employee);


  const submitForm = () => {
    const params = {...savedShareSaleInstruction};
    delete params["remainingShares"];
    dispatch(employeeAction.sendShareSaleInstruction(params));
  }


  useEffect(() => {
    if (sendShareSaleInstruction.success === true){
      dispatch(employeeAction.saveShareSaleInstruction({}));
      dispatch(employeeAction.sendShareSaleInstructionReset());
      navigate(sendInstructionSelectionUrl + "/instruction/" + schemeTypeId + "/sales-success");
    }
  }, [sendShareSaleInstruction]);


  useEffect(() => {
    if (
      isNaN(savedShareSaleInstruction.numberOfShareForSale) ||
      isNaN(savedShareSaleInstruction.shareSalePrice) ||
      isNaN(savedShareSaleInstruction.remainingShares)
    ){
      navigate(sendInstructionSelectionUrl + "/instruction/" + schemeTypeId + "/sales");
    }
  }, []);


  return (
    <div>

      <FormWizardNavBar currentStep={2} totalSteps={3} showSteps={true} closeUrl={employeeDetailsUrl} />

      <div className="container py-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Preview
          </div>
          <p className="mt-2 text-fade text-sm">
            Kindly preview details before submitting
          </p>
        </div>

        <div className="mt-12">
          
          <div>

            <div className="text-center grid grid-cols-1 gap-4">
              <div className="p-8 text-sm text-white bg-vetiva-brown bg-vetiva-pattern rounded-md">
                <div>
                  Shares for Sale
                </div>
                <div className="mt-2 font-bold text-3xl">
                  {NumberFormat(savedShareSaleInstruction.numberOfShareForSale, "no-kobo")}
                </div>
              </div>
              <div className="p-8 text-sm text-white bg-vetiva-black bg-vetiva-pattern rounded-md">
                <div>
                  New Shares Balance
                </div>
                <div className="mt-2 font-bold text-3xl">
                  {NumberFormat(savedShareSaleInstruction.remainingShares, "no-kobo")}
                </div>
              </div>
            </div>

            <div className="mt-10 flex justify-end space-x-3">
              <Link to={sendInstructionSelectionUrl + "/instruction/" + schemeTypeId + "/sales"} className="btn btn-gold">
                Back
              </Link>
              <ButtonLoader type="button" onClick={submitForm} loading={sendShareSaleInstructionLoading} className="btn btn-brown">
                Continue
              </ButtonLoader>
            </div>

          </div>

        </div>
      </div>

    </div>
  );
}

export default CompanyEmployeeInstructionSalesPreview;
