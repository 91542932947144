import * as actions from "./actionTypes";
// import { toast } from 'react-toastify';
import errorReducer from "utils/errorReducer";

const initialState = {
    dashboardStatsLoading: false,
    dashboardStats: {},

    shareDistributionLoading: false,
    shareDistribution: {},

    schemeMetricsAwardsLoading: false,
    schemeMetricsAwards: {},
    schemeMetricsOptionsLoading: false,
    schemeMetricsOptions: {},
    schemeMetricsPurchaseLoading: false,
    schemeMetricsPurchase: {},
    schemeMetricsLtipLoading: false,
    schemeMetricsLtip: {},
    schemeMetricsOthersLoading: false,
    schemeMetricsOthers: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_DASHBOARD_STATS:
            state = {
                ...state,
                dashboardStatsLoading: false,
                dashboardStats: { ...action.payload },
            }
            return state;


        case actions.GET_DASHBOARD_STATS_START:
            state = {
                ...state,
                dashboardStatsLoading: true,
                dashboardStats: {},
            }
            return state;


        case actions.GET_DASHBOARD_STATS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                dashboardStatsLoading: false,
                dashboardStats: { ...action.payload },
            }
            return state;
    

        case actions.GET_SHARE_DISTRIBUTION:
            state = {
                ...state,
                shareDistributionLoading: false,
                shareDistribution: action.payload,
            }
            return state;


        case actions.GET_SHARE_DISTRIBUTION_START:
            state = {
                ...state,
                shareDistributionLoading: true,
                shareDistribution: {},
            }
            return state;


        case actions.GET_SHARE_DISTRIBUTION_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                shareDistributionLoading: false,
                shareDistribution: action.payload,
            }
            return state;
    

        case actions.GET_SCHEME_METRIC_AWARDS:
            state = {
                ...state,
                schemeMetricsAwardsLoading: false,
                schemeMetricsAwards: action.payload,
            }
            return state;


        case actions.GET_SCHEME_METRIC_AWARDS_START:
            state = {
                ...state,
                schemeMetricsAwardsLoading: true,
                schemeMetricsAwards: {},
            }
            return state;


        case actions.GET_SCHEME_METRIC_AWARDS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                schemeMetricsAwardsLoading: false,
                schemeMetricsAwards: action.payload,
            }
            return state;
    
            
        case actions.GET_SCHEME_METRIC_OPTIONS:
            state = {
                ...state,
                schemeMetricsOptionsLoading: false,
                schemeMetricsOptions: action.payload,
            }
            return state;


        case actions.GET_SCHEME_METRIC_OPTIONS_START:
            state = {
                ...state,
                schemeMetricsOptionsLoading: true,
                schemeMetricsOptions: {},
            }
            return state;


        case actions.GET_SCHEME_METRIC_OPTIONS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                schemeMetricsOptionsLoading: false,
                schemeMetricsOptions: action.payload,
            }
            return state;
    
            
        case actions.GET_SCHEME_METRIC_PURCHASE:
            state = {
                ...state,
                schemeMetricsPurchaseLoading: false,
                schemeMetricsPurchase: action.payload,
            }
            return state;


        case actions.GET_SCHEME_METRIC_PURCHASE_START:
            state = {
                ...state,
                schemeMetricsPurchaseLoading: true,
                schemeMetricsPurchase: {},
            }
            return state;


        case actions.GET_SCHEME_METRIC_PURCHASE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                schemeMetricsPurchaseLoading: false,
                schemeMetricsPurchase: action.payload,
            }
            return state;
    
            
        case actions.GET_SCHEME_METRIC_LTIP:
            state = {
                ...state,
                schemeMetricsLtipLoading: false,
                schemeMetricsLtip: action.payload,
            }
            return state;


        case actions.GET_SCHEME_METRIC_LTIP_START:
            state = {
                ...state,
                schemeMetricsLtipLoading: true,
                schemeMetricsLtip: {},
            }
            return state;


        case actions.GET_SCHEME_METRIC_LTIP_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                schemeMetricsLtipLoading: false,
                schemeMetricsLtip: action.payload,
            }
            return state;
    
            
        case actions.GET_SCHEME_METRIC_OTHERS:
            state = {
                ...state,
                schemeMetricsOthersLoading: false,
                schemeMetricsOthers: action.payload,
            }
            return state;


        case actions.GET_SCHEME_METRIC_OTHERS_START:
            state = {
                ...state,
                schemeMetricsOthersLoading: true,
                schemeMetricsOthers: {},
            }
            return state;


        case actions.GET_SCHEME_METRIC_OTHERS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                schemeMetricsOthersLoading: false,
                schemeMetricsOthers: action.payload,
            }
            return state;
    

        case actions.RESET_SCHEME_METRIC:
            state = {
                ...state,
                schemeMetricsAwardsLoading: false,
                schemeMetricsAwards: {},
                schemeMetricsOptionsLoading: false,
                schemeMetricsOptions: {},
                schemeMetricsPurchaseLoading: false,
                schemeMetricsPurchase: {},
                schemeMetricsLtipLoading: false,
                schemeMetricsLtip: {},
                schemeMetricsOthersLoading: false,
                schemeMetricsOthers: {},
            }
            return state;
    
            
        default:
            return state;
    }
}