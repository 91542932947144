import * as actions from "./actionTypes";
import * as config from "data/config";
import ObjectToQueryString from 'utils/ObjectToQueryString';

export function getUserMgmtDashboardStats() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUserMgmtDashboardStats,
            method: "get",
            data: {},
            onStart: actions.GET_USERMGMTS_DASHBOARD_STATS_START,
            onSuccess: actions.GET_USERMGMTS_DASHBOARD_STATS,
            onError: actions.GET_USERMGMTS_DASHBOARD_STATS_FAILED,
        },
    }
}

export function getUserMgmts(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUserMgmt + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_USERMGMTS_START,
            onSuccess: actions.GET_USERMGMTS,
            onError: actions.GET_USERMGMTS_FAILED,
        },
    }
}

export function getUserMgmt(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiUserMgmt}/${id}`,
            method: "get",
            data: {},
            onStart: actions.GET_USERMGMT_START,
            onSuccess: actions.GET_USERMGMT,
            onError: actions.GET_USERMGMT_FAILED,
        },
    }
}

export function createUser(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUserMgmt,
            method: "post",
            data: params,
            onStart: actions.CREATE_USERMGMT_START,
            onSuccess: actions.CREATE_USERMGMT,
            onError: actions.CREATE_USERMGMT_FAILED,
        },
    }
}

export function createUserReset() {
    return {
        type: actions.CREATE_USERMGMT_RESET,
        payload: {},
    }
}

export function editUser(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiUserMgmt}/${id}/update`,
            method: "post",
            data: params,
            onStart: actions.EDIT_USERMGMT_START,
            onSuccess: actions.EDIT_USERMGMT,
            onError: actions.EDIT_USERMGMT_FAILED,
        },
    }
}

export function editUserReset() {
    return {
        type: actions.EDIT_USERMGMT_RESET,
        payload: {},
    }
}

export function activateUserMgmt(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiUserMgmt}/${id}/activate`,
            method: "post",
            data: {},
            onStart: actions.ACTIVATE_USERMGMT_START,
            onSuccess: actions.ACTIVATE_USERMGMT,
            onError: actions.ACTIVATE_USERMGMT_FAILED,
        },
    }
}

export function deactivateUserMgmt(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiUserMgmt}/${id}/deactivate`,
            method: "post",
            data: {},
            onStart: actions.DEACTIVATE_USERMGMT_START,
            onSuccess: actions.DEACTIVATE_USERMGMT,
            onError: actions.DEACTIVATE_USERMGMT_FAILED,
        },
    }
}

export function resendInvite(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiUserMgmt}/${id}/resend-invite`,
            method: "post",
            data: {},
            onStart: actions.RESEND_INVITE_USERMGMT_START,
            onSuccess: actions.RESEND_INVITE_USERMGMT,
            onError: actions.RESEND_INVITE_USERMGMT_FAILED,
        },
    }
}

export function getUserActivityHistory(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUserActivityHistory + id + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_USER_ACTIVITY_HISTORY_START,
            onSuccess: actions.GET_USER_ACTIVITY_HISTORY,
            onError: actions.GET_USER_ACTIVITY_HISTORY_FAILED,
        },
    }
}

export function getCompanyUserMgmtDashboardStats(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCompany}/${id}/admin-stats`,
            method: "get",
            data: {},
            onStart: actions.GET_COMPANY_USERMGMTS_DASHBOARD_STATS_START,
            onSuccess: actions.GET_COMPANY_USERMGMTS_DASHBOARD_STATS,
            onError: actions.GET_COMPANY_USERMGMTS_DASHBOARD_STATS_FAILED,
        },
    }
}
