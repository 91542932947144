import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "components/ui";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import { Link } from "react-router-dom";
import validate from "utils/validate";
import { toast } from "react-toastify";
import { PageContent } from "components/PageContent";
import Maybe from "components/Maybe";
import ButtonLoader from "components/ButtonLoader";
import useLoginInfo from "hooks/useLoginInfo";
import * as employeeAction from "store/entities/employee/action";

export interface CompanyEmployeesCreateSingleBeneficiaryProps {}

export function CompanyEmployeesCreateSingleBeneficiary(props: CompanyEmployeesCreateSingleBeneficiaryProps) {
  let { companyId, id } = useParams();
  let navigationBaseUrl = "/trustee/company/"+companyId;
  const {userInfo} = useLoginInfo();
  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    navigationBaseUrl = "/company/employees";
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { employeeLoading, employee, createEmployeeLoading4, createEmployee4 } = useSelector((s: any) => s.entities.employee);


  const [employeeForm, setEmployeeForm] = useState({
    alternateBenefiaryFirstName: "",
    alternateBenefiaryLasName: "",
    alternateBenefiaryPhoneNumber: "",
    alternateBenefiaryEmail: "",
  })


  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    let validateParams:any = {
      // firstName: employeeForm.alternateBenefiaryFirstName,
      // lastName: employeeForm.alternateBenefiaryLasName,
      // email: employeeForm.alternateBenefiaryEmail,
    };

    const errors:any = validate(validateParams);
    if (errors){
      for (var x in errors) {
        toast.error(errors[x]);
      };
      return;
    }
    else{
      const params:any = {...employeeForm, id, organizationId: companyId};
      // console.log(params);
      dispatch(employeeAction.createStep4(params));
    }
  }


  useEffect(() => {
    if (createEmployee4.success && createEmployee4.success === true){
      dispatch(employeeAction.createStep4Reset());
      navigate(navigationBaseUrl+"/form/"+createEmployee4.data.id+"/preview");
    }
  }, [createEmployee4]);


  const populateForm = () => {
    if (employee.data?.userInfo){
      const {data} = employee;

      if (data.id === id){
        const form = {
          firstName: data.userInfo.firstName || "",
          lastName: data.userInfo.lastName || "",
          email: data.userInfo.email || "",

          alternateBenefiaryFirstName: data.alternateBenefiaryFirstName || "",
          alternateBenefiaryLasName: data.alternateBenefiaryLasName || "",
          alternateBenefiaryPhoneNumber: data.alternateBenefiaryPhoneNumber || "",
          alternateBenefiaryEmail: data.alternateBenefiaryEmail || "",
        }
        setEmployeeForm(form);
      }
    }
  }

  useEffect(() => {
    dispatch(employeeAction.getEmployee(id));
  }, []);

  
  useEffect(() => {
    populateForm();
  }, [employee]);


  return (
    <div>

      <FormWizardNavBar showSteps={true} currentStep={4} totalSteps={6} closeUrl={navigationBaseUrl} />

      <div className="container py-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Alternate beneficiary details
          </div>
          <p className="mt-2 text-fade text-sm">
            Fill in alternate beneficiary details
          </p>
        </div>

        <div className="mt-12">
          <PageContent loading={employeeLoading} data={employee.data || undefined} />

          <Maybe condition={employeeLoading === false && employee.data && Object.keys(employee.data).length > 0}>
            <form onSubmit={submitForm}>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input label="First name (optional)" type={"text"} value={employeeForm.alternateBenefiaryFirstName} onChange={(e:any) => setEmployeeForm({...employeeForm, alternateBenefiaryFirstName: e.target.value})} />
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input label="Last name (optional)" type={"text"} value={employeeForm.alternateBenefiaryLasName} onChange={(e:any) => setEmployeeForm({...employeeForm, alternateBenefiaryLasName: e.target.value})} />
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input label="Phone number (optional)" type={"tel"} value={employeeForm.alternateBenefiaryPhoneNumber} onChange={(e:any) => setEmployeeForm({...employeeForm, alternateBenefiaryPhoneNumber: e.target.value})} />
                </div>
              </div>

              <div className="flex flex-wrap mb-6">
                <div className="w-full">
                  <Input label="Email Address (optional)" type={"email"} value={employeeForm.alternateBenefiaryEmail} onChange={(e:any) => setEmployeeForm({...employeeForm, alternateBenefiaryEmail: e.target.value})} />
                </div>
              </div>

              <div className="flex justify-end space-x-3 mt-6 mb-6">
                <Link to={navigationBaseUrl + "/form/" + id + "/scheme"} className="btn btn-gold">
                  Back
                </Link>
                <ButtonLoader type="submit" loading={createEmployeeLoading4} className="btn btn-brown">
                  Continue
                </ButtonLoader>
              </div>

            </form>
          </Maybe>

        </div>
      </div>

    </div>
  );
}

export default CompanyEmployeesCreateSingleBeneficiary;
