import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "components/ui";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import validate from "utils/validate";
import { toast } from "react-toastify";
import { PageContent } from "components/PageContent";
import Maybe from "components/Maybe";
import ButtonLoader from "components/ButtonLoader";
import { Link } from "react-router-dom";
import useLoginInfo from "hooks/useLoginInfo";
import * as employeeAction from "store/entities/employee/action";

export interface CompanyEmployeesCreateSinglePersonalProps {}

export function CompanyEmployeesCreateSinglePersonal(props: CompanyEmployeesCreateSinglePersonalProps) {
  let { companyId, id } = useParams();
  let navigationBaseUrl = "/trustee/company/"+companyId;
  const {userInfo} = useLoginInfo();
  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    navigationBaseUrl = "/company/employees"
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { employeeLoading, employee, createEmployeeLoading1, createEmployee1 } = useSelector((s: any) => s.entities.employee);


  const [employeeForm, setEmployeeForm] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  })


  const getPageType = () => {
    if (!id){
      return true;
    }
    else if (id && employeeLoading === false && employee.data && Object.keys(employee.data).length > 0){
      return true;
    }
    else{
      return false;
    }
  }


  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    let validateParams:any = {...employeeForm};
    delete validateParams["phoneNumber"];

    const errors:any = validate(validateParams);
    if (errors){
      for (var x in errors) {
        toast.error(errors[x]);
      };
      return;
    }
    else{
      let params:any = {...employeeForm, organizationId: companyId};
      if (id){ params = {...params, id} };
      if (params.phoneNumber === ""){
        delete params["phoneNumber"];
      }
      // console.log(params);
      dispatch(employeeAction.createStep1(params));
    }
  }


  useEffect(() => {
    if (createEmployee1.success && createEmployee1.success === true){
      dispatch(employeeAction.createStep1Reset());
      navigate(navigationBaseUrl+"/form/"+createEmployee1.data.id+"/official");
    }
  }, [createEmployee1]);


  const populateForm = () => {
    if (employee.data?.userInfo){
      const data = employee.data.userInfo;

      if (employee.data.id === id){
        const form = {
          firstName: data.firstName || "",
          lastName: data.lastName || "",
          email: data.email || "",
          phoneNumber: data.phoneNumber || "",
        }
        setEmployeeForm(form);
      }
    }
  }
  

  useEffect(() => {
    if (id){
      dispatch(employeeAction.getEmployee(id));
    }
  }, []);

  
  useEffect(() => {
    if (id){
      populateForm();
    }
  }, [employee]);


  return (
    <div>

      <FormWizardNavBar showSteps={true} currentStep={1} totalSteps={6} closeUrl={navigationBaseUrl} />

      <div className="container pb-10 mt-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Personal details
          </div>
          <p className="mt-2 text-fade text-sm">
            Fill in employee details
          </p>
        </div>

        <div className="mt-12">

          <Maybe condition={id !== undefined}>
            <PageContent loading={employeeLoading} data={employee.data || undefined} />
          </Maybe>
          
          <Maybe condition={getPageType()}>
            <div>
              <form onSubmit={submitForm}>

                <div className="flex flex-wrap mb-6">
                  <div className="w-full">
                    <Input label="First name" type={"text"} value={employeeForm.firstName} onChange={(e:any) => setEmployeeForm({...employeeForm, firstName: e.target.value})} />
                  </div>
                </div>

                <div className="flex flex-wrap mb-6">
                  <div className="w-full">
                    <Input label="Last name" type={"text"} value={employeeForm.lastName} onChange={(e:any) => setEmployeeForm({...employeeForm, lastName: e.target.value})} />
                  </div>
                </div>

                <div className="flex flex-wrap mb-6">
                  <div className="w-full">
                    <Input type="tel" label="Phone number (optional)" value={employeeForm.phoneNumber} onChange={(e:any) => setEmployeeForm({...employeeForm, phoneNumber: e.target.value})} />
                  </div>
                </div>

                <div className="flex flex-wrap mb-6">
                  <div className="w-full">
                    <Input type="email" label="Personal Email Address" value={employeeForm.email} onChange={(e:any) => setEmployeeForm({...employeeForm, email: e.target.value})} />
                  </div>
                </div>

                <div className="flex justify-end space-x-3 mt-6 mb-6">
                  <Link to={navigationBaseUrl} className="btn btn-gold">
                    Back
                  </Link>
                  <ButtonLoader type="submit" loading={createEmployeeLoading1} className="btn btn-brown">
                    Continue
                  </ButtonLoader>
                </div>

              </form>
            </div>
          </Maybe>

        </div>
      </div>

    </div>
  );
}

export default CompanyEmployeesCreateSinglePersonal;
