import * as actions from "./actionTypes";
import * as config from "data/config";
// import ObjectToQueryString from 'utils/ObjectToQueryString';


export function login(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiLoginUser,
            method: "post",
            data: params,
            onStart: actions.LOGIN_USER_START,
            onSuccess: actions.LOGIN_USER,
            onError: actions.LOGIN_USER_FAILED
        },
    }
}

export function forgotPassword(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiForgotPassword,
            method: "post",
            data: params,
            onStart: actions.FORGOT_PASSWORD_START,
            onSuccess: actions.FORGOT_PASSWORD,
            onError: actions.FORGOT_PASSWORD_FAILED
        }
    }
}

export function resetForgotPassword() {
    return {
        type: actions.FORGOT_PASSWORD_RESET,
        payload: {},
    }
}

export function verifyToken(token) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiVerifyToken + "?token=" + token,
            method: "get",
            data: {},
            onStart: actions.VERIFY_TOKEN_START,
            onSuccess: actions.VERIFY_TOKEN,
            onError: actions.VERIFY_TOKEN_FAILED
        }
    }
}

export function resetVerifyToken() {
    return {
        type: actions.VERIFY_TOKEN_RESET,
        payload: {},
    }
}

export function resetPassword(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiResetPassword,
            method: "post",
            data: params,
            onStart: actions.RESET_PASSWORD_START,
            onSuccess: actions.RESET_PASSWORD,
            onError: actions.RESET_PASSWORD_FAILED
        }
    }
}

export function resetResetPassword() {
    return {
        type: actions.RESET_PASSWORD_RESET,
        payload: {},
    }
}

export function logoutUser(params) {
    return {
        type: actions.LOGOUT_USER,
        payload: {
            params
        }
    }
}

export function switchUserView(view) {
    return {
        type: actions.SWITCH_USER_VIEW,
        payload: {
            view
        },
    }
}
