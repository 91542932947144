import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "@headlessui/react";
import { FormDrawer, FileUploadPreview, Input, DocPicker, PreviewAccordion } from "components/ui";
import { TimeDateBlock } from "components/time-date-block";
import { UserInfoCard } from "components/user-info-card";
import { CSVLink } from "react-csv";
// import { DownloadFile } from "components/download-file";
// import iconSuspend from "assets/images/icons/suspend.svg";
import iconSendDark from "assets/images/icons/send-dark.svg";
import iconDownload from "assets/images/icons/download.svg";
import iconDisabled from "assets/images/icons/disabled.svg";
import iconExit from "assets/images/icons/exit.svg";
import iconImageUpload from "assets/images/icons/imageUpload.svg";
import { renderStatusColor } from "utils/statusColor";
import useUrlNavigation from "hooks/useUrlNavigation";
import useMultiSelect from "hooks/useMultiSelect";
import Pagination from "components/Pagination";
import Maybe from "components/Maybe";
import NumberFormat from "utils/numberFormat";
import { TableContent } from "components/TableContent";
import { nameInitials } from "utils/nameInitials";
import DialogBox from "utils/dialogBox";
import iconEdit from "assets/images/icons/edit.svg";
import DateFormat, { DateMiniFormat, TimeFormat } from "utils/dateFormat";
import { pluralizeFull } from "utils/pluralize";
import CurrencyFormat from "utils/currencyFormat";
import SearchBar from "components/SearchBar";
import { PageContent } from "components/PageContent";
import { jsonToFormData } from "utils/jsonToFormData";
import getFileSize from "utils/getFileSize";
import ButtonLoader from "components/ButtonLoader";
import { toast } from "react-toastify";
import validate from "utils/validate";
import { Editor } from '@tinymce/tinymce-react';
import { dateFormatSubmit } from "utils/dataTypes";
import CompanyLogoName from "components/CompanyLogoName";
import useLoginInfo from "hooks/useLoginInfo";
import * as companyAction from "store/entities/company/action";
import * as employeeAction from "store/entities/employee/action";
import * as userMgmtAction from "store/entities/userMgmt/action";



export interface CompanyEmployeesListProps { }

export function CompanyEmployeesList(props: CompanyEmployeesListProps) {
  let { companyId } = useParams();
  let navigationBaseUrl = "/trustee/company/" + companyId;
  let employeeDetailsUrl = navigationBaseUrl + "/employee";

  const { userInfo } = useLoginInfo();
  if (userInfo.userType === "SCHEME_COMMITTEE") {
    companyId = userInfo.organizationId;
    navigationBaseUrl = "/company/employees";
    employeeDetailsUrl = navigationBaseUrl;
  }


  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { urlQuery } = useUrlNavigation();
  const { selections, toggleMultiSelectHeader, getMultiSelectBody, clearSelections, removeFromSelections } = useMultiSelect();
  const [searchParams] = useSearchParams();


  const { companyEmployeeDashboardStatsLoading, companyEmployeeDashboardStats, companyLoading, company, adminsLoading, admins } = useSelector((s: any) => s.entities.company);
  const { employeesLoading, employees, employeeActivateSuccess, employeeDeactivateSuccess, employeeExitSuccess } = useSelector((s: any) => s.entities.employee);


  const [employeeParams, setEmployeeParams] = useState<any>({
    "PageNumber": 1,
    "PageSize": 20,
    "type": searchParams.get("type") || "",
    "dateCreated": searchParams.get("dateCreated") || "",
    "filter": searchParams.get("filter") || "",
    "lastActivity": searchParams.get("lastActivity") || "",
  });


  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [isOpenAdmin, setIsOpenAdmin] = useState(false);
  const [adminDetails, setAdminDetails] = useState<any>({});
  const [isOpenUploads, setIsOpenUploads] = useState(false);


  const toggleFormDrawerProfile = (action: boolean) => {
    setIsOpenProfile(action);
  };

  const toggleFormDrawerAdmin = (action: boolean, admin = "") => {
    setIsOpenAdmin(action);
    setAdminDetails(admin);
  };


  const setSubSchemeAplhaNumberTypes = (data: any) => {
    let nameType: string = "";
    if (data.length > 0) {
      const nameTypeValue = parseInt(data[0].name.split(" ")[1]);
      if (isNaN(nameTypeValue)) {
        nameType = "Alphabets";
      }
      else {
        nameType = "Numbers";
      }
    }
    return nameType;
  }


  const plural = (data: string) => {
    if (data === "Batch") return "Batches";
    else if (data === "Group") return "Groups";
    else if (data === "Category") return "Categories";
    else return "";
  }


  const getDashboardStats = () => {
    dispatch(companyAction.getCompanyEmployeesDashboardStats({ "id": companyId }));
  }


  const getEmployees = () => {
    let params = { ...employeeParams };

    const page = searchParams.get("page");
    if (page !== null) {
      params = { ...params, PageNumber: parseInt(page) }
    }
    else {
      params = { ...params, PageNumber: 1 }
    }

    const limit = searchParams.get("limit");
    if (limit !== null) {
      params = { ...params, PageSize: parseInt(limit) }
    }
    else {
      params = { ...params, PageSize: 20 }
    }

    const search = searchParams.get("search");
    if (search !== null) {
      params = { ...params, search }
    }

    const filter = searchParams.get("filter");
    if (filter !== null) {
      params = { ...params, filter }
    }

    // remove empty queries
    setEmployeeParams(params);
    dispatch(employeeAction.getEmployees(companyId, params));
  }


  const getAdmins = () => {
    let params: any = {
      "PageNumber": 1,
      "PageSize": 1000,
    };

    const search = searchParams.get("search-admin");
    if (search !== null) {
      params = { ...params, search }
    }

    dispatch(companyAction.getAdmins(companyId, params));
  }


  const checkFilterStatus = (value: string) => {
    let response = false;
    if (searchParams.get("filter") !== null) {
      if (searchParams.get("filter") === value) {
        response = true;
      }
    }
    else {
      if (value === "") {
        response = true;
      }
    }
    return response;
  }


  const activateEmployee = async (employee: any, userType: string = "employee") => {
    const result = await DialogBox({
      buttonYes: "Yes, activate " + userType,
    });
    if (result) {
      dispatch(employeeAction.activateEmployee(employee.id));
    }
  }


  const deactivateEmployee = async (employee: any, userType: string = "employee") => {
    const result = await DialogBox({
      theme: "red",
      buttonYes: "Yes, deactivate " + userType,
    });
    if (result) {
      dispatch(employeeAction.deactivateEmployee(employee.id));
    }
  }


  const resendInvite = async (employee: any) => {
    const result = await DialogBox({
      buttonYes: "Yes, resend invite",
    });
    if (result) {
      dispatch(employeeAction.resendInvite(employee.id));
    }
  }


  const exitEmployee = async (employee: any) => {
    const result = await DialogBox({
      theme: "red",
      buttonYes: "Yes, exit employee",
    });
    if (result) {
      dispatch(employeeAction.exitEmployee(employee.id));
    }
  }


  useEffect(() => {
    getDashboardStats();
    getEmployees();
    getAdmins();

  }, [location]);


  useEffect(() => {
    dispatch(companyAction.getCompany(companyId));
  }, []);


  useEffect(() => {
    if (employeeActivateSuccess === true || employeeDeactivateSuccess === true || employeeExitSuccess === true) {
      getDashboardStats();
      getEmployees();
      getAdmins();
      setIsOpenAdmin(false);
    }

  }, [employeeActivateSuccess, employeeDeactivateSuccess, employeeExitSuccess]);


  return (
    <div className="pb-10">

      <div className="bg-vetiva-black">
        <div className="container pt-16 pb-24 flex flex-wrap">
          <div className="w-full xl:w-3/12">
            <div className="h-full flex xl:flex-col justify-between xl:justify-start">
              <TimeDateBlock />


              {userInfo.userType === "TRUSTEE" &&
                <div className="xl:mt-10 flex">
                  <div onClick={() => setIsOpenUploads(true)} className="py-1 my-auto text-sm text-vetiva-brown border-b border-dashed border-vetiva-brown inline-block cursor-pointer">
                    Uploads
                    <FontAwesomeIcon icon="angle-right" className="ml-2" />
                  </div>
                </div>
              }


              {userInfo.userType === "SCHEME_COMMITTEE" &&
                <div className="xl:mt-10 flex">
                  <div onClick={() => toggleFormDrawerProfile(true)} className="py-1 my-auto text-sm text-vetiva-brown border-b border-dashed border-vetiva-brown inline-block cursor-pointer">
                    View Company Profile
                    <FontAwesomeIcon icon="angle-right" className="ml-2" />
                  </div>
                </div>
              }

            </div>
          </div>
          <div className="w-full xl:w-9/12">
            <div className="ml-10">

              {userInfo.userType === "TRUSTEE" &&
                <div className="flex">
                  <Link to="/trustee/company" className="w-8 h-7 flex mr-3 my-auto bg-gray-700 justify-center rounded group hover:bg-white">
                    <FontAwesomeIcon icon="angle-left" className="text-xl text-white my-auto group-hover:text-vetiva-brown" />
                  </Link>
                  <div className="text-2xl">
                    <Maybe condition={companyLoading}>
                      <FontAwesomeIcon icon="spinner" spin className="text-vetiva-brown" />
                    </Maybe>
                    <Maybe condition={!companyLoading}>
                      <span className="text-vetiva-brown mr-2">
                        {company.data?.name}
                      </span>
                      <span className="text-gray-300 uppercase">
                        {company.data?.rcNumber}
                      </span>
                    </Maybe>
                  </div>
                </div>
              }

              {userInfo.userType === "SCHEME_COMMITTEE" &&
                <div className="-mt-12 mb-3">
                  <CompanyLogoName company={company} />
                </div>
              }

              <div className="mt-3">

                <div className="w-full flex space-x-4">
                  <div className="h-36 p-6 bg-white flex-grow flex items-center rounded-lg">
                    <div className="flex-grow flex">
                      <div className="mx-auto">
                        <div className="text-sm text-gray-500">
                          Shares alloted
                        </div>
                        <div className="text-2xl">
                          <Maybe condition={companyEmployeeDashboardStatsLoading}>
                            <FontAwesomeIcon icon="spinner" spin />
                          </Maybe>
                          <Maybe condition={!companyEmployeeDashboardStatsLoading}>
                            {NumberFormat(companyEmployeeDashboardStats.data?.sharesAlloted, "no-kobo") || "---"}
                          </Maybe>
                        </div>
                      </div>
                    </div>
                    <div className="w-6 flex-shrink-0 flex items-center justify-center">/</div>
                    <div className="flex-grow flex">
                      <div className="mx-auto">
                        <div className="text-sm text-vetiva-green">
                          Total allocated
                        </div>
                        <div className="text-vetiva-brown text-2xl">
                          <Maybe condition={companyEmployeeDashboardStatsLoading}>
                            <FontAwesomeIcon icon="spinner" spin />
                          </Maybe>
                          <Maybe condition={!companyEmployeeDashboardStatsLoading}>
                            {NumberFormat(companyEmployeeDashboardStats.data?.totalAllocated, "no-kobo") || "---"}
                          </Maybe>
                        </div>
                      </div>
                    </div>
                    <div className="w-6 flex-shrink-0 flex items-center justify-center">/</div>
                    <div className="flex-grow flex">
                      <div className="mx-auto">
                        <div className="text-sm text-orange-400">
                          Total unallocated
                        </div>
                        <div className="text-2xl">
                          <Maybe condition={companyEmployeeDashboardStatsLoading}>
                            <FontAwesomeIcon icon="spinner" spin />
                          </Maybe>
                          <Maybe condition={!companyEmployeeDashboardStatsLoading}>
                            {NumberFormat(companyEmployeeDashboardStats.data?.totalUnAllocated, "no-kobo") || "---"}
                          </Maybe>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-[25%] h-36 p-6 bg-white flex items-center rounded-lg">
                    <div>
                      <div className="text-sm text-gray-500">
                        Total employees
                      </div>
                      <div className="text-2xl">
                        <Maybe condition={employeesLoading}>
                          <FontAwesomeIcon icon="spinner" spin />
                        </Maybe>
                        <Maybe condition={!employeesLoading}>
                          {employees.meta?.pagination?.total}
                        </Maybe>
                      </div>
                    </div>
                  </div>

                  {userInfo.userType === "TRUSTEE" &&
                    <Link to={navigationBaseUrl + "/create"} className="w-[15%] h-36 p-2 flex flex-col justify-center items-center space-y-1 text-white bg-vetiva-brown hover:bg-vetiva-brown-darker rounded-lg">
                      <FontAwesomeIcon icon="plus" className="text-3xl" />
                      <div className="text-center text-sm">
                        Add Employee
                      </div>
                    </Link>
                  }


                </div>

              </div>
            </div>

          </div>
        </div>
      </div>





      <CompanyUploads
        isOpenUploads={isOpenUploads}
        setIsOpenUploads={setIsOpenUploads}
      />




      <AdminActivityHistory
        isOpenAdmin={isOpenAdmin}
        setIsOpenAdmin={setIsOpenAdmin}
        adminDetails={adminDetails}
        activateEmployee={(e: any, type: any) => activateEmployee(e, type)}
        deactivateEmployee={(e: any, type: any) => deactivateEmployee(e, type)}
      />

      {/* 
      <FormDrawer size="2xl" isOpen={isOpenAdmin} setIsOpen={setIsOpenAdmin}>
        <div className="h-full">
          <div className="w-full relative">
            
            <Maybe condition={adminDetails !== ""}>
              <div className="w-full flex h-full relative">
                <div className="w-[50%] fixed top-0 bottom-0 left-0 flex-shrink-0 p-6 bg-[#F9F6F1] bg-vetiva-pattern-2">

                  <div className="w-28 h-28 mt-6 text-white text-4xl font-bold bg-vetiva-brown rounded-full mx-auto uppercase flex items-center justify-center">
                    {nameInitials(adminDetails.userInfo?.firstName, adminDetails.userInfo?.lastName)}
                  </div>
                  
                  <div>
                    <div className="mt-10 space-y-3">
                      <div>
                        <div className="font-semibold">
                          Name
                        </div>
                        <div className="text-gray-500">
                          {adminDetails.userInfo?.firstName} {adminDetails.userInfo?.lastName}
                        </div>
                      </div>
                      <div>
                        <div className="font-semibold">
                          Role
                        </div>
                        <div className="text-gray-500">
                          {adminDetails.jobTitle}
                        </div>
                      </div>
                    </div>

                    <div className="mt-10 text-gray-400 font-bold">
                      Contact
                    </div>

                    <div className="mt-3 space-y-3">
                      <div>
                        <div className="font-semibold">
                          Email
                        </div>
                        <div className="text-vetiva-purple">
                          {adminDetails.userInfo?.email}
                        </div>
                      </div>
                      <div>
                        <div className="font-semibold">
                          Phone number
                        </div>
                        <div className="text-vetiva-purple">
                          {adminDetails.userInfo?.phoneNumber}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full absolute bottom-0 right-0 p-6">
                    <Maybe condition={adminDetails.isActive === true}>
                      <button type="button" onClick={() => deactivateEmployee(adminDetails, "admin")} className="btn bg-white btn-block text-red-500">
                        Deactivate this user
                      </button>
                    </Maybe>
                    <Maybe condition={adminDetails.isActive === false}>
                      <button type="button" onClick={() => activateEmployee(adminDetails, "admin")} className="btn bg-white btn-block text-red-500">
                        Activate this user
                      </button>
                    </Maybe>
                  </div>

                </div>

                <div className="flex-grow ml-[50%] relative">
                  <div className="h-20 fixed z-[51] top-0 left-0 right-0 ml-[50%] p-4 bg-white border-b">
                    <div className="w-full">
                      <Input type="date" label="Jun 23, 2022" />
                    </div>
                  </div>
                  <div className="p-4 relative top-20">
                    
                    <div className="hidden">
                      {[...Array(4)].map((data, index) =>
                        <div key={index} className="flex space-x-4">
                          <div>
                            <div className="w-2 h-2 bg-vetiva-brown rounded-full mx-auto"></div>
                            <div className="w-px h-full bg-gray-300 mx-auto"></div>
                          </div>
                          <div className="pb-6">
                            <div className="-mt-1 text-xs text-gray-500">
                              11:34PM
                            </div>
                            <div className="mt-1 text-gray-700">
                              User added a new employee <span className="font-bold">Adebola Aliu Chioma</span>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="flex space-x-4">
                        <div>
                          <div className="w-2 h-2 bg-vetiva-brown rounded-full mx-auto"></div>
                          <div className="w-px h-full bg-gray-300 mx-auto"></div>
                        </div>
                        <div className="pb-6">
                          <div className="-mt-1 text-xs text-gray-500">
                            Yesterday
                          </div>
                          <div className="mt-1 text-gray-700">
                            User suspended <span className="font-bold">12</span> employees
                          </div>
                        </div>
                      </div>

                      {[...Array(14)].map((data, index) =>
                        <div key={index} className="flex space-x-4">
                          <div>
                            <div className="w-2 h-2 bg-vetiva-brown rounded-full mx-auto"></div>
                            <div className="w-px h-full bg-gray-300 mx-auto"></div>
                          </div>
                          <div className="pb-6">
                            <div className="-mt-1 text-xs text-gray-500">
                              Jun 23, 2022
                            </div>
                            <div className="mt-1 text-gray-700">
                              User added a new employee <span className="font-bold">Adebola Aliu Chioma</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                  </div>
                </div>

              </div>
            </Maybe>

          </div>
        </div>
      </FormDrawer>
      */}




      <FormDrawer isOpen={isOpenProfile} setIsOpen={setIsOpenProfile}>
        <div className="h-full">
          <div className="fixed h-32 w-full top-0 bg-vetiva-black">
            <UserInfoCard
              image={company.data?.logoObject?.url} imageSize="14"
              name={company.data?.name}
              date={"Reg. Date: " + DateMiniFormat(company.data?.createdAt)}
              status={company.data?.status}
              idNumber={company.data?.rcNumber}
              iconUrl={userInfo.userType === "SCHEME_COMMITTEE" ? "" : "/trustee/company/form/" + companyId + "/company-details"}
            />
          </div>

          <div className="w-full absolute top-32 bottom-16 overflow-y-auto">
            <div className="p-6">

              <div className="mt-2 border-b border-gray-300 mb-6">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <h2 className="text-fade text-sm font-bold">
                      ADDRESS
                    </h2>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <div className="space-y-1">
                      <label className="text-fade font-normal">
                        Address 1
                      </label>
                      <p className="font-bold">
                        {company.data?.address1}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <div className="space-y-1">
                      <label className="text-fade font-normal">
                        City
                      </label>
                      <p className="font-bold">
                        {company.data?.city}
                      </p>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <div className="space-y-1">
                      <label className="text-fade font-normal">
                        Postal/ZIP code
                      </label>
                      <p className="font-bold">
                        {company.data?.postalCode}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <div className="space-y-1">
                      <label className="text-fade font-normal">
                        State
                      </label>
                      <p className="font-bold">
                        {company.data?.state}
                      </p>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <div className="space-y-1">
                      <label className="text-fade font-normal">
                        Country
                      </label>
                      <p className="font-bold">
                        {company.data?.country}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-b border-gray-300 mb-6">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <h2 className="text-fade text-sm font-bold">
                      ADMIN DETAILS
                    </h2>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <div className="space-y-1">
                      <label className="text-fade font-normal">
                        Name
                      </label>
                      <p className="font-bold">
                        {company.data?.admin?.userInfo?.firstName} {company.data?.admin?.userInfo?.lastName}
                      </p>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <div className="space-y-1">
                      <label className="text-fade font-normal">
                        Job Title
                      </label>
                      <p className="font-bold">
                        {company.data?.admin?.jobTitle}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <div className="space-y-1">
                      <label className="text-fade font-normal">
                        Phone
                      </label>
                      <p className="font-bold">
                        {company.data?.admin?.userInfo?.phoneNumber}
                      </p>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <div className="space-y-1">
                      <label className="text-fade font-normal">
                        Email address
                      </label>
                      <p className="font-bold text-blue-500">
                        {company.data?.admin?.userInfo?.email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-b border-gray-300 mb-6">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <h2 className="text-fade text-sm font-bold">
                      COMPANY SETTINGS
                    </h2>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3 mb-4">
                    <div className="flex justify-between">
                      <label className="text-fade font-normal">
                        Allocation Type
                      </label>
                      <p>
                        {company.data?.organizationShareSetting?.sharesAllocation}
                      </p>
                    </div>
                  </div>
                  <div className="w-full px-3">

                    {company.data?.organizationShareSetting?.schemeTypes.map((schemeType: any, index: number) =>

                      <div key={index} className="bg-gray-100 p-4 mb-8">
                        <div id="header" className="flex justify-between border-b border-gray-300 mb-4 pb-3">
                          <label className="text-fade font-normal">
                            Scheme Type
                          </label>
                          <p className="text-vetiva-brown font-bold">
                            {schemeType.name !== "Access to Others" ? schemeType.name : schemeType?.schemeDetail?.otherSchemeName}
                          </p>
                        </div>

                        <div className="border-b border-gray-300 mb-4">
                          {schemeType.schemePropertyType === 1 &&
                            <div className="flex justify-between mb-4">
                              <label className="text-fade font-normal">
                                Strike Price
                              </label>
                              <p>
                                {CurrencyFormat(schemeType.schemeDetail?.strikePrice)}
                              </p>
                            </div>
                          }
                          {schemeType.schemePropertyType === 2 &&
                            <div className="flex justify-between mb-4">
                              <label className="text-fade font-normal">
                                Purchase Price
                              </label>
                              <p>
                                {CurrencyFormat(schemeType.schemeDetail?.purchasePrice)}
                              </p>
                            </div>
                          }
                          {(schemeType.schemePropertyType === 1 || schemeType.schemePropertyType === 2) &&
                            <div className="flex justify-between mb-4">
                              <label className="text-fade font-normal">
                                Valuation
                              </label>
                              <p>
                                {CurrencyFormat(schemeType.schemeDetail?.valuation)}
                              </p>
                            </div>
                          }
                          {schemeType.schemePropertyType === 1 &&
                            <div className="flex justify-between mb-4">
                              <label className="text-fade font-normal">
                                Exercise Period
                              </label>
                              <p>
                                {DateMiniFormat(schemeType.schemeDetail?.exercisePeriodStartDate)} - {DateMiniFormat(schemeType.schemeDetail?.exercisePeriodEndDate)}
                              </p>
                            </div>
                          }
                          {schemeType.schemePropertyType === 1 &&
                            <div className="flex justify-between mb-4">
                              <label className="text-fade font-normal">
                                Exercise Date
                              </label>
                              <p>
                                {DateMiniFormat(schemeType.schemeDetail?.expirationDate)}
                              </p>
                            </div>
                          }
                          <div className="flex justify-between mb-4">
                            <label className="text-fade font-normal">
                              Holding Period
                            </label>
                            <p>
                              {DateMiniFormat(schemeType.startDate)} - {DateMiniFormat(schemeType.endDate)}
                            </p>
                          </div>
                          <div className="flex justify-between mb-4">
                            <label className="text-fade font-normal">
                              Access to subscheme
                            </label>
                            <p>
                              {schemeType.hasSubSchemeAccess === true ? "Yes" : "No"}
                            </p>
                          </div>
                          {schemeType.hasSubSchemeAccess === false &&
                            <>
                              <div className="flex justify-between mb-4">
                                <label className="text-fade font-normal">
                                  Number of Allocation
                                </label>
                                <p>
                                  {schemeType.schemeDetail?.numberOfAllocation}
                                </p>
                              </div>
                              <div className="flex justify-between mb-4">
                                <label className="text-fade font-normal">
                                  Date of Allocation
                                </label>
                                <p>
                                  {DateMiniFormat(schemeType.schemeDetail?.dateOfAllocation)}
                                </p>
                              </div>
                            </>
                          }
                        </div>

                        <div className="mb-4">

                          <Maybe condition={schemeType.hasSubSchemeAccess === true}>
                            {schemeType.subSchemes.length > 0 &&
                              <>
                                <Maybe condition={schemeType.subSchemes[0].batches.length > 0}>
                                  <>
                                    {(() => {
                                      let subSchemeBatch: any = [];
                                      let subSchemeGroup: any = [];
                                      let subSchemeCategory: any = [];

                                      schemeType.subSchemes[0].batches.map((data: any) => {
                                        if (data.name.includes("Batch")) {
                                          subSchemeBatch.push(data);
                                        }
                                        else if (data.name.includes("Group")) {
                                          subSchemeGroup.push(data);
                                        }
                                        else if (data.name.includes("Category")) {
                                          subSchemeCategory.push(data);
                                        }
                                        return null;
                                      })

                                      let subSchemeIndex: any = ["Batch", "Group", "Category"];
                                      let subSchemeAll: any = [subSchemeBatch, subSchemeGroup, subSchemeCategory];

                                      let render = subSchemeAll.map((data: any, index2: number) =>
                                        <Maybe key={index2} condition={data.length > 0}>
                                          <div className="flex justify-between mb-4">
                                            <PreviewAccordion
                                              allocType={subSchemeIndex[index2]}
                                              countType={setSubSchemeAplhaNumberTypes(data)}
                                              stats={data.length + " " + pluralizeFull(data.length, subSchemeIndex[index2], plural(subSchemeIndex[index2])).toLowerCase()}
                                            >
                                              <div className="py-2 flex flex-col space-y-2">

                                                {data.map((dataBatch: any, index3: number) =>
                                                  <div key={index3} className="bg-white p-3">
                                                    <div>
                                                      {dataBatch.name}
                                                    </div>
                                                    <Maybe condition={dataBatch.allocations.length > 0}>
                                                      {dataBatch.allocations.map((dataAllocation: any, index4: number) =>
                                                        <div key={index4} className="mt-4 pt-4 border-t border-gray-300">
                                                          <div className="flex flex-wrap -mx-3 mb-6">
                                                            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                                              <div className="space-y-1">
                                                                <label className="text-fade font-normal">
                                                                  Number of allocation
                                                                </label>
                                                                <p className="font-normal">
                                                                  {NumberFormat(dataAllocation.numberOfAllocation)}
                                                                </p>
                                                              </div>
                                                            </div>
                                                            <div className="w-full md:w-1/3 px-3">
                                                              <div className="space-y-1">
                                                                <label className="text-fade font-normal">
                                                                  Date of allocation
                                                                </label>
                                                                <p className="font-normal">
                                                                  {DateMiniFormat(dataAllocation.dateOfAllocation)}
                                                                </p>
                                                              </div>
                                                            </div>
                                                            <div className="w-full md:w-1/3 px-3">
                                                              <div className="space-y-1">
                                                                <label className="text-fade font-normal">
                                                                  Percentage for Vesting
                                                                </label>
                                                                <p className="font-normal">
                                                                  {dataAllocation.percentageVesting}%
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="flex flex-wrap -mx-3 mb-6">
                                                            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                                              <div className="space-y-1">
                                                                <label className="text-fade font-normal">
                                                                  Vesting Period
                                                                </label>
                                                                <p className="font-normal">
                                                                  {dataAllocation.vestingPeriod}
                                                                </p>
                                                              </div>
                                                            </div>
                                                            <Maybe condition={dataAllocation.vestingPeriod === "Fixed period"}>
                                                              <div className="w-full md:w-1/3 px-3">
                                                                <div className="space-y-1">
                                                                  <label className="text-fade font-normal">
                                                                    Vesting date
                                                                  </label>
                                                                  <p className="font-normal">
                                                                    Every {dataAllocation.period} {pluralizeFull(dataAllocation.period, "year", "years")}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </Maybe>
                                                            <Maybe condition={dataAllocation.vestingPeriod === "Recurring date"}>
                                                              <div className="w-full md:w-1/3 px-3">
                                                                <div className="space-y-1">
                                                                  <label className="text-fade font-normal">
                                                                    Vesting date
                                                                  </label>
                                                                  <p className="font-normal">
                                                                    {DateMiniFormat(dataAllocation.vestingDate)}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </Maybe>
                                                          </div>
                                                          <div className="mb-2">
                                                            <FileUploadPreview
                                                              className="bg-gray-100"
                                                              file={dataAllocation.schemeRuleObj ? dataAllocation.schemeRuleObj[0] || undefined : undefined}
                                                            />
                                                          </div>
                                                        </div>
                                                      )}
                                                    </Maybe>
                                                    <Maybe condition={dataBatch.allocations.length <= 0}>
                                                      <span className="italic">No allocations</span>
                                                    </Maybe>
                                                  </div>
                                                )}

                                              </div>
                                            </PreviewAccordion>
                                          </div>
                                        </Maybe>
                                      )

                                      return (render);

                                    })()}
                                  </>
                                </Maybe>

                                <Maybe condition={schemeType.subSchemes[0].batches.length <= 0}>
                                  <div className="page-info-italic">
                                    No batches, groups & categories
                                  </div>
                                </Maybe>

                                <div className="border-t border-gray-300 mt-4">
                                  <div className="flex flex-col justify-between mt-4">
                                    <label className="text-fade font-normal">
                                      Trust Deed
                                    </label>
                                    <div className="mt-2">
                                      <FileUploadPreview
                                        className="bg-white"
                                        file={schemeType.subSchemes[0].trustDeedObject ? schemeType.subSchemes[0].trustDeedObject[0] || undefined : undefined}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            }

                            <Maybe condition={schemeType.subSchemes.length <= 0}>
                              <div className="page-info-italic mb-4">
                                No subschemes
                              </div>
                            </Maybe>
                          </Maybe>

                        </div>

                      </div>

                    )}

                  </div>


                  <div className="w-full px-3 mb-4 mt-8">
                    <div className="flex justify-between">
                      <label className="text-fade font-normal">
                        Employees Access To Share Sale Or Transfer
                      </label>
                      <p className="font-bold">

                        <Maybe condition={company.data?.organizationShareSetting?.canEmployeeInitiateShareSale === true}>
                          <FontAwesomeIcon icon="check" className="mr-2" />
                          Access granted
                        </Maybe>

                        <Maybe condition={company.data?.organizationShareSetting?.canEmployeeInitiateShareSale === false}>
                          <FontAwesomeIcon icon="times" className="mr-2" />
                          Access not granted
                        </Maybe>

                      </p>
                    </div>
                  </div>

                  <div className="w-full px-3 mb-4">
                    <div className="flex justify-between">
                      <label className="text-fade font-normal">
                        Dividend Type
                      </label>
                      <p className="font-bold">
                        {company.data?.organizationShareSetting?.dividendTypes.join(", ")}
                      </p>
                    </div>
                  </div>

                </div>
              </div>

              <div className="">
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <h2 className="text-fade text-sm font-bold">
                      Supporting Documents
                    </h2>
                  </div>
                </div>
                <div>
                  {company.data?.organizationShareSetting?.supportingDocumentObject !== null &&
                    <div className="mb-3">
                      <FileUploadPreview
                        className="bg-gray-100"
                        file={company.data?.organizationShareSetting?.supportingDocumentObject[0] || undefined}
                        allowDismiss={false}
                      />
                    </div>
                  }

                  {company.data?.organizationShareSetting?.supportingDocumentObject === null &&
                    <div className="page-info-italic py-12 bg-gray-100">
                      No supporting documents
                    </div>
                  }
                </div>
              </div>

            </div>
          </div>

          <div className="w-full fixed bottom-0 p-3 bg-white flex space-x-3 items-center justify-end">
            <button type="button" onClick={() => toggleFormDrawerProfile(false)} className="btn btn-gold">
              Close
            </button>
          </div>
        </div>
      </FormDrawer>





      <div className="container flex -mt-16">

        {userInfo.userType === "TRUSTEE" &&
          <div className="w-full lg:w-3/12">
            <div className="mr-10">
              <div className="text-white mb-2 font-bold text-sm">
                Administrators
              </div>

              <div className="p-6 bg-white shadow-md rounded-lg">
                <div>
                  <SearchBar placeholder="Search Admin" name="search-admin" />
                </div>
                <div className="mt-6 space-y-2 max-h-[250px] overflow-y-auto">

                  <PageContent loading={adminsLoading} loadingText="Loading admins" data={admins?.data} emptyText="No admins yet" />

                  {admins.data?.map((admin: any, index: number) =>
                    <div key={index} onClick={() => toggleFormDrawerAdmin(true, admin)} className="py-2 flex items-center rounded-md space-x-2 hover:bg-gray-100 cursor-pointer">
                      <div className="w-9 h-9 text-white text-sm text-center flex-shrink-0 flex items-center justify-center rounded-full bg-vetiva-purple">
                        {nameInitials(admin.userInfo.firstName, admin.userInfo.lastName)}
                      </div>
                      <div className="text-sm">
                        <div>
                          {admin.userInfo.firstName} {admin.userInfo.lastName}
                        </div>
                        <div className="text-gray-400 -mt-0.5">
                          {admin.jobTitle}
                        </div>
                      </div>
                    </div>
                  )}

                </div>
              </div>

              <div onClick={() => toggleFormDrawerProfile(true)} className="px-6 py-4 mt-5 font-bold flex justify-between text-vetiva-brown bg-white hover:bg-gray-100 shadow-md rounded-lg cursor-pointer text-sm">
                <div>
                  View company's profile
                </div>
                <FontAwesomeIcon icon="angle-right" className="my-auto" />
              </div>
            </div>
          </div>
        }

        <div className={"w-full " + (userInfo.userType === "TRUSTEE" ? "lg:w-9/12" : "")}>
          <div>
            <div className="text-white mb-2 font-bold text-sm">
              Employees
            </div>

            <div className="p-4 bg-white shadow-md rounded-lg">
              <div className="w-full flex justify-end">
                <SearchBar placeholder="Search Employees" name="search" />
              </div>

              <div>

                <div className="mt-3 table-container">
                  <table className="table table-auto table-rounded table-border">
                    <thead>
                      <tr>
                        <th>
                          <input type="checkbox" className="form-checkbox multi-select-header" onChange={toggleMultiSelectHeader} />
                        </th>
                        <th>Employee</th>
                        <th>Share/Unit allocation</th>
                        <th>
                          <Menu>
                            <Menu.Button>
                              Status
                              <FontAwesomeIcon icon="angle-down" className="ml-1" />
                            </Menu.Button>
                            <Menu.Items className="dropdown">
                              <Menu.Item>
                                <Link to={urlQuery("filter=")} className="dropdown-item flex">
                                  <input type="checkbox" className="form-checkbox mr-2" defaultChecked={checkFilterStatus("")} />
                                  All
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link to={urlQuery("filter=Active")} className="dropdown-item flex">
                                  <input type="checkbox" className="form-checkbox mr-2" defaultChecked={checkFilterStatus("Active")} />
                                  Active
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link to={urlQuery("filter=Pending")} className="dropdown-item flex">
                                  <input type="checkbox" className="form-checkbox mr-2" defaultChecked={checkFilterStatus("Pending")} />
                                  Pending
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link to={urlQuery("filter=Deactivated")} className="dropdown-item flex">
                                  <input type="checkbox" className="form-checkbox mr-2" defaultChecked={checkFilterStatus("Deactivated")} />
                                  Deactivated
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link to={urlQuery("filter=Exited")} className="dropdown-item flex">
                                  <input type="checkbox" className="form-checkbox mr-2" defaultChecked={checkFilterStatus("Exited")} />
                                  Exited
                                </Link>
                              </Menu.Item>
                            </Menu.Items>
                          </Menu>
                        </th>
                        <th>Last activity</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                      <TableContent loading={employeesLoading} loadingText="Loading employees" data={employees} colspan={6} />

                      {employees.data?.map((employee: any, index: number) =>
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              className="form-checkbox multi-select"
                              onChange={getMultiSelectBody}
                              // checked={selections.includes(employee.id)} 
                              // value={employee.id}
                              checked={selections.filter((selection: any) => JSON.parse(selection).id === employee.id).length === 1}
                              value={JSON.stringify({
                                ...employee,
                                id: employee.id,
                                name: employee.userInfo.firstName + " " + employee.userInfo.lastName,
                                email: employee.userInfo.email,
                              })}
                            />
                          </td>
                          <td onClick={() => navigate(employeeDetailsUrl + "/" + employee.id)} className="cursor-pointer">
                            <div className="flex items-center space-x-2">
                              <div className="w-9 h-9 text-white text-sm text-center flex-shrink-0 flex items-center justify-center rounded-full bg-vetiva-purple">
                                {nameInitials(employee.userInfo.firstName, employee.userInfo.lastName)}
                              </div>
                              <div className="text-sm">
                                <div>
                                  {employee.userInfo.firstName} {employee.userInfo.lastName}
                                </div>
                                <div className="text-gray-400 -mt-0.5">
                                  {/* {employee.jobTitle} */}
                                  {employee.userInfo.email}
                                  <br />
                                  ID: {employee.employeeNumber}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td onClick={() => navigate(employeeDetailsUrl + "/" + employee.id)} className="cursor-pointer">
                            {NumberFormat(employee.numberOfShareUnits, "no-kobo")}
                          </td>
                          <td onClick={() => navigate(employeeDetailsUrl + "/" + employee.id)} className="cursor-pointer">

                            <Maybe condition={employee.isPending === true && employee.isActive === true && employee.isExitedFromOrganization === false}>
                              <div className={"label " + renderStatusColor("pending")}>
                                pending
                              </div>
                            </Maybe>

                            <Maybe condition={employee.isPending === false && employee.isActive === true && employee.isExitedFromOrganization === false}>
                              <div className={"label " + renderStatusColor("active")}>
                                active
                              </div>
                            </Maybe>

                            <Maybe condition={employee.isActive === false}>
                              <Maybe condition={checkFilterStatus("Deactivated") === false && employee.isExitedFromOrganization === false}>
                                <div className={"label " + renderStatusColor("deactivated")}>
                                  deactivated
                                </div>
                              </Maybe>
                              <Maybe condition={checkFilterStatus("Deactivated") === true}>
                                <div className={"label " + renderStatusColor("deactivated")}>
                                  deactivated
                                </div>
                              </Maybe>
                            </Maybe>

                            <Maybe condition={employee.isExitedFromOrganization === true && checkFilterStatus("Deactivated") === false}>
                              <div className={"label " + renderStatusColor("exited")}>
                                exited
                              </div>
                            </Maybe>

                          </td>
                          <td onClick={() => navigate(employeeDetailsUrl + "/" + employee.id)} className="cursor-pointer">
                            {DateMiniFormat(employee.userInfo.lastActivity) || "-"}
                          </td>
                          <td>
                            <Menu>
                              <Menu.Button className="w-7 h-7 rounded-sm flex bg-white">
                                <FontAwesomeIcon icon="ellipsis-v" className="m-auto" />
                              </Menu.Button>
                              <Menu.Items className="dropdown">

                                {userInfo.userType === "TRUSTEE" &&
                                  <Menu.Item>
                                    <Link to={navigationBaseUrl + "/form/" + employee.id} className="dropdown-item flex">
                                      <img src={iconEdit} alt="Edit" className="w-4 h-4 mr-3" />
                                      Edit
                                    </Link>
                                  </Menu.Item>
                                }

                                {/* <Maybe condition={employee.isPending === true}> */}
                                <Menu.Item>
                                  <div onClick={() => resendInvite(employee)} className="dropdown-item flex">
                                    <img src={iconSendDark} alt="Resend Invite" className="w-4 h-4 mr-3" />
                                    Resend Invite
                                  </div>
                                </Menu.Item>
                                {/* </Maybe> */}

                                {/* 
                                  <Menu.Item>
                                    <div className="dropdown-item flex">
                                      <img src={iconSuspend} alt="Suspend" className="w-4 h-4 mr-3" />
                                      Suspend
                                    </div>
                                  </Menu.Item>
                                  */}

                                {userInfo.userType === "TRUSTEE" &&
                                  <>
                                    <Maybe condition={employee.isActive === false}>
                                      <Menu.Item>
                                        <div onClick={() => activateEmployee(employee)} className="dropdown-item flex">
                                          <FontAwesomeIcon icon={["far", "check-circle"]} className="w-4 h-4 mr-3 text-gray-400" />
                                          Activate
                                        </div>
                                      </Menu.Item>
                                    </Maybe>

                                    <Maybe condition={employee.isActive === true}>
                                      <Menu.Item>
                                        <div onClick={() => deactivateEmployee(employee)} className="dropdown-item flex">
                                          <img src={iconDisabled} alt="Disable" className="w-4 h-4 mr-3" />
                                          Deactivate
                                        </div>
                                      </Menu.Item>
                                    </Maybe>
                                  </>
                                }

                                <Maybe condition={employee.isExitedFromOrganization === false}>
                                  <Menu.Item>
                                    <div onClick={() => exitEmployee(employee)} className="dropdown-item flex">
                                      <img src={iconExit} alt="Exit" className="w-4 h-4 mr-3" />
                                      Exit
                                    </div>
                                  </Menu.Item>
                                </Maybe>

                              </Menu.Items>
                            </Menu>
                          </td>
                        </tr>
                      )}

                    </tbody>
                  </table>
                </div>

                <Pagination meta={employees.meta} />

                <SelectionActions
                  selections={selections}
                  clearSelections={clearSelections}
                  removeFromSelections={removeFromSelections}
                  getDashboardStats={getDashboardStats}
                  getEmployees={getEmployees}
                  getAdmins={getAdmins}
                  setIsOpenAdmin={() => setIsOpenAdmin(false)}
                />

              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  );
}





const fileUploadTemplate: any = {
  "Sales Upload": {
    filename: "Sales Upload Template.csv",
    file: process.env.REACT_APP_TEMPLATE_SALES_UPLOAD,
  },
  "Bonus Upload": {
    filename: "Bonus Upload Template.csv",
    file: process.env.REACT_APP_TEMPLATE_BONUS_UPLOAD,
  },
  "Dividend Upload": {
    filename: "Dividend Upload Template.csv",
    file: process.env.REACT_APP_TEMPLATE_DIVIDEND_UPLOAD,
  },
  "Transfer Upload": {
    filename: "Transfer Upload Template.csv",
    file: process.env.REACT_APP_TEMPLATE_TRANSFER_UPLOAD,
  },
}

function CompanyUploads(props: any) {
  const { isOpenUploads, setIsOpenUploads } = props;


  const { companyId } = useParams();
  const docPickerRef: any = useRef();
  const dispatch = useDispatch();
  const { companyUploadsLoading, companyUploads, companyUploadSalesLoading, companyUploadSales, companyUploadBonusLoading, companyUploadBonus, companyUploadDividendLoading, companyUploadDividend, companyUploadTransferLoading, companyUploadTransfer } = useSelector((s: any) => s.entities.company);


  const [isOpenUploadType, setIsOpenUploadType] = useState(false);
  const [FormDrawerUploadTypeTitle, setFormDrawerUploadTypeTitle] = useState('');
  const [FormDrawerUploadTypeDesc, setFormDrawerUploadTypeDesc] = useState('');
  const [FormDrawerUploadParams, setFormDrawerUploadParams] = useState<any>({
    file: "",
    PricePerShare: "",
    WHT: "",
  });


  const toggleFormDrawerUploadType = (type: string, desc: string) => {
    if (type !== "") {
      setIsOpenUploadType(true);
      setFormDrawerUploadTypeTitle(type);
      setFormDrawerUploadTypeDesc(desc);
      docPickerRef.current.cancelFileUpload(0);
      getFileUploads(type);
    }
    else {
      setIsOpenUploadType(false);
    }
  };


  const getFileUploads = (type: string) => {
    const fileUploadParams: any = {
      "Sales Upload": "SALES",
      "Bonus Upload": "BONUS",
      "Dividend Upload": "DIVIDEND",
      "Transfer Upload": "TRANSFER",
    }

    if (type !== "" && fileUploadParams[type] !== undefined) {
      const filterParams = {
        "Filter": fileUploadParams[type],
        "PageNumber": 1,
        "PageSize": 100,
      };
      dispatch(companyAction.getCompanyUploads(companyId, filterParams));
    }
  }


  const getCompanyUploadsLoading = () => {
    if (FormDrawerUploadTypeTitle === "Sales Upload" && companyUploadSalesLoading === true) {
      return true;
    }
    else if (FormDrawerUploadTypeTitle === "Bonus Upload" && companyUploadBonusLoading === true) {
      return true;
    }
    else if (FormDrawerUploadTypeTitle === "Dividend Upload" && companyUploadDividendLoading === true) {
      return true;
    }
    else if (FormDrawerUploadTypeTitle === "Transfer Upload" && companyUploadTransferLoading === true) {
      return true;
    }
    return false;
  }


  const submitFormUploads = async (e: React.FormEvent) => {
    e.preventDefault();

    if (FormDrawerUploadTypeTitle !== "") {
      if (FormDrawerUploadParams.file !== "") {
        const params = { file: FormDrawerUploadParams.file };
        const formDataParams = jsonToFormData(params);

        if (FormDrawerUploadTypeTitle === "Sales Upload") {
          dispatch(companyAction.companyUploadSales(companyId, formDataParams));
        }
        else if (FormDrawerUploadTypeTitle === "Bonus Upload") {
          dispatch(companyAction.companyUploadBonus(companyId, formDataParams));
        }
        else if (FormDrawerUploadTypeTitle === "Dividend Upload") {
          let errors = [];
          if (FormDrawerUploadParams.PricePerShare === "") {
            const error = "Please enter the dividend price per share";
            errors.push(error);
            toast.error(error);
          }
          if (FormDrawerUploadParams.WHT === "") {
            const error = "Please enter the witholding tax";
            errors.push(error);
            toast.error(error);
          }
          if (errors.length === 0) {
            const dividendParams = {
              ...FormDrawerUploadParams,
              DividendType: "",
              OrganizationId: companyId,
            };
            const dividendFormDataParams = jsonToFormData(dividendParams);
            dispatch(companyAction.companyUploadDividend(companyId, dividendFormDataParams));
          }
        }
        else if (FormDrawerUploadTypeTitle === "Transfer Upload") {
          dispatch(companyAction.companyUploadTransfer(companyId, formDataParams));
        }
      }
      else {
        toast.error("Please select a file to upload");
      }
    }
  }


  const emptyCompanyUploadParams = () => {
    const params = {
      file: "",
      PricePerShare: "",
      WHT: "",
    }
    setFormDrawerUploadParams(params);
    docPickerRef.current.cancelFileUpload(0);
    getFileUploads(FormDrawerUploadTypeTitle);
  }


  const processCompanyUploadResponse = (response: any) => {
    if (response.processErrors?.length === 0) {
      toast.success("File uploaded successfully");
      emptyCompanyUploadParams();
    }
    else {
      response.processErrors.map((error: any) => toast.error(error));
      getFileUploads(FormDrawerUploadTypeTitle);
    }
  }


  useEffect(() => {
    if (companyUploadSales.success === true) {
      processCompanyUploadResponse(companyUploadSales);
      dispatch(companyAction.companyUploadSalesReset());
    }
  }, [companyUploadSales]);


  useEffect(() => {
    if (companyUploadBonus.success === true) {
      processCompanyUploadResponse(companyUploadBonus);
      dispatch(companyAction.companyUploadBonusReset());
    }
  }, [companyUploadBonus]);


  useEffect(() => {
    if (companyUploadDividend.success === true) {
      processCompanyUploadResponse(companyUploadDividend);
      dispatch(companyAction.companyUploadDividendReset());
    }
  }, [companyUploadDividend]);


  useEffect(() => {
    if (companyUploadTransfer.success === true) {
      processCompanyUploadResponse(companyUploadTransfer);
      dispatch(companyAction.companyUploadTransferReset());
    }
  }, [companyUploadTransfer]);


  return (
    <>



      <FormDrawer isOpen={isOpenUploads} setIsOpen={setIsOpenUploads}>
        <div className="h-full">
          <div className="fixed h-20 flex items-center w-full top-0 bg-vetiva-black text-white">
            <div className="px-6">
              <div className="text-xl">
                Uploads
              </div>
              <div className="text-gray-400 text-xs">
                Select upload type
              </div>
            </div>
          </div>

          <div className="w-full p-6 absolute top-20 bottom-16 overflow-y-auto">

            <div className="text-sm">
              <div onClick={() => toggleFormDrawerUploadType("Sales Upload", "Upload sales and view history, upload sales and view history")} className="px-5 py-1.5 flex items-center justify-between border-b hover:bg-gray-100 cursor-pointer">
                <div className="w-10 h-10 text-center flex items-center flex-shrink-0">
                  <img src={iconImageUpload} alt="Sales Upload" className="w-4 h-4 mr-3" />
                </div>
                <div className="flex-grow flex justify-between">
                  <span>Sales Upload</span>
                  <FontAwesomeIcon icon="angle-right" />
                </div>
              </div>
              <div onClick={() => toggleFormDrawerUploadType("Bonus Upload", "Upload transfer and view history, upload transfer and view history")} className="px-5 py-1.5 flex items-center justify-between border-b hover:bg-gray-100 cursor-pointer">
                <div className="w-10 h-10 text-center flex items-center flex-shrink-0">
                  <img src={iconImageUpload} alt="Sales Upload" className="w-4 h-4 mr-3" />
                </div>
                <div className="flex-grow flex justify-between">
                  <span>Bonus Upload</span>
                  <FontAwesomeIcon icon="angle-right" />
                </div>
              </div>
              <div onClick={() => toggleFormDrawerUploadType("Dividend Upload", "Upload dividend and view history, upload dividend and view history")} className="px-5 py-1.5 flex items-center justify-between border-b hover:bg-gray-100 cursor-pointer">
                <div className="w-10 h-10 text-center flex items-center flex-shrink-0">
                  <img src={iconImageUpload} alt="Sales Upload" className="w-4 h-4 mr-3" />
                </div>
                <div className="flex-grow flex justify-between">
                  <span>Dividend Upload</span>
                  <FontAwesomeIcon icon="angle-right" />
                </div>
              </div>
              <div onClick={() => toggleFormDrawerUploadType("Transfer Upload", "Upload transfer and view history, upload transfer and view history")} className="px-5 py-1.5 flex items-center justify-between hover:bg-gray-100 cursor-pointer">
                <div className="w-10 h-10 text-center flex items-center flex-shrink-0">
                  <img src={iconImageUpload} alt="Sales Upload" className="w-4 h-4 mr-3" />
                </div>
                <div className="flex-grow flex justify-between">
                  <span>Transfer Upload</span>
                  <FontAwesomeIcon icon="angle-right" />
                </div>
              </div>
            </div>

          </div>

          <div className="w-full fixed bottom-0 p-3 bg-white flex space-x-3 items-center justify-end">
            <button type="button" onClick={() => setIsOpenUploads(false)} className="btn btn-gold">
              Close
            </button>
          </div>
        </div>
      </FormDrawer>




      <FormDrawer isOpen={isOpenUploadType} setIsOpen={setIsOpenUploadType}>
        <form onSubmit={submitFormUploads} className="h-full">
          <div className="fixed h-20 flex items-center w-full top-0 bg-vetiva-black text-white">
            <div className="px-6 flex">
              <div className="w-5 cursor-pointer" onClick={() => toggleFormDrawerUploadType("", "")}>
                <FontAwesomeIcon icon="angle-left" className="mt-2" />
              </div>
              <div>
                <div className="text-xl">
                  {FormDrawerUploadTypeTitle}
                </div>
                <div className="text-gray-400 text-xs">
                  {FormDrawerUploadTypeDesc}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full p-6 absolute top-20 bottom-16 overflow-y-auto">


            {FormDrawerUploadTypeTitle === "Dividend Upload" &&
              <div className="w-full flex space-x-4 mb-6">
                <div className="w-1/2">
                  <Input label="Dividend price per share" value={FormDrawerUploadParams.PricePerShare} onChange={(e: any) => setFormDrawerUploadParams({ ...FormDrawerUploadParams, PricePerShare: e.target.value })} />
                </div>
                <div className="w-1/2">
                  <Input label="Witholding tax" value={FormDrawerUploadParams.WHT} onChange={(e: any) => setFormDrawerUploadParams({ ...FormDrawerUploadParams, WHT: e.target.value })} />
                </div>
              </div>
            }

            <div className="border border-gray-200">
              <DocPicker
                onValueChange={(e: any) => setFormDrawerUploadParams({ ...FormDrawerUploadParams, file: e[0] })}
                ref={docPickerRef}
              />
            </div>

            <div className="mt-3">
              <div className="w-max border-b border-transparent hover:border-vetiva-brown cursor-pointer">
                {/* 
                <DownloadFile filename={fileUploadTemplate[FormDrawerUploadTypeTitle]?.filename} file={fileUploadTemplate[FormDrawerUploadTypeTitle]?.file}>
                  <div className="text-vetiva-brown flex">
                    <img src={iconDownload} alt="download" className="mr-2" />
                    Download file template
                  </div>
                </DownloadFile>
                 */}

                <a href={fileUploadTemplate[FormDrawerUploadTypeTitle]?.file} target="_blank" rel="noreferrer" download>
                  <div className="text-vetiva-brown flex">
                    <img src={iconDownload} alt="download" className="mr-2" />
                    Download file template
                  </div>
                </a>

              </div>
            </div>

            <div className="mt-8 border-t border-gray-300"></div>

            <div className="mt-8 text-sm">
              <div className="font-bold">
                Previous Uploads
              </div>

              <PageContent loading={companyUploadsLoading} loadingText="Loading uploads" data={companyUploads?.data} emptyText="No uploads yet" />

              {companyUploads.data?.map((companyUpload: any, index: number) =>
                <div key={index} className="mt-5">
                  <div className="flex justify-between text-gray-400">
                    <div>
                      {DateMiniFormat(companyUpload.uploadedAt)} • {TimeFormat(companyUpload.uploadedAt)}
                    </div>
                    <div>
                      Uploaded by: {companyUpload.uploadedBy}
                    </div>
                  </div>
                  <div className="mt-2 shadow">
                    <FileUploadPreview
                      name={companyUpload.fileName}
                      size={getFileSize(companyUpload.bytes)}
                      url={companyUpload.url}
                      type={companyUpload.extension?.substring(1)}
                    />
                  </div>
                </div>
              )}
            </div>

          </div>

          <div className="w-full fixed bottom-0 p-3 bg-white flex space-x-3 items-center justify-end">
            <button type="button" onClick={() => toggleFormDrawerUploadType("", "")} className="btn btn-gold">
              Cancel
            </button>
            <ButtonLoader type="submit" loading={getCompanyUploadsLoading()} className="btn btn-brown">
              Upload
            </ButtonLoader>
          </div>
        </form>
      </FormDrawer>



    </>
  )
}





export function AdminActivityHistory(props: any) {
  const { isOpenAdmin, setIsOpenAdmin, adminDetails, activateEmployee, deactivateEmployee } = props;


  const navigate = useNavigate();
  const location = useLocation();
  const { urlQuery } = useUrlNavigation();
  const [searchParams] = useSearchParams();


  const dispatch = useDispatch();
  const { userActivityHistoryLoading, userActivityHistory } = useSelector((s: any) => s.entities.userMgmt);


  const [historyParams, setHistoryParams] = useState<any>({
    "PageNumber": 1,
    "PageSize": 20,
    "historyStartDate": searchParams.get("historyStartDate") || "",
    "historyEndDate": searchParams.get("historyEndDate") || "",
  })

  const [filterForm, setFilterForm] = useState<any>({
    "historyStartDate": searchParams.get("historyStartDate") || "",
    "historyEndDate": searchParams.get("historyEndDate") || "",
  });


  const getUserActivityHistory = () => {
    let params = { ...historyParams };
    delete params["historyStartDate"];
    delete params["historyEndDate"];

    const page = searchParams.get("historypage");
    if (page !== null) {
      params = { ...params, PageNumber: parseInt(page) }
    }
    else {
      params = { ...params, PageNumber: 1 }
    }

    const limit = searchParams.get("historylimit");
    if (limit !== null) {
      params = { ...params, PageSize: parseInt(limit) }
    }
    else {
      params = { ...params, PageSize: 20 }
    }

    const StartDate = searchParams.get("historyStartDate");
    if (StartDate !== null) {
      params = { ...params, StartDate: dateFormatSubmit(StartDate) }
    }

    const EndDate = searchParams.get("historyEndDate");
    if (EndDate !== null) {
      params = { ...params, EndDate: dateFormatSubmit(EndDate) }
    }

    setHistoryParams(params);
    dispatch(userMgmtAction.getUserActivityHistory(adminDetails.id, params));
  }


  const submitFilterForm = async (e: React.FormEvent) => {
    e.preventDefault();

    let url = "";
    for (let i in filterForm) {
      if (url !== "") {
        url += "&";
      }
      url += i + "=" + filterForm[i];
    }

    navigate(urlQuery(url));
  }


  const clearFilterForm = () => {
    let url = "";
    const newFilterForm = {
      ...filterForm,
      "historyStartDate": "",
      "historyEndDate": "",
    };

    for (let i in newFilterForm) {
      if (url !== "") {
        url += "&";
      }
      url += i + "=" + newFilterForm[i];
    }

    setFilterForm(newFilterForm);
    navigate(urlQuery(url));
  }


  useEffect(() => {
    if (isOpenAdmin === true) {
      getUserActivityHistory();
    }
  }, [isOpenAdmin])


  useEffect(() => {
    if (adminDetails.id !== undefined) {
      getUserActivityHistory();
    }
  }, [location]);


  return (
    <FormDrawer size="2xl" isOpen={isOpenAdmin} setIsOpen={setIsOpenAdmin}>
      <div className="h-full">
        <div className="w-full relative">

          <div className="w-full flex h-full relative">
            <div className="w-52 fixed top-0 bottom-0 left-0 flex-shrink-0 p-6 bg-[#F9F6F1] bg-vetiva-pattern-2">

              <div className="w-28 h-28 mt-6 text-white text-4xl font-bold bg-vetiva-brown rounded-full mx-auto uppercase flex items-center justify-center">
                {nameInitials(adminDetails.userInfo?.firstName, adminDetails.userInfo?.lastName)}
              </div>

              <div>
                <div className="mt-10 space-y-3">
                  <div>
                    <div className="font-semibold">
                      Name
                    </div>
                    <div className="text-gray-500">
                      {adminDetails.userInfo?.firstName} {adminDetails.userInfo?.lastName}
                    </div>
                  </div>
                  <div>
                    <div className="font-semibold">
                      Role
                    </div>
                    <div className="text-gray-500">
                      {adminDetails.jobTitle}
                    </div>
                  </div>
                </div>

                <div className="mt-10 text-gray-400 font-bold">
                  Contact
                </div>

                <div className="mt-3 space-y-3">
                  <div>
                    <div className="font-semibold">
                      Email
                    </div>
                    <div className="text-vetiva-purple">
                      {adminDetails.userInfo?.email}
                    </div>
                  </div>
                  <div>
                    <div className="font-semibold">
                      Phone number
                    </div>
                    <div className="text-vetiva-purple">
                      {adminDetails.userInfo?.phoneNumber}
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full absolute bottom-0 right-0 p-6">
                <Maybe condition={adminDetails.isActive === true}>
                  <button type="button" onClick={() => deactivateEmployee(adminDetails, "admin")} className="btn bg-white btn-block text-red-500">
                    Deactivate this user
                  </button>
                </Maybe>
                <Maybe condition={adminDetails.isActive === false}>
                  <button type="button" onClick={() => activateEmployee(adminDetails, "admin")} className="btn bg-white btn-block text-red-500">
                    Activate this user
                  </button>
                </Maybe>
              </div>

            </div>

            <div className="w-full ml-52 relative">

              <form onSubmit={submitFilterForm} className="h-24 fixed z-[51] top-0 left-0 right-0 ml-52 p-4 bg-white border-b">
                <div className="flex justify-between">
                  <div>
                    <Input type="date" label="Start date" value={filterForm.historyStartDate} onChange={(e: any) => setFilterForm({ ...filterForm, historyStartDate: e.target.value })} />
                  </div>
                  <div>
                    <Input type="date" label="End date" value={filterForm.historyEndDate} onChange={(e: any) => setFilterForm({ ...filterForm, historyEndDate: e.target.value })} />
                  </div>
                </div>
                <div className="mt-2 flex justify-end space-x-6 text-xs">
                  <div onClick={clearFilterForm} className="hover:underline cursor-pointer">
                    Clear filter
                  </div>
                  <div onClick={submitFilterForm} className="hover:underline cursor-pointer">
                    Apply filter
                  </div>
                </div>
              </form>

              <div className="w-[19rem] p-4 relative top-24">


                <PageContent loading={userActivityHistoryLoading} loadingText="Loading history" data={userActivityHistory?.data} emptyText={<div className="normal-case">No activity yet.<br />Activities done by this user will show here</div>} />

                {userActivityHistory.data?.map((history: any, index: number) =>
                  <div key={index} className="flex space-x-4">
                    <div>
                      <div className="w-2 h-2 bg-vetiva-brown rounded-full mx-auto"></div>
                      <div className="w-px h-full bg-gray-300 mx-auto"></div>
                    </div>
                    <div className="pb-6">
                      <div className="-mt-1 text-xs text-gray-500">
                        {DateFormat(history.createdAt)}
                        {/* 11:34PM */}
                      </div>
                      <div className="mt-1 text-gray-700">
                        {history.details}
                        {/* User added a new employee <span className="font-bold">Adebola Aliu Chioma</span> */}
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-4">
                  <Pagination meta={userActivityHistory.meta} prefix="history" showLimit={false} />
                </div>


              </div>
            </div>
          </div>

        </div>
      </div>
    </FormDrawer>
  )
}





function SelectionActions(props: any) {
  const { userInfo } = useLoginInfo();


  const dispatch = useDispatch();
  const { employeeActivateLoading, employeeActivateSuccess, employeeActivateResponse,
    employeeDeactivateLoading, employeeDeactivateSuccess, employeeDeactivateResponse } = useSelector((s: any) => s.entities.employee);
  const userPlural = pluralizeFull(props.selections.length, "user", "users");


  const csvDownloadStatusRef = useRef(false);
  const csvDownloadLinkRef = useRef<any>(null);
  const [isOpenEmail, setIsOpenEmail] = useState(false);
  const [selectionJson, setSelectionJson] = useState<any>([]);


  const fileDownloadColumns = [
    { label: "Employee ID", key: "employeeNumber" },
    { label: "Full Name", key: "name" },
    { label: "Email Address", key: "userInfo.email" },
    { label: "Phone Number", key: "userInfo.phoneNumber" },

    { label: "Number Of Share Units", key: "numberOfShareUnits" },
    { label: "Job Title", key: "jobTitle" },
    { label: "Grade Category", key: "gradeCategory" },
    { label: "Date of Hire", key: "dateOfHire" },
    { label: "Personal Email Address", key: "personalEmail" },
    { label: "Official Email Address", key: "officialEmail" },

    { label: "Status", key: "status" },
    { label: "isPending", key: "isPending" },
    { label: "isActive", key: "isActive" },
    { label: "isExited", key: "isExitedFromOrganization" },

    { label: "Bank Name", key: "bankName" },
    { label: "Account Name", key: "accountName" },
    { label: "Account Number", key: "accountNumber" },

    { label: "Beneficiary First Name", key: "alternateBenefiaryFirstName" },
    { label: "Beneficiary Last Name", key: "alternateBenefiaryLasName" },
    { label: "Beneficiary Email Address", key: "alternateBenefiaryEmail" },
    { label: "Beneficiary Phone Number", key: "alternateBenefiaryPhoneNumber" },

    { label: "Date Added", key: "userInfo.createdAt" },
    { label: "Can Receive Instruction Mail", key: "userInfo.receiveInstructionMail" },
  ]


  const downloadEmployees = async () => {
    const result = await DialogBox({
      content: <>{props.selections.length} {userPlural} selected. Are you sure you want to download the list of selected users?</>,
      buttonYes: "Yes, download list",
    });
    if (result) {
      toast("Downloading");
      const newSelectionJson = props.selections.map((selection: any) => JSON.parse(selection));
      setSelectionJson(newSelectionJson);
      csvDownloadStatusRef.current = true;
    }
  }


  const bulkActivate = async () => {
    const result = await DialogBox({
      content: "You are about to activate " + props.selections.length + " " + userPlural + ". This action cannot be reversed.",
      buttonYes: "Yes, activate " + userPlural,
    });
    if (result) {
      const params = {
        employeeIds: props.selections.map((selection: any) => JSON.parse(selection).id),
        statusAction: "Activate"
      }
      dispatch(employeeAction.activateBulkEmployees(params));
    }
  }


  const bulkDeactivate = async () => {
    const result = await DialogBox({
      theme: "red",
      content: "You are about to deactivate " + props.selections.length + " " + userPlural + ". This action cannot be reversed.",
      buttonYes: "Yes, deactivate " + userPlural,
    });
    if (result) {
      const params = {
        employeeIds: props.selections.map((selection: any) => JSON.parse(selection).id),
        statusAction: "Deactivate"
      }
      dispatch(employeeAction.deactivateBulkEmployees(params));
    }
  }


  const processResponse = (response: any) => {
    props.getDashboardStats();
    props.getEmployees();
    props.getAdmins();
    props.setIsOpenAdmin(false);
    if (response.processErrors?.length > 0) {
      response.processErrors.map((error: any) => toast.error(error.message + " : (" + error.id + ")"));
    }
  }


  useEffect(() => {
    if (employeeActivateSuccess === true) {
      processResponse(employeeActivateResponse)
      dispatch(employeeAction.activateBulkEmployeesReset());
    }
  }, [employeeActivateSuccess]);


  useEffect(() => {
    if (employeeDeactivateSuccess === true) {
      processResponse(employeeDeactivateResponse)
      dispatch(employeeAction.deactivateBulkEmployeesReset());
    }
  }, [employeeDeactivateSuccess]);


  useEffect(() => {
    if (csvDownloadStatusRef.current === true) {
      csvDownloadLinkRef?.current.link.click();
      toast.success("Downloaded");
      csvDownloadStatusRef.current = false;
    }
  }, [selectionJson])


  return (
    <>
      <SendEmail
        isOpenEmail={isOpenEmail}
        setIsOpenEmail={setIsOpenEmail}
        selections={props.selections}
        removeFromSelections={props.removeFromSelections}
      />

      <Maybe condition={props.selections.length > 0}>
        <div className="box flex space-x-36 justify-between fixed -bottom-1 left-1/2 shadow-2xl -translate-x-1/2 rounded-b-none">
          <div className="my-auto flex space-x-4">
            <div onClick={props.clearSelections} className="w-8 h-8 my-auto flex flex-shrink-0 rounded hover:bg-red-100 cursor-pointer">
              <FontAwesomeIcon icon="times" className="m-auto text-xl" />
            </div>
            <div className="flex-shrink-0">
              <div>
                Bulk Action
              </div>
              <div>
                {props.selections.length} {pluralizeFull(props.selections.length, "item", "items")} selected
              </div>
            </div>
          </div>
          <div className="my-auto flex space-x-4">

            <CSVLink filename="Employees list.csv" data={selectionJson} headers={fileDownloadColumns} ref={csvDownloadLinkRef} />
            <button type="button" onClick={downloadEmployees} className="btn btn-vetiva-brown">
              Download
            </button>

            <Maybe condition={userInfo.userType === "TRUSTEE"}>
              <ButtonLoader type="button" loading={employeeActivateLoading} onClick={bulkActivate} className="btn btn-brown">
                Activate
              </ButtonLoader>
              <ButtonLoader type="button" loading={employeeDeactivateLoading} onClick={bulkDeactivate} className="btn btn-brown">
                Deativate
              </ButtonLoader>
              <button type="button" onClick={() => setIsOpenEmail(true)} className="btn btn-vetiva-brown">
                Email blast
              </button>
            </Maybe>

          </div>
        </div>
      </Maybe>
    </>
  )
}





function SendEmail(props: any) {

  const dispatch = useDispatch();
  const { sendEmailBroadcastLoading, sendEmailBroadcast } = useSelector((s: any) => s.entities.employee);
  const editorRef = useRef<any>(null);


  const [emailForm, setEmailForm] = useState({
    subject: "",
    message: "",
  })


  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    const newEmailForm = {
      ...emailForm,
      message: editorRef.current.getContent(),
    }

    const errors: any = validate(newEmailForm);
    if (errors) {
      for (var x in errors) {
        toast.error(errors[x]);
      };
      return;
    }
    else {
      if (props.selections.length > 0) {
        const params = {
          EmployeeIds: props.selections.map((selection: any) => JSON.parse(selection).id),
          Subject: emailForm.subject,
          Body: newEmailForm.message,
        }
        // console.log("params", params);
        const formDataParams = jsonToFormData(params);
        dispatch(employeeAction.sendEmailBroadcast(formDataParams));
      }
      else {
        toast.error("Please select at least one employee");
      }
    }
  }


  useEffect(() => {
    if (sendEmailBroadcast.success === true) {
      if (sendEmailBroadcast.processErrors?.length === 0) {
        toast.success("Email sent successfully");
        setEmailForm({
          subject: "",
          message: "",
        });
        props.setIsOpenEmail(false);
      }
      else {
        sendEmailBroadcast.processErrors?.map((error: string) => toast.error(error));
      }
      dispatch(employeeAction.sendEmailBroadcastReset());
    }
  }, [sendEmailBroadcast]);


  return (
    <FormDrawer display="center" isOpen={props.isOpenEmail} setIsOpen={props.setIsOpenEmail}>
      <div className="">
        <div className="h-16 px-4 flex items-center justify-between w-full bg-vetiva-gold">
          <div className="text-lg font-bold">Email Blast</div>
          <div onClick={() => props.setIsOpenEmail(false)} className="px-2 py-1 rounded hover:bg-red-300 cursor-pointer">
            <FontAwesomeIcon icon="times" className="mr-1" />
            Close
          </div>
        </div>

        <div className="p-4">
          <form onSubmit={submitForm}>

            <div className="flex space-x-3">
              <div>
                To:
              </div>
              <div className="w-full max-h-[100px] border p-2 pb-0 flex flex-wrap overflow-y-scroll">
                {props.selections.map((selection: any, index: number) =>
                  <div key={index} className="label label-default-case w-max h-max mr-2 mb-2 border border-gray-400 rounded-full relative pr-8">
                    {JSON.parse(selection).name}
                    <FontAwesomeIcon icon="times" className="w-4 h-4 absolute top-1.5 right-2 hover:bg-red-300 cursor-pointer" onClick={() => props.removeFromSelections(selection)} />
                  </div>
                )}
              </div>
            </div>

            <div className="mt-4">
              <Input type="text" label="Subject" value={emailForm.subject} onChange={(e: any) => setEmailForm({ ...emailForm, subject: e.target.value })} />
            </div>

            <div className="mt-4">
              {/* 
              <textarea className="w-full h-64 form-input resize-none" placeholder="Message" value={emailForm.message} onChange={(e:any) => setEmailForm({...emailForm, message: e.target.value})}></textarea>
              */}

              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue=""
                init={{
                  height: 256,
                  menubar: false,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                  ],
                  toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
            </div>

            <div className="mt-4 flex space-x-3 justify-end">
              <button type="button" onClick={() => props.setIsOpenEmail(false)} className="btn btn-gold">
                Close
              </button>
              <ButtonLoader type="submit" loading={sendEmailBroadcastLoading} className="btn btn-brown">
                Send email
              </ButtonLoader>
            </div>

          </form>

        </div>
      </div>
    </FormDrawer>
  )
}





export default CompanyEmployeesList;
