
export const ObjectToQueryString = (params) => {

    // console.log("params", params);
    let paramsToQs = "?";

    if (params){
        if (Object.keys(params).length > 0) {
            Object.keys(params).forEach((param) => {
                if (params[param] !== "") {
                    paramsToQs += param + "=" + params[param] + "&";
                }
            });
            paramsToQs = paramsToQs.substring(0, paramsToQs.length - 1);
            // console.log("paramsToQs", paramsToQs);

            return paramsToQs;
        }
    }
    
    return "";

};

export default ObjectToQueryString;