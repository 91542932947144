import Maybe from "components/Maybe";

export interface FormDrawerProps {
    children: any;
    isOpen: boolean;
    size?: string;
    display?: string;
    setIsOpen: any;
    closeDialog?: any;

}
export function FormDrawer(props: FormDrawerProps) {
  const { children, size, display, isOpen, setIsOpen, closeDialog } = props;
  let sizeRender = size || "lg";  
  

  let displayClassBackdrop = "";
  let displayClassModal = "";
  let displayClassModalContent = "";
  if (display && display === "center"){
    displayClassBackdrop = "fixed overflow-hidden z-30 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " + (isOpen ? "transition-opacity opacity-100 duration-500 translate-y-0": "transition-all delay-500 opacity-0 translate-y-full");
    displayClassModal = "w-screen max-w-[800px] absolute top-1/2 right-1/2 bg-white h-max shadow-xl duration-500 ease-in-out transition-all transform translate-x-1/2  " + (isOpen ? "-translate-y-1/2 " : "translate-y-full");
    displayClassModalContent = "relative w-screen max-w-[800px] flex flex-col space-y-6 overflow-y-scroll h-full";
  }
  else{
    displayClassBackdrop = "fixed overflow-hidden z-30 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " + (isOpen ? "transition-opacity opacity-100 duration-500 translate-x-0": "transition-all delay-500 opacity-0 translate-x-full");
    displayClassModal = "w-screen max-w-lg right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  " + (isOpen ? "translate-x-0 " : "translate-x-full");
    displayClassModalContent = "relative w-screen max-w-"+(sizeRender)+" flex flex-col space-y-6 overflow-y-scroll h-full";
  }
  
  
  return (
    <div className={displayClassBackdrop}>
      <div className={displayClassModal}>
        <div className={displayClassModalContent}>
          {children}
        </div>
      </div>

      <Maybe condition={closeDialog !== undefined}>
        <div
          className=" w-screen h-full cursor-pointer "
          onClick={() => {
            closeDialog();
          }}
        ></div>
      </Maybe>

      <Maybe condition={closeDialog === undefined}>
        <div
          className=" w-screen h-full cursor-pointer "
          onClick={() => {
            setIsOpen(false);
          }}
        ></div>
      </Maybe>

    </div>
  );
}

export default FormDrawer;