const Footer = () => {

    return (
        <div>
        
        </div>
    )
}

export default Footer
