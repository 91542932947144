import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FileUploadPreview, PreviewAccordion } from "components/ui";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import UserInfoCard from "components/user-info-card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageContent } from "components/PageContent";
import Maybe from "components/Maybe";
import { pluralize, pluralizeFull } from "utils/pluralize";
import CurrencyFormat from "utils/currencyFormat";
import { DateMiniFormat } from "utils/dateFormat";
import NumberFormat from "utils/numberFormat";
import * as companyAction from "store/entities/company/action";

export interface CompanyCreatePreviewProps {}

export function CompanyCreatePreview(props: CompanyCreatePreviewProps) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { companyLoading, company } = useSelector((s: any) => s.entities.company);


  // const [ schemeTypes, setSchemeTypes ] = useState<any>([]);
  

  const setSubSchemeAplhaNumberTypes = (data:any) => {
    let nameType:string = "";
    if (data.length > 0){
      const nameTypeValue = parseInt(data[0].name.split(" ")[1]);
      if (isNaN(nameTypeValue)){
        nameType = "Alphabets";
      }
      else{
        nameType = "Numbers";
      }
    }
    return nameType;
  }
  

  const plural = (data: string) => {
    if (data === "Batch") return "Batches";
    else if (data === "Group") return "Groups";
    else if (data === "Category") return "Categories";
    else return "";
  }


  useEffect(() => {
    // let allSchemeTypes:any = [];
    // if (company.data?.organizationShareSetting?.schemeTypes){
    //   company.data?.organizationShareSetting?.schemeTypes.map((schemeType:any) => {
          // sort by schemePropertyType
    //   }
    // }
  }, [company]);


  useEffect(() => {
    dispatch(companyAction.getCompany(id));
  }, []);


  return (


    <div>

      <FormWizardNavBar showSteps={true} currentStep={4} totalSteps={5} closeUrl="/trustee/company" />

      <div className="container pb-10 mt-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Preview
          </div>
          <p className="mt-2 text-fade text-sm">
            Kindly preview details before submitting
          </p>
        </div>

        <div className="mt-12">
          

          <PageContent loading={companyLoading} data={company.data || undefined} />

          <Maybe condition={companyLoading === false && company.data && Object.keys(company.data).length > 0}>
            <div>
              <div>

                <UserInfoCard
                  image={company?.data?.logo} imageSize="14"
                  name={company.data?.name} company="Limited Liability Company" idNumber={company.data?.rcNumber} employeesCount={`${company.data?.size} ${pluralize("employee", company.data?.size)}`} />


                <div className="mt-8 border-b border-gray-300 mb-6">
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                      <h2 className="text-fade text-sm font-bold">
                        ADDRESS
                      </h2>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                      <div className="space-y-1">
                        <label className="text-fade font-normal">
                          Address 1
                        </label>
                        <p className="font-bold">
                          {company.data?.address1}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <div className="space-y-1">
                        <label className="text-fade font-normal">
                          City
                        </label>
                        <p className="font-bold">
                          {company.data?.city}
                        </p>
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <div className="space-y-1">
                        <label className="text-fade font-normal">
                          Postal/ZIP code
                        </label>
                        <p className="font-bold">
                          {company.data?.postalCode}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <div className="space-y-1">
                        <label className="text-fade font-normal">
                          State
                        </label>
                        <p className="font-bold">
                          {company.data?.state}
                        </p>
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <div className="space-y-1">
                        <label className="text-fade font-normal">
                          Country
                        </label>
                        <p className="font-bold">
                          {company.data?.country}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-b border-gray-300 mb-6">
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                      <h2 className="text-fade text-sm font-bold">
                        ADMIN DETAILS
                      </h2>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <div className="space-y-1">
                        <label className="text-fade font-normal">
                          Name
                        </label>
                        <p className="font-bold">
                          {company.data?.admin?.userInfo?.firstName} {company.data?.admin?.userInfo?.lastName}
                        </p>
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <div className="space-y-1">
                        <label className="text-fade font-normal">
                          Job Title
                        </label>
                        <p className="font-bold">
                          {company.data?.admin?.jobTitle}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <div className="space-y-1">
                        <label className="text-fade font-normal">
                          Phone
                        </label>
                        <p className="font-bold">
                          {company.data?.admin?.userInfo?.phoneNumber}
                        </p>
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <div className="space-y-1">
                        <label className="text-fade font-normal">
                          Email address
                        </label>
                        <p className="font-bold text-blue-500">
                          {company.data?.admin?.userInfo?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-b border-gray-300 mb-6">
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                      <h2 className="text-fade text-sm font-bold">
                        COMPANY SETTINGS
                      </h2>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3 mb-4">
                      <div className="flex justify-between">
                        <label className="text-fade font-normal">
                          Allocation Type
                        </label>
                        <p>
                          {company.data?.organizationShareSetting?.sharesAllocation}
                        </p>
                      </div>
                    </div>
                    <div className="w-full px-3">
                      
                      {company.data?.organizationShareSetting?.schemeTypes.map((schemeType:any, index:number) => 

                        <div key={index} className="bg-gray-100 p-4 mb-8">
                          <div id="header" className="flex justify-between border-b border-gray-300 mb-4 pb-3">
                            <label className="text-fade font-normal">
                              Scheme Type
                            </label>
                            <p className="text-vetiva-brown font-bold">
                              {schemeType.name !== "Access to Others" ? schemeType.name : schemeType.schemeDetail.otherSchemeName}
                            </p>
                          </div>

                          <div className="border-b border-gray-300 mb-4">
                            {schemeType.schemePropertyType === 1 &&
                              <div className="flex justify-between mb-4">
                                <label className="text-fade font-normal">
                                  Strike Price
                                </label>
                                <p>
                                  {CurrencyFormat(schemeType.schemeDetail.strikePrice)}
                                </p>
                              </div>
                            }
                            {schemeType.schemePropertyType === 2 &&
                              <div className="flex justify-between mb-4">
                                <label className="text-fade font-normal">
                                  Purchase Price
                                </label>
                                <p>
                                  {CurrencyFormat(schemeType.schemeDetail.purchasePrice)}
                                </p>
                              </div>
                            }
                            {(schemeType.schemePropertyType === 1 || schemeType.schemePropertyType === 2) &&
                              <div className="flex justify-between mb-4">
                                <label className="text-fade font-normal">
                                  Valuation
                                </label>
                                <p>
                                  {CurrencyFormat(schemeType.schemeDetail.valuation)}
                                </p>
                              </div>
                            }
                            {schemeType.schemePropertyType === 1 &&
                              <div className="flex justify-between mb-4">
                                <label className="text-fade font-normal">
                                  Exercise Period
                                </label>
                                <p>
                                  {DateMiniFormat(schemeType.schemeDetail.exercisePeriodStartDate)} - {DateMiniFormat(schemeType.schemeDetail.exercisePeriodEndDate)}
                                </p>
                              </div>
                            }
                            {schemeType.schemePropertyType === 1 &&
                              <div className="flex justify-between mb-4">
                                <label className="text-fade font-normal">
                                  Exercise Date
                                </label>
                                <p>
                                  {DateMiniFormat(schemeType.schemeDetail.expirationDate)}
                                </p>
                              </div>
                            }
                            <div className="flex justify-between mb-4">
                              <label className="text-fade font-normal">
                                Holding Period
                              </label>
                              <p>
                                {DateMiniFormat(schemeType.startDate)} - {DateMiniFormat(schemeType.endDate)}
                              </p>
                            </div>
                            <div className="flex justify-between mb-4">
                              <label className="text-fade font-normal">
                                Access to subscheme
                              </label>
                              <p>
                                {schemeType.hasSubSchemeAccess === true ? "Yes" : "No"}
                              </p>
                            </div>

                            {schemeType.hasSubSchemeAccess === false &&
                              <>
                                <div className="flex justify-between mb-4">
                                  <label className="text-fade font-normal">
                                    Number of Allocation
                                  </label>
                                  <p>
                                    {NumberFormat(schemeType.schemeDetail.numberOfAllocation, "no-kobo")}
                                  </p>
                                </div>
                                <div className="flex justify-between mb-4">
                                  <label className="text-fade font-normal">
                                    Date of Allocation
                                  </label>
                                  <p>
                                    {DateMiniFormat(schemeType.schemeDetail.dateOfAllocation)}
                                  </p>
                                </div>
                                <div className="flex justify-between mb-4">
                                  <label className="text-fade font-normal">
                                    Percentage for vesting
                                  </label>
                                  <p>
                                    {schemeType.schemeDetail.percentageVesting}%
                                  </p>
                                </div>
                                <div className="flex justify-between mb-4">
                                  <label className="text-fade font-normal">
                                    Vesting period
                                  </label>
                                  <p>
                                    {schemeType.schemeDetail.vestingPeriod}
                                  </p>
                                </div>
                                <Maybe condition={schemeType.schemeDetail.vestingPeriod === "Fixed period"}>
                                  <div className="flex justify-between mb-4">
                                    <label className="text-fade font-normal">
                                      Vesting date
                                    </label>
                                    <p>
                                      Every {schemeType.schemeDetail.period} {pluralizeFull(schemeType.schemeDetail.period, "year", "years")}
                                    </p>
                                  </div>
                                </Maybe>
                                <Maybe condition={schemeType.schemeDetail.vestingPeriod === "Recurring date"}>
                                  <div className="flex justify-between mb-4">
                                    <label className="text-fade font-normal">
                                      Vesting date
                                    </label>
                                    <p>
                                      {DateMiniFormat(schemeType.schemeDetail.vestingDate)}
                                    </p>
                                  </div>
                                </Maybe>
                              </>
                            }
                          </div>

                          <div className="mb-4">

                            <Maybe condition={schemeType.hasSubSchemeAccess === true}>
                              {schemeType.subSchemes.length > 0 &&
                                <>
                                  <Maybe condition={schemeType.subSchemes[0].batches.length > 0}>
                                    <>
                                      {(() => {
                                        let subSchemeBatch:any = [];
                                        let subSchemeGroup:any = [];
                                        let subSchemeCategory:any = [];
                                        
                                        schemeType.subSchemes[0].batches.map((data:any) => {
                                          if (data.name.includes("Batch")){
                                            subSchemeBatch.push(data);
                                          }
                                          else if (data.name.includes("Group")){
                                            subSchemeGroup.push(data);
                                          }
                                          else if (data.name.includes("Category")){
                                            subSchemeCategory.push(data);
                                          }
                                          return null;
                                        })

                                        let subSchemeIndex:any = ["Batch","Group","Category"];
                                        let subSchemeAll:any = [subSchemeBatch, subSchemeGroup, subSchemeCategory];

                                        let render = subSchemeAll.map((data:any, index2:number) =>
                                          <Maybe key={index2} condition={data.length > 0}>
                                            <div className="flex justify-between mb-4">
                                              <PreviewAccordion
                                                allocType={subSchemeIndex[index2]}
                                                countType={setSubSchemeAplhaNumberTypes(data)}
                                                stats={data.length + " " + pluralizeFull(data.length, subSchemeIndex[index2], plural(subSchemeIndex[index2])).toLowerCase()}
                                              >
                                                <div className="py-2 flex flex-col space-y-2">

                                                  {data.map((dataBatch:any, index3:number) =>
                                                    <div key={index3} className="bg-white p-3">
                                                      <div>
                                                        {dataBatch.name}
                                                      </div>
                                                      <Maybe condition={dataBatch.allocations.length > 0}>
                                                        {dataBatch.allocations.map((dataAllocation:any, index4:number) =>
                                                          <div key={index4} className="mt-4 pt-4 border-t border-gray-300">
                                                            <div className="flex flex-wrap -mx-3 mb-6">
                                                              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                                                <div className="space-y-1">
                                                                  <label className="text-fade font-normal">
                                                                    Number of allocation
                                                                  </label>
                                                                  <p className="font-normal">
                                                                    {NumberFormat(dataAllocation.numberOfAllocation, "no-kobo")}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                              <div className="w-full md:w-1/3 px-3">
                                                                <div className="space-y-1">
                                                                  <label className="text-fade font-normal">
                                                                    Date of allocation
                                                                  </label>
                                                                  <p className="font-normal">
                                                                    {DateMiniFormat(dataAllocation.dateOfAllocation)}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                              <div className="w-full md:w-1/3 px-3">
                                                                <div className="space-y-1">
                                                                  <label className="text-fade font-normal">
                                                                    Percentage for vesting
                                                                  </label>
                                                                  <p className="font-normal">
                                                                    {dataAllocation.percentageVesting}%
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="flex flex-wrap -mx-3 mb-6">
                                                              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                                                <div className="space-y-1">
                                                                  <label className="text-fade font-normal">
                                                                    Vesting period
                                                                  </label>
                                                                  <p className="font-normal">
                                                                    {dataAllocation.vestingPeriod}
                                                                  </p>
                                                                </div>
                                                              </div>
                                                              <Maybe condition={dataAllocation.vestingPeriod === "Fixed period"}>
                                                                <div className="w-full md:w-1/3 px-3">
                                                                  <div className="space-y-1">
                                                                    <label className="text-fade font-normal">
                                                                      Vesting date
                                                                    </label>
                                                                    <p className="font-normal">
                                                                      Every {dataAllocation.period} {pluralizeFull(dataAllocation.period, "year", "years")}
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </Maybe>
                                                              <Maybe condition={dataAllocation.vestingPeriod === "Recurring date"}>
                                                                <div className="w-full md:w-1/3 px-3">
                                                                  <div className="space-y-1">
                                                                    <label className="text-fade font-normal">
                                                                      Vesting date
                                                                    </label>
                                                                    <p className="font-normal">
                                                                      {DateMiniFormat(dataAllocation.vestingDate)}
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </Maybe>
                                                            </div>
                                                            <div className="mb-2">
                                                              <FileUploadPreview
                                                                className="bg-gray-100"
                                                                file={dataAllocation.schemeRuleObj ? dataAllocation.schemeRuleObj[0] || undefined : undefined}
                                                              />
                                                            </div>
                                                          </div>
                                                        )}
                                                      </Maybe>
                                                      <Maybe condition={dataBatch.allocations.length <= 0}>
                                                        <span className="italic">No allocations</span>
                                                      </Maybe>
                                                    </div>
                                                  )}

                                                </div>
                                              </PreviewAccordion>
                                            </div>
                                          </Maybe>
                                        )

                                        return (render);

                                      })()}
                                    </>
                                  </Maybe>

                                  <Maybe condition={schemeType.subSchemes[0].batches.length <= 0}>
                                    <div className="page-info-italic">
                                      No batches, groups & categories
                                    </div>
                                  </Maybe>

                                  <div className="border-t border-gray-300 mt-4">
                                    <div className="flex flex-col justify-between mt-4">
                                      <label className="text-fade font-normal">
                                        Trust Deed
                                      </label>
                                      <div className="mt-2">
                                        <FileUploadPreview
                                          className="bg-white"
                                          file={schemeType.subSchemes[0].trustDeedObject ? schemeType.subSchemes[0].trustDeedObject[0] || undefined : undefined}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              }

                              <Maybe condition={schemeType.subSchemes.length <= 0}>
                                <div className="page-info-italic mb-4">
                                  No subschemes
                                </div>
                              </Maybe>
                            </Maybe>

                          </div>

                        </div>

                      )}

                    </div>


                    <div className="w-full px-3 mb-4 mt-8">
                      <div className="flex justify-between">
                        <label className="text-fade font-normal">
                          Employees Access To Share Sale Or Transfer
                        </label>
                        <p className="font-bold">
                          
                          <Maybe condition={company.data?.organizationShareSetting?.canEmployeeInitiateShareSale === true}>
                            <FontAwesomeIcon icon="check" className="mr-2" />
                            Access granted
                          </Maybe>
                          
                          <Maybe condition={company.data?.organizationShareSetting?.canEmployeeInitiateShareSale === false}>
                            <FontAwesomeIcon icon="times" className="mr-2" />
                            Access not granted
                          </Maybe>
                          
                        </p>
                      </div>
                    </div>

                    <div className="w-full px-3 mb-4">
                      <div className="flex justify-between">
                        <label className="text-fade font-normal">
                          Dividend Type
                        </label>
                        <p className="font-bold">
                          {company.data?.organizationShareSetting?.dividendTypes.join(", ")}
                        </p>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="mb-10">
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <h2 className="text-fade text-sm font-bold">
                        Supporting Documents
                      </h2>
                    </div>
                  </div>

                  <div>
                    {company.data?.organizationShareSetting?.supportingDocumentObject !== null &&
                      <div className="mb-3">
                        <FileUploadPreview
                            className="bg-gray-100"
                            file={company.data?.organizationShareSetting?.supportingDocumentObject[0] || undefined}
                            allowDismiss={false}
                        />
                      </div>
                    }

                    {company.data?.organizationShareSetting?.supportingDocumentObject === null &&
                      <div className="page-info-italic py-12 bg-gray-100">
                        No supporting documents
                      </div>
                    }
                  </div>
                </div>
                

                <div className="flex justify-end space-x-3 mt-6 mb-6">
                  <Link to={"/trustee/company/form/"+id+"/company-settings"} className="btn btn-gold">
                    Back
                  </Link>
                  <Link to={"/trustee/company/form/"+id+"/success"} className="btn btn-brown">
                    Continue
                  </Link>
                </div>


              </div>
            </div>
          </Maybe>

        </div>
      </div>

    </div>
  );
}

export default CompanyCreatePreview;
