import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CompanySearchSelect } from "components/company-search-select";
import { TimeDateBlock } from "components/time-date-block";
import { PieChart } from "components/ui/charts/pie-chart";
import iconBuilding from "assets/images/icons/building-alt.svg";
import iconShares from "assets/images/icons/shares-gray.svg";
import iconGroup from "assets/images/icons/group.svg";
import Maybe from "components/Maybe";
import NumberFormat from "utils/numberFormat";
import { PageContent } from "components/PageContent";
import { OverviewDashboard } from "components/OverviewDashboard";
import * as overviewAction from "store/entities/overview/action";
import * as companyAction from "store/entities/company/action";


const Index = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();    
    const { dashboardStatsLoading, dashboardStats, shareDistributionLoading, shareDistribution, schemeMetricsAwardsLoading, schemeMetricsAwards, schemeMetricsOptionsLoading, schemeMetricsOptions, schemeMetricsPurchaseLoading, schemeMetricsPurchase, schemeMetricsLtipLoading, schemeMetricsLtip, schemeMetricsOthersLoading, schemeMetricsOthers } = useSelector((s: any) => s.entities.overview);
    const { companyLoading, company } = useSelector((s: any) => s.entities.company);


    const [companyId, setCompanyId] = useState(searchParams.get("company") || "");


    const getDashboardStats = () => {
        dispatch(overviewAction.getDashboardStats());
    }
    

    const getCompanyShareDistribution = (id:string) => {
        dispatch(overviewAction.getCompanyShareDistribution(id));
    }


    const getCompanyDetails = (id:string) => {
        dispatch(companyAction.getCompany(id));
    }


    const getCompanySchemeTypeInfo = () => {
        dispatch(overviewAction.resetAllSchemeMetrics());

        const id = searchParams.get("company");
        if (id !== "" && id !== null){
                
            const schemeTypes = company.data?.organizationShareSetting?.schemeTypes;
            schemeTypes && schemeTypes.map((schemeType:any) => {
                if (schemeType.name === "Access to Share Awards"){
                    dispatch(overviewAction.getSchemeMetricAwards(id));
                }
                else if (schemeType.name === "Access to Share Options"){
                    dispatch(overviewAction.getSchemeMetricOptions(id));
                }
                else if (schemeType.name === "Access to Share Purchase"){
                    dispatch(overviewAction.getSchemeMetricPurchase(id));
                }
                else if (schemeType.name === "Access to Long Term Incentice Plan (LTIP)"){
                    dispatch(overviewAction.getSchemeMetricLtip(id));
                }
                else if (schemeType.name === "Access to Others"){
                    dispatch(overviewAction.getSchemeMetricOthers(id));
                }
                return null;
            })

        }
    }


    useEffect(() => {
        getDashboardStats();
    }, []);


    useEffect(() => {
        const company = searchParams.get("company");
        if (company !== "" && company !== null){
            setCompanyId(company);
            getCompanyShareDistribution(company);
            getCompanyDetails(company);
        }
        else{
            setCompanyId("");
        }
    }, [location]);


    useEffect(() => {
        if (company.success && company.success === true && company.data !== undefined){
            getCompanySchemeTypeInfo();
        }
    }, [company]);


    return (
        <div className="pb-10">

            <div className="bg-vetiva-black">
                <div className="container pt-16 pb-24 flex flex-wrap">
                    <div className="w-full xl:w-3/12">
                        <div className="h-full flex flex-col justify-between">
                            <div className="overflow-hidden">
                                <TimeDateBlock />
                            </div>

                            <div>
                                <CompanySearchSelect />
                            </div>
                        </div>
                    </div>
                    <div className="w-full xl:w-9/12">
                        <div className="ml-10">

                            <div className="flex space-x-4">

                                <div className="h-36 p-6 bg-white flex-grow flex items-center rounded-lg">
                                    <div>
                                        <div className="h-12 text-left">
                                            <img src={iconBuilding} alt="Companies" className="object-contain w-12" />
                                        </div>
                                        <div className="mt-1 text-sm text-gray-500">
                                            Total Companies
                                        </div>
                                        <div className="text-2xl">
                                            <Maybe condition={dashboardStatsLoading}>
                                                <FontAwesomeIcon icon="spinner" spin />
                                            </Maybe>
                                            <Maybe condition={!dashboardStatsLoading}>
                                                {NumberFormat(dashboardStats.data?.totalCompanies, "no-kobo") || "---"}
                                            </Maybe>
                                        </div>
                                    </div>
                                </div>

                                <div className="h-36 p-6 bg-white flex-grow flex items-center rounded-lg">
                                    <div>
                                        <div className="h-12 text-left">
                                            <img src={iconGroup} alt="Group" className="object-contain w-12" />
                                        </div>
                                        <div className="mt-1 text-sm text-gray-500">
                                            Total Scheme Members
                                        </div>
                                        <div className="text-2xl">
                                            <Maybe condition={dashboardStatsLoading}>
                                                <FontAwesomeIcon icon="spinner" spin />
                                            </Maybe>
                                            <Maybe condition={!dashboardStatsLoading}>
                                                {NumberFormat(dashboardStats.data?.totalSchemeMembers, "no-kobo") || "---"}
                                            </Maybe>
                                        </div>
                                    </div>
                                </div>

                                <div className="h-36 p-6 bg-white flex-grow flex items-center rounded-lg">
                                    <div>
                                        <div className="h-12 text-left">
                                            <img src={iconShares} alt="Scheme" className="object-contain w-12" />
                                        </div>
                                        <div className="mt-1 text-sm text-gray-500">
                                            Total Scheme Types
                                        </div>
                                        <div className="text-2xl">
                                            <Maybe condition={dashboardStatsLoading}>
                                                <FontAwesomeIcon icon="spinner" spin />
                                            </Maybe>
                                            <Maybe condition={!dashboardStatsLoading}>
                                                {NumberFormat(dashboardStats.data?.totalSchemeTypes, "no-kobo") || "---"}
                                            </Maybe>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>



            <Maybe condition={companyId === ""}>
                <div className="container">
                    <div className="table-info">
                        <div className="flex justify-center">
                            <img src={iconBuilding} alt="Companies" className="object-contain w-24" />
                        </div>
                        <div className="mt-2 font-bold uppercase text-lg">
                            Please select a company
                        </div>
                    </div>
                </div>
            </Maybe>

            <Maybe condition={companyId !== ""}>
                <div className="container flex -mt-16">
                    <div className="w-full md:w-3/12">
                        <div className="text-white mb-2 font-bold text-sm">
                            Share distribution
                        </div>

                        <div className="p-6 bg-white shadow-md rounded-lg">

                            <PageContent loading={shareDistributionLoading} data={shareDistribution.data || undefined} emptyText="Info not found" errorText="Info not found" />
                            
                            <Maybe condition={!shareDistributionLoading}>
                                <Maybe condition={shareDistribution.data !== undefined}>
                                    <div className="w-48 h-48 mx-auto">
                                        <PieChart data={[
                                            Math.round(shareDistribution.data?.shareAwardPercentage) || 0,
                                            Math.round(shareDistribution.data?.shareOptionPercentage) || 0,
                                            Math.round(shareDistribution.data?.sharePurchasePercentage) || 0,
                                            Math.round(shareDistribution.data?.longTermIncentivePercentage) || 0,
                                            Math.round(shareDistribution.data?.othersPercentage) || 0,
                                        ]} />
                                    </div>
                                </Maybe>

                                <div className="mt-6 space-y-6 text-gray-600">
                                    <div className="flex space-x-2 justify-between">
                                        <div>
                                            Share award
                                        </div>
                                        <div className="font-bold text-right">
                                            <Maybe condition={shareDistribution.data?.totalShareAwardAllocated !== undefined}>
                                                {NumberFormat(shareDistribution.data?.totalShareAwardAllocated, "no-kobo")} ({Math.round(shareDistribution.data?.shareAwardPercentage)}%)
                                            </Maybe>
                                            <Maybe condition={shareDistribution.data?.totalShareAwardAllocated === undefined}>
                                                ---
                                            </Maybe>
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 justify-between">
                                        <div>
                                            Share option
                                        </div>
                                        <div className="font-bold text-right">
                                            <Maybe condition={shareDistribution.data?.totalShareOptionAllocated !== undefined}>
                                                {NumberFormat(shareDistribution.data?.totalShareOptionAllocated, "no-kobo")} ({Math.round(shareDistribution.data?.shareOptionPercentage)}%)
                                            </Maybe>
                                            <Maybe condition={shareDistribution.data?.totalShareOptionAllocated === undefined}>
                                                ---
                                            </Maybe>
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 justify-between">
                                        <div>
                                            Share purchase
                                        </div>
                                        <div className="font-bold text-right">
                                            <Maybe condition={shareDistribution.data?.totalSharePurchaseAllocated !== undefined}>
                                                {NumberFormat(shareDistribution.data?.totalSharePurchaseAllocated, "no-kobo")} ({Math.round(shareDistribution.data?.sharePurchasePercentage)}%)
                                            </Maybe>
                                            <Maybe condition={shareDistribution.data?.totalSharePurchaseAllocated === undefined}>
                                                ---
                                            </Maybe>
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 justify-between">
                                        <div>
                                            Long term incentive
                                        </div>
                                        <div className="font-bold text-right">
                                            <Maybe condition={shareDistribution.data?.totalLongTermIncentiveAllocated !== undefined}>
                                                {NumberFormat(shareDistribution.data?.totalLongTermIncentiveAllocated, "no-kobo")} ({Math.round(shareDistribution.data?.longTermIncentivePercentage)}%)
                                            </Maybe>
                                            <Maybe condition={shareDistribution.data?.totalLongTermIncentiveAllocated === undefined}>
                                                ---
                                            </Maybe>
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 justify-between">
                                        <div>
                                            Others
                                        </div>
                                        <div className="font-bold text-right">
                                            <Maybe condition={shareDistribution.data?.totalOthersAllocated !== undefined}>
                                                {NumberFormat(shareDistribution.data?.totalOthersAllocated, "no-kobo")} ({Math.round(shareDistribution.data?.othersPercentage)}%)
                                            </Maybe>
                                            <Maybe condition={shareDistribution.data?.totalOthersAllocated === undefined}>
                                                ---
                                            </Maybe>
                                        </div>
                                    </div>
                                </div>
                            </Maybe>

                        </div>
                    </div>

                    <div className="w-full md:w-9/12">
                    <div className="ml-10">
                        <div className="text-white mb-2 font-bold text-sm">
                            Share distribution
                        </div>

                        <div className="p-6 bg-white shadow-md rounded-lg">
                            
                            <PageContent loading={companyLoading} data={company.data || undefined} loadingText="Loading company information..." emptyText="Info not found" errorText="Info not found" />

                            <Maybe condition={!companyLoading}>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    
                                    
                                    <OverviewDashboard name="Share Award" loading={schemeMetricsAwardsLoading} data={schemeMetricsAwards} />
                                    <OverviewDashboard name="Share Option" loading={schemeMetricsOptionsLoading} data={schemeMetricsOptions} />
                                    <OverviewDashboard name="Share Purchase" loading={schemeMetricsPurchaseLoading} data={schemeMetricsPurchase} />
                                    <OverviewDashboard name="Long-term Incentive" loading={schemeMetricsLtipLoading} data={schemeMetricsLtip} />
                                    <OverviewDashboard name="Others" loading={schemeMetricsOthersLoading} data={schemeMetricsOthers} />


                                </div>
                            </Maybe>

                        </div>

                    </div>
                    </div>
                </div>
            </Maybe>



        </div>
    )
}

export default Index