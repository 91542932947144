import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DocPicker } from "components/ui";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import { toast } from "react-toastify";
import { jsonToFormData } from "utils/jsonToFormData";
import ButtonLoader from "components/ButtonLoader";
import useLoginInfo from "hooks/useLoginInfo";
import * as employeeAction from "store/entities/employee/action";


export interface CompanyEmployeesCreateBulkUploadProps {}

const fileUploadTemplate:any = {
  "Employee Bulk Upload": {
    filename: "Employee Bulk Upload Template.csv",
    file: process.env.REACT_APP_TEMPLATE_EMPLOYEE_UPLOAD,
  },
}

export function CompanyEmployeesCreateBulkUpload(props: CompanyEmployeesCreateBulkUploadProps) {
  let { companyId } = useParams();
  let navigationBaseUrl = "/trustee/company/"+companyId;
  const {userInfo} = useLoginInfo();
  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    navigationBaseUrl = "/company/employees"
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uploadEmployeesLoading, uploadEmployees } = useSelector((s: any) => s.entities.employee);


  const [companyForm, setCompanyForm] = useState<any>({
    File: "",
  })


  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (companyForm.File === "" || companyForm.File === undefined){
      toast.error("Please select a file to upload");
    }
    else{
      const params = {
        ...companyForm,
        OrganizationId: companyId,
      }
      
      const formDataParams = jsonToFormData(params);
      dispatch(employeeAction.uploadEmployees(formDataParams));
    }
  }


  useEffect(() => {
    dispatch(employeeAction.uploadEmployeesReset());
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (uploadEmployees.success && uploadEmployees.success === true){
      navigate(navigationBaseUrl+"/create/bulk/success");
    }
    // eslint-disable-next-line
  }, [uploadEmployees]);


  return (
    <div>

      <FormWizardNavBar showSteps={true} currentStep={1} totalSteps={2} closeUrl={navigationBaseUrl} />

      <div className="container py-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Upload document
          </div>
          <p className="mt-2 text-fade text-sm">
            Upload csv file for bulk upload of employees
          </p>
        </div>

        <div className="mt-12">
          
          <form onSubmit={submitForm}>

            <div>
              <a href={fileUploadTemplate["Employee Bulk Upload"]?.file} target="_blank" rel="noreferrer" download className="text-vetiva-brown text-sm inline-block hover:underline">
                Download sample document
              </a>
              <div className="mt-5 bg-gray-50 shadow rounded">
                <DocPicker
                  accept="text/csv, .csv"
                  onValueChange={(e:any) => setCompanyForm({...companyForm, File: e[0]})}
                />
              </div>
            </div>
          
            <div className="mt-10 flex justify-end space-x-3">
              <Link to={navigationBaseUrl + "/create"} className="btn btn-gold">
                Back
              </Link>
              <ButtonLoader type="submit" loading={uploadEmployeesLoading} className="btn btn-brown">
                Continue
              </ButtonLoader>
            </div>

          </form>


        </div>
      </div>

    </div>
  );
}

export default CompanyEmployeesCreateBulkUpload;
