import { combineReducers } from "redux";
import selectionsReducer from "./selections/reducer";
import countriesReducer from "./countries/reducer";
import banksReducer from "./banks/reducer";
import stockBrockingFirmReducer from "./stockBrockingFirm/reducer";

export default combineReducers({
    selections: selectionsReducer,
    countries: countriesReducer,
    banks: banksReducer,
    stockBrockingFirm: stockBrockingFirmReducer,
});