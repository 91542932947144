import { useLocation } from "react-router-dom";
import queryString from 'query-string';


const useUrlNavigation = () => {
    const location = useLocation();


    const qsToUrl = (query: string) => {
        const qsUrl:any = queryString.parse(location.search);
        const qsString:any = queryString.parse("?" + query);

        Object.keys(qsString).map((key) => 
            delete qsUrl[key]
        )
        
        let qsToUrl = new URLSearchParams(qsUrl).toString();
        qsToUrl = "?" + ((qsToUrl !== "")? qsToUrl + "&" : "");

        return qsToUrl;
    }


    /**
     * usage
     * urlQuery("key1=value1&key2=value2")
     * */
    const urlQuery = (query: string) => {
        return (qsToUrl(query) + query);
    }


    return {qsToUrl, urlQuery};
}


export default useUrlNavigation;
