import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { AllocationTypes, } from "data/constants/company-settings";
import { ButtonGroupSelector, ButtonGroupCheckbox, Input, FileUploadPreview } from "components/ui";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import { getCustomSchemeName, removeSchemeTypeNamePrefix, removeNull } from "utils/misc";
import { toast } from "react-toastify";
import { PageContent } from "components/PageContent";
import Maybe from "components/Maybe";
import ButtonLoader from "components/ButtonLoader";
import NumberFormat from "utils/numberFormat";
import { DateMiniFormat } from "utils/dateFormat";
import { pluralizeFull } from "utils/pluralize";
import { dateFormatRemoveISO, dateFormatSubmit } from "utils/dataTypes";
import useLoginInfo from "hooks/useLoginInfo";
import * as companyAction from "store/entities/company/action";
import * as employeeAction from "store/entities/employee/action";


const Option = (props:any) => {
  return (
      <div className="p-0">
          <components.Option {...props}>
              <div className="-mx-2 -my-1.5 p-4 text-sm flex justify-between border rounded-sm">
                  <div className="w-10 flex-shrink-0">
                    <input
                      type="checkbox"
                      checked={props.isSelected}
                      onChange={() => null}
                      className="w-5 h-5 form-checkbox rounded-full"
                    />
                  </div>
                  <div className="flex-grow text-left font-semibold">
                    {props.label}
                  </div>
              </div>
          </components.Option>
      </div>
  );
};


const ReactSelectStyles = {
  option: (provided:any, state:any) => ({
      ...provided,
      color: "#333",
      background: "#fff",
      cursor: "pointer",
      "&:hover": {
          background: "#eee",
      }
    }),
};


export interface CompanyEmployeesCreateSingleSchemeProps {}

export function CompanyEmployeesCreateSingleScheme(props: CompanyEmployeesCreateSingleSchemeProps) {
  let { companyId, id } = useParams();
  let navigationBaseUrl = "/trustee/company/"+companyId;
  const {userInfo} = useLoginInfo();
  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    navigationBaseUrl = "/company/employees"
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyLoading, company } = useSelector((s: any) => s.entities.company);
  const { employeeLoading, employee, employeeSchemeLoading, employeeScheme, createEmployeeLoading3, createEmployee3 } = useSelector((s: any) => s.entities.employee);
  

  const [schemeTypes, setSchemeTypes] = useState([]);

  /*
  const [SAMPLE_schemeTypes] = useState([
    {
      id: "26537b2e-76b8-4e89-935b-dceca58ba39b",
      name: "Access to Share Awards",
      selected: true,
      subSchemes: [
        {
          id: "0b49c4e4-c41b-41e9-bfdf-03f3ae7839d1",
          trustDeedObject: null,
          batches: [
            {
              id: "GAf1d75527-feb6-4604-8b66-cf49c6d9ccd7",
              name: "Group A",
              selected: false,
              allocations: [
                {
                  id: "3c1418e8-36ea-44bc-a885-7e8b3d7d38a4",
                  numberOfAllocation: 1,
                  dateOfAllocation: "2022-11-30T23:00:00Z",
                  employeeNumberOfAllocation: 10,
                  employeeDateOfAllocation: "2022-11-10T20:00:00Z",
                  percentageVesting: 1,
                  vestingPeriod: "Fixed period",
                  period: 2,
                  vestingDate: null,
                  schemeRuleObj: null,
                }
              ]
            },
            {
              id: "GBf1d75527-feb6-4604-8b66-cf49c6d9ccd7",
              name: "Group B",
              selected: true,
              allocations: [
                {
                  id: "A13c1418e8-36ea-44bc-a885-7e8b3d7d38a4",
                  numberOfAllocation: 1,
                  dateOfAllocation: "2022-11-20T23:00:00Z",
                  employeeNumberOfAllocation: 11,
                  employeeDateOfAllocation: "2022-11-11T20:00:00Z",
                  percentageVesting: 1,
                  vestingPeriod: "Fixed period",
                  period: 1,
                  vestingDate: null,
                  schemeRuleObj: null,
                },
                {
                  id: "A23c1418e8-36ea-44bc-a885-7e8b3d7d38a4",
                  numberOfAllocation: 2,
                  dateOfAllocation: "2022-11-30T23:00:00Z",
                  employeeNumberOfAllocation: 12,
                  employeeDateOfAllocation: "2022-11-12T20:00:00Z",
                  percentageVesting: 2,
                  vestingPeriod: "Recurring date",
                  period: 2,
                  vestingDate: "2022-11-30T23:00:00Z",
                  schemeRuleObj: null,
                },
              ]
            },
            {
              id: "C1f1d75527-feb6-4604-8b66-cf49c6d9ccd7",
              name: "Category 1",
              selected: true,
              allocations: [
                {
                  id: "3c1418e8-36ea-44bc-a885-7e8b3d7d38a4",
                  numberOfAllocation: 1,
                  dateOfAllocation: "2022-11-30T23:00:00Z",
                  employeeNumberOfAllocation: 13,
                  employeeDateOfAllocation: "2022-11-13T20:00:00Z",
                  percentageVesting: 1,
                  vestingPeriod: "Fixed period",
                  period: 1,
                  vestingDate: null,
                  schemeRuleObj: null,
                }
              ]
            },
          ]
        }
      ],
    },
    {
      id: "8ff45b00-2dd7-448c-bbc0-7e5b1b8cafcc",
      name: "Access to Long Term Incentice Plan (LTIP)",
      selected: true,
      subSchemes: [
        {
          id: "0b49c4e4-c41b-41e9-bfdf-03f3ae7839d1",
          trustDeedObject: null,
          batches: [
            {
              id: "B1f1d75527-feb6-4604-8b66-cf49c6d9ccd7",
              name: "Batch 1",
              selected: true,
              allocations: [
                {
                  id: "3c1418e8-36ea-44bc-a885-7e8b3d7d38a4",
                  numberOfAllocation: 1,
                  dateOfAllocation: "2022-11-30T23:00:00Z",
                  employeeNumberOfAllocation: 14,
                  employeeDateOfAllocation: "2022-11-14T20:00:00Z",
                  percentageVesting: 1,
                  vestingPeriod: "Fixed period",
                  period: 1,
                  vestingDate: null,
                  schemeRuleObj: null,
                }
              ]
            },
            {
              id: "G1f1d75527-feb6-4604-8b66-cf49c6d9ccd7",
              name: "Group 1",
              selected: false,
              allocations: [
                {
                  id: "3c1418e8-36ea-44bc-a885-7e8b3d7d38a4",
                  numberOfAllocation: 1,
                  dateOfAllocation: "2022-11-30T23:00:00Z",
                  employeeNumberOfAllocation: 15,
                  employeeDateOfAllocation: "2022-11-15T20:00:00Z",
                  percentageVesting: 1,
                  vestingPeriod: "Fixed period",
                  period: 1,
                  vestingDate: null,
                  schemeRuleObj: null,
                }
              ]
            },
          ]
        }
      ],
    },
    {
      id: "8ff413323-2dd7-448c-bbc0-7e5b1b8cafcc",
      name: "Access to Share Purchase",
      selected: false,
      subSchemes: [],
    },
  ]);
  */
  
  /*
  const [SAMPLE_employeeForm] = useState({
    schemeTypeIds: [
      "26537b2e-76b8-4e89-935b-dceca58ba39b"
    ],
    employeeSubSchemes: [
      {
        id,
        orgSubSchemeId: "cba6d666-74ca-4661-8ec9-3302099ee232",
        schemeTypeId: "26537b2e-76b8-4e89-935b-dceca58ba39b",
        batches: [
          {
            id,
            employeeOrgBatchId: "c398e6f3-b9df-4644-805c-ee27a569a0c9",
            name: "Batch A",
            allocations: [
              {
                id,
                employeeOrgAlloctionId: "87181062-a73f-4bc2-a522-2a94fb942a67",
                numberOfAllocation: 5,
                dateOfAllocation: "2022-12-5T09:09:28.624Z",
              },
              {
                id,
                employeeOrgAlloctionId: "87181062-a73f-4bc2-a522-2a94fb942a67",
                numberOfAllocation: 10,
                dateOfAllocation: "2022-12-10T09:09:28.624Z",
              },
            ]
          },
          {
            id,
            employeeOrgBatchId: "c398e6f3-b9df-4644-805c-ee27a569a0c9",
            name: "Batch B",
            allocations: [
              {
                id,
                employeeOrgAlloctionId: "87181062-a73f-4bc2-a522-2a94fb942a67",
                numberOfAllocation: 15,
                dateOfAllocation: "2022-12-15T09:09:28.624Z",
              },
            ]
          },
          {
            id,
            employeeOrgBatchId: "c398e6f3-b9df-4644-805c-ee27a569a0c9",
            name: "Group 1",
            allocations: [
              {
                id,
                employeeOrgAlloctionId: "87181062-a73f-4bc2-a522-2a94fb942a67",
                numberOfAllocation: 20,
                dateOfAllocation: "2022-12-20T09:09:28.624Z",
              },
            ]
          },
        ]
      }
    ]
  });
  */


  const getAllocationType = () => {
    const companySharesAllocation = company.data?.organizationShareSetting?.sharesAllocation;
    const allocationResponse = AllocationTypes.map(allocation => {
      let newAllocationType:any = {};
      if (companySharesAllocation === allocation.value){
        newAllocationType = {...allocation, selected: true}
      }
      else{
        newAllocationType = {...allocation, selected: false}
      }
      if (Object.keys(newAllocationType).length === 0){
        newAllocationType = {
          label: companySharesAllocation,
          value: companySharesAllocation,
          selected: true,
        }
      }
      return newAllocationType;
    })
    return allocationResponse;
  }
  

  const updateSchemeTypeSelection = (selectedSchemeTypes:any) => {
    const newSchemeType:any = schemeTypes.map((schemeType:any) => {
      if (selectedSchemeTypes.includes(schemeType.id)){
        return {
          ...schemeType,
          selected: true,
        }
      }
      else{
        return {
          ...schemeType,
          selected: false,
        }
      }
    })
    setSchemeTypes(newSchemeType);
  }


  const getBatches = (batches:any) => {
    if (batches !== undefined){
      const batchesSelection = batches.map((batch:any) => {
        return {
          label: batch.name,
          value: batch.id,
        }
      })
      return batchesSelection;
    }
    return [];
  }


  const checkBatchSelection = (selection:any, batchId:string) => {
    let response = false;
    selection.map((each:any) => {
      if (each.value === batchId){
        response = true;
      }
    });
    return response;
  }

  
  const updateBatchSelection = (selection:any, schemeTypeObj:any) => {
    const newSchemeType:any = schemeTypes.map((schemeType:any) => {
      if (schemeType.id === schemeTypeObj.id){
        const newSubSchemeBatches = schemeType.subSchemes[0]?.batches.map((batch:any) => {
          if (checkBatchSelection(selection,batch.id) === true){
            return {...batch, selected: true};
          }
          else{
            return {...batch, selected: false};
          }
        })
        const newSubScheme = [{
          id: schemeType.subSchemes[0].id,
          trustDeedObject: schemeType.subSchemes[0].trustDeedObject,
          batches: newSubSchemeBatches,
        }]

        return {...schemeType, subSchemes: newSubScheme};
      }
      else{
        return schemeType;
      }
    })
    setSchemeTypes(newSchemeType);
  }


  const updateSchemeTypeValues = (key:string, value:any, schemeTypeObj:any, batchObj:any = "", allocationObj:any = "") => {
    const newSchemeType:any = schemeTypes.map((schemeType:any) => {
      if (schemeType.id === schemeTypeObj.id){

        if (batchObj === "" && allocationObj === ""){
          return {
            ...schemeType,
            schemeDetail: {
              ...schemeType.schemeDetail,
              [key]: value,
            }
          }
        }

        if (batchObj !== "" && allocationObj !== ""){
          const newSubSchemeBatches = schemeType.subSchemes[0]?.batches.map((batch:any) => {
            if (batch.id === batchObj.id){
              const newAllocations = batch.allocations.map((allocation:any) => {
                if (allocation.id === allocationObj.id){
                  return {
                    ...allocation,
                    [key]: value,
                  }
                }
                else{
                  return allocation;
                }
              })
              return {
                id: batch.id,
                name: batch.name,
                selected: batch.selected,
                allocations: newAllocations
              };
            }
            else{
              return batch;
            }
          })
          const newSubScheme = [{
            id: schemeType.subSchemes[0].id,
            trustDeedObject: schemeType.subSchemes[0].trustDeedObject,
            batches: newSubSchemeBatches,
          }]
          return {...schemeType, subSchemes: newSubScheme};
        }
      }
      else{
        return schemeType;
      }
    })
    setSchemeTypes(newSchemeType);
  }


  const getAllocationErrorIndicator = (allocationCount:any, allocationIndex:number) => {
    if (allocationCount === 1){
      return "";
    }
    else{
      return ", Allocation " + (allocationIndex + 1);
    }
  }
  

  const getEmployeeForm = () => {
    let schemeTypeIds:any = [];
    let employeeSubSchemes:any = [];
    let employeeNoSubSchemes:any = [];
    let errors:any = [];

    schemeTypes.filter((schemeType:any) => schemeType.selected === true).map((schemeType:any) => {
      schemeTypeIds.push(schemeType.id)

      if (schemeType.hasSubSchemeAccess === true){
        const employeeSubScheme = {
          id: getEmployeeSchemeData("schemeType", "value", schemeType),
          orgSubSchemeId: schemeType.subSchemes[0]?.id || "",
          schemeTypeId: schemeType.id,
          batches: schemeType.subSchemes[0]?.batches.filter((batch:any) => batch.selected === true).map((batch:any) => {
            return {
              id: getEmployeeSchemeData("batches", "value", schemeType, batch),
              employeeOrgBatchId: batch.id,
              name: batch.name,
              allocations: batch.allocations.map((allocation:any, allocationIndex:number) => {

                if (allocation.employeeNumberOfAllocation === ""){
                  const error = removeSchemeTypeNamePrefix(schemeType.name) + " (" + batch.name + "): Please enter the number of allocation";
                  toast.error(error);
                  errors.push(error);
                }

                const errorIndicator = removeSchemeTypeNamePrefix(schemeType.name) + " (" + batch.name + (getAllocationErrorIndicator(batch.allocations.length, allocationIndex)) + "): ";
                // if (allocation.employeeNumberOfAllocation > allocation.numberOfAllocation){
                if (allocation.employeeNumberOfAllocation > allocation.currentAllocationValue){
                  const error = errorIndicator + "Number of allocation cannot be greater than the total available allocations";
                  toast.error(error);
                  errors.push(error);
                }

                if (allocation.employeeDateOfAllocation === ""){
                  const error = errorIndicator + "Please enter the employee's date of allocation";
                  toast.error(error);
                  errors.push(error);
                }

                if (dateFormatSubmit(allocation.employeeDateOfAllocation) < dateFormatSubmit(allocation.dateOfAllocation)){
                  const error = errorIndicator + "Employee's date of allocation cannot be less than the company's date of allocation";
                  toast.error(error);
                  errors.push(error);
                }

                return {
                  id: getEmployeeSchemeData("allocation", "object", schemeType, batch, allocation)?.id || null,
                  employeeOrgAlloctionId: allocation.id,
                  numberOfAllocation: parseFloat(allocation.employeeNumberOfAllocation),
                  dateOfAllocation: dateFormatSubmit(allocation.employeeDateOfAllocation),
                }

              }),
            } 
          }) || [],
        }
        employeeSubSchemes.push(employeeSubScheme)
      }

      if (schemeType.hasSubSchemeAccess === false){
        const employeeNoSubScheme = {
          schemeTypeId: schemeType.id,
          numberOfAllocation: parseFloat(schemeType.schemeDetail?.employeeNumberOfAllocation) || "",
          dateOfAllocation: dateFormatSubmit(schemeType.schemeDetail?.employeeDateOfAllocation) || "",
        }

        const errorIndicator = removeSchemeTypeNamePrefix(schemeType.name) + ": ";
        if (employeeNoSubScheme.dateOfAllocation !== "" && employeeNoSubScheme.dateOfAllocation < dateFormatSubmit(schemeType.schemeDetail?.dateOfAllocation)){
          const error = errorIndicator + "Employee's date of allocation cannot be less than the company's date of allocation";
          toast.error(error);
          errors.push(error);
        }

        employeeNoSubSchemes.push(employeeNoSubScheme);
      }

      return null;
    })

    return {
      schemeTypeIds,
      employeeSubSchemes,
      employeeNoSubSchemes,
      errors,
    }
  }

  
  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    let params:any= {};
    
    const {schemeTypeIds, employeeSubSchemes, employeeNoSubSchemes, errors} = getEmployeeForm();
    const employeeSubSchemesNoNull = removeNull(employeeSubSchemes);
    params = {
      schemeTypeIds,
      employeeSubSchemes: employeeSubSchemesNoNull,
      noSubschemeSchemeTypeDetailsRequests: employeeNoSubSchemes,
    };
    // console.log("params", params);
    
    if (params.schemeTypeIds.length === 0){
      toast.error("Please select one or more scheme types")
    }
    else if (errors.length === 0){
      // console.log("all good");
      dispatch(employeeAction.createStep3(id, params));
    }
  }

  
  useEffect(() => {
    if (createEmployee3.success && createEmployee3.success === true){
      if (createEmployee3.processErrors?.length === 0){
        navigate(navigationBaseUrl+"/form/"+id+"/beneficiary");
      }
      else{
        createEmployee3.processErrors?.map((error:string) => toast.error(error));
      }
      dispatch(employeeAction.createStep3Reset());
    }
  }, [createEmployee3]);


  const getEmployeeSchemeData = (type:string, response:string, schemeDb:any, batchDb:any="", allocationDb:any="") => {
    let elseResponse: any = false;
    if (response === "value"){
      elseResponse = null;
    }

    if (type === "schemeType"){
      if (response === "status"){
        const schemeTypeData = employeeScheme.data?.schemeTypes?.filter((data:any) => data.id === schemeDb.id);
        if (schemeTypeData && schemeTypeData.length === 1){
          return true;
        }
        else{
          return elseResponse;
        }
      }
      else if (response === "value"){
        const schemeTypeData = employeeScheme.data?.employeeSubSchemes?.filter((data:any) => data.schemeTypeId === schemeDb.id);
        if (schemeTypeData && schemeTypeData.length === 1){
          return schemeTypeData[0]?.id;
        }
        else{
          return elseResponse;
        }
      }
    }

    else if (type === "batches"){
      const schemeTypeData = employeeScheme.data?.employeeSubSchemes.filter((data:any) => data.schemeTypeId === schemeDb.id);      
      if (schemeTypeData && schemeTypeData.length === 1){
        const batchesData = schemeTypeData[0].batches.filter((batch:any) => batch.employeeOrgBatchId === batchDb.id);
        if (batchesData && batchesData.length === 1){
          if (response === "status"){
            return true;
          }
          else if (response === "value"){
            return batchesData[0]?.id;
          }
        }
        else{
          return elseResponse;
        }
      }
      else{
        return elseResponse;
      }
    }

    else if (type === "allocation"){
      const schemeTypeData = employeeScheme.data?.employeeSubSchemes.filter((data:any) => data.schemeTypeId === schemeDb.id);
      // console.log("schemeTypeData", schemeTypeData);
      // console.log("batchDb", batchDb);

      if (schemeTypeData && schemeTypeData.length === 1){
        const batchesData = schemeTypeData[0].batches.filter((batch:any) => batch.employeeOrgBatchId === batchDb.id);
        // console.log("batchesData", batchesData);
        
        if (batchesData && batchesData.length === 1){
          const allocationData = batchesData[0].allocations.filter((allocation:any) => allocation.employeeOrgAlloctionId === allocationDb.id);
          // console.log("allocationData", allocationData);

          if (allocationData && allocationData.length === 1){
            if (response === "object"){
              return allocationData[0];
            }
          }
          else{
            return elseResponse;
          }
        }
        else{
          return elseResponse;
        }
      }
      else{
        return elseResponse;
      }
    }

    return null;
  }
  

  const getEmployeeNoSubSchemeData = (schemeDb:any) => {
    let elseResponse = null;

    const schemeTypeData = employeeScheme.data?.noSubschemeSchemeTypeDetailsResponses?.filter((data:any) => data.schemeTypeId === schemeDb.id);
    if (schemeTypeData && schemeTypeData.length === 1){
      return schemeTypeData[0];
    }

    return elseResponse;
  }


  const populateForm = () => {
    // console.log("company", company?.data);
    // console.log("employee", employee?.data);
    // console.log("employeeScheme", employeeScheme);

    if (company.data && employee.data){
      const {data: companyData} = company;
      const {data: employeeData} = employee;
      if (employeeData.id === id){

        const currentSchemeTypes = companyData.organizationShareSetting?.schemeTypes?.map((schemeType:any) => {
          return {
            id: schemeType.id,
            name: schemeType.name,
            selected: getEmployeeSchemeData("schemeType", "status", schemeType),
            hasSubSchemeAccess: schemeType.hasSubSchemeAccess,
            schemeDetail: {
              ...schemeType.schemeDetail,
              employeeNumberOfAllocation: getEmployeeNoSubSchemeData(schemeType)?.numberOfAllocation || "",
              employeeDateOfAllocation: dateFormatRemoveISO(getEmployeeNoSubSchemeData(schemeType)?.dateOfAllocation) || "",
            },
            subSchemes: [{
              id: schemeType.subSchemes[0]?.id,
              trustDeedObject: schemeType.subSchemes[0]?.trustDeedObject,
              batches: schemeType.subSchemes[0]?.batches.map((batch:any) => {
                return {
                  id: batch.id,
                  name: batch.name,
                  selected: getEmployeeSchemeData("batches", "status", schemeType, batch),
                  allocations: batch.allocations.map((allocation:any) => {
                    return {
                      id: allocation.id,
                      numberOfAllocation: allocation.numberOfAllocation,
                      dateOfAllocation: dateFormatRemoveISO(allocation.dateOfAllocation),
                      currentAllocationValue: allocation.currentAllocationValue,
                      employeeNumberOfAllocation: getEmployeeSchemeData("allocation", "object", schemeType, batch, allocation)?.numberOfAllocation || "",
                      employeeDateOfAllocation: dateFormatRemoveISO(getEmployeeSchemeData("allocation", "object", schemeType, batch, allocation)?.dateOfAllocation) || "",
                      percentageVesting: allocation.percentageVesting,
                      vestingPeriod: allocation.vestingPeriod,
                      period: allocation.period,
                      vestingDate: allocation.vestingDate,
                      schemeRuleObj: allocation.schemeRuleObj,
                    }
                  }),
                } 
              }) || [],
            }]
          }
        })

        // console.log("currentSchemeTypes", currentSchemeTypes);
        setSchemeTypes(currentSchemeTypes);
      }
    }
  }


  useEffect(() => {
    dispatch(companyAction.getCompany(companyId));
    dispatch(employeeAction.getEmployee(id));
    dispatch(employeeAction.getEmployeeScheme(id));
  }, []);

  
  useEffect(() => {
    populateForm();
  }, [company, employee, employeeScheme]);


  return (
    <div>

      <FormWizardNavBar showSteps={true} currentStep={3} totalSteps={6} closeUrl={navigationBaseUrl} />

      <div className="container pb-10 mt-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Scheme settings
          </div>
          <p className="mt-2 text-fade text-sm">
            Fill in employee scheme settings
          </p>
        </div>

        <div className="mt-12">
          <PageContent loading={companyLoading || employeeLoading || employeeSchemeLoading} data={employee.data || undefined} />

          <Maybe condition={companyLoading === false && company.data && Object.keys(company.data).length > 0}>
            <Maybe condition={employeeLoading === false && employee.data && Object.keys(employee.data).length > 0}>
              <Maybe condition={employeeSchemeLoading === false}>
                <form onSubmit={submitForm}>

                  <div className="flex flex-wrap">
                    <div className="w-full">
                      <label className="block">Type of allocation</label>
                      <span className="block text-fade text-sm mb-3">
                        Kindly select the applicable type of allocation
                      </span>
                      <ButtonGroupSelector selectOptions={getAllocationType()} allowValueChange={false} />
                    </div>
                  </div>

                  <div className="flex flex-wrap mt-8">
                    <div className="w-full">
                      <label className="block">Scheme type</label>
                      <span className="block text-fade text-sm mb-3">
                        Kindly select the applicable scheme type(s)
                      </span>
                      <ButtonGroupCheckbox
                        selectOptions={
                          schemeTypes.map((schemeType:any) => {
                            return {...schemeType, label: removeSchemeTypeNamePrefix(getCustomSchemeName(schemeType)), value: schemeType.id}
                          })
                        }
                        onValueChange={(e:any) => updateSchemeTypeSelection(e)}
                      />
                    </div>
                  </div>

                  <div className="flex flex-wrap mt-8">
                    <div className="w-full">
                      <label className="block">Sub scheme type</label>
                      <span className="block text-fade text-sm mb-3">
                        Kindly select the applicable sub-scheme type(s)
                      </span>

                      <div className="flex flex-col space-y-4">

                        <Maybe condition={schemeTypes.filter((schemeType:any) => schemeType.selected === true).length > 0}>
                          {schemeTypes.filter((schemeType:any) => schemeType.selected === true).map((schemeType:any, indexSchemeType:number) =>
                            <div key={indexSchemeType} className="p-4 sm:p-6 bg-vetiva-gold-lighter border border-dashed border-vetiva-brown rounded-lg">                      
                              <div className="font-bold">
                                {removeSchemeTypeNamePrefix(getCustomSchemeName(schemeType))}
                              </div>

                              <Maybe condition={schemeType.hasSubSchemeAccess === true}>
                                <Maybe condition={schemeType.subSchemes.length > 0 && schemeType.subSchemes[0]?.batches.length > 0}>
                                  <div className="mt-4 border border-gray-300 rounded-md">

                                    <div className="relative w-full min-w-[200px]">
                                      <ReactSelect
                                        options={getBatches(schemeType.subSchemes[0]?.batches)}
                                        placeholder="Select batches, groups, categories"
                                        styles={ReactSelectStyles}
                                        isMulti
                                        closeMenuOnSelect={schemeType.subSchemes[0]?.batches.length > 1 ? false : true}
                                        hideSelectedOptions={false}
                                        components={{Option}}
                                        onChange={(e:any) => updateBatchSelection(e, schemeType)}
                                        value={getBatches(schemeType.subSchemes[0]?.batches.filter((batch:any) => batch.selected === true))}
                                      />
                                    </div>

                                  </div>
                                  {schemeType.subSchemes[0]?.batches.filter((batch:any) => batch.selected === true).map((batch:any, indexBatch:number) =>
                                    <div key={indexBatch} className="mt-3 p-4 border border-dotted border-vetiva-brown rounded-lg">

                                      <div className="uppercase font-bold text-sm">
                                        {batch.name}
                                      </div>

                                      {batch.allocations.map((allocation:any, indexAllocation:number) =>
                                        <div key={indexAllocation} className="mt-6">
                                          <div>

                                            <div className="mt-2 flex flex-wrap sm:flex-nowrap space-y-6 sm:space-y-0 justify-between mb-6">
                                              <div className="w-full">
                                                <Input type="text" label="Number of allocation" value={allocation.employeeNumberOfAllocation}
                                                  onChange={(e:any) => updateSchemeTypeValues("employeeNumberOfAllocation", e.target.value, schemeType, batch, allocation)}
                                                />
                                                <div className="text-xs text-vetiva-brown mt-px ml-4">
                                                  {/* Value: {NumberFormat(allocation.numberOfAllocation, "no-kobo")} */}
                                                  {/* <br /> */}
                                                  Value: {NumberFormat(allocation.currentAllocationValue, "no-kobo")}
                                                </div>
                                              </div>
                                              <div className="w-6 flex-shrink-0 hidden sm:flex"></div>
                                              <div className="w-full">
                                                <Input type="date" label="Date of allocation" value={dateFormatRemoveISO(allocation.employeeDateOfAllocation)}
                                                  onChange={(e:any) => updateSchemeTypeValues("employeeDateOfAllocation", e.target.value, schemeType, batch, allocation)}
                                                />
                                                <div className="text-xs text-vetiva-brown mt-px ml-4">
                                                  {DateMiniFormat(allocation.dateOfAllocation)}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="mt-3 flex flex-wrap sm:flex-nowrap space-y-6 sm:space-y-0 justify-between mb-6">
                                              <div className="w-full">
                                                <Input label="Percentage for vesting" value={allocation.percentageVesting + "%"} disabled={true} />
                                              </div>
                                              <div className="w-6 flex-shrink-0 hidden sm:flex"></div>
                                              <div className="w-full">
                                                <Maybe condition={allocation.vestingPeriod === "Fixed period"}>
                                                  <Input label="Vesting period" value={"Every " + allocation.period + " " + pluralizeFull(allocation.period, "year", "years")} disabled={true} />
                                                </Maybe>
                                                <Maybe condition={allocation.vestingPeriod === "Recurring date"}>
                                                  <Input label="Vesting date" value={DateMiniFormat(allocation.vestingDate)} disabled={true} />
                                                </Maybe>
                                              </div>
                                            </div>

                                            <div className="mt-2 font-bold text-sm">
                                              Scheme rules
                                            </div>

                                            <div className="mt-2 bg-white rounded">
                                              <FileUploadPreview
                                                className="bg-white"
                                                file={allocation.schemeRuleObj ? allocation.schemeRuleObj[0] || undefined : undefined}
                                              />
                                            </div>
                                            
                                          </div>

                                          <Maybe condition={batch.allocations.length > 1}>
                                            <Maybe condition={indexAllocation+1 < batch.allocations.length}>
                                              <div className="my-6 border-b border-dotted border-vetiva-brown"></div>
                                            </Maybe>
                                          </Maybe>
                                        </div>
                                      )}

                                      {/* 
                                      <div>
                                        <div className="mt-2 font-bold text-sm">
                                          Trust Deed
                                        </div>
                                        <div className="mt-2 bg-white rounded">
                                          <FileUploadPreview
                                            className="bg-white"
                                            file={schemeType.subSchemes[0]?.trustDeedObject ? schemeType.subSchemes[0]?.trustDeedObject[0] || undefined : undefined}
                                          />
                                        </div>
                                      </div>
                                       */}

                                    </div>
                                  )}
                                </Maybe>

                                <Maybe condition={schemeType.subSchemes.length === 0 || schemeType.subSchemes[0]?.batches.length === 0}>
                                  <div className="page-info-italic my-4">
                                    No sub-schemes configured
                                  </div>
                                </Maybe>
                              </Maybe>

                              <Maybe condition={schemeType.hasSubSchemeAccess === false}>
                                <div className="mt-3 p-4 border border-dotted border-vetiva-brown rounded-lg">
                                  <div>
                                    <div>

                                      <div className="mt-2 flex flex-wrap sm:flex-nowrap space-y-6 sm:space-y-0 justify-between mb-6">
                                        <div className="w-full">
                                          <Input type="text" label="Number of allocation" value={schemeType.schemeDetail.employeeNumberOfAllocation}
                                            onChange={(e:any) => updateSchemeTypeValues("employeeNumberOfAllocation", e.target.value, schemeType)}
                                            />
                                          <div className="text-xs text-vetiva-brown mt-px ml-4">
                                            {/* Value: {NumberFormat(schemeType.schemeDetail.numberOfAllocation, "no-kobo")} */}
                                            {/* <br /> */}
                                            Value: {NumberFormat(schemeType.schemeDetail.currentAllocationValue, "no-kobo")}
                                          </div>
                                        </div>
                                        <div className="w-6 flex-shrink-0 hidden sm:flex"></div>
                                        <div className="w-full">
                                          <Input type="date" label="Date of allocation" value={dateFormatRemoveISO(schemeType.schemeDetail.employeeDateOfAllocation)}
                                            onChange={(e:any) => updateSchemeTypeValues("employeeDateOfAllocation", e.target.value, schemeType)}
                                            />
                                          <div className="text-xs text-vetiva-brown mt-px ml-4">
                                            {DateMiniFormat(schemeType.schemeDetail.dateOfAllocation)}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mt-3 flex flex-wrap sm:flex-nowrap space-y-6 sm:space-y-0 justify-between mb-6">
                                        <div className="w-full">
                                          <Input label="Percentage for vesting" value={schemeType.schemeDetail.percentageVesting + "%"} disabled={true} />
                                        </div>
                                        <div className="w-6 flex-shrink-0 hidden sm:flex"></div>
                                        <div className="w-full">
                                          <Maybe condition={schemeType.schemeDetail.vestingPeriod === "Fixed period"}>
                                            <Input label="Vesting period" value={"Every " + schemeType.schemeDetail.period + " " + pluralizeFull(schemeType.schemeDetail.period, "year", "years")} disabled={true} />
                                          </Maybe>
                                          <Maybe condition={schemeType.schemeDetail.vestingPeriod === "Recurring date"}>
                                            <Input label="Vesting date" value={DateMiniFormat(schemeType.schemeDetail.vestingDate)} disabled={true} />
                                          </Maybe>
                                        </div>
                                      </div>
                                       
                                      
                                    </div>
                                  </div>
                                </div>
                              </Maybe>

                            </div>
                          )}
                        </Maybe>

                        <Maybe condition={schemeTypes.filter((schemeType:any) => schemeType.selected === true).length === 0}>
                          <div className="page-info-italic py-10 bg-gray-100">
                            No scheme types selected
                          </div>
                        </Maybe>

                      </div>

                    </div>
                  </div>

                  <div className="mt-6 flex justify-end space-x-3">
                    <Link to={navigationBaseUrl + "/form/" + id + "/official"} className="btn btn-gold">
                      Back
                    </Link>
                    <ButtonLoader type="submit" loading={createEmployeeLoading3} className="btn btn-brown">
                      Continue
                    </ButtonLoader>
                  </div>

                </form>
              </Maybe>
            </Maybe>
          </Maybe>

        </div>
      </div>

    </div>
  );
}

export default CompanyEmployeesCreateSingleScheme;
