import Maybe from "./Maybe";
import { PageContent } from "./PageContent";
import NumberFormat from "utils/numberFormat";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface OverviewDashboardCompanyProps {
    name: string,
    loading: any,
    data: any,
    showDetailsButton?: boolean,
}


export const OverviewDashboardCompany = (props: OverviewDashboardCompanyProps) => {
    const {name, loading, data, showDetailsButton} = props;
    const renderDetailsButton = showDetailsButton !== undefined ? showDetailsButton : true;

    const getLoadingText = (name:string) => {
        return (<span>Loading<br />{name}<br />metrics</span>)
    }
    
    return (
        <div>

            <Maybe condition={loading === true}>
                <div className="px-6 py-10 mb-5 bg-white shadow-md rounded-lg">
                    <PageContent loading={loading} data={data.data || undefined} loadingText={getLoadingText(name)} emptyText="Info not found" errorText="Info not found" />
                </div>
            </Maybe>

            <Maybe condition={!loading && data.data !== undefined}>
                <div className="mb-5 block bg-white shadow-md rounded-lg">
                    <div className="p-4 text-sm flex justify-between border-b border-gray-200">
                        <div className="flex font-bold items-center">
                            {name}
                        </div>
                        <div>
                            {renderDetailsButton &&
                                <Link to={"/company/overview/scheme/" + data?.data?.schemeTypeId} className="btn btn-brown btn-sm">
                                    View details
                                    <FontAwesomeIcon icon="angle-right" className="ml-2" />
                                </Link>
                            }
                        </div>
                    </div>
                    <div className="p-4">
                        <div className="bg-[#f9f6f1] rounded-lg">
                            <div className="w-max h-28 p-2 flex items-center">
                                <div className="px-4 flex">
                                    <div className="mx-auto">
                                        <div className="text-sm font-bold text-gray-500">
                                            Total shares
                                        </div>
                                        <div className="text-2xl text-vetiva-brown">
                                            {NumberFormat(data.data?.totalSharesAllocated, "no-kobo") || "---"}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-4 lg:w-12 flex-shrink-0 text-gray-300 text-4xl font-normal flex items-center justify-center">/</div>
                                <div className="px-4 flex">
                                    <div className="mx-auto">
                                        <div className="text-sm font-bold text-gray-500">
                                            Shares allocated
                                        </div>
                                        <div className="text-2xl text-vetiva-brown">
                                            {NumberFormat(data.data?.totalSharesAllocatedToMembers, "no-kobo") || "---"}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-4 lg:w-12 flex-shrink-0 text-gray-300 text-4xl font-normal flex items-center justify-center">/</div>
                                <div className="px-6 flex">
                                    <div className="mx-auto">
                                        <div className="text-sm font-bold text-gray-500">
                                            Shares unallocated
                                        </div>
                                        <div className="text-2xl text-vetiva-brown">
                                            {NumberFormat(data.data?.totalSharesUnAllocatedToMembers, "no-kobo") || "---"}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-4 lg:w-12 flex-shrink-0 text-gray-300 text-4xl font-normal flex items-center justify-center">/</div>
                                <div className="px-4 flex">
                                    <div className="mx-auto">
                                        <div className="text-sm font-bold text-gray-500">
                                            Scheme Members
                                        </div>
                                        <div className="text-2xl text-vetiva-brown">
                                            {NumberFormat(data.data?.totalSchemeMembers, "no-kobo") || "---"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Maybe>

        </div>
    )
}
