import * as actions from "./actionTypes";
import * as config from "data/config";
import ObjectToQueryString from 'utils/ObjectToQueryString';

export function getCompaniesDashboardStats() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCompanyDashboardStats,
            method: "get",
            data: {},
            onStart: actions.GET_COMPANIES_DASHBOARD_STATS_START,
            onSuccess: actions.GET_COMPANIES_DASHBOARD_STATS,
            onError: actions.GET_COMPANIES_DASHBOARD_STATS_FAILED,
        },
    }
}

export function getCompanies(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCompany + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_COMPANIES_START,
            onSuccess: actions.GET_COMPANIES,
            onError: actions.GET_COMPANIES_FAILED,
        },
    }
}

export function getCompany(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCompany}/${id}`,
            method: "get",
            data: {},
            onStart: actions.GET_COMPANY_START,
            onSuccess: actions.GET_COMPANY,
            onError: actions.GET_COMPANY_FAILED,
        },
    }
}

export function createStep1(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCompany,
            method: "post",
            data: params,
            onStart: actions.CREATE_COMPANY_STEP_1_START,
            onSuccess: actions.CREATE_COMPANY_STEP_1,
            onError: actions.CREATE_COMPANY_STEP_1_FAILED,
            customHeaders: {'Content-Type':'multipart/form-data'},
        },
    }
}

export function createStep1Reset() {
    return {
        type: actions.CREATE_COMPANY_STEP_1_RESET,
        payload: {},
    }
}

export function createStep2(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCompany}/${id}/invite-admin`,
            method: "post",
            data: params,
            onStart: actions.CREATE_COMPANY_STEP_2_START,
            onSuccess: actions.CREATE_COMPANY_STEP_2,
            onError: actions.CREATE_COMPANY_STEP_2_FAILED,
        },
    }
}

export function createStep2Reset() {
    return {
        type: actions.CREATE_COMPANY_STEP_2_RESET,
        payload: {},
    }
}

export function createStep3(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCompany}/${id}/create-settings`,
            method: "post",
            data: params,
            onStart: actions.CREATE_COMPANY_STEP_3_START,
            onSuccess: actions.CREATE_COMPANY_STEP_3,
            onError: actions.CREATE_COMPANY_STEP_3_FAILED,
            customHeaders: {'content-type':'multipart/form-data'},
        },
    }
}

export function createStep3Reset() {
    return {
        type: actions.CREATE_COMPANY_STEP_3_RESET,
        payload: {},
    }
}

export function createStep31(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCompany}/${id}/create-settings`,
            method: "post",
            data: params,
            onStart: actions.CREATE_COMPANY_STEP_31_START,
            onSuccess: actions.CREATE_COMPANY_STEP_31,
            onError: actions.CREATE_COMPANY_STEP_31_FAILED,
            customHeaders: {'content-type':'multipart/form-data'},
        },
    }
}

export function createStep31Reset() {
    return {
        type: actions.CREATE_COMPANY_STEP_31_RESET,
        payload: {},
    }
}

export function activateCompany(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCompany}/${id}/activate`,
            method: "post",
            data: {},
            onStart: actions.ACTIVATE_COMPANY_START,
            onSuccess: actions.ACTIVATE_COMPANY,
            onError: actions.ACTIVATE_COMPANY_FAILED,
        },
    }
}

export function deactivateCompany(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCompany}/${id}/deactivate`,
            method: "post",
            data: {},
            onStart: actions.DEACTIVATE_COMPANY_START,
            onSuccess: actions.DEACTIVATE_COMPANY,
            onError: actions.DEACTIVATE_COMPANY_FAILED,
        },
    }
}

export function resendInvite(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiCompany}/${id}/resend-invite`,
            method: "post",
            data: {},
            onStart: actions.RESEND_INVITE_COMPANY_START,
            onSuccess: actions.RESEND_INVITE_COMPANY,
            onError: actions.RESEND_INVITE_COMPANY_FAILED,
        },
    }
}

export function getCompanyEmployeesDashboardStats(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCompanyEmployeeDashboardStats + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_COMPANY_EMPLOYEE_DASHBOARD_STATS_START,
            onSuccess: actions.GET_COMPANY_EMPLOYEE_DASHBOARD_STATS,
            onError: actions.GET_COMPANY_EMPLOYEE_DASHBOARD_STATS_FAILED,
        },
    }
}

export function getAdmins(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCompany + "/" + id + "/admins" + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_COMPANY_ADMIN_START,
            onSuccess: actions.GET_COMPANY_ADMIN,
            onError: actions.GET_COMPANY_ADMIN_FAILED,
        },
    }
}

export function getCompanyUploads(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCompanyUploads + id + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_COMPANY_UPLOADS_START,
            onSuccess: actions.GET_COMPANY_UPLOADS,
            onError: actions.GET_COMPANY_UPLOADS_FAILED,
        },
    }
}

export function companyUploadSales(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCompanyUploadSales + id,
            method: "post",
            data: params,
            onStart: actions.COMPANY_UPLOAD_SALES_START,
            onSuccess: actions.COMPANY_UPLOAD_SALES,
            onError: actions.COMPANY_UPLOAD_SALES_FAILED,
        },
    }
}

export function companyUploadSalesReset() {
    return {
        type: actions.COMPANY_UPLOAD_SALES_RESET,
        payload: {},
    }
}

export function companyUploadBonus(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCompanyUploadBonus + id,
            method: "post",
            data: params,
            onStart: actions.COMPANY_UPLOAD_BONUS_START,
            onSuccess: actions.COMPANY_UPLOAD_BONUS,
            onError: actions.COMPANY_UPLOAD_BONUS_FAILED,
        },
    }
}

export function companyUploadBonusReset() {
    return {
        type: actions.COMPANY_UPLOAD_BONUS_RESET,
        payload: {},
    }
}

export function companyUploadDividend(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            // url: config.apiCompanyUploadDividend + id,
            url: config.apiCompanyUploadDividend,
            method: "post",
            data: params,
            onStart: actions.COMPANY_UPLOAD_DIVIDEND_START,
            onSuccess: actions.COMPANY_UPLOAD_DIVIDEND,
            onError: actions.COMPANY_UPLOAD_DIVIDEND_FAILED,
        },
    }
}

export function companyUploadDividendReset() {
    return {
        type: actions.COMPANY_UPLOAD_DIVIDEND_RESET,
        payload: {},
    }
}

export function companyUploadTransfer(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCompanyUploadTransfer + id,
            method: "post",
            data: params,
            onStart: actions.COMPANY_UPLOAD_TRANSFER_START,
            onSuccess: actions.COMPANY_UPLOAD_TRANSFER,
            onError: actions.COMPANY_UPLOAD_TRANSFER_FAILED,
        },
    }
}

export function companyUploadTransferReset() {
    return {
        type: actions.COMPANY_UPLOAD_TRANSFER_RESET,
        payload: {},
    }
}
