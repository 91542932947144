

export const getPageNumber = (url: string | null) => {

  if (url && url !== ""){
    const index = url.indexOf("?")
    const query = url.substring(index);
    const params = new URLSearchParams(query); 
    const PageNumber = params.get('PageNumber');
    return PageNumber;
  }

  return null;
  
}

export default getPageNumber;