import { Link, useParams } from "react-router-dom";
import { FormWizardNavBar } from "components/form-wizard-navbar";
import iconHome from "assets/images/icons/home.svg";
import useLoginInfo from "hooks/useLoginInfo";

export interface CompanyEmployeesCreateOptionsProps {}

export function CompanyEmployeesCreateOptions(props: CompanyEmployeesCreateOptionsProps) {
  let { companyId } = useParams();
  let navigationBaseUrl = "/trustee/company/"+companyId;
  const {userInfo} = useLoginInfo();
  if (userInfo.userType === "SCHEME_COMMITTEE"){
    companyId = userInfo.organizationId;
    navigationBaseUrl = "/company/employees"
  }

  return (
    <div>

      <FormWizardNavBar showSteps={false} closeUrl={navigationBaseUrl} />

      <div className="container py-10 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="text-vetiva-black text-2xl tracking-tight">
            Select the mode to add Employee(s)
          </div>
          <p className="mt-2 text-fade text-sm">
            Select your preferred mode to add Employee(s)
          </p>
        </div>

        <div className="mt-12 mx-auto grid grid-cols-2 gap-6 sm:gap-10 max-w-md">
          
          <Link to={navigationBaseUrl+"/create/personal"} className="shadow-md px-4 py-8 rounded-md hover:bg-gray-100">
            <div>
              <img src={iconHome} alt="home" />
            </div>
            <div className="mt-4 text-lg">
              Single creation
            </div>
            <div className="mt-1 text-sm text-fade">
              Select to add employees individually
            </div>
          </Link>

          <Link to={navigationBaseUrl+"/create/bulk/upload"} className="shadow-md px-4 py-8 rounded-md hover:bg-gray-100">
            <div>
              <img src={iconHome} alt="home" />
            </div>
            <div className="mt-4 text-lg">
              Bulk upload
            </div>
            <div className="mt-1 text-sm text-fade">
              Select to upload all employees
            </div>
          </Link>

        </div>
      </div>

    </div>
  );
}

export default CompanyEmployeesCreateOptions;
