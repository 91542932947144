import { useState } from "react";
import { Link } from "react-router-dom";
import useUrlNavigation from "hooks/useUrlNavigation";

/* eslint-disable-next-line */
export interface TabBarToggleOptions {
  name: string,
  urlKey: string,
  urlValue: string,
  active?: boolean,
}

export interface TabBarToggleProps {
  data: TabBarToggleOptions[],
}

export function TabBarToggle(props: TabBarToggleProps) {
  const {urlQuery} = useUrlNavigation();
  

  const [data, setData] = useState(props.data);
  

  const getActiveIndicator = (option:TabBarToggleOptions) => {
    if (option.active === true){
      return "bg-white";
    }
    return "bg-transparent";
  }


  const switchActive = (index:number) => {
    setData(
      data.map((dataEach, i) => {
        if (index === i) {
          return { ...dataEach, active: true };
        }
        return { ...dataEach, active: false };
      })
    );
  }

  
  return (
    <div className="flex bg-gray-200 p-1 rounded">
      {data.map((option, index) =>
        <Link
          key={index}
          to={urlQuery(option.urlKey + "=" + option.urlValue)}
          onClick={() => switchActive(index)}
          className={"px-4 py-1 text-gray-400 rounded " + getActiveIndicator(option)}
        >
          {option.name}
        </Link>
      )}
    </div>
  );
}

export default TabBarToggle;
