import * as actions from "./actionTypes";

const initialState = {
    selections: [],
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.SAVE_SELECTION:
            state = {
                ...state,
                selections: [...action.payload],
            }
            return state;


        default:
            return state;
    }
}