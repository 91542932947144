
export const GET_DASHBOARD_STATS = "GET_DASHBOARD_STATS";
export const GET_DASHBOARD_STATS_START = "GET_DASHBOARD_STATS_START";
export const GET_DASHBOARD_STATS_FAILED = "GET_DASHBOARD_STATS_FAILED";

export const GET_SHARE_DISTRIBUTION = "GET_SHARE_DISTRIBUTION";
export const GET_SHARE_DISTRIBUTION_START = "GET_SHARE_DISTRIBUTION_START";
export const GET_SHARE_DISTRIBUTION_FAILED = "GET_SHARE_DISTRIBUTION_FAILED";

export const GET_SCHEME_METRIC_AWARDS = "GET_SCHEME_METRIC_AWARDS";
export const GET_SCHEME_METRIC_AWARDS_START = "GET_SCHEME_METRIC_AWARDS_START";
export const GET_SCHEME_METRIC_AWARDS_FAILED = "GET_SCHEME_METRIC_AWARDS_FAILED";

export const GET_SCHEME_METRIC_OPTIONS = "GET_SCHEME_METRIC_OPTIONS";
export const GET_SCHEME_METRIC_OPTIONS_START = "GET_SCHEME_METRIC_OPTIONS_START";
export const GET_SCHEME_METRIC_OPTIONS_FAILED = "GET_SCHEME_METRIC_OPTIONS_FAILED";

export const GET_SCHEME_METRIC_PURCHASE = "GET_SCHEME_METRIC_PURCHASE";
export const GET_SCHEME_METRIC_PURCHASE_START = "GET_SCHEME_METRIC_PURCHASE_START";
export const GET_SCHEME_METRIC_PURCHASE_FAILED = "GET_SCHEME_METRIC_PURCHASE_FAILED";

export const GET_SCHEME_METRIC_LTIP = "GET_SCHEME_METRIC_LTIP";
export const GET_SCHEME_METRIC_LTIP_START = "GET_SCHEME_METRIC_LTIP_START";
export const GET_SCHEME_METRIC_LTIP_FAILED = "GET_SCHEME_METRIC_LTIP_FAILED";

export const GET_SCHEME_METRIC_OTHERS = "GET_SCHEME_METRIC_OTHERS";
export const GET_SCHEME_METRIC_OTHERS_START = "GET_SCHEME_METRIC_OTHERS_START";
export const GET_SCHEME_METRIC_OTHERS_FAILED = "GET_SCHEME_METRIC_OTHERS_FAILED";

export const RESET_SCHEME_METRIC = "RESET_SCHEME_METRIC";
